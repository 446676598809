/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable no-return-assign */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Virtuoso } from 'react-virtuoso';
import { useTranslation } from 'react-i18next';
import { Combobox } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid';
import { BuildingLibraryIcon } from '@heroicons/react/24/outline';
import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { Auth, API } from 'aws-amplify';
import Dropdown from '../../../elements/Dropdown';
import countryNames, { countryCodes } from '../../../misc/countries';
import MiniSpinner from '../../../misc/MiniSpinner';
import Button from '../../../elements/Button';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

function getCountryCode(countryNameInput) {
  const index = countryNames.findIndex((item) => item === countryNameInput);
  return countryCodes[index];
}

async function prepCallToApi(body) {
  const session = await Auth.currentSession();
  const myInit = {
    body,
    headers: {
      'Content-Type': 'application/json',
      Authorization: session.idToken.jwtToken,
    },
  };
  return myInit;
}

function YourSelection({ item }) {
  return (
    <div className="flex items-center cursor-default select-none border shadow-md bg-white rounded-md p-4">
      {item.logo ? (
        <img src={item.logo} alt="" className="flex h-10 w-10 flex-none items-center justify-center rounded-lg" />
      ) : (
        <div className={classNames('flex h-10 w-10 flex-none items-center justify-center rounded-lg', 'bg-brandBlue-500')}>
          <BuildingLibraryIcon className="h-6 w-6 text-white" aria-hidden="true" />
        </div>
      )}
      <div className="ml-4 flex-auto">
        <p className={classNames('text-lg font-bold text-gray-700')}>{item.providerInstitutionName}</p>
        <p className={classNames('text-md sm:text-base text-gray-500')}>
          {/* eslint-disable-next-line max-len */}
          {`${item.bic ? `BIC: ${item.bic}` : ''}${item.bic && (item.blz || item.city) ? '  |  ' : ''}${item.blz ? `BLZ: ${item.blz}` : ''}${item.blz && item.city ? '  |  ' : ''}${
            item.city ? `${item.city}` : ''
          }`}
        </p>
      </div>
    </div>
  );
}

function Row({ index, style, data }) {
  const item = data[index];

  return (
    <Combobox.Option key={item.id} value={item} style={style} className={({ active }) => classNames('flex items-center cursor-default select-none rounded-md py-3', active && 'bg-gray-100')}>
      {({ active }) => (
        <>
          {item.logo ? (
            <img src={item.logo} alt="" className="flex h-10 w-10 flex-none items-center justify-center rounded-lg" />
          ) : (
            <div className={classNames('flex h-10 w-10 flex-none items-center justify-center rounded-lg', 'bg-brandBlue-500')}>
              <BuildingLibraryIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </div>
          )}
          <div className="ml-4 flex-auto">
            <p className={classNames('text-sm font-medium truncate', active ? 'text-gray-900' : 'text-gray-700')}>{item.providerInstitutionName}</p>
            <p className={classNames('text-xs sm:text-sm', active ? 'text-gray-700' : 'text-gray-500')}>
              {/* eslint-disable-next-line max-len */}
              {`${item.bic ? `BIC: ${item.bic}` : ''}${item.bic && (item.blz || item.city) ? '  |  ' : ''}${item.blz ? `BLZ: ${item.blz}` : ''}${item.blz && item.city ? '  |  ' : ''}${
                item.city ? `${item.city}` : ''
              }`}
            </p>
          </div>
        </>
      )}
    </Combobox.Option>
  );
}

function ManualButton({ selectedInstitution, handleManualButton, category }) {
  const { t } = useTranslation('app', { keyPrefix: 'addAccount.D3' });
  return (
    <div className="col-start-2 col-span-2 mt-6 flex flex-col items-center justify-center">
      {selectedInstitution && selectedInstitution === 'manual' ? (
        <div className="text-center prose mb-4">
          <p>{t('thisAccountManual')}</p>
          <p>{t('thisAccountManual2', { anInstitutionType: category === 'deposits' ? t('aBank') : t('aBroker') })}</p>
          <p>{t('thisAccountManual3')}</p>
        </div>
      ) : (
        <Button text={t('addManualAccount', { anInstitutionType: category === 'deposits' ? t('aBank') : t('aBroker') })} onClick={handleManualButton} disabled={false} className="mt-8" />
      )}
    </div>
  );
}

function SelectProvider({ userInput, setUserInput, workflowStepStatus, updateWorkflowStatus, index, setMessageToShowBeforeNext, category }) {
  const [query, setQuery] = useState('');
  const [comboboxDisabled, setComboboxDisabled] = useState(true); // manages combobox disabled state
  const [loading, setLoading] = useState(false);
  const [selectedInstitution, setSelectedInstitution] = useState(null); // bank selected from the list
  const [list, setList] = useState([]);
  const [nothingFound, setNothingFound] = useState(null); // 'nothingFound' when no institutions are found, 'noSlots' when there are no more slots for foreign automated accounts (different UI message)

  const { t } = useTranslation('app', { keyPrefix: 'addAccount.D3' });
  const { t: tApp } = useTranslation('app');

  function resetFurtherSteps() {
    updateWorkflowStatus(index, undefined); // step D2 reset
    updateWorkflowStatus(index + 1, undefined); // step D3 reset
    updateWorkflowStatus(index + 2, undefined); // step D4 reset
  }

  function handleManualButton() {
    setUserInput((prev) => ({ ...prev, syncType: 'manual', provider: undefined }));
    resetFurtherSteps();
    setSelectedInstitution('manual');
    setMessageToShowBeforeNext(null);
    updateWorkflowStatus(index, true);
  }

  // handles changes to the selectedInstitution object (when user clicks an item in the dropdown or the manual bank button)
  useEffect(() => {
    // if selection is set, set userInput to selection; country has alredy been set automatically by the dropdown
    if (selectedInstitution !== 'manual' && selectedInstitution !== null) {
      setUserInput({ ...userInput, syncType: 'automatic', provider: selectedInstitution });
      if (workflowStepStatus[index + 1] || workflowStepStatus[index + 2]) {
        resetFurtherSteps();
      } // handles case when user came back from step 4 and changed the bank
      updateWorkflowStatus(index, true);
    }
  }, [selectedInstitution]);

  function handleCountryDropdownChange(newCountry) {
    let apiPromise;
    let abortToken = false;
    async function runGetInstitutions() {
      // if there was already a selected country and the user selected a new one now, reset the form
      if (newCountry && newCountry !== t('selectCountry')) {
        setComboboxDisabled(true);
        setSelectedInstitution(null);
        setLoading(true);
        resetFurtherSteps(); // if this runs
        setUserInput({
          ...userInput,
          country: newCountry,
          countryCode: getCountryCode(newCountry), // category passed to API to filter results
          syncType: null, // we need to reset the syncType and provider, in case the user previously selected a manual account
          provider: undefined,
        });
        // check if there are any slots left for foreign automated accounts
        const user = await Auth.currentAuthenticatedUser({ bypassCache: true }); // force-refresh the user attributes
        const exDeDepositSlots = JSON.parse(user.attributes['custom:ex_de_deposit_slots']);
        // if there are no nulls left, set nothingFound to 'noSlots'
        if (newCountry !== 'Germany' && exDeDepositSlots.every((i) => i !== null)) setNothingFound('noSlots');
        else {
          // prepare apiPromise and get data from API
          const requestPayload = await prepCallToApi({ country: getCountryCode(newCountry), category });
          let institutions = {};
          try {
            setList([]); // reset list (otherwise old list will be shown while new one is loading
            setNothingFound(null); // reset nothingFound
            if (abortToken) return;
            apiPromise = API.post('myAPI', 'deposits/institutions', requestPayload);
            // get institutions from API
            institutions = await apiPromise;
            // for TEST stages only (this is a terrible manual hack, but what can we do)
            if (process.env.REACT_APP_ENV_SUFFIX !== 'prod' && newCountry !== 'Germany' && institutions.length > 0) {
              institutions.push({
                id: 'SANDBOXFINANCE_SFIN0000',
                name: 'GoCardless Test Bank',
                bic: 'SFIN0000',
                logo: 'https://cdn.nordigen.com/ais/SANDBOXFINANCE_SFIN0000.png',
                provider: 'gocardless',
              });
            }
            if (process.env.REACT_APP_ENV_SUFFIX !== 'prod' && newCountry === 'Germany' && institutions.length > 0) {
              institutions.push({
                id: '280024',
                name: 'FinAPI MockBank',
                bic: 'MOCKDEXXXXX',
                logo: 'https://cdn.finapi.io/assets/images/banks-2024.18.1/logos/DE_MOCKBANK/default.svg',
                provider: 'finapi',
              });
            }
            if ((institutions || []).length === 0) {
              setNothingFound('nothingFound'); // displays the UI message
              handleManualButton(); // if no institutions are found, set the manual account automatically, the user does not have to click the button
            } else {
              setList((institutions || []).map(({ id, name, ...i }) => ({ ...i, providerInstitutionId: id, providerInstitutionName: name })));
            }
          } catch (error) {
            // Handle the error appropriately
            console.error('Error fetching institutions:', error);
            setNothingFound('nothingFound');
          }
        }
        setLoading(false);
        setComboboxDisabled(false);
      }
    }

    runGetInstitutions();

    return function cleanupGetInstitutions() {
      abortToken = true;
      setLoading(false);
      API.cancel(apiPromise);
    };
  }

  // run handleCountryDropdownChange when the component mounts
  useEffect(() => {
    handleCountryDropdownChange(userInput.country);
  }, []);

  // this is the search filter
  const filteredItems = query === ''
    ? list.filter((r) => r.blz !== 'DEMO0001' && r.bic !== 'HYVEDEMM488')
    : list.filter((institution) => {
      const q = query.toLowerCase();
      const name = (institution.providerInstitutionName || '').toLowerCase();
      const city = (institution.city || '').toLowerCase();
      const blz = (institution.blz || '').toLowerCase();
      const bic = (institution.bic || '').toLowerCase();
      return name.includes(q) || city.includes(q) || blz.includes(q) || bic.includes(q);
    });

  const spaceForDropdown = window.innerHeight - 525;

  const selectCountry = t('selectCountry'); // translation to be displayed as the first item in the country dropdown
  const countryList = [selectCountry].concat(countryNames.map((item) => tApp(item)));

  const yourInstitutionType = category === 'stocks' ? t('yourBroker') : t('yourBank');

  return (
    <div className="space-y-6 md:space-y-0 md:grid md:grid-cols-4 md:gap-6">
      <div id="select-provider-country-header" className="">
        <h3 className="text-xl md:text-2xl font-bold leading-6 text-gray-900">{t('accountCountry')}</h3>
        <p className="mt-4 text-sm text-gray-500">{t('accountCountryBody')}</p>
      </div>
      <div id="select-provider-country-dropdown" className="mt-5 md:mt-0 col-span-3 lg:col-span-2 sm:grid sm:grid-cols-2 md:gap-6 relative z-30">
        <div className="mt-4 md:mt-0 col-span-3 xl:col-span-2 text-sm md:text-base">
          <Dropdown list={countryList} label={t('accountCountry')} value={userInput.country || selectCountry} onChange={handleCountryDropdownChange} optional={false} />
        </div>
      </div>
      {/* SEE BANK LIST */}
      <div className="col-start-1 mt-8">
        <h3 className="mt-2 text-xl md:text-2xl font-bold leading-6 text-gray-900">{t('select', { yourInstitutionType })}</h3>
        {userInput.syncType !== 'automatic' ? (
          <>
            {' '}
            <p className="mt-4 text-sm text-gray-500">{t('findYourBank', { yourInstitutionType })}</p>
            <p className="mt-4 text-sm text-gray-500">{t('cannotFind', { yourInstitutionType })}</p>
          </>
        ) : (
          <p className="mt-4 text-sm text-gray-500">{t('intelligentImport')}</p>
        )}
      </div>
      <div className="z-20 mt-8 col-span-3 xl:col-span-2 relative">
        {!loading && !comboboxDisabled && selectedInstitution && selectedInstitution !== 'manual' && (
          <div className="absolute inset-x-0 top-16 md:top-20 col-start-2 col-span-2 flex justify-center mt-12">
            <div className="flex flex-col gap-4 items-center">
              <span className="text-lg sm:text-xl font-bold">{t('youHaveSelected')}</span>
              <YourSelection item={selectedInstitution} />
              <div className="w-full mt-2 md:mt-10" />
              <ManualButton handleManualButton={handleManualButton} selection={selectedInstitution} category={category} />
            </div>
          </div>
        )}
        <Combobox
          as="div"
          value={selectedInstitution}
          onChange={setSelectedInstitution} // executes this function with value of the selected Combobox.Option
          className="transform overflow-hidden rounded-md bg-white shadow-md ring-1 ring-black ring-opacity-5 transition-all min-h-[10rem]"
        >
          {({ open }) => (
            <div className="px-2 xs:px-4">
              <div className="relative">
                <MagnifyingGlassIcon className="pointer-events-none absolute top-3.5 left-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                <Combobox.Input
                  className="h-12 w-full border-0 bg-transparent pl-8 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                  placeholder={`${t('search')}...`}
                  displayValue={(item) => item && item.providerInstitutionName} // takes whatever is the value of Combobox as parameter and displays the returned value
                  onChange={(event) => setQuery(event.target.value)}
                />
              </div>

              {/* open is a render prop which tells us if the component would be open should it be controlled automatically */}
              {(selectedInstitution === null || open) && filteredItems.length > 0 && (
                <div>
                  <Combobox.Options static>
                    <Virtuoso
                      style={{ height: spaceForDropdown }}
                      totalCount={filteredItems.length}
                      // eslint-disable-next-line react/no-unstable-nested-components
                      itemContent={(idx) => <Row index={idx} data={filteredItems} />}
                    />
                  </Combobox.Options>
                </div>
              )}
              {query !== '' && filteredItems.length === 0 && (
                <div className="border-t border-gray-100 py-14 px-6 text-center text-sm sm:px-14">
                  <p className="mt-4 font-semibold text-gray-900">{t('noResultsFound')}</p>
                  <p className="mt-2 text-gray-500">{t('couldNotFindAnything')}</p>
                </div>
              )}
            </div>
          )}
        </Combobox>
        {/* gray out search area before country has been selected and the results have been returned */}
        {comboboxDisabled && (
          <div className="absolute inset-0 min-h-[12rem] bg-gray-300 text-gray-900 opacity-70 flex justify-center items-center sm:rounded-md" aria-hidden="true">
            {loading ? (
              <div className="flex flex-col items-center">
                <MiniSpinner className="h-6 w-6 animate-spin text-gray-700 mb-4" />
                {t('retrievingInstitutions')}
              </div>
            ) : (
              <>
                <InformationCircleIcon className="h-6 w-6 text-brandBlue-600 mr-1.5" aria-hidden="true" />
                {t('selectCountryFirst')}
              </>
            )}
          </div>
        )}
        {!comboboxDisabled && nothingFound === 'nothingFound' && (
          <div className="absolute inset-0 bg-gray-300 text-gray-900 opacity-70 p-2 flex justify-center items-center sm:rounded-md" aria-hidden="true">
            <InformationCircleIcon className="h-6 w-6 text-brandBlue-600 mr-1.5 flex-shrink-0" aria-hidden="true" />
            <span className="prose text-sm sm:text-base">{t('nothingFound', { yourInstitutions: category === 'deposits' ? t('banks') : t('brokers') })}</span>
          </div>
        )}
        {!comboboxDisabled && nothingFound === 'noSlots' && (
          <div className="absolute inset-0 bg-gray-300 text-gray-900 opacity-70 p-2 flex justify-center items-center sm:rounded-md" aria-hidden="true">
            <InformationCircleIcon className="h-6 w-6 text-brandBlue-600 mr-1.5 flex-shrink-0" aria-hidden="true" />
            <span className="prose text-sm sm:text-base">{t('noSlots', { yourInstitutions: category === 'deposits' ? t('banks') : t('brokers') })}</span>
          </div>
        )}
      </div>
      {/* this button's function is to give user the choice automatic vs manual when there are automatic options */}
      {!comboboxDisabled && (selectedInstitution === null || selectedInstitution === 'manual') && (
        <ManualButton handleManualButton={handleManualButton} selectedInstitution={selectedInstitution} category={category} />
      )}
    </div>
  );
}

SelectProvider.propTypes = {
  userInput: PropTypes.object,
  setUserInput: PropTypes.func,
  workflowStepStatus: PropTypes.array.isRequired,
  updateWorkflowStatus: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
};
SelectProvider.defaultProps = {
  userInput: {},
  setUserInput: {},
};
export default SelectProvider;
