/**
 * When it detects a formula which is present in most of the rows below the headers (90%), it returns a formula with offset-style arguments to command history,
 * so that it may be reapplied by ColumnMatcher in the import process (as pretransformation).
 * The formula is taken from the middle row of the column (to make sure we don't use an edge case from the first or last row), and references are replaced with relative offsets.
 * At present this does not check if the formulas are the same (by design), as this is meant to re-apply the formula to the column as a whole during pretransformation.
 * If the formulas are different, there is nothing we can do automatically.
 *
 * Note: this function operates on what is displayed to user in Grid, i.e. row 0 is already the header row. It also assumes there are no hidden columns, because this is edititng ingested files.
 *
 * If this function returns a string, then it should be added to the commands array in the importFileMappings object.
 * If it returns null, it means no valid formula was found or the 90% condition was not met.
 *
 * @param {obj} instance
 * @param {number} x - 0-based column index
 * @returns {string|null} - formula with offset-style arguments (e.g. ""=SUM(§#§[-2,0]§#§,§#§[-1,0]§#§)"), or null if no valid formula is found or 90% condition is not met
 */
export default function addFormulaToCommands(instance, x) {
  const totalRows = instance.getData().filter((row) => row.join('').length > 0).length; // Get total number of rows with data
  let formulaCount = 0;

  // Iterate through the rows to check for formulas in the current column (start with the one below headers)
  for (let i = 0; i < totalRows; i += 1) {
    const cellValue = instance.options.data[i][x];
    // const cellValue = instance.getValueFromCoords(x, i);
    if (cellValue && cellValue.startsWith('=')) {
      formulaCount += 1;
    }
  }

  // Check if 75% of cells in the column have formulas
  const threshold = Math.floor(totalRows * 0.75);
  if (formulaCount >= threshold) {
    const middleRow = Math.floor(totalRows / 2); // Get middle row number
    const middleFormula = instance.options.data[middleRow][x];

    if (middleFormula.startsWith('=')) {
      // Parse and replace references with relative offsets
      const replacedFormula = middleFormula.replace(/([A-Z]+)([0-9]+)/g, (match, col, row) => {
        const colOffset = col.charCodeAt(0) - 'A'.charCodeAt(0) - x; // Calculate column offset
        const rowOffset = parseInt(row, 10) - (middleRow + 1); // Calculate row offset

        const colReference = colOffset === 0 ? '0' : `${colOffset > 0 ? '+' : ''}${colOffset}`;
        const rowReference = rowOffset === 0 ? '0' : `${rowOffset > 0 ? '+' : ''}${rowOffset}`;

        return `§#§[${colReference},${rowReference}]§#§`;
      });

      console.log('DEBUG: replacedFormula', replacedFormula, 'adding to history');
      return replacedFormula;
    }
  }

  console.log('DEBUG: no valid formula found or 75% condition not met');
  return null; // If no valid formula is found or 75% condition is not met
}
