import React, { useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid';
import { RadioGroup } from '@headlessui/react';

/**
 * This component is displayed to user if the system detects that the imported file is from rvuebersicht.de.
 * It displays all products available in the file and allows the user to select one.
 */
export default function ColumnMatcherRVUebersicht({ inputFileObject, callback }) {
  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.pension.import' });
  const [selection, setSelection] = useState(null);

  function handleIgnore() {
    window.dispatchEvent(new CustomEvent('setDialog', {}));
  }

  function handleOK() {
    if (!selection) return;

    callback(selection.row);
    handleIgnore();
  }

  const options = inputFileObject.slice(1).map((row, index) => ({
    row: index + 1,
    name: row[10],
    accountNumber: row[11],
    productType: row[12],
    productSubtype: row[13],
  }));

  return (
    <div className="mx-auto max-w-3xl p-4 overflow-hidden rounded-lg bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
      <div>
        <QuestionMarkCircleIcon className="mx-auto flex items-center justify-center h-12 w-12 text-brandBlue-500" aria-hidden="true" />
        <div className="mt-3 text-center sm:mt-5">
          <h3 className="text-lg leading-6 font-bold text-gray-900">{t('title')}</h3>
          <p className="text-center mx-auto mt-2 text-sm text-gray-500 prose">{t('prompt')}</p>
          <RadioGroup value={selection} by="row" onChange={setSelection} className="mt-4 p-2 gap-4 flex content-stretch overflow-x-auto">
            {options.map((o) => (
              <RadioGroup.Option value={o} key={o.row}>
                {({ checked }) => (
                  <button
                    className={`border border-gray-100 shadow p-2 w-60 rounded-md text-left h-full space-y-1 hover:bg-gray-50 cursor-pointer ${checked ? 'ring ring-brandBlue-500 ring-offset-1' : ''}`}
                    type="button"
                  >
                    <p className="font-semibold leading-tight">{o.name}</p>
                    <p className="text-sm">{`${o.productType} ${o.productSubype || ''}`}</p>
                    <p className="text-sm">{o.accountNumber}</p>
                  </button>
                )}
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </div>
      </div>

      {/* TODO
      - horizontal scroll bar
      */}

      <div className="mt-7 sm:mt-8 grid grid-cols-2 gap-2">
        <button
          type="button"
          name="dialog-cancel-button"
          id="dialog-form-cancel-button"
          // eslint-disable-next-line max-len
          className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-500 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-400 sm:text-sm"
          onClick={handleIgnore}
        >
          {t('ignore')}
        </button>
        <button
          type="button"
          name="dialog-save-button"
          id="dialog-form-save-button"
          // eslint-disable-next-line max-len
          className="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-brandBlue-500 text-base font-medium text-white hover:bg-brandBlue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-400 sm:text-sm"
          onClick={handleOK}
        >
          OK
        </button>
      </div>
    </div>
  );
}
ColumnMatcherRVUebersicht.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  inputFileObject: propTypes.array.isRequired,
  callback: propTypes.func.isRequired,
};
