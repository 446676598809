/* eslint-disable no-await-in-loop */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { React, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Auth, API } from 'aws-amplify';
// eslint-disable-next-line camelcase
// import { read, utils, set_cptable } from 'xlsx';
// import * as cptable from 'xlsx/dist/cpexcel.full.mjs';
import dayjs from 'dayjs';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import { Base64 } from 'js-base64';
import ToolTipNoIcon from './ToolTipNoIcon';
import { setMessage } from '../redux/actions/message';
import MiniSpinner from '../misc/MiniSpinner';
import Button from './Button';

const { read, utils } = require('xlsx');
// set_cptable(cptable);

const PDF = 'application/pdf';
const XLSX = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const XLS = 'application/vnd.ms-excel';
const CSV = 'text/csv';
const JPEG = 'image/jpeg';
const JPG = 'image/jpg';
const PNG = 'image/png';

function processCsvXlsxFile(arrayBufferObject) {
  const fileRead = read(arrayBufferObject, {
    raw: true,
    cellText: false,
    cellDates: true,
    codepage: 65001,
    dateNF: 'dd/mm/yyyy',
  });
  if (fileRead && fileRead.Sheets && fileRead.Sheets[fileRead.SheetNames[0]] && Object.keys(fileRead.Sheets[fileRead.SheetNames[0]]).length > 0) {
    return utils.sheet_to_json(fileRead.Sheets[fileRead.SheetNames[0]], { header: 1, raw: false });
  }
  return null;
}

function getTypeForFileName(fileName) {
  if (fileName.match(/\.csv$/i)) return CSV;
  if (fileName.match(/\.xlsx$/i)) return XLSX;
  if (fileName.match(/\.xls$/i)) return XLS;
  if (fileName.match(/\.pdf$/i)) return PDF;
  if (fileName.match(/\.jpg$/i)) return JPG;
  if (fileName.match(/\.jpeg$/i)) return JPEG;
  if (fileName.match(/\.png$/i)) return PNG;
  return null;
}

// convert from base64-string to FileList (FileList is what input element of type 'file' returns)
// expects { fileData: <file as base64-string>, fileName: <file name> }
// returns FileList with one file
export function convertToFileList({ fileData, fileName }) {
  // browser version
  if (typeof window !== 'undefined' && typeof window.atob === 'function') {
    // In a browser environment
    const binaryString = window.atob(fileData);
    const byteNumbers = new Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i += 1) {
      byteNumbers[i] = binaryString.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const fileBlob = new Blob([byteArray], { type: getTypeForFileName(fileName) });

    const file = new File([fileBlob], fileName, { type: getTypeForFileName(fileName) });
    const dataTransfer = new DataTransfer();
    dataTransfer.items.add(file);

    return dataTransfer.files;
  }
  throw new Error('Unable to decode base64 string');
}

// use as test function in browser env (run runConvert() in console)
// function runConvert() {
//   const result = convertToFileList({ fileData: base64str, fileName: 'test.pdf' });
//   console.log('runConvert result', result);
// }
// window.runConvert = runConvert;

// Example usage:
// const fileList = createFileListFromBase64(base64Data, 'example.txt');

export default function SelectFile({ account, setDisplayedComponent, fileReceived, setIngested, setFileNames }) {
  const [fileList, setFileList] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [emptyFiles, setEmptyFiles] = useState([]);
  const [timestamp, setTimestamp] = useState(undefined);
  const [delayInfo, setDelayInfo] = useState(undefined);

  const debugLevel = process.env.REACT_APP_MDEBUG || 3;

  const dispatch = useDispatch();
  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.buttons' });

  console.log('rendering DialogSelectFile, fileName is', fileReceived.fileName, 'fileData.length is', fileReceived.fileData?.length);

  // handles PDF + image files
  async function processBinaryFile(arrayBufferObject) {
    // FIXME
    if (window.mobileApp) window.mobileApp.postMessage(`processBinaryFile: processing object ${JSON.stringify(arrayBufferObject)}`);
    // Convert ArrayBuffer to base64
    if (window.mobileApp) window.mobileApp.postMessage('processBinaryFile: about to start btoa');
    let base64String;
    try {
      // base64String = btoa(String.fromCharCode(...new Uint8Array(arrayBufferObject))); // THIS WORKED FOR ANDROID AND THREW RANGE ERROR FOR iOS
      base64String = Base64.fromUint8Array(new Uint8Array(arrayBufferObject));
    } catch (error) {
      console.error('DialogSelectFile > processBinaryFile: btoa failed', error);
      if (window.mobileApp) window.mobileApp.postMessage(`processBinaryFile: btoa failed ${error}`);
      throw new Error('Unable to convert file to base64 string');
    }
    if (window.mobileApp) window.mobileApp.postMessage(`processBinaryFile: base64String ${base64String}`);

    const idToken = (await Auth.currentSession()).getIdToken().getJwtToken();
    if (window.mobileApp) window.mobileApp.postMessage(`processBinaryFile: idToken ${idToken}`);

    const payload = {
      body: base64String,
      headers: {
        'Content-Type': 'application/json',
        Authorization: idToken,
      },
    };

    // Send the base64-encoded string
    const jobId = await API.post('myAPI', 'customer/processDocument', payload);
    if (window.mobileApp) window.mobileApp.postMessage(`processBinaryFile: jobId ${jobId}`);

    // Function to wait for a specified time
    // eslint-disable-next-line no-promise-executor-return
    const wait = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

    // Check the status
    let response;
    do {
      await wait(5000); // Wait for 5 seconds
      response = await API.get('myAPI', `customer/processDocument/${jobId}`, { ...payload, body: null });
      // this returns a 202 status code if the job is still processing, but Amplify thinks it is not an error

      if (!(typeof response === 'object') && response.match(/Document analysis not ready yet/)) {
        await wait(1000); // Wait for 1 second if status is > 200
        if (dayjs().diff(timestamp, 'second') > 10) {
          setDelayInfo(true);
        }
      }
    } while (!(typeof response === 'object') && response.match(/Document analysis not ready yet/));

    // this function is designed to return an array of tables, so we need to let the caller know how many tables there are
    // Return the array provided by the backend when status code is 200
    return {
      numberOfTables: response.length,
      data: response,
    };
  }

  // clear import files from state when entering this component
  useEffect(() => {
    setIngested([]);
  }, []);

  // handle file passed from mobile device via dashboard (postFile)
  useEffect(() => {
    // initialise loading process
    if (fileReceived) {
      console.log('DialogSelectFile: initialising fileReceived');
      setLoading(true);
      setTimestamp(dayjs());
      setFileList(convertToFileList(fileReceived)); // this will trigger the processFileList function below
    }
  }, [fileReceived]);

  // process files
  // it is possible to move this to backend if it adds value
  useEffect(() => {
    async function processFileList() {
      if (fileList !== undefined) {
        if (debugLevel > 2) console.log('DialogSelectFile > processFileList started for fileList length: ', Array.from(fileList).length);
        try {
          // accept multiple files and convert them to ArrayBuffer
          if (Array.from(fileList || []).length > 0) {
            const promises = Array.from(fileList).map((file) => {
              if (file.size < 5000000) {
                return file.arrayBuffer();
              }
              // if you got past the if, it means the file is too big
              dispatch(setMessage('fileTooLarge'));
              return null;
            });
            const arrayBufferListPromises = await Promise.allSettled(promises);

            // if any of the files got rejected, display error message
            if (arrayBufferListPromises.some((item) => item.status === 'rejected')) {
              console.error(arrayBufferListPromises.filter((item) => item.status === 'rejected'));
              dispatch(setMessage('fileUploadError'));
              // eslint-disable-next-line max-len
              if (window.mobileApp) window.mobileApp.postMessage(`fileUploadError: ArrayBufferListPromises rejected ${JSON.stringify(arrayBufferListPromises.filter((item) => item.status === 'rejected'))}`);
              return;
            }
            const arrayBufferList = arrayBufferListPromises.filter((item) => item.status !== 'rejected').map((item) => item.value);
            if (arrayBufferList.length === 0) {
              setLoading(false);
              return;
            }

            // parse them with XLSX or run them through Textract
            const promises2 = arrayBufferList.map((item, idx) => {
              const { type } = Array.from(fileList || [])[idx];
              console.log('DialogSelectFile > processFileList: type', type);
              if (window.mobileApp) window.mobileApp.postMessage(`DialogSelectFile > processFileList: type ${type}`); // FIXME
              // if it's a CSV or XLSX file
              if (item && item.byteLength > 0 && (type === XLSX || type === CSV)) {
                return processCsvXlsxFile(item);
              }
              // if it's a PDF or image file
              if (item && item.byteLength > 0 && (type === PDF || type === JPEG || type === JPG || type === PNG)) {
                return processBinaryFile(item); // this may return more than one table, so it returns { numberOfTables, data } and needs to process before moving on
              }
              // if it's not any of the above, return null
              return null;
            });
            const extractedFilesListPromises = await Promise.allSettled(promises2);
            if (extractedFilesListPromises.some((item) => item.status === 'rejected')) {
              console.error(extractedFilesListPromises.filter((item) => item.status === 'rejected'));
              dispatch(setMessage('fileUploadError'));
              // eslint-disable-next-line max-len
              if (window.mobileApp) window.mobileApp.postMessage(`fileUploadError: extractedFilesListPromises rejected ${JSON.stringify(extractedFilesListPromises.filter((item) => item.status === 'rejected'))}`);
            }
            const extractedFilesList = extractedFilesListPromises.filter((item) => item.status !== 'rejected').map((item) => item.value);
            if (extractedFilesList.length === 0) {
              setLoading(false);
              return;
            }

            // binary files return an array of tables, so in some cases there will be several tables for one file
            const tableFileNames = [];
            const extractedTablesList = [];

            // handle multiple tables from processBinaryFiles
            extractedFilesList.forEach((item, fileIdx) => {
              // handle binary files
              if (item && item.numberOfTables) {
                for (let tableIdx = 0; tableIdx < item.numberOfTables; tableIdx += 1) {
                  tableFileNames.push(item.numberOfTables === 1 ? `${fileList[fileIdx].name}` : `${fileList[fileIdx].name} (${tableIdx}/${item.numberOfTables})`);
                  extractedTablesList.push(item.data[tableIdx]);
                }
              } else {
                // handle CSV/XLSX files
                tableFileNames.push(fileList[fileIdx].name);
                extractedTablesList.push(item);
              }
            });

            // return results to parent's state for further processing
            extractedTablesList.forEach((fileRead, index) => {
              if (fileRead) {
                setFileNames((prev) => [...prev, tableFileNames[index]]);
                setIngested((prev) => [...prev, fileRead]);
              } else {
                setEmptyFiles((prev) => [...prev, fileList[index].name]);
              }
            });
            // if at least one file had data, go to column matcher
            if (emptyFiles.length < fileList.length) {
              setDisplayedComponent('column');
            } else {
              setLoading(false);
              setDelayInfo(undefined);
              dispatch(setMessage('allFilesWereEmpty'));
            }
          } else {
            dispatch(setMessage('fileUploadFailed'));
            if (window.mobileApp) window.mobileApp.postMessage('fileUploadFailed: FileArray is empty');
            console.error('DialogSelectFile > processFileList: no files found in fileList');
            setDelayInfo(undefined);
            setLoading(false);
          }
        } catch (error) {
          dispatch(setMessage('fileUploadFailed'));
          if (window.mobileApp) window.mobileApp.postMessage(`fileUploadFailed: ${error}`);
          setDelayInfo(undefined);
          setLoading(false);
        }
      }
    }

    processFileList();
  }, [fileList]);

  return (
    <>
      <section className="ml-1 flex flex-rows items-center gap-2 sm:gap-6">
        <ToolTipNoIcon info="Go back to table view.">
          <Button text={t('cancelFileUpload.label')} Icon={XMarkIcon} onClick={() => setDisplayedComponent('table')} size="lg" />
        </ToolTipNoIcon>
        {loading && delayInfo && (
          <div className="rounded-md bg-brandBlue-50 px-3 py-2" id="info-message">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon className="h-5 w-5 text-brandBlue-400" aria-hidden="true" />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-brandBlue-600">{t('loadingTakesLong')}</p>
              </div>
            </div>
          </div>
        )}
      </section>

      <form className="w-full md:w-2/3">
        <div className="w-full p-6 border-2 border-gray-300 border-dashed rounded-md h-48">
          <div className="w-full h-full flex justify-center items-center space-x-2">
            {!loading ? (
              <div className="space-y-2 flex flex-col items-center">
                <svg className="h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                  <path
                    // eslint-disable-next-line max-len
                    d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <div className="flex text-sm text-gray-600">
                  <label htmlFor="file-upload" className="relative cursor-pointer rounded-md py-1.5 px-2.5 text-white bg-brandBlue-500 hover:bg-brandBlue-600">
                    <span className="font-semibold text-sm md:text-base">{t('uploadFiles.label')}</span>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, image/jpeg, image/jpg, application/pdf, image/png"
                      multiple
                      className="sr-only"
                      onChange={(e) => {
                        if (e.target.files) {
                          console.log('DialogSelectFile > onChange: e.target.files', e.target.files);
                          setLoading(true);
                          setTimestamp(dayjs());
                          setFileList(e.target.files);
                        }
                      }}
                    />
                  </label>
                </div>
                <p className="pt-1 text-xs text-gray-500 pb-4">XLSX / CSV / PDF / JPEG / PNG (max. 5MB)</p>
              </div>
            ) : (
              <>
                <MiniSpinner className="h-5 w-5 animate-spin text-gray-400" />
                <span className="text-gray-500">{t('loading')}</span>
              </>
            )}
          </div>
        </div>
      </form>
    </>
  );
}
SelectFile.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  account: PropTypes.objectOf(PropTypes.any).isRequired,
  setDisplayedComponent: PropTypes.func.isRequired,
  fileReceived: PropTypes.shape({
    fileName: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    fileData: PropTypes.any,
  }),
  setIngested: PropTypes.func.isRequired,
  setFileNames: PropTypes.func.isRequired,
};
SelectFile.defaultProps = {
  fileReceived: null,
};
