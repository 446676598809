/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/forbid-prop-types */
import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getSchemaByCategory } from '@monestry-dev/schema';
import { EllipsisVerticalIcon, ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { Menu, Transition } from '@headlessui/react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { globalAccountsView, putAccount } from '../../redux/reducers/data';
import { getCountryName } from '../../misc/countries';
import AttributeArrayOfObjectsFormEffectiveDate from './AttributeArrayOfObjectsFormEffectiveDate';
import AttributeArrayOfObjectsFormQuote from './AttributeArrayOfObjectsFormQuote';

dayjs.extend(utc);

function parseIf(exp) {
  return typeof exp === 'string' ? JSON.parse(exp) : exp;
}

function LabelPair({ label, value, menuMode }) {
  LabelPair.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.oneOf([null])]).isRequired,
    menuMode: PropTypes.bool,
  };
  LabelPair.defaultProps = {
    menuMode: false,
  };

  let calculatedValue = value;

  if (typeof value === 'number') {
    console.log(value);
    // round to 2 decimal places
    const stringValue = value.toFixed(2);
    // eliminate all decimal 0s, then eliminate trailing decimal point
    calculatedValue = Number(stringValue.replace(/\.0+$/, '').replace(/\.$/, ''));
  }
  if (value === true || value === false) {
    calculatedValue = value ? '✔️' : '✖️';
  }
  if (value === null || value === '') {
    calculatedValue = '—';
  }
  if (Array.isArray(value)) {
    const arrayLength = value.length;
    // if (arrayLength > 0) calculatedValue = `[${value.length}]`;
    if (arrayLength > 0) calculatedValue = <span className="px-2 rounded-sm bg-gray-300 text-white font-bold text-xs">{value.length}</span>;
    else calculatedValue = <ExclamationCircleIcon className="w-4 h-4 text-brandRed-400" />;
  }
  return (
    <>
      <span className="text-gray-400" id="link-attribute-label">
        {label}
      </span>
      <span className={`pl-1.5 text-gray-700 ${value === true || value === false ? 'text-xs' : ''}`} id="link-attribute-value">
        {calculatedValue}
      </span>
    </>
  );
}

// INFO:
// this component gets all properties of the account object passed as prop, removes the ones that are not editable (notEditableFields object below) and displays them in a bar
// it uses schema to determine the type of each attribute and displays it accordingly (text, dropdown, boolean)
// properties can be edited by clicking on them (it opens a DialogForm via event -- handled by handleClick function)

// CAVEATS:
// - there are several special cases that are handled differently (subtype in realEstate, country across all categories, connectedProjectId etc.)
// (subtype lists depend on what is selected for type -- if there are more use cases like that, a more generic solution must be found)

// INFO: i18n translations for KEY NAMES are in schema.<category>.account.<key>.label or schema.<category>.account.valuationParameters.<key>;
// i18n translations for VALUES for keys which are limited to a list of values are in schema.<category>.account.parameters.<key>.<value>
export default function AttributeBar({ account }) {
  const [barIsVisible, setBarIsVisible] = useState(true);
  const linkWidths = React.useRef([]); // this must be passed to event handler, so not state, but a ref
  const [breakpoint, setBreakpoint] = useState(100);
  const [subtypeList, setSubtypeList] = useState([]);

  const { t } = useTranslation('app', { keyPrefix: `schema.${account.category}.account` });
  const { t: tG } = useTranslation('app', { keyPrefix: 'schema' });
  const dispatch = useDispatch();
  const accounts = useSelector(globalAccountsView);
  const depositAccounts = accounts.filter((x) => x.category === 'deposits');
  const projects = useSelector((state) => state.projects);

  // get a list of account fields from schema that we want to display to access lists for dropdowns as well as verify boolean values
  const schema = getSchemaByCategory(account.category, 'account');

  // handle accounts for categories that have valuationParameters (rE) and those without (d, s)
  const accountSchemaObject = account.valuationParameters ? schema.describe().fields.valuationParameters.fields : schema.describe().fields;
  // ↑↑ object of { field1: { type: 'string', _whitelist: ... }, field2: { type: 'number', _whitelist: ... }, ... }
  // _whitelist contains the oneOf members; its a ReferenceSet class instance; size property is the number of members

  // list of non-editable fields which should not be shown to user
  // INFO: this can also be stored in all the schemas (.meta), but for now it seems it is easier to maintain it here
  // (to maintain it in schema see how it is done, look up hideFromImport)
  // eslint-disable-next-line max-len
  const nonEditableFields = [
    'id',
    'name',
    'userId',
    'tags',
    'status',
    'syncType',
    'category',
    'currency',
    'statusTimestamp',
    'lastMarketPriceRetrieved',
    'importFileMappings',
    'lastAutomaticUpdate',
    'importFlag',
    'schedulingExceptions',
    'selfValuations',
    'subcategory',
    'accountId',
    'highlightTransactionId',
    'credential',
  ];

  // crypto accounts have slightly less attributes, so do not show them
  if (account.category === 'crypto') {
    nonEditableFields.push('countryCode', 'ownCurrentAccount', 'credential', 'username');
  }

  // incoming editable attributes mapped to an array of { key, value }
  // this is the source of all attributes for the bar
  const accountAttributes = Object.entries(account)
    // remove non-editable attributes from account object
    .filter(([key, value]) => !nonEditableFields.includes(key))
    .flatMap(([key, value]) => {
      if (['string', 'number', 'boolean'].includes(typeof value)) return { key, value };
      if (!value) return { key, value };
      if (key === 'valuationParameters') {
        // handle nested attributes
        return Object.entries(value).map(([nestedKey, nestedValue]) => ({ key: `valuationParameters.${nestedKey}`, value: nestedValue }));
      }
      return { key, value };
    });
  const type = (accountAttributes.find((attr) => attr.key === 'valuationParameters.type') || { value: null }).value;

  // prepare data for subtype attribute in realEstate -- when type changes, reset subtype
  useEffect(() => {
    if (account.category === 'realEstate') {
      if (!type) setSubtypeList([]);
      if (type === 'building') {
        setSubtypeList(Object.entries(t('parameters.subtype.building', { returnObjects: true })).map(([key, value]) => ({ id: key, name: value })));
      }
      if (type === 'apartment') {
        setSubtypeList(Object.entries(t('parameters.subtype.apartment', { returnObjects: true })).map(([key, value]) => ({ id: key, name: value })));
      }
    }
  }, [type]);

  // this function can get called from useEffect that initialises the link widths (because state updates take place too slowly for the initialisation)
  // or from the event listener that listens for window resize events
  function handleResize(localLinkWidths) {
    // if localLinkWidths is passed, use it, otherwise use state
    const calculatedLinkWidths = localLinkWidths.type ? linkWidths.current : localLinkWidths; // if there is .type, then this is an event, so no array is passed -- use state
    const attributeBarWidth = document.querySelector('#attribute-bar').offsetWidth;
    let counter = -1;
    let visibleLinksWidth = 0;
    do {
      counter += 1;
      visibleLinksWidth += calculatedLinkWidths[counter];
    } while (visibleLinksWidth < attributeBarWidth - 100 && counter < calculatedLinkWidths.length); // 100 is an arbitrary margin
    setBreakpoint(counter);
    return counter;
  }

  // runs once on initialisation and calculates the width of each link
  function measureAllLinks() {
    const attributeLinks = document.querySelectorAll('.attribute-item');

    const temp = [];
    attributeLinks.forEach((link) => {
      temp.push(link.offsetWidth);
    });
    linkWidths.current = temp;
    return temp;
  }

  // initialise link widths
  useEffect(() => {
    setBarIsVisible(false);
    const localLinkWidthts = measureAllLinks();
    const localBreakpoint = handleResize(localLinkWidthts);
    setBreakpoint(localBreakpoint);
    setBarIsVisible(true);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /**
   * @param {string} attrKey - name of the attribute property
   * @returns attribute type (for DialogForm to know what to display)
   *
   * This function will check yup meta dialogFormType property and if it exists, it will be returned.
   * If the above property does not exist, it will apply the logic to determine the type.
   */
  function getAttributeType(attrKey) {
    const yupAttrObject = accountSchemaObject[attrKey];
    let attrType = yupAttrObject?.type; // this looks up valuationParameters, so it won't work for connectedDepositAccounts in RealEstate
    if (attrType === undefined) attrType = schema.describe().fields[attrKey]?.type; // this looks up the root of account object to handle the case above

    // if the yup attribute definition has meta.dialogFormType, return it immediatelly
    if (yupAttrObject?.meta?.dialogFormType) return yupAttrObject.meta.dialogFormType;

    const isStringOrNumber = ['number', 'string'].includes(attrType);
    const hasOneOf = (yupAttrObject?.oneOf.length || -1) > 0; // checks if the schema definition for this attr has oneOf (i.e. a whitelist)

    if (isStringOrNumber && hasOneOf) return 'dropdown';
    if (account.category === 'realEstate' && attrKey === 'subtype') return 'dropdown';
    if (account.category === 'loans' && attrKey === 'connectedProjectId') return 'dropdown'; // special case for loans
    if (attrType === 'boolean') return 'boolean';
    if (isStringOrNumber && !hasOneOf) return 'text';
    if (attrType === 'array') {
      if (yupAttrObject?.innerType?.type === 'object') {
        return 'arrayOfObjects'; // used for arrays that carry objects in loans (e.g. interest rate which has { value, effectiveDate })
      }
      return 'array'; // this will show a dropdown with multiple selection allowed (connectedDepositAccounts is an array of objects)
    }
    return undefined;
  }

  // attrKey without valuationParameters
  async function putAttribute(attrKey, value) {
    const data = JSON.parse(JSON.stringify(account));
    // if there are valuationParameters, add the attribute to them; otherwise to the root of account object (with the exception of connectedDepositAccounts which is always in the root)
    if (account.valuationParameters && attrKey !== 'connectedDepositAccounts') {
      data.valuationParameters[attrKey] = value;
      // for 'realEstate' type changes, we must reset subtype when type changes
      if (account.category === 'realEstate' && attrKey === 'type') data.valuationParameters.subtype = null;
    } else {
      data[attrKey] = value;
    }

    await dispatch(
      putAccount({
        data,
        category: account.category,
      }),
    );
  }

  // this function handles translations for attribute values
  // get a different part of i18n for this (tCountries instead of t)
  const { t: tRoot } = useTranslation('app');
  // checks if attr.key has a value list and if so, it returns the translated value
  function getTranslatedValue(attr) {
    if (attr.value === null) return null;
    const attrKey = attr.key.replace('valuationParameters.', '');

    // handle special cases

    // country (has a different source of translations)
    if (attrKey === 'countryCode') {
      return tRoot([`countryNames.${getCountryName(attr.value)}`, getCountryName(attr.value)]);
    }

    // 'subtype' for realEstate (different logic)
    if (attrKey === 'subtype' && account.category === 'realEstate') {
      return t(`parameters.subtype.${type}.${attr.value}`);
    }

    // connectedProjectId for loans
    if (attrKey === 'connectedProjectId' && account.category === 'loans') {
      console.log(
        attr,
        projects.find((x) => x.id === attr.value),
      );
      return projects.find((x) => x.id === attr.value)?.name;
    }

    const attrType = getAttributeType(attrKey);

    // date
    if (attrType === 'date') {
      return dayjs.utc(attr.value).format('DD.MM.YYYY');
    }

    // handle standard case
    if (attrType === 'dropdown' && attrKey !== 'energyClass') {
      // energyClass are letters, so no translation
      return t(`parameters.${attrKey}.${attr.value}`);
    }

    return attr.value;
  }

  function handleClick(attr) {
    // accountSchemaObject automatically goes to the valuationParameters object if it exists (i.e. accountSchemaObject.type and not accountSchemaObject.valuationParameters.type)
    // also, when we look up translations for values in i18n, they are under account.parameters.<key>.<value> (and not parameters.valuatuionParameters.<key>.<value>)
    const attrKey = attr.key.replace('valuationParameters.', '');

    // check if we must provide a list for dropdown
    const attrValueType = getAttributeType(attrKey);

    // if yes, prepare the list
    let dropdownList = [];
    if (attrValueType === 'dropdown') {
      // handle special case subtype, which has no whitelist, because it depends on the type attribute
      if (account.category === 'realEstate' && attrKey === 'subtype') dropdownList = subtypeList;
      // handle special case countryCode (different source of translations)
      else if (attrKey === 'countryCode') {
        dropdownList = [...accountSchemaObject[attrKey].oneOf].map((item) => ({ id: item, name: item === null ? null : tRoot([`countryNames.${getCountryName(item)}`, getCountryName(item)]) }));
      } else if (account.category === 'loans' && attrKey === 'connectedProjectId') {
        dropdownList = projects.map((x) => ({ id: x.id, name: x.name }));
      } else {
        dropdownList = [...accountSchemaObject[attrKey].oneOf].map((item) => ({ id: item, name: item === null ? null : t(`parameters.${attrKey}.${item}`) }));
      }
    }
    // attrType === 'array' -- there is only connectedDepositAccounts for now, so handling it via attrKey
    if (attrKey === 'connectedDepositAccounts') dropdownList = depositAccounts.map((x) => ({ id: x.id, name: x.name }));

    // DialogForm will only provide the value to the callback, so we will send there this wrapper
    async function dialogCallback(value) {
      let calculatedValue;
      // for connected deposit account we have { id, name } in the dropdown, but we need only id
      if (attrKey === 'connectedDepositAccounts') {
        calculatedValue = value.map((item) => item.id);
      } else {
        calculatedValue = value?.id === undefined ? value : value.id; // dropdown's value in DialogForm state is { id, name }
      }
      await putAttribute(attrKey, calculatedValue);
    }

    async function dialogCallbackForArrayOfObjectsEffectiveDate(formValues) {
      // "de-transform" values in the shape of { effectiveDate, value: { id, name } } to { effectiveDate, value: id }
      const calculatedValue = formValues.map((item) => ({ effectiveDate: item.effectiveDate, value: item.value.id || item.value }));
      await putAttribute(attrKey, calculatedValue);
    }

    async function dialogCallbackForArrayOfObjectsQuote(formValues) {
      await putAttribute(attrKey, formValues);
    }

    let calculatedValue = attr.value;
    if (attrValueType === 'dropdown') calculatedValue = dropdownList.find((x) => x.id === attr.value);

    // handle array
    // need to provide memory references to the objects in the list, so we can compare them to the values in the array
    // attr.value is an array of ids, so we must convert it to an array of objects
    // caveat: this can be an array of items (currency) or an array of objects (connectedDepositAccounts)
    if (attrValueType === 'array') calculatedValue = dropdownList.filter((x) => attr.value.includes(x.id));

    // REVERTING THE FIX BELOW on 240508 for connectedDepositAccounts shall now be an array of ids
    // FIX below applied 240427 to make it possible for connectedDepositAccounts to be an array of objects
    // if (attrValueType === 'array') { calculatedValue = dropdownList.filter((item) => attr.value.some((val) => (val?.id || val) === (item?.id || item))); }

    // value should be an item or an object --> we need to get object to item
    // then convert dropdownList to an array of ids and compare it to the value

    // handle array of objects - like interestRate in loans (array of { value, effectiveDate })
    if (attrValueType === 'arrayOfObjects') {
      let formValues = parseIf(attr.value); // array of { effectiveDate, value } or array of { type, quote, quoteBaseCurrency }
      // other kinds of array are not implemented yet
      const fieldsInObject = Object.keys(accountSchemaObject[attrKey].innerType?.fields);
      if (!fieldsInObject.includes('effectiveDate') && !fieldsInObject.includes('quote')) {
        throw new Error('AttributeBar: unsupported arrayOfObjects type');
      }
      let mode;
      if (fieldsInObject.includes('effectiveDate')) mode = 'effectiveDate';
      if (fieldsInObject.includes('quote')) mode = 'quote';

      // if this is an array field which is an array of { effectiveDate, value } and the value is a oneOf list
      // take the list from yup and convert it to { id, name } format to be displayed in Dropdown
      // only pass values, the child component will look for transaction keys for them
      let valueDropdown;

      if (mode === 'effectiveDate') {
        // handle { effectiveDate, value } array of objects
        // check if this is an array of { ..., value }
        const valueOneOf = accountSchemaObject[attrKey].innerType?.fields?.value?.oneOf;
        // if the value field is a list, we must provide a list of translated values to the component (valueDropdown)
        if (valueOneOf && valueOneOf.length > 0) {
          // convert all values to { id, name } format suitable to be displayed in Dropdown
          valueDropdown = [...valueOneOf].map((item) => ({ id: item, name: t(`parameters.${attrKey}.${item}`) }));
          // also convert existing values
          formValues = formValues.map((row) => ({
            effectiveDate: row.effectiveDate,
            value: valueDropdown.find((x) => x.id === row.value),
          }));
        }
        const props = {
          formValues,
          header: t(`${attr.key}.label`),
          prompt: t(`${attr.key}.tooltip`).includes('.tooltip') ? tG('pleaseEnterValue') : t(`${attr.key}.tooltip`),
          callback: dialogCallbackForArrayOfObjectsEffectiveDate,
          valueDropdown, // optional, otherwise child component displays a number input
        };

        window.dispatchEvent(new CustomEvent('setDialog', { detail: { Component: AttributeArrayOfObjectsFormEffectiveDate, props } }));
      }

      if (mode === 'quote') {
        const props = {
          formValues,
          header: t(`${attr.key}.label`),
          prompt: t(`${attr.key}.tooltip`).includes('.tooltip') ? tG('pleaseEnterValue') : t(`${attr.key}.tooltip`),
          callback: dialogCallbackForArrayOfObjectsQuote,
        };

        window.dispatchEvent(new CustomEvent('setDialog', { detail: { Component: AttributeArrayOfObjectsFormQuote, props } }));
      }
    } else {
      // event for all other cases
      window.dispatchEvent(
        new CustomEvent('setDialog', {
          detail: {
            header: t(`${attr.key}.label`),
            prompt: t(`${attr.key}.tooltip`).includes('.tooltip') ? tG('pleaseEnterValue') : t(`${attr.key}.tooltip`),
            value: calculatedValue,
            callback: dialogCallback,
            ...(attrValueType === 'date' && { date: true }),
            ...(attrValueType === 'dropdown' && { dropdown: dropdownList }),
            ...(attrValueType === 'array' && { array: dropdownList }),
            ...(attrValueType === 'boolean' && { boolean: true }),
          },
        }),
      );
    }
  }

  return (
    <div id="attribute-bar" className={`${barIsVisible ? '' : 'invisible'} w-full flex text-gray-500 text-sm`}>
      {/* VISIBLE ATTRIBUTES IN HORIZONTAL BAR */}

      {accountAttributes
        .filter((attr, index) => index < breakpoint)
        .map((attr) => (
          <button
            type="button"
            className="attribute-item flex items-center justify-between pr-8 py-2 rounded-md hover:underline transition-colors duration-150 ease-in-out whitespace-nowrap"
            key={attr.key}
            id={`attribute-item-${attr.key.replace('valuationParameters.', '')}`}
            // handle special case: iban is not editable for automatic syncType
            // (see warning in https://monestry.atlassian.net/wiki/spaces/TECH/pages/383516690/Open+banking+API#Provide-current-transactions-%2F-saldos)
            disabled={account.category === 'deposits' && attr.key === 'iban' && account.syncType === 'automatic'}
            onClick={(e) => handleClick(attr)}
          >
            <LabelPair label={t(`${attr.key}.label`)} value={getTranslatedValue(attr)} />
          </button>
        ))}

      {/* ATTTRIBUTES HIDDEN IN MENU */}

      {accountAttributes.filter((attr, index) => index >= breakpoint).length > 0 && (
        // below XS it should overlap with the Table component below, otherwise we would have to embed it in all five versions of that component
        <Menu as="div" className="absolute xs:relative right-4 xs:right-auto pt-2 xs:pt-0">
          <Menu.Button
            // eslint-disable-next-line max-len
            className="flex items-center justify-center w-8 h-8 rounded-md bg-white xs:bg-inherit border border-gray-300 xs:border-none group hover:bg-gray-100 transition-colors duration-150 ease-in-out"
            type="button"
          >
            <EllipsisVerticalIcon className="w-5 h-5 text-gray-500" />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Menu.Items as="div" id="attributes-menu-panel" className="absolute left-0 mt-5 flex w-screen max-w-min -translate-x-3/4 px-4 z-[100]">
              <div className="w-72 flex flex-col items-start shrink rounded-xl bg-white p-2 text-sm text-left leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                {accountAttributes
                  .filter((attr, index) => index >= breakpoint)
                  .map((attr) => (
                    <Menu.Item
                      as="button"
                      type="button"
                      // eslint-disable-next-line max-len
                      className="px-4 py-2 whitespace-nowrap truncate max-w-[100%] text-gray-700 hover:bg-gray-50 transition-colors duration-150 ease-in-out ui-active:bg-brandBlue-500 ui-active:font-semibold"
                      key={JSON.stringify(attr.id || attr)}
                      onClick={(e) => handleClick(attr)}
                      // handle special case: iban is not editable for automatic syncType
                      // (see warning in https://monestry.atlassian.net/wiki/spaces/TECH/pages/383516690/Open+banking+API#Provide-current-transactions-%2F-saldos)
                      disabled={account.category === 'deposits' && attr.key === 'iban' && account.syncType === 'automatic'}
                    >
                      <LabelPair label={t(`${attr.key}.label`)} value={getTranslatedValue(attr)} menuMode />
                    </Menu.Item>
                  ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}
    </div>
  );
}
AttributeBar.propTypes = {
  account: PropTypes.objectOf(PropTypes.any).isRequired,
};
