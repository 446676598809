/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
/* eslint-disable array-callback-return */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable prefer-spread */
/* eslint-disable no-plusplus */
/* eslint-disable max-classes-per-file */
import React from 'react';
import PropTypes from 'prop-types';
import { PivotData } from 'react-pivottable/Utilities';
import { withTranslation } from 'react-i18next';

// helper function for setting row/col-span in pivotTableRenderer
const spanSize = function spanSize(arr, i, j) {
  let x;
  if (i !== 0) {
    let asc;
    let end;
    let noDraw = true;
    for (x = 0, end = j, asc = end >= 0; asc ? x <= end : x >= end; asc ? x++ : x--) {
      if (arr[i - 1][x] !== arr[i][x]) {
        noDraw = false;
      }
    }
    if (noDraw) {
      return -1;
    }
  }
  let len = 0;
  while (i + len < arr.length) {
    let asc1;
    let end1;
    let stop = false;
    for (x = 0, end1 = j, asc1 = end1 >= 0; asc1 ? x <= end1 : x >= end1; asc1 ? x++ : x--) {
      // vv this does not work for NaN === NaN, because that is always false
      if (arr[i][x] !== arr[i + len][x] && !Number.isNaN(arr[i][x]) && !Number.isNaN(arr[i + len][x])) {
        stop = true;
      }
    }
    if (stop) {
      break;
    }
    len++;
  }
  return len;
};

class ListRenderer extends React.PureComponent {
  render() {
    const pivotData = new PivotData(this.props);
    const rows = pivotData.getRowKeys(); // array of arrays (rows)
    const columnNames = pivotData.props.rows; // array with column names
    const hiddenCols = this.props.hiddenRows; // because the columns come in "rows" to a 2D list...

    const { t } = this.props;

    const grandTotalAggregator = pivotData.getAggregator([], []);

    const getClickHandler = () => {};

    return (
      <table className="text-sm text-gray-500 divide-y divide-gray-300 border-collapse table-auto">
        <thead className="text-gray-700 sticky top-0 z-10">
          {columnNames.length !== 0 && (
            <tr className="border-b-2 border-gray-300 ">
              {columnNames.map((rowAttr, idx) => (
                <th
                  className={`${hiddenCols?.includes(idx) ? 'hiddenCol' : ''}
                  sticky table-cell top-0 py-3.5 pl-2 pr-2 text-left text-xs uppercase font-semibold text-gray-900 border-b border-gray-300 bg-gray-50 bg-opacity-75 backdrop-blur backdrop-filter`}
                  key={`rowAttr${idx}`}
                >
                  {t(`app:accountDetails.columns.${rowAttr}`).slice(0, 3) === 'key' ? t(`app:accountDetails.columns.${rowAttr}.label`) : t(`app:accountDetails.columns.${rowAttr}`)}
                </th>
              ))}
              {/* <th className="pvtTotalLabel">
                Totals
              </th> */}
            </tr>
          )}
        </thead>

        <tbody className="bg-white">
          {rows.map((row, rowIdx) => (
            <tr key={`row${rowIdx}`} className="border-y border-gray-200">
              {row.map((colValue, colIdx) => {
                const z = spanSize(rows, rowIdx, colIdx);
                const thisColName = columnNames[colIdx];
                if (z === -1) return null;
                if (!colValue) return <td key={`rowKeyLabel${rowIdx}-${colIdx}`} className={row.includes('Σ') ? 'font-bold bg-brandBlue-50' : ''} />;

                return (
                  <td
                    key={`rowKeyLabel${rowIdx}-${colIdx}`}
                    className={`${hiddenCols?.includes(colIdx) ? 'hiddenCol' : ''} ${row.includes('Σ') ? 'font-bold bg-brandBlue-50' : ''}
                      py-3 px-2`}
                    style={typeof colValue === 'number' || colValue.includes('%') ? { textAlign: 'right' } : {}}
                    rowSpan={z}
                    colSpan={1}
                  >
                    {typeof colValue === 'number' && !(thisColName.toLowerCase().slice(0, 4) === 'date')
                      ? colValue.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                      : !colValue
                        ? ''
                        : colValue}
                  </td>
                );
              })}
              {/* <td
                  className=""
                  onClick={
                    getClickHandler
                    && getClickHandler(totalAggregator.value(), rowKey, [null])
                  }
                  style={colTotalColors(totalAggregator.value())}
                >
                  {totalAggregator.format(totalAggregator.value())}
                </td> */}
            </tr>
          ))}

          <tr>
            <th className="text-left py-3.5 px-2" colSpan={columnNames.length - 1 - (hiddenCols || []).length}>
              Totals
            </th>
            <td onClick={getClickHandler && getClickHandler(grandTotalAggregator.value(), [null], [null])} className="px-2 text-right">
              {grandTotalAggregator.format(grandTotalAggregator.value())}
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}

ListRenderer.defaultProps = PivotData.defaultProps;
ListRenderer.propTypes = PivotData.propTypes;

export default {
  List: withTranslation()(ListRenderer),
};
