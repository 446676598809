import allDepositTransactions from './prebuiltReports/allDepositTransactions';
import allStockPositions from './prebuiltReports/allStockPositions';
import allAssetsByROI from './prebuiltReports/allAssetsByROI';
import allAssetsSunburst from './prebuiltReports/allAssetsSunburst';
import transactions12m from './prebuiltReports/12mTransactions';
import assetBalanceChanges from './prebuiltReports/assetsBalanceChanges';
import inflowsByType from './prebuiltReports/inflowsByType';
import stocks12m from './prebuiltReports/12mStocks';
import realEstate10y from './prebuiltReports/10yRealEstate';
import kpiReport from './prebuiltReports/kpiReport';
import kpiReportUnisel from './prebuiltReports/kpiReportUnisel';
import loan10y from './prebuiltReports/10yLoan';

const prebuiltReportDefs = [
  allDepositTransactions,
  allStockPositions,
  allAssetsByROI,
  allAssetsSunburst,
  transactions12m,
  assetBalanceChanges,
  inflowsByType,
  stocks12m,
  realEstate10y,
  kpiReport,
  kpiReportUnisel,
  loan10y,
];

export default prebuiltReportDefs;
