/* eslint-disable max-len */
const debugLevel = process.env.MDEBUG || 0;

// inputs as outlined below

// returns an array of objects of { category, assetClass, currency, categoryRebalancingAssetDeltaFactor }
export default function getDimensionDeltaFactors(currentAllocationFiltered, targetAllocationFiltered) {
  // target allocation should be provided at assetClass level (even if no user data, it will be inherited), so we can skip category level
  // filter out those rows of targetAllocation, which have a value in the category fields but have no values in all the other fields of assetHierarchy or the field which is now the dimension

  // if there is no assetClass, add it with null
  const currentAllocationFilteredFixed = currentAllocationFiltered.map((x) => (x.assetClass ? x : { ...x, assetClass: 'null' })); // add assetClass if missing

  const categories = [...new Set(currentAllocationFilteredFixed.map((entry) => entry.category))];

  const targetAssetClassAllocation = targetAllocationFiltered.map((row) => ({ ...row, assetClass: row.assetClass || 'null' })).filter((row) => row.category && row.assetClass && row.currency);
  // returns array of the rows which contain a value in the field topHierarchyLevel and have no values in all the other fields of assetHierarchy (i.e. for the category-level allocation)
  if (debugLevel > 2) console.info('getDimensionDeltaFactors: targetAssetClassAllocation after replacing null fields', targetAssetClassAllocation);

  // return row for each assetClass and currency
  const rebalancingFactors = [];

  categories.forEach((category) => {
    const assetClasses = [...new Set(currentAllocationFilteredFixed.filter((x) => x.category === category).map((entry) => entry.assetClass))];
    assetClasses.forEach((assetClass) => {
      if (debugLevel > 2) console.info('getDimensionDeltaFactors: starting loop for category', category, 'and assetClass', assetClass);
      const assetClassCurrentAllocation = currentAllocationFilteredFixed.filter((row) => row.category === category && row.assetClass === assetClass);
      const assetClassSum = assetClassCurrentAllocation.reduce((acc, row) => acc + row.current.valueBaseCurrency, 0);
      if (debugLevel > 2) console.info('getDimensionDeltaFactors: current holdings for', category, 'and asset class', assetClass, 'are', assetClassCurrentAllocation);
      if (debugLevel > 2) console.info('getDimensionDeltaFactors: current asset sum for assetClass', assetClass, 'is', assetClassSum);

      if (debugLevel > 2) {
        console.info(
          'getDimensionDeltaFactors: target allocation for',
          category,
          'and asset class',
          assetClass,
          'are',
          targetAssetClassAllocation.filter((row) => row.category === category && row.assetClass === assetClass),
        );
      }
      const targetAllocationWithFactor = targetAssetClassAllocation
        .filter((row) => row.category === category && row.assetClass === assetClass) // this should produce an array with one element per each currency and assetClass
        .map((row) => {
          const sumCurrentAmountByAssetClassAndCurrency = assetClassCurrentAllocation
            .filter((position) => position.assetCurrency === row.currency)
            .reduce((acc, assetObject) => acc + assetObject.current.valueBaseCurrency, 0);

          // handle situation where there are only null values for the entire assetClass (if there is one null value in the assetClass, all other will also be null, otherwise there would be a 0)
          if (row.value === null) {
            if (debugLevel > 2) {
              console.info(
                'getDimensionDeltaFactors: target allocation value for category',
                category,
                'and assetClass',
                assetClass,
                'and currency',
                row.currency,
                'is null. Assumming no allocation available for this assetClass and returning factor of 0.',
              );
            }
            return {
              category: row.category,
              assetClass: row.assetClass,
              currency: row.currency,
              dimensionRebalancingAssetDeltaFactor: 0,
            };
          }
          const sumTargetAmountByAssetClassAndCurrency = assetClassSum * (row.value / 100); // take the current total and determine what part should be allocated to this currency

          // FIXME: continue setting  stocks > EUR to 60% and debug the resulting logic (at the moment, current sum and target sum are not as expected)

          if (debugLevel > 2) console.info('getDimensionDeltaFactors: current sum for category', category, 'and assetClass', assetClass, 'and currency', row.currency, 'is', sumCurrentAmountByAssetClassAndCurrency);
          if (debugLevel > 2) console.info('getDimensionDeltaFactors: target sum for category', category, 'and assetClass', assetClass, 'and currency', row.currency, 'is', sumTargetAmountByAssetClassAndCurrency);
          return {
            category: row.category,
            assetClass: row.assetClass,
            currency: row.currency,
            dimensionRebalancingAssetDeltaFactor: (sumTargetAmountByAssetClassAndCurrency - sumCurrentAmountByAssetClassAndCurrency) / sumCurrentAmountByAssetClassAndCurrency,
          };
        });
      rebalancingFactors.push(...targetAllocationWithFactor);
    });
  });

  console.log('getDimensionDeltaFactors: returnArray', rebalancingFactors);

  // TODO prevent users from entering null into the form
  // TODO remember 'null'
  return currentAllocationFilteredFixed.map((row) => {
    const rowWithFactor = rebalancingFactors.find((x) => x.category === row.category && String(x.assetClass) === String(row.assetClass) && x.currency === (row.assetCurrency || row.accountCurrency));
    if (debugLevel > 2) console.info('getDimensionDeltaFactors: rowWithFactor', rowWithFactor, 'matchingFactor:', rowWithFactor?.dimensionRebalancingAssetDeltaFactor);
    return {
      ...row,
      assetClass: row.assetClass === 'null' ? null : row.assetClass,
      dimensionRebalancingAssetDeltaFactor: rowWithFactor?.dimensionRebalancingAssetDeltaFactor || 0,
    };
  });
}
