/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect } from 'react';
import { PivotData } from 'react-pivottable/Utilities';
import { ResponsiveBar, ResponsiveBarCanvas } from '@nivo/bar';

const colors = ['#1797F2', '#92D050', '#BB6DCE', '#FFD600', '#ED1C23', '#2E3192'];

function makeNivoRenderer(traceOptions = {}, layoutOptions = {}, transpose = false) {
  return function NivoRenderer(props) {
    const timer = Date.now();
    console.log('DEBUG BarChart started with props:', props);
    const pivotData = new PivotData(props);
    const rowKeys = pivotData.getRowKeys();
    const colKeys = pivotData.getColKeys();

    let fullAggName = props.aggregatorName;
    const numInputs = props.aggregators[fullAggName]([])().numInputs || 0;
    if (numInputs !== 0) {
      fullAggName += ` of ${props.vals.slice(0, numInputs).join(', ')}`;
    }

    // console.log('rowKeys', rowKeys); // all the possible combinations of main key category fields [[2015, 'DKB Giro'], [2017, 'Festgeld'],[2019, 'Festgeld'] ...]
    // console.log('colKeys', colKeys); // all the possible combinations of the secondary key category fields [['American Express', 'EUR'], ['Arbeitgeber AG', 'EUR'], ['Avis AG', 'EUR']...]
    // console.log('pivotData with props', pivotData);

    // rowKeys and colKeys have all the needed combinations of keys already
    // e.g. [[2015, 'EUR'], [2015, 'USD'], [2016, 'EUR'], [2017, 'EUR']]
    // even if there are more than 1 attribute in each group
    // so all that needs to happen is to produce for each object (which is an array) in rowKeys
    // an object with the joinedKey
    const rowNames = pivotData.props.rows; // ["Date (Year)", "accountName"]
    const colNames = pivotData.props.cols; // names of the fields that go to secondary category ["otherParty", "currency"]

    // bar chart input object looks like the following:
    // [ {
    //   main-category-field-name: main-category-key,
    //   secondary-category-key1: secondary-category-value,
    //   secondary-category-key2: secondary-category-value ...
    // }]

    function joinKeys(keys) {
      return typeof keys === 'object' ? keys.join(String.fromCharCode(0)) : keys;
    }

    const mainCategory = rowNames.join(' ');
    const secondaryCategories = colKeys.map((colName) => joinKeys(colName));

    // const secondaryCategories = joinKeys(colNames);

    let data = [];
    // this has all unique values organised by field name, i.e.:
    // { 'Date (Year)': [2015, 2016, 2017, 2018, 2019], 'accountName': ['DKB Giro', 'Festgeld', 'Girokonto', 'Kreditkarte', 'Sparkonto'], ... }
    // the sequence of keys is the hierarchy structure, i.e.: first object goes on top level, then the next object goes as a child of the first object, etc.
    // TODO this has some room for improvement, because this includes all possible combinations of keys, and not only those with data

    // generate the standard data structure
    data = rowKeys.map((rowKey) => {
      const joinedKey = joinKeys(rowKey);
      const row = { [mainCategory]: joinedKey };
      colKeys.forEach((colKey) => {
        const col = joinKeys(colKey);
        row[col] = pivotData.getAggregator(rowKey, colKey).value();
      });
      return row;
    });

    // if there is a lot of data elements, use canvas instead of svg (because the latter takes 10s+ to render)
    const complexity = data.length * secondaryCategories.length;
    const NivoChart = complexity > 1000 ? ResponsiveBarCanvas : ResponsiveBar;

    let chartProps = {
      margin: {
        top: 16,
        right: 60,
        bottom: 24,
        left: 16,
      },
      groupMode: 'grouped',
      colors,
      theme: { fontFamily: 'Lato' },
      padding: 0.2,
      labelTextColor: 'inherit:darker(1.4)',
      labelSkipWidth: 16,
      labelSkipHeight: 16,
      label: false,
      ...props.layoutOptions, // spread any properties which may have been passed to PivotTable
    };

    chartProps = {
      ...chartProps,
      data,
      indexBy: mainCategory,
      keys: secondaryCategories,
    };

    // FIXME: how to keep this a rolling last 12M filter?)

    // if (!props.layoutOptions.mode) {
    // chartProps.width = props.layoutOptions.width || 900;
    // chartProps.height = props.layoutOptions.height || 400;
    // }

    if (layoutOptions.mode === 'diverging-stacked') {
      chartProps = {
        ...chartProps,
        groupMode: 'stacked',
        padding: 0.45,
        innerPadding: 2,
        axisLeft: null,
        axisRight: {
          format: (v) => `${v.toLocaleString('de')}`,
        },
        axisBottom: null,
      };
    }
    console.log('DEBUG BarChart finished in', Date.now() - timer, 'ms', 'with data', data);
    return <NivoChart {...chartProps} />;
  };
}

// FIXME: the function below should pass layoutOptions from the parent component!!!
export default function createNivoRenderers() {
  return {
    'Bar chart, vertical, grouped': makeNivoRenderer(),
    'Bar chart, vertical, diverging, stacked': makeNivoRenderer(null, { mode: 'diverging-stacked' }),
  };
}
