/* eslint-disable no-unsafe-optional-chaining */
// globals have all global objects which do not need to import any category-specific objects
// as it creates a circular dependency

// this file has selectors which need to import different category-specific objects
// eslint-disable-next-line import/prefer-default-export
import { createSelector } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { incomeFromCapitalLabels, depositTransactions, depositTransactionsProjectView, incomeFromCapitalPayoutsAndFeesCurrent, incomeFromCapitalPayoutsAndFeesBaseline } from '../deposits';
import { stockTransactions, stockTransactionsProjectView } from '../stocks';
import { realEstateTransactions, realEstateTransactionsProjectView } from '../realEstate';
import { loanTransactions, loanTransactionsProjectView } from '../loans';
import { pensionTransactions, pensionTransactionsProjectView } from '../pension';
import { objectsOfValueTransactions, objectsOfValueTransactionsProjectView } from '../objectsOfValue';
import { metalsTransactions, metalsTransactionsProjectView } from '../metals';
import { unlistedSharesTransactions, unlistedSharesTransactionsProjectView } from '../unlistedShares';
import { cryptoTransactions, cryptoTransactionsProjectView } from '../crypto';
import * as globals from '..';
import { groupBy, objectToArray, applyFIFO, applyFIFOReturnAll } from '../../../../misc/arrayHelpers';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

const debugLevel = 0;

function parseIf(str) {
  if (typeof str === 'object') return str;
  return JSON.parse(str);
}

const logSelectorChanges = (name, previousInputs, currentInputs) => {
  previousInputs.forEach((previousInput, index) => {
    const currentInput = currentInputs[index];

    if (previousInput !== currentInput) {
      console.log(`${name} > Input selector ${index + 1} has changed.`);
    }
  });
};

// returns an array of all transactions in the app (UNISEL format)
export function allTransactions(state) {
  const deposits = depositTransactions(state);
  const stocks = stockTransactions(state);
  const realEstate = realEstateTransactions(state);
  const loans = loanTransactions(state);
  const pension = pensionTransactions(state);
  const objectsOfValue = objectsOfValueTransactions(state);
  const metals = metalsTransactions(state);
  const unlistedShares = unlistedSharesTransactions(state);
  const crypto = cryptoTransactions(state);

  return (
    deposits
      .concat(stocks, realEstate, loans, objectsOfValue, metals, unlistedShares, crypto)
      // only pension-purchase transactions should be used to calculate net worth (contributions and payouts do not influence its present value)
      .concat(pension.filter((t) => t.label === 'pension-purchase'))
  );
}

// returns an array of all transactions until the simulation (slider) END DATE
// the recurring / interest / dividend transactions will be created until END DATE
// regardless of the project end date
export function allTransactionsProjectView(state) {
  const deposits = depositTransactionsProjectView(state);
  const stocks = stockTransactionsProjectView(state);
  const realEstate = realEstateTransactionsProjectView(state);
  const loans = loanTransactionsProjectView(state);
  const pension = pensionTransactionsProjectView(state);
  const objectsOfValue = objectsOfValueTransactionsProjectView(state);
  const metals = metalsTransactionsProjectView(state);
  const unlistedShares = unlistedSharesTransactionsProjectView(state);
  const crypto = cryptoTransactionsProjectView(state);

  return deposits.concat(stocks, realEstate, loans, pension, objectsOfValue, metals, unlistedShares, crypto);
  // we are passing also the non-KPI-relevant transactions which are NOT pension-purchase (only pension-purchase transactions should be used to calculate net worth and other sums)
  // because ProjectTransactionList needs to show simulated contributions and payouts too and this is its data source
  // all components which use allTransactionsProjectView to calculate sums / balances / indicators should filter out isNot KpiRelevant: true transactions
}

function getAccountName(accounts, accountId) {
  const account = accounts.find((a) => a.id === accountId);
  return account?.name;
}

// returns a hierarchical object category > accountId > assetId > { quantity, displayName, current, baseline, referenceBaseline }
// for the last three objects it returns { quoteBaseCurrency, quoteTransactionCurrency, valueBaseCurrency, valueAccountCurrency }
export const assetBalances = createSelector(
  // prettier-ignore
  allTransactions,
  globals.globalBaselineView,
  globals.globalQuotesView,
  globals.globalAccountsView,
  function assetBalancesCallback(transactions, baselineView, quotes, accounts) {
    const assetSaleTransactions = []; // for calculating incomeFromCapitalSaleTransactions*

    const result = transactions.reduce((acc, transaction) => {
      try {
        // account currency only will destructure for deposits
        // eslint-disable-next-line max-len
        const {
          id,
          date,
          category,
          accountId,
          assetId,
          providerAssetId,
          transactionType,
          quantity: transactionQuantity,
          quantityOpen,
          transactionCurrency,
          assetCurrency,
          accountCurrency,
          displaySymbol,
          displayName,
          assetName, // used in lieu of displayName in metals
          uptc,
          upbc,
        } = transaction;

        const { baseline: b, referenceBaseline: rb } = baselineView;
        const currentQuote = quotes[assetId]?.current?.quote || 0; // assume 0 to indicate a problem to user without throwing NaN
        const baselineQuote = quotes[assetId]?.baseline?.quote || 0; // if transaction happened after baseline, take transaction price, otherwise take baseline price
        const referenceQuote = quotes[assetId]?.referenceBaseline?.quote || 0; // as above, but for referenceBaseline

        const currentQuoteBaseCurrency = quotes[assetId]?.current?.quoteBaseCurrency || 0;
        const baselineQuoteBaseCurrency = quotes[assetId]?.baseline?.quoteBaseCurrency || 0;
        const referenceQuoteBaseCurrency = quotes[assetId]?.referenceBaseline?.quoteBaseCurrency || 0;

        const transactionWithQuotes = { ...transaction, baselineQuoteBaseCurrency, referenceQuoteBaseCurrency, baselineQuote, referenceQuote };

        const currentQuantity = transactionQuantity;
        const baselineQuantity = date < b ? transactionQuantity : 0;
        const referenceQuantity = date < rb ? transactionQuantity : 0;

        // baselineAssetValueChange needs to calculate FIFO on all transactions before baseline; to do that it needs to know if reverseMode must be applied
        const currentAccount = accounts.find((a) => a.id === accountId);
        const reverseMode = !!(category === 'loans' && currentAccount.direction === 'taken');

        if (category !== 'deposits' && transactionType === 'sale') assetSaleTransactions.push(transactionWithQuotes);

        // handling asset currency: (see PRD-1424)
        // - clear for deposits (asset currency = account currency)
        // - for assets let us make an assumption that for a given account and asset combination there can only be one asset currency
        //   (i.e. if first transaction is in USD, the following transactions are in USD)
        //   If that is not the case, we will flag it as an error here and show message (we could probably convert the new currency to old currency then somehow)

        if (!acc[category]) acc[category] = {};
        if (!acc[category][accountId]) acc[category][accountId] = {};
        if (!acc[category][accountId][assetId]) {
          acc[category][accountId][assetId] = {
            displayName: displayName || assetName || getAccountName(accounts, accountId), // only add displayName when there isn't one already
            displaySymbol: displaySymbol || (category === 'metals' ? assetId : null), // this could be assetId forever, but i don't have time to regression test for all categories
            providerAssetId: providerAssetId || null,
            // assetCurrency: category === 'deposits' ? accountCurrency : transactionCurrency, // fixed 241116: assetCurrency is now calculated when uploading data to Redux
            assetCurrency: assetCurrency || accountCurrency, //                                   fixed 241116: assetCurrency is now calculated when uploading data to Redux
            reverseMode,
            current: {
              quantity: currentQuantity,
              quoteBaseCurrency: currentQuoteBaseCurrency,
              quoteTransactionCurrency: currentQuote,
              valueBaseCurrency: currentQuoteBaseCurrency * currentQuantity || 0, // || 0 for all values to avoid NaN displayed to user
              purchaseValueBaseCurrency: upbc * quantityOpen || 0, // used to display purchase value for atomicData and related reports (for everything except for deposits, where it shows 0)
              // ↑ applyFIFO makes sure no sale transaction has quantityOpen
              // purchaseValueTransactionCurrency: uptc * quantityOpen || 0, // not in use
              ...(category === 'deposits' && { valueAccountCurrency: currentQuote * currentQuantity || 0 }),
              // ↑↑ this must only be used for deposits, it will be wrong for assets, as assets may have EUR and USD transactions in one account
              ...(category === 'deposits' && { accountCurrency: transactionCurrency }),
              // ↑↑ this is set by transactionCurrency of the first transaction, which should for all transactions be the same as accountCurrency
            },
            baseline: {
              quantity: baselineQuantity,
              quoteBaseCurrency: baselineQuoteBaseCurrency,
              quoteTransactionCurrency: baselineQuote,
              valueBaseCurrency: baselineQuoteBaseCurrency * baselineQuantity || 0,
              ...(category === 'deposits' && { valueAccountCurrency: currentQuote * baselineQuantity || 0 }),
              // ↑↑ this must only be used for deposits, it will be wrong for assets, as assets may have EUR and USD transactions in one account
              ...(category === 'deposits' && { accountCurrency: transactionCurrency }),
              // ↑↑ this is set by transactionCurrency of the first transaction, which should for all transactions be the same as accountCurrency
            },
            referenceBaseline: {
              quantity: referenceQuantity,
              quoteBaseCurrency: referenceQuoteBaseCurrency,
              quoteTransactionCurrency: referenceQuote,
              valueBaseCurrency: referenceQuoteBaseCurrency * referenceQuantity || 0,
              ...(category === 'deposits' && { valueAccountCurrency: referenceQuote * referenceQuantity || 0 }),
              // ↑↑ this must only be used for deposits, it will be wrong for assets, as assets may have EUR and USD transactions in one account
              ...(category === 'deposits' && { accountCurrency: transactionCurrency }),
              // ↑↑ this is set by transactionCurrency of the first transaction, which should for all transactions be the same as accountCurrency
            },
            transactions: [transactionWithQuotes], // for debug purposes
          };
        } else {
          const { quantity, current, baseline, referenceBaseline } = acc[category][accountId][assetId];
          // handle transaction inconsistencies (i.e. situation where we have asset transactions for the same account/asset in different transactionCurrencies)
          // in general, we assume that the asset will be run in the same currency as its first purchase transaction
          // as we only sum up baseCurrency values, this is not a problem (quotes will sort it out - getQuotes gets currency parameter as part of the payload)
          // there is inconsistentAssetCurrency message already prepared, but trying to use dispatch here causes circular dependency
          if (
            acc[category][accountId][assetId].assetCurrency !== null
            && (category === 'deposits' ? accountCurrency : transactionCurrency) !== null
            && acc[category][accountId][assetId].assetCurrency !== (category === 'deposits' ? accountCurrency : transactionCurrency)
          ) {
            console.warn(`Transaction currency mismatch for account ${accountId} and asset ${assetId} in category ${category} - transactionId ${id}.`);
            console.warn(`Expected ${acc[category][accountId][assetId].assetCurrency}, received ${category === 'deposits' ? accountCurrency : transactionCurrency}.`);
          }
          acc[category][accountId][assetId].current = {
            ...current,
            quantity: current.quantity + currentQuantity,
            valueBaseCurrency: current.valueBaseCurrency + (currentQuoteBaseCurrency * currentQuantity || 0),
            purchaseValueBaseCurrency: current.purchaseValueBaseCurrency + (upbc * quantityOpen || 0),
            // purchaseValueTransactionCurrency: current.purchaseValueTransactionCurrency + (uptc * quantityOpen || 0), // not in use
            ...(category === 'deposits' && { valueAccountCurrency: current.valueAccountCurrency + (currentQuote * currentQuantity || 0) }),
          };
          acc[category][accountId][assetId].baseline = {
            ...baseline,
            quantity: baseline.quantity + baselineQuantity,
            valueBaseCurrency: baseline.valueBaseCurrency + (baselineQuoteBaseCurrency * baselineQuantity || 0),
            ...(category === 'deposits' && { valueAccountCurrency: baseline.valueAccountCurrency + (baselineQuote * baselineQuantity || 0) }),
          };
          acc[category][accountId][assetId].referenceBaseline = {
            ...referenceBaseline,
            quantity: referenceBaseline.quantity + referenceQuantity,
            valueBaseCurrency: referenceBaseline.valueBaseCurrency + (referenceQuoteBaseCurrency * referenceQuantity || 0),
            ...(category === 'deposits' && { valueAccountCurrency: referenceBaseline.valueAccountCurrency + (referenceQuote * referenceQuantity || 0) }),
          };
          acc[category][accountId][assetId].transactions.push(transactionWithQuotes); // for debug purposes

          // if for some reason providerAssetId has not been updated before, update it now if it is available
          if (!acc[category][accountId][assetId].providerAssetId && providerAssetId) acc[category][accountId][assetId].providerAssetId = providerAssetId;
        }

        acc.assetSaleTransactions = assetSaleTransactions;
        return acc;
      } catch (e) {
        console.error('Error in assetBalances selector', e);
        console.error('Transaction', JSON.stringify(transaction, null, 2));
        return acc;
      }
    }, {});

    return result;
  },
);

function getProjectNextGoalDate(projectId, projects) {
  const project = projects.find((p) => p.id === projectId);
  if (!project) return null;
  const { goals } = project;
  if (!goals || goals.length === 0) return null;
  return goals.sort((a, b) => a.date - b.date)[0].date;
}

// returns a hierarchical object project > accountId > assetId > { quantity, displayName, current, baseline, referenceBaseline }
// for the last three objects it returns { quoteBaseCurrency, quoteTransactionCurrency, valueBaseCurrency, valueAccountCurrency }
export const projectBalances = createSelector(
  // prettier-ignore
  allTransactionsProjectView,
  (state) => state.projects,
  globals.globalBaselineView,
  (state) => state.user.profile?.settings.sliderEndDate,
  globals.globalQuotesView, // this, called without the second argument, will return the memoized globalQuotesView (generic - for all projects, without isolated)
  globals.globalAccountsView,
  globals.assetHierarchyDnorm,
  (state) => state.simulation?.dashboardMode,
  function projectBalancesCallback(transactions, projects, baselineView, sliderEnd, quotes, accounts, assetHierarchy, dashboardMode) {
    // this only needs to run in project mode
    if (dashboardMode !== 'projects') return {};

    const isolatedQuotesCache = {}; // cache for isolated project quotes

    const result = transactions
      .filter((transaction) => transaction.projectId && transaction.projectId !== 'null') // only keep project transactions
      .reduce((acc, transaction) => {
        // account currency only will destructure for deposits
        const { date, category, projectId, accountId, assetId, quantity: transactionQuantity, transactionCurrency, accountCurrency, displaySymbol, displayName } = transaction;
        const { current: c } = baselineView;
        let goalDate;
        let quotesForProject;
        try {
          // if project has goals, this will be the date of the next (chronologically) goal; otherwise slider end date
          goalDate = getProjectNextGoalDate(projectId, projects) || sliderEnd;
          const projectIsIsolated = projects.find((p) => p.id === projectId)?.settings?.isIsolated;
          // check cache if the quotes for this project are there; if yes, use them; if no, calculate them and put them in the cache
          if (projectIsIsolated) {
            if (!isolatedQuotesCache[projectId]) {
              console.log('DEBUG - running runQuotesCalcs from projectBalances');
              isolatedQuotesCache[projectId] = globals.runQuotesCalculations(sliderEnd, dashboardMode, quotes, accounts, baselineView, projects, assetHierarchy, projectId);
            }
          }

          // if the project is isolated, use isolated quotes, otherwise use global quotes
          quotesForProject = projectIsIsolated ? isolatedQuotesCache[projectId] : quotes;

          const currentQuote = quotesForProject[assetId]?.current.quote || 1; // assume 1 for this is transaction currency (ASSUMPTION: "quote" is always in transaction currency)
          const nextGoalDateQuote = quotesForProject[assetId]?.[goalDate].quote || 1;

          const currentQuoteBaseCurrency = quotesForProject[assetId]?.current.quoteBaseCurrency || 0; // assume 0 for this is baseCurrency (for lack of a better idea for now)
          const nextGoalDateQuoteBaseCurrency = quotesForProject[assetId]?.[goalDate].quoteBaseCurrency || 0;

          const transactionWithQuotes = { ...transaction, currentQuote, currentQuoteBaseCurrency, nextGoalDate: goalDate, nextGoalDateQuoteBaseCurrency, nextGoalDateQuote };

          const currentQuantity = date <= c ? transactionQuantity : 0;
          const nextGoalDateQuantity = date <= goalDate ? transactionQuantity : 0;

          // handling asset currency: (see PRD-1424)
          // - clear for deposits (asset currency = account currency)
          // - for assets let us make an assumption that for a given account and asset combination there can only be one asset currency
          //   (i.e. if first transaction is in USD, the following transactions are in USD)
          //   If that is not the case, we will flag it as an error here and show message (we could probably convert the new currency to old currency then somehow)

          const isConsideredInvestment = ['investment-purchase', 'investment-payout', 'investment-outflow', 'investment-contribution'].includes(transaction.label);
          // PL 230415 not sure if investment-contibution should be considered investment tho

          if (!acc[projectId]) acc[projectId] = {};
          if (!acc[projectId][accountId]) acc[projectId][accountId] = {};
          if (!acc[projectId][accountId][assetId]) {
            acc[projectId][accountId][assetId] = {
              account: accounts.find((a) => a.id === accountId), // used in ProjectDetails > ProjectAssetTile
              displayName: displayName || getAccountName(accounts, accountId), // only add displayName when there isn't one already
              displaySymbol: displaySymbol || null,
              assetCurrency: category === 'deposits' ? accountCurrency : transactionCurrency,
              current: {
                quantity: currentQuantity,
                quoteBaseCurrency: currentQuoteBaseCurrency,
                quoteTransactionCurrency: currentQuote,
                valueBaseCurrency: currentQuoteBaseCurrency * currentQuantity,
                // ↓ used in ProgressRoi
                investedAmount: isConsideredInvestment ? currentQuoteBaseCurrency * currentQuantity : 0,
              },
              nextGoalDate: {
                quantity: nextGoalDateQuantity,
                quoteBaseCurrency: nextGoalDateQuoteBaseCurrency,
                quoteTransactionCurrency: nextGoalDateQuote,
                valueBaseCurrency: nextGoalDateQuantity * nextGoalDateQuoteBaseCurrency,
                // ↓ used in ProgressRoi
                investedAmount: isConsideredInvestment ? nextGoalDateQuantity * nextGoalDateQuoteBaseCurrency : 0,
              },
              transactions: [transactionWithQuotes], // for debug purposes
            };
          } else {
            const { current, nextGoalDate } = acc[projectId][accountId][assetId];

            acc[projectId][accountId][assetId].current = {
              ...current,
              quantity: current.quantity + currentQuantity,
              valueBaseCurrency: current.valueBaseCurrency + currentQuoteBaseCurrency * currentQuantity,
              investedAmount: current.investedAmount + (isConsideredInvestment ? currentQuoteBaseCurrency * currentQuantity : 0),
            };
            acc[projectId][accountId][assetId].nextGoalDate = {
              ...nextGoalDate,
              quantity: nextGoalDate.quantity + nextGoalDateQuantity,
              valueBaseCurrency: nextGoalDate.valueBaseCurrency + nextGoalDateQuoteBaseCurrency * nextGoalDateQuantity,
              investedAmount: nextGoalDate.investedAmount + (isConsideredInvestment ? nextGoalDateQuoteBaseCurrency * nextGoalDateQuantity : 0),
            };
            acc[projectId][accountId][assetId].transactions.push(transactionWithQuotes); // for debug purposes
          }

          return acc;
        } catch (e) {
          console.error('Error in projectBalances selector', e);
          console.info('Transaction', JSON.stringify(transaction, null, 2));
          console.info('Quotes: current', JSON.stringify(quotesForProject[assetId]?.current, null, 2), 'nextGoalDate', JSON.stringify(quotesForProject[assetId]?.[goalDate], null, 2));
          console.info('nextGoalDate:', goalDate, dayjs(goalDate).format('YYYY-MM-DD'));
          return acc;
        }
      }, {});

    return result;
  },
);

export const projectBalancesArray = createSelector(projectBalances, (balances) => {
  const { assetSaleTransactions, ...rest } = balances;
  return objectToArray(rest, ['projectId', 'accountId', 'assetId']);
});

// helper -- returns purchase value of asset sale transaction; needs baseline or referenceBaseline date as 2nd argument and array of purchase transactions to look up the purchase price
function getLinkedTransactionPurchaseCost(assetTransaction, periodCutoffDate, periodQuoteBaseCurrency) {
  // get linked transactions for asset transaction passed as argument
  if (!assetTransaction) return 0;
  const { linkedTransactions } = assetTransaction;
  if (!linkedTransactions || linkedTransactions.length === 0) return 0;

  // sale transaction must be linked to a purchase transaction
  // for each linked stock transaction check if the purchase date is before the baseline date, if so, replace purchase price with baseline price
  // sample: linkedTransactions: [{ id: 'abcde', category: 'cat1', {"date": 1607731200000, "type": "purchase", "upbc": 1, "quantity": 12}}, ...]
  const purchaseCost = linkedTransactions
    .filter((txn) => txn.tags.type === 'purchase') // from the linkedTransactions keep only the purchase transactions (there may also be others)
    .reduce(
      (prev, curr) => prev
        + (curr.tags.date < periodCutoffDate // calculate baseline or purchase value depending on the date
          ? periodQuoteBaseCurrency // in case quote is not available
          : curr.tags.upbc)
          * curr.tags.quantity,
      0,
    );

  return purchaseCost;
}

// sum of gain of each sale transaction since baseline
// gain = (salePrice - baselineOrPurchasePrice) * quantity
// see Confluence "new selector landscape"
// we expect each asset sale transaction to have one or more linked transactions with tags.type 'purchase'
// returns a number (sum of all the gains)
export const incomeFromCapitalSaleTransactionsCurrent = createSelector(globals.globalBaselineView, assetBalances, function incomeFromCapitalSaleTransactionsCurrent(baselineView, balances) {
  const { baseline: b } = baselineView;
  const { assetSaleTransactions } = balances;
  if ((assetSaleTransactions || []).length === 0) return 0;
  const result = assetSaleTransactions
    .filter((saleTransaction) => saleTransaction.date >= b) // only consider sale transactions after baseline
    .reduce((acc, saleTransaction) => {
      const { quantity, upbc, baselineQuoteBaseCurrency } = saleTransaction;
      return acc + -quantity * upbc - getLinkedTransactionPurchaseCost(saleTransaction, b, baselineQuoteBaseCurrency);
    }, 0);
  return result;
});

export const incomeFromCapitalSaleTransactionsBaseline = createSelector(globals.globalBaselineView, assetBalances, function incomeFromCapitalSaleTransactionsBaseline(baselineView, balances) {
  const { baseline: b, referenceBaseline: rb } = baselineView;
  const { assetSaleTransactions } = balances;
  if ((assetSaleTransactions || []).length === 0) return 0;
  const result = assetSaleTransactions
    .filter((saleTransaction) => saleTransaction.date < b && saleTransaction.date >= rb) // only consider sale transactions after baseline
    .reduce((acc, saleTransaction) => {
      const { quantity, upbc, referenceQuoteBaseCurrency } = saleTransaction;
      return acc + -quantity * upbc - getLinkedTransactionPurchaseCost(saleTransaction, rb, referenceQuoteBaseCurrency);
    }, 0);
  return result;
});

export const assetBalancesArray = createSelector(assetBalances, function assetBalancesArrayCallback(balances) {
  const { assetSaleTransactions, ...rest } = balances;
  return objectToArray(rest, ['category', 'accountId', 'assetId']);
});

export const currentNetWorth2 = createSelector(assetBalancesArray, function currentNetWorth2Callback(balances) {
  return balances.reduce((acc, curr) => {
    const { current } = curr;
    return acc + current.valueBaseCurrency;
  }, 0);
});

export const baselineNetWorth = createSelector(assetBalancesArray, function baselineNetWorthCallback(balances) {
  return balances.reduce((acc, curr) => {
    const { baseline } = curr;
    return acc + baseline.valueBaseCurrency;
  }, 0);
});

// used by KPI report for displaying data leading to current asset value (KPIReportUnisel)
export function displayCurrentAssetValueChangeCalculation(balances, baselineView) {
  const allAssets = balances.map((assetObject) => {
    // result is a sum of all current assets in baseline or purchase prices
    const sumCurrentAssetsInBaselineOrPurchasePrices = assetObject.transactions.reduce((transactionsAcc, transactionObject) => {
      const { date, quantityOpen, upbc, baselineQuoteBaseCurrency } = transactionObject;
      return transactionsAcc + (quantityOpen || 0) * (date > baselineView.baseline ? upbc : baselineQuoteBaseCurrency);
    }, 0);
    const { current } = assetObject;
    return {
      displayName: assetObject.displayName,
      assetId: assetObject.assetId,
      currentQuantity: current.quantity,
      currentValueBaseCurrency: current.valueBaseCurrency,
      baselineOrPurchaseValueBaseCurrency: sumCurrentAssetsInBaselineOrPurchasePrices,
    };
  });
  return allAssets;
}
// OLD VERSION (has its own applyFIFO which would need to be adjusted for loans):
// export function displayCurrentAssetValueChangeCalculation(balances, baselineView) {
//   const allAssets = balances.map((assetObject) => {
//     // result is a sum of all current assets in baseline or purchase prices
//     const sumCurrentAssetsInBaselineOrPurchasePrices = applyFIFO(assetObject.transactions) // returns an array of positive quantity transactions with remaining quantity in 'quantity'
//       .reduce((transactionsAcc, transactionObject) => {
//         const { date, quantity, upbc, baselineQuoteBaseCurrency } = transactionObject;
//         return transactionsAcc + quantity * (date > baselineView.baseline ? upbc : baselineQuoteBaseCurrency);
//       }, 0);
//     const { current } = assetObject;
//     return {
//       assetId: assetObject.assetId,
//       currentQuantity: current.quantity,
//       currentValueBaseCurrency: current.valueBaseCurrency,
//       baselineOrPurchaseValueBaseCurrency: sumCurrentAssetsInBaselineOrPurchasePrices,
//     };
//   });
//   return allAssets;
// }

// used by KPI report for individual categories (KPIReportUnisel)
// balances are from assetBalanceArray
// CAUTION: the function above must use the same logic as the innermost reduce function here
export function calculateCurrentAssetValueChange(balances, baselineView) {
  // go through transaction objects for each account-asset and do FIFO calculation
  // calculate the purchase/baseline values of the remaining assets and subtract it from current value
  // console.log('DEBUG aba', balances);
  const sumAllAssets = balances.reduce((assetsAcc, assetObject) => {
    // result is a sum of all current assets in baseline or purchase prices
    const sumCurrentAssetsInBaselineOrPurchasePrices = assetObject.transactions.reduce((transactionsAcc, transactionObject) => {
      try {
        const { date, quantityOpen, upbc, baselineQuoteBaseCurrency } = transactionObject;
        // quantityOpen is added to only the PURCHASE transactions by applyFIFOReturnAll, so we need to filter out the results of those who do not have it
        if (Number.isNaN((quantityOpen || 0) * (date > baselineView.baseline ? upbc : baselineQuoteBaseCurrency))) throw new Error('NaN in calculateCurrentAssetValueChange');
        return transactionsAcc + (quantityOpen || 0) * (date > baselineView.baseline ? upbc : baselineQuoteBaseCurrency);
      } catch (e) {
        console.error('Error in calculateCurrentAssetValueChange', e);
        console.error('Transaction', JSON.stringify(transactionObject, null, 2));
        return transactionsAcc;
      }
    }, 0);
    const { current } = assetObject;
    return assetsAcc + (current.valueBaseCurrency - sumCurrentAssetsInBaselineOrPurchasePrices);
  }, 0);
  return sumAllAssets;
}
// OLD VERSION (has its own applyFIFO which would need to be adjusted for loans):
// export function calculateCurrentAssetValueChange(balances, baselineView) {
//   // go through transaction objects for each account-asset and do FIFO calculation
//   // calculate the purchase/baseline values of the remaining assets and subtract it from current value
//   const sumAllAssets = balances.reduce((assetsAcc, assetObject) => {
//     // result is a sum of all current assets in baseline or purchase prices
//     const sumCurrentAssetsInBaselineOrPurchasePrices = applyFIFO(assetObject.transactions)
//     // ↑↑ returns an array of positive quantity transactions with remaining quantity in 'quantity' ('quantity' is really 'openQuantity', i.e. these are only transactions with openQuantity)
//       .reduce((transactionsAcc, transactionObject) => {
//         const { date, quantity, upbc, baselineQuoteBaseCurrency } = transactionObject; // quantity is openQuantity
//         return transactionsAcc + quantity * (date > baselineView.baseline ? upbc : baselineQuoteBaseCurrency);
//       }, 0);
//     const { current } = assetObject;
//     return assetsAcc + (current.valueBaseCurrency - sumCurrentAssetsInBaselineOrPurchasePrices);
//   }, 0);
//   return sumAllAssets;
// }

// CURRENT ASSETS IN CURRENT PRICES - CURRENT ASSETS IN PURCHASE PRICES OR BASELINE PRICES (whichever is more recent)
export const currentAssetValueChange = createSelector(assetBalancesArray, globals.globalBaselineView, function currentAssetValueChangeCallback(balances, baselineView) {
  return calculateCurrentAssetValueChange(balances, baselineView);
});

// OLD VERSION: had applyFIFO instead of applyFIFOReturnAll and quantity instead of quantityOpen
// used by KPI report for displaying data leading to current asset value (KPIReportUnisel)
export function displayBaselineAssetValueChangeCalculation(balances, baselineView) {
  const { baseline: b, referenceBaseline: rb } = baselineView;
  const allAssets = balances.map((assetObject) => {
    // result is a sum of all current assets in reference or purchase prices
    const sumBaselineAssetsInReferenceOrPurchasePrices = applyFIFOReturnAll(
      assetObject.transactions.filter((t) => t.date < b),
      assetObject.reverseMode,
    )
      // applyFIFO returns an array of positive quantity transactions with remaining quantity in 'quantity' (in this case for transactions before baseline)
      .reduce((transactionsAcc, transactionObject) => {
        const { date, quantityOpen, upbc, referenceQuoteBaseCurrency } = transactionObject;
        return transactionsAcc + (quantityOpen || 0) * (date > rb ? upbc : referenceQuoteBaseCurrency); // transactions later than baseline have already been removed
      }, 0);
    const { baseline } = assetObject;
    return {
      displayName: assetObject.displayName,
      assetId: assetObject.assetId,
      baselineQuantity: baseline.quantity,
      baselineValueBaseCurrency: baseline.valueBaseCurrency,
      referenceOrPurchaseValueBaseCurrency: sumBaselineAssetsInReferenceOrPurchasePrices,
    };
  });
  return allAssets;
}

// OLD VERSION: had applyFIFO instead of applyFIFOReturnAll and quantity instead of quantityOpen
// used by KPI report for individual categories (KPIReportUnisel)
// balances are from assetBalanceArray
// CAUTION: the function above must use the same logic as the innermost reduce function here
export function calculateBaselineAssetValueChange(balances, baselineView) {
  // go through transaction objects for each account-asset and do FIFO calculation
  // calculate the purchase/baseline values of the remaining assets and subtract it from current value
  const { baseline: b, referenceBaseline: rb } = baselineView;
  const sumAllAssets = balances.reduce((assetsAcc, assetObject) => {
    // result is a sum of all current assets in reference or purchase prices
    // assetObject.reverseMode contains information if reverseMode should be applied in this calculation (determined in accountBalances above)
    const sumBaselineAssetsInReferenceOrPurchasePrices = applyFIFOReturnAll(
      assetObject.transactions.filter((t) => t.date < b),
      assetObject.reverseMode,
    )
      // applyFIFO returns an array of positive quantity transactions with remaining quantity in 'quantity' (in this case for transactions before baseline)
      .reduce((transactionsAcc, transactionObject) => {
        const { date, quantityOpen, upbc, referenceQuoteBaseCurrency } = transactionObject;
        return transactionsAcc + (quantityOpen || 0) * (date > rb ? upbc : referenceQuoteBaseCurrency); // transactions later than baseline have already been removed
      }, 0);
    const { baseline } = assetObject;
    return assetsAcc + (baseline.valueBaseCurrency - sumBaselineAssetsInReferenceOrPurchasePrices);
  }, 0);
  return sumAllAssets;
}

// BASELINE ASSETS IN BASELINE PRICES - BASELINE ASSETS IN PURCHASE PRICES OR REFERENCE BASELINE PRICES (whichever is more recent)
export const baselineAssetValueChange = createSelector(assetBalancesArray, globals.globalBaselineView, function baselineAssetValueChangeCallback(balances, baselineView) {
  return calculateBaselineAssetValueChange(balances, baselineView);
});

// INCOME FROM CAPITAL = GAIN FROM SALES TRANSACTIONS + DIVIDENDS+INTEREST+FEES (since baseline)
export const incomeFromCapitalCurrent = createSelector(
  incomeFromCapitalPayoutsAndFeesCurrent,
  incomeFromCapitalSaleTransactionsCurrent,
  function incomeFromCapitalCurrentCallback(payoutsAndFees, saleTransactions) {
    return payoutsAndFees + saleTransactions;
  },
);

export const incomeFromCapitalBaseline = createSelector(
  incomeFromCapitalPayoutsAndFeesBaseline,
  incomeFromCapitalSaleTransactionsBaseline,
  function incomeFromCapitalBaseline(payoutsAndFees, saleTransactions) {
    return payoutsAndFees + saleTransactions;
  },
);

// return the correct selector per category
export function globalTransactionViewPerCategory(state, category) {
  if (category === 'stocks') return stockTransactions(state);
  if (category === 'realEstate') return realEstateTransactions(state);
  if (category === 'deposits') return depositTransactions(state);
  if (category === 'loans') return loanTransactions(state);
  if (category === 'pension') return pensionTransactions(state);
  if (category === 'objectsOfValue') return objectsOfValueTransactions(state);
  if (category === 'metals') return metalsTransactions(state);
  if (category === 'unlistedShares') return unlistedSharesTransactions(state);
  if (category === 'crypto') return cryptoTransactions(state);
  return [];
}

// get transactions from globalTransactionView per category
export function globalTransactionViewPerCategoryWithIsolated(state, category) {
  if (category === 'stocks') return stockTransactionsProjectView(state);
  if (category === 'realEstate') return realEstateTransactionsProjectView(state);
  if (category === 'deposits') return depositTransactionsProjectView(state);
  if (category === 'loans') return loanTransactionsProjectView(state);
  if (category === 'pension') return pensionTransactionsProjectView(state);
  if (category === 'objectsOfValue') return objectsOfValueTransactionsProjectView(state);
  if (category === 'metals') return metalsTransactionsProjectView(state);
  if (category === 'unlistedShares') return unlistedSharesTransactionsProjectView(state);
  if (category === 'crypto') return cryptoTransactionsProjectView(state);
  return [];
}

// TODO in the connectedDepositAccounts we can pass a "most likely" transaction (+/- 5 days +/- 10% to value, watch currency)

// used in SumTileExpendable to show a list of asset transactions which have no deposit transactions linked to them
// as this is KPI-relevant, it should only display transations after referenceBaseline
export const orphanedSaleTransactions = createSelector(
  allTransactions,
  globals.globalAccountsView,
  globals.globalBaselineView,
  function orphanedSaleTransactionsCallback($transactions, $accounts, $baselineView) {
    return (
      $transactions
        // run filter by date first to limit the number of transactions that need to go through the subsequent filter
        .filter((transaction) => transaction.date > $baselineView.referenceBaseline)
        .filter((transaction) => {
          const LTs = transaction.linkedTransactions || [];
          const saleToTransactionLT = LTs.find((lt) => lt.tags.linkType === 'sale-to-transaction'); // this means we need to have LTs entered on deposits also visible in assets
          return (
            transaction.category !== 'deposits' //                               only show asset transactions
            && transaction.transactionType === 'sale' //                         of type 'sale'
            && (!saleToTransactionLT //                                          which either do not have a LT with linkType sale-to-transaction
              || !$transactions.find((t) => t.id === saleToTransactionLT.id)) // or they do, but the referenced transaction cannot be found
          );
        })
        .map((t) => {
          const assetAccount = $accounts.find((a) => a.id === t.accountId);
          const connectedDepositAccounts = (assetAccount.connectedDepositAccounts || []).map((id) => $accounts.find((a) => a.id === id)).filter((a) => !!a); // whole Account objects without undefined

          return {
            ...t,
            accountName: assetAccount.name, // get the name of the transaction's actual account
            // get the names of the connected deposit accounts (in case we need to redirect the user from the dialog to AccountDetails by passing account to setAccountDetails)
            connectedDepositAccounts,
          };
        })
    ); // attaching the entire account object, as it will then be used to pass to AccountDetails if the user clicks
  },
);

// let previousInputs = null;

// export const genericTransactionView = (state) => {
//   const currentInputs = genericTransactionViewSource.dependencies.map((selector) => selector(state));

//   if (previousInputs) {
//     logSelectorChanges(previousInputs, currentInputs);
//   }

//   previousInputs = currentInputs;

//   return genericTransactionViewSource(state);
// };

function sortByDateThenDepositsLast(a, b) {
  if (a.date !== b.date) {
    return a.date - b.date; // sort by date in ascending order
  }
  if (a.category === 'deposits' && b.category !== 'deposits') {
    return 1; // place 'deposits' category transactions last
  }
  if (a.category !== 'deposits' && b.category === 'deposits') {
    return -1; // place 'deposits' category transactions last
  }
  return 0;
}

// for each assetId array:
// - sort by date, ascending
// - calculate positionOnDate, valueOnDate for each row
// TODO: if performance in Dashboard is slow, may disable payoutPerPiece and related calcs depending on whether this is dashboard or reports mode
function cavHelper(baselineView, quotes, cavAssetIdTransactions, assetId, accounts) {
  if (debugLevel > 2) console.log('quotes view', quotes);

  // for each transaction for a given assetId in the input array calculate extra properties
  return (
    cavAssetIdTransactions
      // inject placeholder items for baseline, referenceBaseline and current dates
      .concat([
        { date: baselineView.baseline, assetId, rowType: 'kpi.baseline' },
        { date: baselineView.referenceBaseline, assetId, rowType: 'kpi.referenceBaseline' },
        { date: baselineView.current, assetId, rowType: 'kpi.current' },
      ])
      // IMPORTANT: asset transactions on a given day must be sorted BEFORE deposits (deposits at the end), because the fees will apply to the NEW amount of deposits!
      .sort(sortByDateThenDepositsLast)
      .reduce((prev, curr) => {
        const prevItem = prev.at(-1) || {}; // this is used to "carry over" the positionOnDate from the previous row; price is calculated from quotes;

        // calculate quotes for this row
        // if this is a purchase or sale transaction, use transaction quote (assumption)
        if (debugLevel > 2) console.log('curr', curr);

        const newObject = {};

        const { displaySymbol, accountId, quantity, quantityOpen, rowType, date, upbc } = curr;

        let positionOnDate = prevItem.positionOnDate || 0;
        let openPositionOnDate = prevItem.openPositionOnDate || 0;
        const displayName = curr.displayName || curr.accountName || getAccountName(accounts, assetId);
        // we are assuming that the two categories with assets (stocks, metals) have already assetNames from assets, deposits have currencies there and the rest is one per account
        if (['purchase', 'sale', 'kpi.current', 'kpi.baseline', 'kpi.referenceBaseline'].includes(rowType)) {
          // if this is a purchase, sale or kpi rowType, add positionOnDate and valueOnDate considering the amount of this transaction
          positionOnDate = !prevItem.positionOnDate ? quantity || 0 : prevItem.positionOnDate + (quantity || 0);
          if (rowType !== 'sale') {
            openPositionOnDate = !prevItem.openPositionOnDate ? quantityOpen || 0 : prevItem.openPositionOnDate + (quantityOpen || 0);
          }

          if (!curr.displayName) newObject.displayName = displayName;
          if (!curr.assetName) newObject.assetName = displayName;
          if (!curr.displaySymbol) newObject.displaySymbol = displaySymbol;
          if (!curr.accountName) newObject.accountName = accounts.find((a) => a.id === accountId)?.name || '';

          // -----------------------------------------------------------------------------------------------
          // Calculate positionOnDate
          // -----------------------------------------------------------------------------------------------

          // if the previous row has a positionOnDate already, add the current quantity it; otherwise start a new positionOnDate
          newObject.positionOnDate = positionOnDate;
          newObject.openPositionOnDate = openPositionOnDate;

          newObject.positionsPerProjectId = !prevItem.positionsPerProjectId
            ? { [curr.projectId || null]: positionOnDate || 0 }
            : { ...prevItem.positionsPerProjectId, [curr?.projectId || null]: (prevItem.positionsPerProjectId[curr?.projectId || null] || 0) + (curr.quantity || 0) };
        } else {
          // positionOnDate is the same as the previous row
          newObject.positionOnDate = prevItem.positionOnDate || 0;
          newObject.openPositionOnDate = prevItem.openPositionOnDate || 0;
        }

        // -----------------------------------------------------------------------------------------------
        // Calculate the PAYOUT / FEE PER PIECE (dividend / interest / rent / fees / costs)
        // -----------------------------------------------------------------------------------------------

        let payoutOrFeePerPiece = 0;
        let payoutOrFeeTotalOpenQuantity = 0;
        // costs are passed from CAV as fees already (in proportion to the amount of assets in account)
        // if (curr.label && ['investment-fees', 'investment-dividend', 'investment-interest', 'investment-rent'].includes(curr.label)) { FIX PL 240326 because why not?
        if (curr.label && incomeFromCapitalLabels.includes(curr.label)) {
          // calculate payout per owned piece
          payoutOrFeePerPiece = (curr.quantity * curr.upbc) / positionOnDate;

          newObject.payoutOrFeePerPiece = payoutOrFeePerPiece;
          payoutOrFeeTotalOpenQuantity = payoutOrFeePerPiece * openPositionOnDate;
          // calculate total for all pieces still owned at current date
          if (payoutOrFeePerPiece < 0) {
            newObject.feesTotalOpenQuantity = (prevItem.feesTotalOpenQuantity || 0) + payoutOrFeeTotalOpenQuantity;
            newObject.payoutTotalOpenQuantity = prevItem.payoutTotalOpenQuantity || 0; // carry over the other one
          } else {
            newObject.payoutTotalOpenQuantity = (prevItem.payoutTotalOpenQuantity || 0) + payoutOrFeeTotalOpenQuantity;
            newObject.feesTotalOpenQuantity = prevItem.feesTotalOpenQuantity || 0; // carry over the other one
          }
          // add payoutOrFeePerPiece to each purchase transaction that came before then and is awaiting weighted annualised ROI calculation
          newObject.annualisedRoiArray = (prevItem.annualisedRoiArray || []).map((item) => {
            const newItem = { ...item };
            if (typeof payoutOrFeePerPiece !== 'number') throw new Error(`payoutOrFeePerPiece is not a number ${curr}`);
            newItem.payoutsOrFeesPerPiece += payoutOrFeePerPiece;
            return newItem;
          });
        } else {
          // carry over if nothing changes
          newObject.payoutTotalOpenQuantity = prevItem.payoutTotalOpenQuantity || 0;
          newObject.feesTotalOpenQuantity = prevItem.feesTotalOpenQuantity || 0;
        }

        // -----------------------------------------------------------------------------------------------
        // Calculate ANNUALISED ROI for each purchase transaction and push it to an array
        // (to be finalised in reporting selectors)
        // -----------------------------------------------------------------------------------------------

        // annualised ROI must also consider payouts and fees for the transaction in question
        if (!newObject.annualisedRoiArray) newObject.annualisedRoiArray = [];
        if (rowType === 'purchase') {
          // for each purchase transaction add the components for calculation of transaction-based annualised ROI
          // each subsequent payout / fee transaction will add perPiece values to each purchase transaction that came before then (not here - it's where we handle them above)
          newObject.annualisedRoiArray = [...(prevItem.annualisedRoiArray || []), { quantityOpen, upbc, yearsElapsed: dayjs().diff(date, 'day') / 365, payoutsOrFeesPerPiece: 0 }];
          // if there is payoutOrFeePerPiece, it has been added to annualisedRoiArray earlier, to be included in the asset-level calculation
        } else if (payoutOrFeePerPiece === 0) {
          newObject.annualisedRoiArray = prevItem.annualisedRoiArray; // carry over if nothing else changes (remember this changes with some labels above too)
        }

        // kpi.current is the last row, so we can calculate the weighted ROI here
        if (rowType === 'kpi.current') {
          // adds an array of annualised ROIs for each purchase transaction with payouts and fees (we need currentValue AND payouts and fees per each purchase transaction to calculate this)
          // for the quantity which is still open, calculate annualised ROI (considering price changes since purchase as well all payouts and fees)
          const annualisedRoiArray = (prevItem.annualisedRoiArray || []).map((item) => {
            try {
              const { quantityOpen: _quantityOpen, upbc: _upbc, date: _date, yearsElapsed, payoutsOrFeesPerPiece } = item;
              if (_quantityOpen === 0) return { ...item, annualisedRoi: 0 }; // handle division by 0
              return {
                ...item,
                annualisedRoi: ((_quantityOpen * (quotes[assetId]?.current.quoteBaseCurrency + payoutsOrFeesPerPiece)) / (_quantityOpen * _upbc)) ** (1 / yearsElapsed) - 1,
              };
            } catch (e) {
              console.error('Error in annualisedRoiArray calculation', e);
              console.info('item', JSON.stringify(item, null, 2));
              throw new Error(e);
            }
          });
          // after we know each individual purchase transaction's annualised ROI, we can calculate the weighted average of all of them with quantityOpen as weight
          const sumWeights = (annualisedRoiArray || []).reduce((acc, item) => acc + item.quantityOpen * item.upbc, 0); // sum up all weights
          const weightedAverage = sumWeights === 0 ? 0 : annualisedRoiArray.reduce((acc, item) => acc + item.quantityOpen * item.upbc * item.annualisedRoi, 0) / sumWeights;
          newObject.weightedAverageAnnualisedRoi = weightedAverage;
        }
        return [...prev, { ...curr, ...newObject }];
      }, [])
  );
}

function handleDepositTransactionForCAV(accounts, t) {
  // all labelled transactions are moved to assets (except for interest, which stay; tbh. own costs should stay as well, albeit they have not been set up yet)
  if (incomeFromCapitalLabels.includes(t.label)) {
    // console.log('handleDepoisitTransactionForCAV', t);
    const rowType = t.label.split('-')[1]; // add rowType depending on label

    if (rowType === 'interest') return { ...t, rowType }; // interest stays in deposits

    // else move the transaction to the asset CAV grouping
    const newAccountId = t.tags?.accountId || 'notFound'; // override category and accountId to move it to the right asset account
    const newAssetId = t.tags?.assetId || 'notFound'; // override category and accountId to move it to the right asset account
    const category = (accounts || []).find((a) => a.id === newAccountId)?.category;
    return {
      ...t,
      rowType,
      category,
      accountId: newAccountId,
      assetId: newAssetId,
    };
  }
  // else it is a non-labelled transaction
  const rowType = t.amount >= 0 ? 'purchase' : 'sale'; // add rowType depending on label
  return {
    ...t,
    rowType,
    amountAssetCurrency: t.accountCurrencyAmount,
  };
}

function handleAssetTransactionForCAV(t) {
  const rowType = t.quantity >= 0 ? 'purchase' : 'sale';
  return {
    ...t,
    rowType,
  };
}

// see: https://monestry.atlassian.net/l/cp/HVeaB2g6
export const completeAssetView = createSelector(
  allTransactions,
  globals.globalAccountsView,
  (state) => state.simulation.dashboardMode,
  globals.globalBaselineView,
  globals.globalQuotesView,
  function completeAssetViewCallback(_allTransactions, accounts, dashboardMode, baselineView, quotesView) {
    // transform incoming transactions
    const data = _allTransactions
      // filter out transactionType === 'split', because it causes problems to calculate positionOnDate and valueOnDate
      .filter((t) => t.transactionType !== 'split')
      .map((t) => {
        // labelled deposits are moved to the pertaining asset account + transformations for all categories respectively
        const t2 = t.category === 'deposits' ? handleDepositTransactionForCAV(accounts, t) : handleAssetTransactionForCAV(t, dashboardMode);
        return t2;
      });
    // group by category, accountId, assetId
    // FIXME this is assetBalances, isn't it?
    const grouped = groupBy(data, ['category', 'accountId', 'assetId']);
    // results in { category: { accountId: { assetId: [transactions] } } }

    // for each assetId array run helper and updated 'grouped' array
    Object.keys(grouped).forEach((category) => {
      Object.keys(grouped[category]).forEach((accountId) => {
        Object.keys(grouped[category][accountId]).forEach((assetId) => {
          // handle account-wide costs for all categories except for real estate (there accountId is always equal to assetId)
          let costTransactionsForAssets = [];
          if (category !== 'realEstate' && accountId !== assetId) {
            // how many are there assets in this account?
            // this gets a list of all keys (assetIds) under this accountId (they must not be equal to accountId)
            const assetsInThisAccount = Object.keys(grouped[category][accountId]);
            // is there an assetId === accountId?
            if (assetsInThisAccount.includes(accountId)) {
              const numberOfAssets = assetsInThisAccount.filter((t) => t !== accountId).length;
              // move all account-level costs to assets, in proportion to how many assets there are in the account (like we do in Table)
              costTransactionsForAssets = grouped[category][accountId][accountId]
                .filter((t) => t.label === 'investment-costs')
                .map((t) => ({
                  ...t,
                  accountLevelCost: true, // for debugging purposes only
                  label: 'investment-fees',
                  rowType: 'fees', // changing them to be handled as account fees
                  quantity: t.quantity / numberOfAssets, // distribute the cost evenly among all assets in the account
                  quantityOpen: t.quantityOpen / numberOfAssets, // distribute the cost evenly among all assets in the account
                }));
              // console.log('CAV costTransactionsForAssets', costTransactionsForAssets);
            }
          }

          grouped[category][accountId][assetId] = cavHelper(baselineView, quotesView, grouped[category][accountId][assetId].concat(costTransactionsForAssets), assetId, accounts);
        });
      });
    });
    // console.info('CAV', grouped);

    return grouped;
  },
);
