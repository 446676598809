// instructions: leave 1 account in accountName filter and 12 most recent months with data in date filter
const realEstate10y = {
  vals: ['value'],
  cols: ['accountName'],
  dataScope: 'snapshots',
  rows: ['Date (Year)'],
  rendererName: 'Bar chart, vertical, grouped',
  rendererType: 'nivo', // 'nivo' requires a height property to be set on its parent to be displayed
  id: 'realEstateByMonthxxxx',
  name: 'Real Estate, value by year',
  aggregatorName: 'Maximum',
  readOnly: true,
  valueFilter: {
    category: {
      deposits: true,
      stocks: true,
      // realEstate: false, // skip value here to show it (not sure if this is the intended way, but works)
      loans: true,
      metals: true,
      crypto: true,
      pension: true,
      objectsOfValue: true,
      unlistedShares: true,
    },
  },
};

export default realEstate10y;
