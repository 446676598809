import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { createSelector } from '@reduxjs/toolkit';
import { completeAssetView } from '../../redux/reducers/data';
import { globalQuotesView } from '../../redux/reducers/globalSelectors';
import Table from '../accountDetails/Table';
import getTableLayout from '../accountDetails/tableLayouts';
import { applyCategorySpecificChanges, getSortingCallback } from '../accountDetails/transactions/stocks';

function TableLayoutStocksWrapper({ isViewer }) {
  // this does not do anything, just to maintain compatibility with AccountDetailsTable's way of calling TableLayoutStocks
  const quotes = isViewer ? useSelector((state) => state.viewer.reportData.quotes) : useSelector(globalQuotesView);
  // console.log('quotes', JSON.stringify(quotes)); // only quotes for today and for the assets in the report are passed
  console.log('quotes', JSON.stringify(quotes, null, 2)); // only quotes for today and for the assets in the report are passed

  // applyCategorySpecificChanges expects completeAssetView as input (completeAssetView[category][accountId]), which is divided up by category and account first
  // to run this report, all positions are moved under a single account

  const selectCompleteAssetViewAllAccounts = createSelector(
    completeAssetView,
    (state) => state.viewer?.reportData?.completeAssetView,
    (regularCAV, isViewerCAV) => {
      // determine which CAV to use (isViewer is only passed if this is rendered as part of SharedReportViewer)
      const cav = isViewer ? isViewerCAV : regularCAV;

      if (!cav.stocks) return []; // do nothing if no stocks found

      const cum = {};
      Object.entries(cav.stocks).forEach(([accountId, accountObject]) => {
        Object.entries(accountObject).forEach(([assetId, assetObject]) => {
          // handle account-level costs
          if (assetId === accountId) {
            if (!cum.costs) {
              cum.costs = assetObject;
            } else {
              cum.costs = [...cum.costs, ...assetObject].sort((a, b) => a.date - b.date);
            }
            return;
          }
          if (!cum[assetId]) {
            cum[assetId] = assetObject;
          } else {
            cum[assetId] = [...cum[assetId], ...assetObject].sort((a, b) => a.date - b.date);
          }
        });
      });
      return cum;
    },
  );

  const stocksAssetViewAllAccounts = useSelector(selectCompleteAssetViewAllAccounts);

  const tableState = {
    sortBy: 'date',
    sortDirectionAsc: true,
    colWidths: {
      date: 100,
      otherParty: 150,
      description: 450,
      amount: 100,
    },
    tableHeight: 300,
  };

  return (
    <div className="h-fit">
      <Table
        // we have moved all account-level costs under 'costs', so now we pretend that 'costs' is an accountId
        // second argument is expected to be the full account object
        // getSortingCallback makes the summary rows appear above the details rows
        data={applyCategorySpecificChanges(stocksAssetViewAllAccounts, { id: 'costs' }, 'table', quotes).sort(getSortingCallback(tableState))}
        tableLayout={getTableLayout('stocksPositionsReport', 'transactions')}
        tableState={tableState}
        setTableState={() => {}} // for compatibility with AccountDetails
      />
    </div>
  );
}
TableLayoutStocksWrapper.propTypes = {
  isViewer: PropTypes.bool,
};
TableLayoutStocksWrapper.defaultProps = {
  isViewer: false,
};

export default {
  'Stock Positions': TableLayoutStocksWrapper,
};
