/**
 * Logic:
 * - add column: dirty / not dirty to every row array based on dirtyRows
 * - remove completely empty rows (i.e. which have absolutely no contents)
 * - add isDirty column to gridLayout as the last column
 * - convert array of arrays to array of objects
 * - add original row number, so that errors can be displayed in the correct row
 * - if there is spreadExtraData, it means we need to spread its contents over each row (it has the same length as gridData with no 0 rows)
 */
export default function gridConvertGridDataToObject(gridData, gridLayout, dirtyRows, spreadExtraData) {
  // array of indices of all visible columns e.g. [2, 3, 4, 5]
  const visibleColumnIdxs = gridLayout.reduce((acc, curr, idx) => (curr.type === 'hidden' ? acc : [...acc, idx]), []);

  return (
    gridData
      // add dirty / not dirty column to every row array based on dirtyRows (but only to rows which are not empty, because they won't be removed in the next step of the chain)
      .map((row, idx) => (row.join('').length > 0 ? [...row, dirtyRows.has(idx)] : row))
      // // remove completely empty rows
      // .filter((row) => row.join('').length > 0)
      // 241024 FIX: we actually need to remove those rows, whose VISIBLE COLUMNS are empty (not all columns)
      // for all indices in visibleColumnIdxs, check if there is a non-empty value in the row; if there is, keep the row; othwerwise, remove it (return false)
      .filter((row) => visibleColumnIdxs.reduce((acc, curr) => acc || String(row[curr]).length > 0, false))
      // add isDirty column to gridLayout as the last column
      .map((row) => gridLayout.concat({ id: 'isDirty', type: 'boolean' }).reduce((prev, curr, idx) => ({ ...prev, [curr.id]: row[idx] }), {})) // convert array of arrays to array of objects
      // add original row number, so that errors can be displayed in the correct row
      .map((row, idx) => ({ ...row, rowNumber: idx }))
      // if there is spreadExtraData, it means we need to spread its contents over each row (it has the same length as gridData with no 0 rows)
      .map((row, idx) => (spreadExtraData ? { ...row, ...spreadExtraData[idx] } : row))
  );
}
