/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */

import React, { useState, useRef, useMemo, useLayoutEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { TableVirtuoso } from 'react-virtuoso';
import TableHeaders from './TableHeaders';
import { TableRow } from './TableBody';

export default function Table({ data, tableLayout, tableState, setTableState, highlightTransactionId, groupByColumn }) {
  // if header is less wide than 5px, then its contents has been hidden due to current breakpoint and the entire containing column needs to be hidden as well
  // the header which detects that its width is below 5px adds its id to the hiddenColumns state array in this component
  // HeaderCell and BodyColumn components check hiddenColumns if their id is there and if so, they add 'hidden' to their className
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [openDetailRows, setOpenDetailRows] = useState([]);

  const virtuosoRef = useRef(null); // Create ref for TableVirtuoso (used to handle scroll to view in Layout Effect)
  const highlightedRow = useRef(); // used to hightlight the row of highlightTransactionId (which is set by SearchResults)

  function toggleDetailRow(value) {
    if (openDetailRows.includes(value)) {
      setOpenDetailRows(openDetailRows.filter((id) => id !== value));
    } else {
      setOpenDetailRows([...openDetailRows, value]);
    }
  }

  // prepare TableHeaders to be passed as fixedHeaderContent to TableVirtuoso
  const headerConst = () => <TableHeaders tableLayout={tableLayout} tableState={tableState} setTableState={setTableState} hiddenColumns={hiddenColumns} setHiddenColumns={setHiddenColumns} />;

  // prepare the contents of TableRow to be passed as itemContent to TableVirtuoso
  // the <tr> element itself is defined in TableVirtuoso.components.TableRow, these are just the cells in a Fragment
  const itemContentConst = (index, item, context) => <TableRow index={index} rowObject={item} context={context} />;

  // Calculate visible rows by filtering out hidden rows
  const visibleData = useMemo(
    () => data.filter((rowObject) => (!Object.prototype.hasOwnProperty.call(rowObject, 'isSummaryRow') || openDetailRows.includes(rowObject[groupByColumn]) ? true : rowObject.isSummaryRow)),
    [data, openDetailRows],
  );

  useLayoutEffect(() => {
    if (highlightTransactionId && virtuosoRef.current) {
      const indexToScroll = visibleData.findIndex((row) => row.id === highlightTransactionId);
      if (indexToScroll !== -1) {
        virtuosoRef.current.scrollToIndex(indexToScroll);
      }
    }
  }, [highlightTransactionId, visibleData]);

  return (
    <section
      // eslint-disable-next-line max-len
      className="max-h-[50vh] xl:max-h-max self-stretch flex flex-col overflow-hidden relative overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-200 hover:scrollbar-thumb-zinc-200"
      aria-label="transaction details"
    >
      <TableVirtuoso
        ref={virtuosoRef}
        style={{ height: '50vh', overflowX: 'hidden' }}
        data={visibleData}
        context={{ tableLayout, hiddenColumns, highlightedRow, highlightTransactionId, toggleDetailRow, openDetailRows, groupByColumn }}
        fixedHeaderContent={headerConst}
        itemContent={itemContentConst}
        components={{
          Table: (props) => <table {...props} style={{ width: '100%', tableLayout: 'fixed' }} />,
          TableBody: forwardRef((props, ref) => (
            // need to pass props down to all child components
            <tbody ref={ref} className="divide-y divide-gray-200 text-gray-500 bg-white text-xs xs:text-sm" {...props}>
              {props.children}
            </tbody>
          )),
          TableRow: (props) => {
            // this renders the <tr> element, the contents of which is provided by itemContent
            // need to pass props down to all child components
            const { index, item, style, children, context } = props;
            const { highlightedRow: _highlightedRow, highlightTransactionId: _highlightTransactionId } = context;
            return (
              <tr
                key={item.id}
                id={`table-row-${index}`}
                style={style}
                {...(item.id === _highlightTransactionId && { ref: _highlightedRow })}
                className={`relative ${item.isSimulated ? 'bg-brandViolet-50 italic' : ''} ${item.id === _highlightTransactionId ? 'bg-brandYellow-100' : ''} ${
                  item.isSummaryRow ? 'font-bold border-gray-300' : 'font-medium border-y border-gray-200'
                }`}
                {...props}
              >
                {children}
              </tr>
            );
          },
        }}
      />
    </section>
  );
}
Table.propTypes = {
  data: PropTypes.array.isRequired, // array of objects (each object should have exactly the same keys as there are columns in the table + an id)
  tableLayout: PropTypes.array.isRequired,
  tableState: PropTypes.object.isRequired, // contains column widths, sorting, filtering, etc., has to be passed to e.g. TableHeaders to be able to change it
  setTableState: PropTypes.func.isRequired,
  highlightTransactionId: PropTypes.string, // coming from search function, hightlights a transaction in the table
  groupByColumn: PropTypes.string, // determins which field of each row will be used to manage the open / closed state; default is 'assetId'
};
Table.defaultProps = {
  highlightTransactionId: null,
  groupByColumn: 'assetId',
};
