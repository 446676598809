/* eslint-disable react/jsx-filename-extension */
/* eslint-disable object-curly-newline */
/* eslint-disable  */
import React from 'react';
import PropTypes from 'prop-types';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import i18n from '../../i18n';
import ToolTipNoIcon from '../../elements/ToolTipNoIcon';

// see realEstateQuotes for an example of a table layout with some extra parameter
const realEstateQuotes = [
  [
    { id: 'date', type: 'date', textAlign: 'text-left' },
    { id: 'source', type: 'string', classNamesBoth: 'sm:hidden' },
  ],
  [{ id: 'source', type: 'string', classNamesBoth: 'hidden sm:block', textAlign: 'text-center' }],
  [
    { id: 'quoteBaseCurrency', usesHeader: 'price', type: 'currency0Decimals' }, // usesHeader: if found, the header and tooltip will use this element from the i18n file
    {
      id: 'pricePerSquareMetre',
      type: 'currency0Decimals',
      classNamesBoth: 'sm:hidden', // pass hide / show classes here, they will be applied to HeaderLabels and BodyColumns
      classNamesBody: 'text-xs', // pass extra classes here, they will be applied to BodyColumns only
    },
  ],
  [{ id: 'currency', type: 'string', classNamesBoth: 'hidden sm:block' }],
  [
    { id: 'quote', usesHeader: 'originalPrice', type: 'number0Decimals' },
    {
      id: 'originalPricePerSquareMetre',
      type: 'number0Decimals',
      classNamesBoth: 'sm:hidden', // pass hide / show classes here, they will be applied to HeaderLabels and BodyColumns
      classNamesBody: 'text-xs', // pass extra classes here, they will be applied to BodyColumns only
    },
    { id: 'currency', type: 'string', classNamesBoth: 'sm:hidden' },
  ],
  [
    {
      // pass hide / show classes here, they will be applied to HeaderLabels and BodyColumns
      id: 'pricePerSquareMetre',
      type: 'currency0Decimals',
      classNamesBoth: 'hidden sm:block',
    },
  ],
  [{ id: 'originalPricePerSquareMetre', type: 'number0Decimals', classNamesBoth: 'hidden sm:block' }],
];

const objectsOfValueQuotes = [
  [
    { id: 'date', type: 'date', textAlign: 'text-left' },
    { id: 'source', type: 'string', classNamesBoth: 'sm:hidden' },
  ],
  [{ id: 'source', type: 'string', classNamesBoth: 'hidden sm:block', textAlign: 'text-center' }],
  [
    { id: 'quoteBaseCurrency', usesHeader: 'price', type: 'currency0Decimals' }, // usesHeader: if found, the header and tooltip will use this element from the i18n file
  ],
  [{ id: 'currency', type: 'string', classNamesBoth: 'hidden sm:block' }],
  [
    { id: 'quote', usesHeader: 'originalPrice', type: 'number0Decimals' },
    { id: 'currency', type: 'string', classNamesBoth: 'sm:hidden' },
  ],
  [{ id: 'note', type: 'string' }],
];

const metalsQuotes = [
  [{ id: 'assetName', type: 'string', textAlign: 'text-left', usesHeader: 'assetId' }],
  [
    { id: 'date', type: 'date', textAlign: 'text-left' },
    { id: 'source', type: 'string', classNamesBoth: 'sm:hidden' },
  ],
  [{ id: 'source', type: 'string', classNamesBoth: 'hidden sm:block', textAlign: 'text-center' }],
  [
    { id: 'quoteBaseCurrency', usesHeader: 'price', type: 'currency0Decimals' }, // usesHeader: if found, the header and tooltip will use this element from the i18n file
  ],
  [{ id: 'currency', type: 'string', classNamesBoth: 'hidden sm:block' }],
  [
    { id: 'quote', usesHeader: 'originalPrice', type: 'number0Decimals' },
    { id: 'currency', type: 'string', classNamesBoth: 'sm:hidden' },
  ],
  [{ id: 'note', type: 'string', width: 300 }],
];

const unlistedQuotes = [
  [{ id: 'assetName', type: 'string', textAlign: 'text-left', usesHeader: 'assetId' }],
  [
    { id: 'date', type: 'date', textAlign: 'text-left' },
    { id: 'source', type: 'string', classNamesBoth: 'sm:hidden' },
  ],
  [{ id: 'source', type: 'string', classNamesBoth: 'hidden sm:block', textAlign: 'text-center' }],
  [
    { id: 'quoteBaseCurrency', usesHeader: 'price', type: 'currency0Decimals' }, // usesHeader: if found, the header and tooltip will use this element from the i18n file
  ],
  [{ id: 'currency', type: 'string', classNamesBoth: 'hidden sm:block' }],
  [
    { id: 'quote', usesHeader: 'originalPrice', type: 'number0Decimals' },
    { id: 'currency', type: 'string', classNamesBoth: 'sm:hidden' },
  ],
];

const stocksQuotes = [
  [{ id: 'displaySymbol', type: 'string', textAlign: 'text-left', usesHeader: 'assetId' }],
  [
    {
      id: 'date',
      type: 'date',
      textAlign: 'text-left', // textAlign on item[0] determines alignment on the whole column and the HTML id of the entire cell
    },
    { id: 'source', type: 'string', classNamesBoth: 'sm:hidden' },
  ],
  [{ id: 'source', type: 'string', classNamesBoth: 'hidden sm:block', textAlign: 'text-center' }],
  [
    { id: 'quoteBaseCurrency', usesHeader: 'price', type: 'currency' }, // usesHeader: if found, the header and tooltip will use this element from the i18n file
  ],
  [{ id: 'currency', type: 'string', classNamesBoth: 'hidden sm:block' }],
  [
    { id: 'quote', usesHeader: 'originalPrice', type: 'number' },
    { id: 'currency', type: 'string', classNamesBoth: 'sm:hidden' },
  ],
];

// eslint-disable-next-line react/react-in-jsx-scope, react/jsx-filename-extension
// eslint-disable-next-line react/jsx-filename-extension
function SplitIcon({ value }) {
  if (typeof value !== 'number') {
    return (
      <div id="split-icon" className="inline-block rounded-full bg-gray-300 ml-0.5 align-text-bottom font-bold text-center px-1 text-xs text-white w-4">
        S
      </div>
    );
  }

  return (
    <div className="ml-1 inline-flex items-center gap-1">
      <span>{`(${value} `}</span>
      <span id="split-icon" className="inline-block rounded-full bg-gray-300 ml-0.5 font-bold text-center px-1 text-xs text-white w-4 h-4">
        S
      </span>
      <span>{')'}</span>
    </div>
  );
}

// TableBody passed 'value' prop to the displayElement component
// pass the id of the message from locale to be shown
function WarningWithTooltip({ value }) {
  return (
    <ToolTipNoIcon info={i18n.t(`app:accountDetails.${value}`)} classNameOwn="w-5 -mb-0.5">
      <ExclamationTriangleIcon className="w-5 h-5 text-brandYellow-500 cursor-pointer" />
    </ToolTipNoIcon>
  );
}
WarningWithTooltip.propTypes = {
  value: PropTypes.string,
};
WarningWithTooltip.defaultProps = {
  value: '',
};

function OriginalCurrencyComponent({ value }) {
  return <span>{` | ${value}`}</span>;
}

const stocksTransactions = [
  [
    // prettier-ignore
    {
      ifSummaryRowDisplay: false, id: 'date', type: 'date', textAlign: 'text-left', classNamesBoth: 'text-xs sm:text-sm flex gap-1 items-align', displayElement: { field: 'itemHasFlag', element: WarningWithTooltip },
      // ^^ need this for both columns
    },
    // prettier-ignore
    {
      ifSummaryRowDisplay: false, id: 'openPositionSize', type: 'number0Decimals', classNamesBody: 'text-xs text-gray-400 font-normal', classNamesBoth: 'xs:hidden', displayElement: { field: 'isPreSplitTransaction', element: SplitIcon }, hideHeaderLabel: true,
    },
    // prettier-ignore
    {
      ifSummaryRowDisplay: true, id: 'displayName', type: 'string', textAlign: 'text-left', classNamesBoth: 'text-xs sm:text-sm', ifSummaryRowClassNames: 'text-brandBlue-600 truncate',
    },
    // prettier-ignore
    {
      ifSummaryRowDisplay: true, id: 'openPositionSize', type: 'number0Decimals', classNamesBody: 'text-xs text-gray-400 font-normal', classNamesBoth: 'xs:hidden', displayElement: { field: 'isPreSplitTransaction', element: SplitIcon }, hideHeaderLabel: true,
    },
  ],
  [
    // prettier-ignore
    {
      id: 'openPositionSize', type: 'number0Decimals', classNamesBody: 'text-xs sm:text-sm', classNamesBoth: 'hidden xs:block', displayElement: { field: 'isPreSplitTransaction', element: SplitIcon },
    },
    { id: 'currentValueBaseCurrency', usesHeader: 'currentValue', type: 'currency', classNamesBody: 'text-xs sm:text-sm', classNamesBoth: 'xs:hidden' },
    { id: 'purchaseValue', usesHeader: 'purchaseValueFull', type: 'currency', classNamesBody: 'text-xs sm:text-sm', classNamesBoth: 'xs:hidden' },
  ],
  [
    { id: 'purchaseValue', usesHeader: 'purchaseValueFull', type: 'currency', classNamesBody: 'text-xs sm:text-sm', classNamesBoth: 'hidden xs:block' },
    {
      id: 'transactionPrice',
      type: 'currency',
      classNamesBody: 'text-xs text-gray-400',
      classNamesBoth: 'hidden xs:block',
      displayElement: { field: 'displayPriceTransactionCurrency', element: OriginalCurrencyComponent },
    },
  ],
  [
    { id: 'currentValueBaseCurrency', usesHeader: 'currentValue', type: 'currency', classNamesBody: 'text-xs sm:text-sm', classNamesBoth: 'hidden xs:block' },
    {
      id: 'currentQuoteBaseCurrency',
      usesHeader: 'currentPrice',
      type: 'currency',
      classNamesBody: 'text-xs text-gray-400',
      classNamesBoth: 'hidden xs:block',
      displayElement: { field: 'displayQuoteTransactionCurrency', element: OriginalCurrencyComponent },
    },
    { id: 'dividendsSincePurchase', type: 'currency', classNamesBody: 'text-xs sm:text-sm', classNamesBoth: 'xs:hidden' },
    { id: 'fees', type: 'currency', hideHeaderLabel: true, classNamesBody: 'text-xs sm:text-sm', classNamesBoth: 'xs:hidden' },
  ],
  [
    { id: 'dividendsSincePurchase', type: 'currency', classNamesBody: 'text-xs sm:text-sm', classNamesBoth: 'hidden xs:block' },
    { id: 'fees', type: 'currency', hideHeaderLabel: true, classNamesBody: 'text-xs sm:text-sm', classNamesBoth: 'hidden xs:block' },
  ],
  [
    { id: 'roi', type: 'percentage', usesHeader: 'roiOnly', classNamesBody: 'text-xs sm:text-sm', classNamesBoth: 'hidden xs:block' },
    { id: 'roiAnnual', type: 'percentage', classNamesBody: 'text-xs text-gray-400', classNamesBoth: 'hidden xs:block' },
  ],
  [
    // prettier-ignore
    {
      ifSummaryRowDisplay: false, id: 'ellipsis', type: 'ellipsis', usesHeader: 'emptyString', classNamesHeader: 'w-12', classNamesBody: '-ml-3',
    },
    // prettier-ignore
    {
      ifSummaryRowDisplay: true, id: 'bn', type: 'number0Decimals', hideHeaderLabel: true, displayElement: { field: 'itemHasFlag', element: WarningWithTooltip },
    },
  ],
];

const stocksTransactionsReport = [
  [
    { ifSummaryRowDisplay: false, id: 'date', type: 'date', textAlign: 'text-left', classNamesBoth: 'text-xs sm:text-sm' },
    // ^^ need this for both types of row

    { ifSummaryRowDisplay: false, id: 'accountName', type: 'string', textAlign: 'text-left', classNamesHeader: 'text-xs sm:text-sm', classNamesBody: 'text-xs text-gray-400 uppercase' },

    { ifSummaryRowDisplay: true, id: 'displayName', type: 'string', textAlign: 'text-left', classNamesBoth: 'text-xs sm:text-sm', ifSummaryRowclassNames: 'text-brandBlue-600' },
  ],
  [{ id: 'openPositionSize', type: 'number0Decimals', classNamesBody: 'text-xs sm:text-sm', displayElement: { field: 'isPreSplitTransaction', element: SplitIcon } }],
  [
    { id: 'purchaseValue', type: 'currency', classNamesBody: 'text-xs sm:text-sm' },

    { id: 'transactionPrice', type: 'currency', classNamesBody: 'text-xs text-gray-400' },
  ],
  [
    { id: 'currentValueBaseCurrency', usesHeader: 'currentValue', type: 'currency', classNamesBody: 'text-xs sm:text-sm' },

    { id: 'currentQuoteBaseCurrency', usesHeader: 'currentPrice', type: 'currency', classNamesBody: 'text-xs text-gray-400', hideHeaderLabel: true },
  ],
  [
    { id: 'dividendsSincePurchase', type: 'currency', classNamesBody: 'text-xs sm:text-sm' },

    { id: 'fees', type: 'currency', hideHeaderLabel: true, classNamesBody: 'text-xs sm:text-sm' },
  ],
  [
    { id: 'roi', type: 'percentage', usesHeader: 'roiOnly', classNamesBody: 'text-xs sm:text-sm' },

    { id: 'roiAnnual', type: 'percentage', classNamesBody: 'text-xs text-gray-400' },
  ],
];

const realEstateTransactions = [
  [{ id: 'date', type: 'date', textAlign: 'text-left', classNamesBoth: 'text-xs sm:text-sm' }],
  [{ id: 'purchaseValue', type: 'currency0Decimals', classNamesBody: 'text-xs sm:text-sm' }],
  [{ id: 'currentValue', type: 'currency0Decimals', classNamesBody: 'text-xs sm:text-sm' }],
  [
    { id: 'dividendsSincePurchase', type: 'currency', classNamesBody: 'text-xs sm:text-sm' },
    { id: 'fees', type: 'currency', hideHeaderLabel: true, classNamesBody: 'text-xs sm:text-sm' },
  ],
  [
    { id: 'roi', type: 'percentage', usesHeader: 'roiOnly', classNamesBody: 'text-xs sm:text-sm' },
    { id: 'roiAnnual', type: 'percentage', classNamesBody: 'text-xs text-gray-400' },
  ],
  [{ id: 'ellipsis', type: 'ellipsis', usesHeader: 'emptyString', classNamesHeader: 'w-12', classNamesBody: '-ml-3' }],
];

const objectsOfValueTransactions = [
  [
    { ifSummaryRowDisplay: false, id: 'date', type: 'date', textAlign: 'text-left', classNamesBoth: 'text-xs sm:text-sm' },
    { ifSummaryRowDisplay: true, id: 'displayName', type: 'string', textAlign: 'text-left', classNamesBoth: 'text-xs sm:text-sm', ifSummaryRowclassNames: 'text-brandBlue-600' },
  ],
  [{ id: 'purchaseValue', type: 'currency0Decimals', classNamesBody: 'text-xs sm:text-sm' }],
  [{ id: 'currentValue', type: 'currency0Decimals', classNamesBody: 'text-xs sm:text-sm' }],
  [
    { id: 'dividendsSincePurchase', usesHeader: 'proceeds', type: 'currency', classNamesBody: 'text-xs sm:text-sm' },
    { id: 'fees', usesHeader: 'costs', type: 'currency', classNamesBody: 'text-xs sm:text-sm' },
  ],
  [
    { id: 'roi', type: 'percentage', usesHeader: 'roiOnly', classNamesBody: 'text-xs sm:text-sm' },
    { id: 'roiAnnual', type: 'percentage', classNamesBody: 'text-xs text-gray-400' },
  ],
  [{ id: 'ellipsis', type: 'ellipsis', usesHeader: 'emptyString', classNamesHeader: 'w-12', classNamesBody: '-ml-3' }],
];

const depositsTransactions = [
  [
    { id: 'date', type: 'date', textAlign: 'text-left', classNamesHeader: 'w-1/2 sm:w-1/3 lg:w-2/12 md:w-auto' },
    { id: 'otherParty', type: 'string', textAlign: 'text-left', classNamesBoth: 'lg:hidden' },
    { id: 'otherPartyAccount', type: 'string', textAlign: 'text-left', classNamesBoth: 'lg:hidden', classNamesBody: 'text-xs truncate', classNamesHeader: 'hidden' },
    { id: 'description', type: 'string', textAlign: 'text-left', classNamesBody: 'truncate', classNamesBoth: 'sm:hidden' },
  ],
  [
    { id: 'otherParty', type: 'string', textAlign: 'text-left', classNamesBoth: 'hidden lg:block', classNamesHeader: 'w-1/4 md:w-auto lg:w-3/12' },
    {
      id: 'otherPartyAccount',
      type: 'string',
      textAlign: 'text-left',
      classNamesBoth: 'hidden lg:block',
      classNamesBody: 'text-xs text-gray-400 rtl truncate',
      classNamesHeader: 'hidden',
    },
  ],
  [
    {
      id: 'description',
      type: 'string',
      textAlign: 'text-left',
      classNamesBoth: 'hidden sm:block',
      classNamesHeader: 'sm:w-4/12 lg:w-3/12', // use classNameHeader to set a fixed width on the header
    },
  ],
  [
    {
      id: 'accountCurrencyAmount',
      type: 'currency',
      usesHeader: 'amount',
      textAlign: 'text-right',
      classNamesHeader: 'sm:w-2/12',
    },
    {
      id: 'fxAmount',
      type: 'fxCurrency',
      classNamesBody: 'text-xs text-gray-400',
      // formatting function takes transaction.currency and transaction.fxCurrency as arguments;
      // the fxCurrency types use fxCurrency instead of currency
    },
  ],
  [
    {
      id: 'label',
      type: 'label',
      textAlign: 'text-center',
      usesHeader: 'type',
      classNamesHeader: 'w-12 sm:w-2/12 lg:w-1/12',
    },
  ],
  [
    {
      id: 'ellipsis',
      type: 'ellipsis',
      usesHeader: 'emptyString',
      classNamesHeader: 'w-12 sm:w-1/12 lg:w-1/12',
      classNamesBody: '-ml-3',
    },
  ],
];

const loanTransactions = [
  [
    { id: 'date', type: 'date', textAlign: 'text-left' },
    { id: 'description', type: 'string', textAlign: 'text-left', classNamesBody: 'truncate', classNamesBoth: 'sm:hidden' },
  ],
  [
    {
      id: 'description',
      type: 'string',
      textAlign: 'text-left',
      classNamesBoth: 'hidden sm:block',
      classNamesHeader: 'sm:w-5/12', // use classNameHeader to set a fixed width on the header
    },
  ],
  [
    {
      id: 'quantity',
      type: 'currency',
      usesHeader: 'principal',
      textAlign: 'text-right',
    },
  ],
  [
    {
      id: 'quantityInterest',
      type: 'currency',
      usesHeader: 'interest',
      textAlign: 'text-right',
    },
  ],
  [
    {
      id: 'ellipsis',
      type: 'ellipsis',
      usesHeader: 'emptyString',
      classNamesHeader: 'w-12',
      classNamesBody: '-ml-3',
    },
  ],
];

const pensionTransactions = [
  [
    { id: 'date', type: 'date', textAlign: 'text-left' },
    // { id: 'description', type: 'string', textAlign: 'text-left', classNamesBody: 'truncate', classNamesBoth: 'sm:hidden' },
  ],
  [
    {
      id: 'description',
      type: 'string',
      textAlign: 'text-left',
      // classNamesBoth: 'hidden sm:block',
      classNamesHeader: 'sm:w-5/12', // use classNameHeader to set a fixed width on the header
    },
  ],
  [
    {
      id: 'quantity',
      type: 'currency',
      usesHeader: 'amount',
      textAlign: 'text-right',
    },
  ],
  [
    {
      id: 'label',
      type: 'label',
      textAlign: 'text-center',
      usesHeader: 'type',
      classNamesHeader: 'sm:w-2/12',
    },
  ],
];

const pensionQuotes = [
  [
    {
      id: 'date',
      type: 'date',
      textAlign: 'text-left', // textAlign on item[0] determines alignment on the whole column and the HTML id of the entire cell
    },
  ],
  [
    { id: 'payoutOnlyContributionsToDate', type: 'currency' },
    { id: 'payoutOnlyContributionsToDateIndexed', type: 'currency' },
  ],
  [
    { id: 'payoutAllPlannedContributions', type: 'currency' },
    { id: 'payoutAllPlannedContributionsIndexed', type: 'currency' },
  ],
];

const metalsTransactions = [
  [
    // prettier-ignore
    {
      ifSummaryRowDisplay: false, id: 'date', type: 'date', textAlign: 'text-left', classNamesBoth: 'text-xs sm:text-sm flex gap-1 items-align', displayElement: { field: 'itemHasFlag', element: WarningWithTooltip },
      // ^^ need this for both columns
    },
    // prettier-ignore
    {
      ifSummaryRowDisplay: false, id: 'openPositionSize', type: 'number0Decimals', classNamesBody: 'text-xs text-gray-400 font-normal', classNamesBoth: 'xs:hidden', hideHeaderLabel: true,
    },
    // prettier-ignore
    {
      ifSummaryRowDisplay: true, id: 'assetName', type: 'string', textAlign: 'text-left', classNamesBoth: 'text-xs sm:text-sm', ifSummaryRowClassNames: 'text-brandBlue-600 truncate', usesHeader: 'displayName'
    },
    // prettier-ignore
    {
      ifSummaryRowDisplay: true, id: 'openPositionSize', type: 'number0Decimals', classNamesBody: 'text-xs text-gray-400 font-normal', classNamesBoth: 'xs:hidden', hideHeaderLabel: true,
    },
  ],
  [
    // prettier-ignore
    {
      id: 'openPositionSize', type: 'number0Decimals', classNamesBody: 'text-xs sm:text-sm', classNamesBoth: 'hidden xs:block', displayElement: { field: 'isPreSplitTransaction', element: SplitIcon },
    },
    { id: 'currentValue', usesHeader: 'currentValue', type: 'currency', classNamesBody: 'text-xs sm:text-sm', classNamesBoth: 'xs:hidden' },
    { id: 'purchaseValue', usesHeader: 'purchaseValueFull', type: 'currency', classNamesBody: 'text-xs sm:text-sm', classNamesBoth: 'xs:hidden' },
  ],
  [
    { id: 'purchaseValue', usesHeader: 'purchaseValueFull', type: 'currency', classNamesBody: 'text-xs sm:text-sm', classNamesBoth: 'hidden xs:block' },
    {
      id: 'purchaseQuote',
      type: 'currency',
      classNamesBody: 'text-xs text-gray-400',
      classNamesBoth: 'hidden xs:block',
      displayElement: { field: 'displayPriceTransactionCurrency', element: OriginalCurrencyComponent },
    },
  ],
  [
    { id: 'currentValue', usesHeader: 'currentValue', type: 'currency', classNamesBody: 'text-xs sm:text-sm', classNamesBoth: 'hidden xs:block' },
    {
      id: 'currentQuote',
      usesHeader: 'currentPrice',
      type: 'currency',
      classNamesBody: 'text-xs text-gray-400',
      classNamesBoth: 'hidden xs:block',
      displayElement: { field: 'displayQuoteTransactionCurrency', element: OriginalCurrencyComponent },
    },
    { id: 'fees', type: 'currency', hideHeaderLabel: true, classNamesBody: 'text-xs sm:text-sm', classNamesBoth: 'xs:hidden' },
  ],
  [{ id: 'fees', type: 'currency', hideHeaderLabel: true, classNamesBody: 'text-xs sm:text-sm', classNamesBoth: 'hidden xs:block' }],
  [
    { id: 'roi', type: 'percentage', usesHeader: 'roiOnly', classNamesBody: 'text-xs sm:text-sm', classNamesBoth: 'hidden xs:block' },
    { id: 'roiAnnual', type: 'percentage', classNamesBody: 'text-xs text-gray-400', classNamesBoth: 'hidden xs:block' },
  ],
  [
    // prettier-ignore
    {
      ifSummaryRowDisplay: false, id: 'ellipsis', type: 'ellipsis', usesHeader: 'emptyString', classNamesHeader: 'w-12', classNamesBody: '-ml-3',
    },
    // prettier-ignore
    {
      ifSummaryRowDisplay: true, id: 'bn', type: 'number0Decimals', hideHeaderLabel: true, displayElement: { field: 'itemHasFlag', element: WarningWithTooltip },
    },
  ],
];

const unlistedSharesTransactions = [
  [
    // prettier-ignore
    {
      ifSummaryRowDisplay: false, id: 'date', type: 'date', textAlign: 'text-left', classNamesBoth: 'text-xs sm:text-sm flex gap-1 items-align', displayElement: { field: 'itemHasFlag', element: WarningWithTooltip },
      // ^^ need this for both columns
    },
    // prettier-ignore
    {
      ifSummaryRowDisplay: false, id: 'openPositionSize', type: 'number0Decimals', classNamesBody: 'text-xs text-gray-400 font-normal', classNamesBoth: 'xs:hidden', displayElement: { field: 'isPreSplitTransaction', element: SplitIcon }, hideHeaderLabel: true,
    },
    // prettier-ignore
    {
      ifSummaryRowDisplay: true, id: 'assetName', type: 'string', textAlign: 'text-left', classNamesBoth: 'text-xs sm:text-sm', ifSummaryRowClassNames: 'text-brandBlue-600 truncate',
    },
    // prettier-ignore
    {
      ifSummaryRowDisplay: true, id: 'openPositionSize', type: 'number0Decimals', classNamesBody: 'text-xs text-gray-400 font-normal', classNamesBoth: 'xs:hidden', displayElement: { field: 'isPreSplitTransaction', element: SplitIcon }, hideHeaderLabel: true,
    },
  ],
  [
    // prettier-ignore
    {
      id: 'openPositionSize', type: 'number0Decimals', classNamesBody: 'text-xs sm:text-sm', classNamesBoth: 'hidden xs:block', displayElement: { field: 'isPreSplitTransaction', element: SplitIcon },
    },
    { id: 'currentValue', usesHeader: 'currentValue', type: 'currency', classNamesBody: 'text-xs sm:text-sm', classNamesBoth: 'xs:hidden' },
    { id: 'purchaseValue', usesHeader: 'purchaseValueFull', type: 'currency', classNamesBody: 'text-xs sm:text-sm', classNamesBoth: 'xs:hidden' },
  ],
  [
    { id: 'purchaseValue', usesHeader: 'purchaseValueFull', type: 'currency', classNamesBody: 'text-xs sm:text-sm', classNamesBoth: 'hidden xs:block' },
    {
      id: 'purchaseQuote',
      type: 'currency',
      classNamesBody: 'text-xs text-gray-400',
      classNamesBoth: 'hidden xs:block',
      displayElement: { field: 'displayPriceTransactionCurrency', element: OriginalCurrencyComponent },
    },
  ],
  [
    { id: 'currentValue', usesHeader: 'currentValue', type: 'currency', classNamesBody: 'text-xs sm:text-sm', classNamesBoth: 'hidden xs:block' },
    {
      id: 'currentQuote',
      usesHeader: 'currentPrice',
      type: 'currency',
      classNamesBody: 'text-xs text-gray-400',
      classNamesBoth: 'hidden xs:block',
      displayElement: { field: 'displayQuoteTransactionCurrency', element: OriginalCurrencyComponent },
    },
    { id: 'dividendsSincePurchase', type: 'currency', classNamesBody: 'text-xs sm:text-sm', classNamesBoth: 'xs:hidden' },
    { id: 'fees', type: 'currency', hideHeaderLabel: true, classNamesBody: 'text-xs sm:text-sm', classNamesBoth: 'xs:hidden' },
  ],
  [
    { id: 'dividendsSincePurchase', type: 'currency', classNamesBody: 'text-xs sm:text-sm', classNamesBoth: 'hidden xs:block' },
    { id: 'fees', type: 'currency', hideHeaderLabel: true, classNamesBody: 'text-xs sm:text-sm', classNamesBoth: 'hidden xs:block' },
  ],
  [
    { id: 'roi', type: 'percentage', usesHeader: 'roiOnly', classNamesBody: 'text-xs sm:text-sm', classNamesBoth: 'hidden xs:block' },
    { id: 'roiAnnual', type: 'percentage', classNamesBody: 'text-xs text-gray-400', classNamesBoth: 'hidden xs:block' },
  ],
  [
    // prettier-ignore
    {
      ifSummaryRowDisplay: false, id: 'ellipsis', type: 'ellipsis', usesHeader: 'emptyString', classNamesHeader: 'w-12', classNamesBody: '-ml-3',
    },
    // prettier-ignore
    {
      ifSummaryRowDisplay: true, id: 'bn', type: 'number0Decimals', hideHeaderLabel: true, displayElement: { field: 'itemHasFlag', element: WarningWithTooltip },
    },
  ],
];

export default function getTableLayout(category, mode) {
  if (!category || !mode) return [];

  if (mode === 'quotes') {
    switch (category) {
      case 'realEstate':
        return realEstateQuotes;
      case 'stocks':
      case 'crypto':
        return stocksQuotes;
      case 'pension':
        return pensionQuotes;
      case 'objectsOfValue':
        return objectsOfValueQuotes;
      case 'metals':
        return metalsQuotes;
      case 'unlistedShares':
        return unlistedQuotes;
      default:
        return [];
    }
  }

  if (mode === 'transactions') {
    switch (category) {
      case 'realEstate':
        return realEstateTransactions;
      case 'stocks':
      case 'crypto':
        return stocksTransactions;
      case 'stocksPositionsReport':
        return stocksTransactionsReport;
      case 'deposits':
        return depositsTransactions;
      case 'loans':
        return loanTransactions;
      case 'pension':
        return pensionTransactions;
      case 'objectsOfValue':
        return objectsOfValueTransactions;
      case 'metals':
        return metalsTransactions;
      case 'unlistedShares':
        return unlistedSharesTransactions;
      default:
        return [];
    }
  }
  return [];
}
