/* eslint-disable max-len */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { XMarkIcon } from '@heroicons/react/24/solid';
import { TableCellsIcon, CogIcon } from '@heroicons/react/20/solid';
import { Switch } from '@headlessui/react';
import ToolTipNoIcon from '../../elements/ToolTipNoIcon';
import { clearAlert } from '../../redux/actions/message';
import GrowthRate from './GrowthRate';
import RealEstateValuations from './RealEstateValuations';
import AccountSettingsMetals from './AccountSettingsMetals';
import DividendRates from '../settings/DividendRates';
import CategoryGrowthRates from '../settings/CategoryGrowthRates';
import { putAccount } from '../../redux/reducers/data';
import Button from '../../elements/Button';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export default function AccountSettings({ account: propAccount, setDisplayedComponent }) {
  // need to get account information from redux store, as the prop is not updated in Tile when the account object changes in store
  const account = useSelector((state) => state.data[propAccount.category].accounts).filter((a) => a.id === propAccount.id)[0];

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const alert = useSelector((state) => state.message.alert);

  const [autoImportPretransformation, setAutoImportPretransformation] = useState(account.tags.applyImportTransformationsAutomatically);
  const [autoImportPretransformationDirty, setAutoImportPretransformationDirty] = useState(false);
  const [autoImportPretransformationLoading, setAutoImportPretransformationLoading] = useState(false);

  // TODO
  function handleNavigateAway() {
    // if (!isSubmitted) dispatch(setAlert('aboutToLoseData', account.id));
  }

  useEffect(() => {
    if (alert?.id === 'aboutToLoseData' && alert?.caller === account.id && alert?.response) {
      // cleanup alert
      dispatch(clearAlert());

      if (alert?.response === 'ok') {
        setDisplayedComponent('table');
      }
    }
  }, [alert]);

  function handleSaveAutoImportPretransformation() {
    setAutoImportPretransformationLoading(true);
    dispatch(
      putAccount({
        data: { ...account, tags: { ...account.tags, applyImportTransformationsAutomatically: autoImportPretransformation } },
        category: account.category,
      }),
    ).then(
      () => {
        // handle ok
        setAutoImportPretransformationLoading(false);
        setAutoImportPretransformationDirty(false);
      },
      () => {
        // handle error
        setAutoImportPretransformationLoading(false);
      },
    );
  }

  return (
    <>
      {/* BUTTONS */}
      <section className="ml-1 flex flex-col xs:flex-row gap-2 justify-between w-full">
        <div className="flex flex-rows gap-2 sm:gap-6" id="account-details-table-buttons">
          {/* FIXME: this is a div which needs its own className at times; change the existing className, which applies to the tooltip displayed, to something else */}
          <ToolTipNoIcon info={t('app:accountDetails.buttons.save.tooltip')}>
            <button
              type="button"
              id="account-details-settings-close-button"
              onClick={() => setDisplayedComponent('table')}
              className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-bold rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-400"
            >
              <XMarkIcon className="-ml-1 mr-2 h-5 w-5 text-gray-600 hidden lg:block" aria-hidden="true" />
              {t('app:accountDetails.buttons.close.label')}
            </button>
          </ToolTipNoIcon>
          {/* TABLE - EDIT - SETTINGS */}
          <span className="isolate inline-flex rounded-md shadow-sm">
            <ToolTipNoIcon info={t('app:accountDetails.buttons.table.tooltip')}>
              <button
                type="button"
                onClick={handleNavigateAway}
                className="'inset relative inline-flex items-center px-4 py-2 rounded-l-md border border-gray-300 bg-white hover:bg-gray-50 text-sm font-bold text-gray-700 focus:z-10 focus:outline-none focus:ring-1 focus:ring-brandBlue-400 focus:border-brandBlue-400"
              >
                <TableCellsIcon className="-ml-1 mr-2 h-4 w-4 hidden lg:block" aria-hidden="true" />
                {t('app:accountDetails.buttons.table.label')}
              </button>
            </ToolTipNoIcon>
            <ToolTipNoIcon info={t('app:accountDetails.buttons.edit.tooltip')} classNameOwn="hidden lg:block">
              <button
                type="button"
                id="acccount-details-table-edit-button"
                name="editbutton"
                onClick={handleNavigateAway}
                className={classNames(
                  '-ml-px inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-bold text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-400',
                )}
              >
                <PencilSquareIcon className="-ml-1 mr-2 h-4 w-4 hidden lg:block" aria-hidden="true" />
                Edit
              </button>
            </ToolTipNoIcon>
            <ToolTipNoIcon info={t('app:accountDetails.buttons.settings.tooltip')}>
              <button
                type="button"
                name="settingsbutton"
                className="-ml-px relative inline-flex items-center px-4 py-2 rounded-r-md border border-gray-300 bg-gray-200 text-sm font-bold text-gray-700 focus:z-10 focus:outline-none focus:ring-1 focus:ring-brandBlue-400 focus:border-brandBlue-400"
              >
                <CogIcon className="-ml-1 mr-2 h-4 w-4 hidden lg:block" aria-hidden="true" />
                {t('app:accountDetails.buttons.settings.label')}
              </button>
            </ToolTipNoIcon>
          </span>
        </div>
      </section>

      {/* CONTENTS */}
      <form className="space-y-8 w-full overflow-y-auto scrollbar-thin scrollbar-track-gray-100 scrollbar-thumb-gray-300 hover:scrollbar-thumb-zinc-400" id="account-details-settings">
        <section aria-labelledby="settings" className="h-full">
          <div className="shadow w-full sm:rounded-md bg-white">
            {/* SIMULATED GROWTH RATE */}

            <div className="py-6 px-4 sm:p-6 space-y-6">
              <div>
                <h2 id="payment-details-heading" className="text-lg font-medium leading-6 text-gray-900">
                  {t('app:accountDetails.settings.simulatedAccountGrowth.heading')}
                </h2>
              </div>

              {['stocks', 'crypto'].includes(account.category) ? (
                <>
                  <CategoryGrowthRates setIsDirty={() => {}} displayOnly={account.category} />
                  {account.category === 'stocks' && <DividendRates setIsDirty={() => {}} />}
                </>
              ) : (
                <GrowthRate propAccount={propAccount} />
              )}

              {account.category === 'realEstate' && (
                <>
                  <div>
                    <h2 id="payment-details-heading" className="text-lg font-medium leading-6 text-gray-900">
                      {t('app:accountDetails.settings.realEstateValuations.heading')}
                    </h2>
                  </div>
                  <div className="mt-4 grid grid-cols-6 gap-6">
                    <div className="col-span-2">
                      <p className="mt-1 mb-4 text-xs xs:text-sm text-gray-500">{t('app:accountDetails.settings.realEstateValuations.label')}</p>
                    </div>
                    <RealEstateValuations account={account} />
                  </div>
                </>
              )}

              {account.category === 'metals' && <AccountSettingsMetals propAccount={propAccount} />}
            </div>

            {/* AUTOMATIC PRETRANSFORMATION */}

            {['deposits', 'stocks', 'realEstate', 'pension', 'loans'].includes(account.category) && (
              <div className="py-6 px-4 sm:p-6 space-y-6">
                <h2 id="auto-import-pretransformation-heading" className="text-lg font-medium leading-6 text-gray-900">
                  {t('app:accountDetails.settings.autoImportPretransformation.heading')}
                </h2>
                <div className="grid grid-cols-6">
                  <p className="prose mt-4 text-xs xs:text-sm text-gray-500 col-span-2">{t('app:accountDetails.settings.autoImportPretransformation.body')}</p>
                  <Switch.Group as="div" className="flex items-center col-span-2">
                    <Switch
                      checked={autoImportPretransformation}
                      onChange={() => {
                        setAutoImportPretransformation(!autoImportPretransformation);
                        setAutoImportPretransformationDirty(true);
                      }}
                      className={classNames(
                        autoImportPretransformation ? 'bg-brandBlue-500' : 'bg-gray-200',
                        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brandBlue-600',
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          autoImportPretransformation ? 'translate-x-5' : 'translate-x-0',
                          'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200',
                        )}
                      />
                    </Switch>
                    <Switch.Label as="span" className="ml-3">
                      <span className="text-sm font-medium text-gray-900">{t('app:accountDetails.settings.autoImportPretransformation.label')}</span>
                    </Switch.Label>
                  </Switch.Group>
                  {autoImportPretransformationDirty && (
                    <Button text="Save" withAccent onClick={handleSaveAutoImportPretransformation} spinnerOn={autoImportPretransformationLoading} size="md" formatting="mt-4 col-start-3 w-20" />
                  )}
                </div>
              </div>
            )}
          </div>
        </section>
      </form>
    </>
  );
}
