/* eslint-disable no-loop-func */
/** This package is a wrapper around @jspreadsheet/formula package, which - due to its usage of eval()
 *  poses a security risk.
 */

// eslint-disable-next-line import/no-unresolved
const formulajs = require('@formulajs/formulajs');

/**
 * Resolves basic mathematical expressions (without parentheses).
 * Supported: +, -, *, /, **
 *
 * @param {string} expression (no spaces, no parentheses)
 * @returns {number}
 */
export function resolveExpression(expression) {
  if (!expression || Object.keys(expression).length === 0) return null;
  // Remove spaces for consistency
  // expression = expression.replace(/\s+/g, '');

  // First, split the expression into tokens (numbers and operators)
  // Note: We use a regex pattern that respects the ** operator
  const tokens = expression.match(/(?<!\d)-?\d+(\.\d+)?|(\*\*)|[+*/-]/g).filter(Boolean);

  // Step 1: Handle ** (exponentiation)
  let i = 0;
  while (i < tokens.length) {
    if (tokens[i] === '**') {
      const base = parseFloat(tokens[i - 1]);
      const exponent = parseFloat(tokens[i + 1]);

      // Perform exponentiation
      const result = base ** exponent;

      // Replace base, operator, and exponent with the result
      tokens.splice(i - 1, 3, result.toString());

      // Move index back since we reduced the array size
      i -= 1;
    } else {
      i += 1;
    }
  }

  // Step 2: Handle * and /
  i = 0;
  while (i < tokens.length) {
    if (tokens[i] === '*' || tokens[i] === '/') {
      const operator = tokens[i];
      const prevNumber = parseFloat(tokens[i - 1]);
      const nextNumber = parseFloat(tokens[i + 1]);

      // Perform multiplication or division
      const result = operator === '*' ? prevNumber * nextNumber : prevNumber / nextNumber;

      // Replace previous number, operator, and next number with the result
      tokens.splice(i - 1, 3, result.toString());

      // Move index back since we reduced the array size
      i -= 1;
    } else {
      i += 1;
    }
  }

  // Step 3: Handle + and -
  i = 0;
  while (i < tokens.length) {
    if (tokens[i] === '+' || tokens[i] === '-') {
      const operator = tokens[i];
      const prevNumber = parseFloat(tokens[i - 1]);
      const nextNumber = parseFloat(tokens[i + 1]);

      // Perform addition or subtraction
      const result = operator === '+' ? prevNumber + nextNumber : prevNumber - nextNumber;

      // Replace previous number, operator, and next number with the result
      tokens.splice(i - 1, 3, result.toString());

      // Move index back since we reduced the array size
      i -= 1;
    } else {
      i += 1;
    }
  }

  // The final result will be the only remaining item in the tokens array
  return parseFloat(tokens[0]);
}

/**
 * Wrapper for formulajs functions.
 *
 * @param {string} functionName
 * @param {string} bracketContent (with no brackets or cell references)
 * @returns
 */
export function resolveFunction(functionName, bracketContent) {
  // parse the arguments
  // run it through resolveExpression in case this isn't a number, but an expression
  const args = bracketContent.split(',').map((arg) => resolveExpression(arg));
  try {
    return formulajs[functionName.toUpperCase()](...args);
  } catch (error) {
    throw new Error(`resolveFunction: Function ${functionName} not found in formulajs`);
  }
}

/**
 * Takes an excel function expression and evaluates it.
 *
 * @param {string} expression: excel function expression without the "=" sign or spaces (e.g. "SUM(1,2,3)"" or "SUM(A1,A2,A3)")
 * @param {object} variables: for every cell reference in the expression, the value to replace it with (e.g. { A1: 1, A2: 2, A3: 3 })
 * @returns {number}
 */
export function evaluateExpression(expression, variables = {}) {
  // console.log('evaluateExpression started with', expression, variables);
  // Step 1: Check for balanced parentheses
  const openBrackets = (String(expression).match(/\(/g) || []).length;
  const closeBrackets = (String(expression).match(/\)/g) || []).length;
  if (openBrackets !== closeBrackets) {
    throw new Error('jspreadsheetFormulaEvaluator > evaluateExpression: Error: Unbalanced parentheses in expression');
  }

  // replace all cell references with their values
  const expressionNoCellRefs = expression.replace(/[A-Za-z]{1,2}\d{1,3}/g, (cellAddress) => {
    // if the cell address is not in the variables, return the cell address
    if (!variables[cellAddress]) {
      throw new Error(`evaluateFunction: Cell reference ${cellAddress} not found in variables`);
    }
    return variables[cellAddress];
  });

  // Step 2: Copy expression to workingExpression
  let workingExpression = expressionNoCellRefs;

  // Step 3: Start loop
  // enter / continue the loop if expression isn't a number or NaN (which is also a kind of number)
  while (typeof workingExpression !== 'number' && !Number.isNaN(workingExpression)) {
    const innermostBrackets = [];

    // Step 4: Find innermost bracket sets
    // const regex = /\(([^()]+)\)/g; // Regex to find the innermost brackets
    const regex = /([A-Za-z]+\w*)?\(([^()]+)\)/g;
    const matchAllNestedBrackets = workingExpression.matchAll(regex);

    // matchAll returns a special kind of object, which needs to be mapped to an array before using
    const matchAllNestedBracketsArray = Array.from(matchAllNestedBrackets).map((match) => ({
      expression: match[0],
      capturedGroup: match[1],
      index: match.index,
    }));

    // Iterate over the results
    matchAllNestedBracketsArray.forEach((match) => {
      const startPos = match.index;
      const endPos = startPos + match.expression.length;

      // if there is a function ABC(...), then match.capturedGroup will be ABC)
      // otherwise it will be undefined
      const excelFunction = match.capturedGroup;

      // Store in innermostBrackets as { bracket, excelFunction, position, length }
      innermostBrackets.push({
        match, // the whole match object
        expression: match.expression, // the whole expression from beginning to end
        bracketContents: match.expression.match(/\(([^()]+)\)/)[1], // the contents of the bracket
        excelFunction, // the function name, if any
        position: startPos, // the starting position of the bracket in the workingExpression
        length: endPos - startPos, // the length of the bracket
      });
    });

    // if we detected no brackets and (since workingExpression was not a number in the beginning of this loop), this must be an expression
    if (innermostBrackets.length === 0) {
      return resolveExpression(workingExpression);
    }

    // Step 5: Pop and resolve innermost brackets
    while (innermostBrackets.length > 0) {
      const { excelFunction, bracketContents, position, length } = innermostBrackets.pop(); // Pop the last innermost bracket set

      // Resolve the expression or function
      let resolvedValue;
      if (excelFunction) {
        resolvedValue = resolveFunction(excelFunction, bracketContents, {});
      } else {
        resolvedValue = resolveExpression(bracketContents);
      }

      // Replace the substring with the resolved value
      workingExpression = workingExpression.slice(0, position) + resolvedValue.toString() + workingExpression.slice(position + length);
    }
  }

  // Return the final resolved value
  return parseFloat(workingExpression);
}
