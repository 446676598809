/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ToolTip from '../../../elements/ToolTip';

/**
 * Displays a button. When clicked, it will expand in place showing a list of buttons with icons, labels, info texts and buttons.
 *
 * @param {string} keyPrefix - which translation key to use
 * @param {object} control - react-hook-form control object
 * @param {string} label - label for the field
 * @param {object[]} list - list of objects containing id, name, description and icon; id is returned to the form
 * @param {boolean} optional - whether the field is optional
 * @param {boolean} initialiseOpen - whether the list is open on initialisation
 *
 * @returns React.Component; id is returned to the form state.
 *
 * Layout placement: this expects to be placed inside a grid layout (cols: 1 | xs+: 2 | lg+: 3)
 */
export default function CollapsibleLabelButtons({ id, keyPrefix, control, label, list, optional, initialiseOpen = false }) {
  // if need more than one translation, switch id
  const { t } = useTranslation('app', { keyPrefix });

  const [expanded, setExpanded] = useState(initialiseOpen);

  function handleClick() {
    setExpanded(true);
  }

  return (
    <Controller
      name={id}
      control={control}
      render={({ field: { onChange, onBlur, value } }) => {
        // ACTUAL COMPONENT STARTS HERE
        const computedValue = list.find((item) => item.id === value); // this will be undefined if value is not in list

        function handleSelect(e, item) {
          console.log('handleSelect', JSON.stringify(item, null, 2));
          onChange(item.id);
          setExpanded(false);
        }

        // COLLAPSED STATE (display button with selected option)

        if (!expanded) {
          return (
            <div onBlur={onBlur} className="col-span-1 flex flex-col space-y-1">
              <div className="flex justify-between text-sm">
                <div className="flex">
                  <label htmlFor="button-collapsible-component" className="font-medium text-gray-700">
                    {label}
                  </label>
                  <ToolTip info={t('tooltip')} />
                </div>
                {optional && (
                  <span className="text-sm text-gray-500" id="email-optional">
                    Optional
                  </span>
                )}
              </div>
              <div className="flex items-center w-full">
                <div className="flex gap-2 items-center w-full">
                  <button
                    id="button-collapsible-component"
                    type="button"
                    // eslint-disable-next-line max-len
                    className={`${optional} rounded-md w-full px-4 py-2 text-sm font-bold shadow-sm border border-gray-300 bg-white text-gray-900 hover:bg-gray-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-brandBlue-500`}
                    onClick={handleClick}
                  >
                    {t(`${computedValue?.id}.name`)}
                  </button>
                </div>
              </div>
            </div>
          );
        }

        // EXPANDED STATE (display list of buttons)

        return (
          <>
            {list.map((item) => (
              <button
                key={item.name}
                onClick={(e) => handleSelect(e, item)}
                type="button"
                className="flex flex-col border bg-white border-gray-100 shadow-sm rounded-md p-4 cursor-pointer hover:border-gray-300"
              >
                <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                  <item.Icon className="h-5 w-5 flex-none text-brandBlue-500" aria-hidden="true" />
                  {item.name}
                  {item.id === computedValue?.id && (
                    <span className="-mb-0.5 inline-flex items-center rounded-md bg-brandBlue-50 px-2 py-1 text-xs font-medium text-brandBlue-700 ring-1 ring-inset ring-brandBlue-600/20">
                      {t('selected')}
                    </span>
                  )}
                </dt>
                <dd className="mt-4 flex flex-auto flex-col text-base leading-7 text-gray-600">
                  <p className="text-left flex-auto text-sm">{item.description}</p>
                  <p className="mt-6">
                    <div className="text-left text-sm font-semibold leading-6 text-brandBlue-500">
                      Select
                      {' '}
                      <span aria-hidden="true">→</span>
                    </div>
                  </p>
                </dd>
              </button>
            ))}
          </>
        );
      }}
    />
  );
}

CollapsibleLabelButtons.propTypes = {
  id: PropTypes.string.isRequired,
  keyPrefix: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  label: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  optional: PropTypes.bool,
  initialiseOpen: PropTypes.bool,
};
CollapsibleLabelButtons.defaultProps = {
  optional: false,
  initialiseOpen: false,
};
