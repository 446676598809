{
  "general": {
    "loginFailed": "Your username or password were incorrect, please try again.",
    "refreshTokenExpired": "You have been logged in for too long. For security reasons, please log in again.",
    "open": "Open",
    "save": "Save",
    "cancel": "Cancel",
    "error": "Error"
  },
  "header": {
    "projects": "Projects",
    "reports": "Reports",
    "help": "Help & Support",
    "survey": "UX Test: Survey",
    "profile": "Your profile",
    "settings": "Settings",
    "recommendUs": "Recommend us!",
    "signOut": "Sign out",
    "noMessages": "No notifications"
  },
  "dashboard": {
    "yourAssets": "Your assets",
    "yourAssetsOn": "Your assets on",
    "setDate": "Set date for this view",
    "edit": "Edit",
    "share": "Share",
    "done": "Finish edit",
    "pleaseWait": "Please wait while we load your data...",
    "postFile": {
      "header": "Which account?",
      "prompt": "Please select the account to which you want to import the file."
    },
    "shareReport": {
      "header": "Share Dashboard",
      "tooltip": "We will create a link for you to share your dashboard with others. Please set a simple passcode to protect your data.",
      "next": "Next",
      "confirmation": {
        "copy": "Copy",
        "copyBoth": "Send the link and the passcode to the recipient. They will get access to your dashboard with today's data.",
        "securityHint": "If possible, do not send the passcode and the link in the same message or in the same chat."
      }
    },
    "sharedReportViewer": {
      "header": "Passcode",
      "prompt": "Please enter your passcode to access the Dashboard.",
      "reportNotFound": "Dashboard not found",
      "unableToLoadReport": "We were unable to load the report. Please check the passcode and try again."
    },
    "categories": {
      "deposits": "Bank accounts",
      "stocks": "Stockbroker accounts",
      "pension": "Pension plans",
      "realEstate": "Real estate",
      "objectsOfValue": "Objects of value",
      "metals": "Precious metals",
      "loans": "Loans",
      "unlistedShares": "Unlisted shares",
      "crypto": "Cryptocurrencies"
    },
    "singleCategory": {
      "deposits": "Bank account",
      "stocks": "Stock brokerage account",
      "pension": "Pension plan",
      "realEstate": "Real estate",
      "objectsOfValue": "Object of value",
      "metals": "Precious metal",
      "loans": "Loan",
      "unlistedShares": "Unlisted shares",
      "crypto": "Cryptocurrency"
    },
    "sumtile": {
      "change": "Change vs. the same indicator in previous period.",
      "inconsistent": "Inconsistent transactions",
      "warning": "There are inconsistencies in your data which may affect this number. Click here to see details.",
      "orphanedDialog": {
        "assetTransactionsDialogTitle": "Asset transactions with no deposit transactions",
        "netWorthExplanation": "In order to calculate your net worth and its changes correctly, each sale of asset needs to be linked to a transaction on a bank / cash account. You can do that by labelling the correct bank transaction in your bank account.",
        "notLinkedTransactionsExplanation": "The following transactions are not linked to any deposit transaction. Click one to go to the first linked deposit account for that transaction and label the matching transaction there.",
        "transactionTableHeaders": {
          "date": "Date",
          "assetAccount": "Asset account",
          "assetName": "Asset name",
          "amount": "Amount",
          "price": "Value"
        },
        "closeButtonText": "Close"
      },
      "generalIncome": {
        "label": "General income",
        "tooltip": "Income from external sources, such as employment."
      },
      "incomeFromCapital": {
        "label": "Income from capital",
        "tooltip": "Income from capital, such as dividends or gains/losses from sales of investments. Blue circle next to a transaction means that this transaction is included in this category."
      },
      "assetValueIncrease": {
        "label": "Asset value change",
        "tooltip": "Change in the value of assets, such as stocks, bonds, real estate, etc."
      },
      "outflows": {
        "label": "Outflows",
        "tooltip": "General expenses, such as rent, utilities, etc. Purchases of investment assets are not included in this category"
      },
      "previousValue": {
        "label": "Baseline value",
        "tooltip": "Total value of all your assets at the beginning of the baseline period that you selected below.",
        "selectBaseline": "Select a baseline date:",
        "sameTimeLastYear": "One year ago",
        "beginning": "Beginning of this year",
        "beginningLastYear": "Beginning of last year",
        "custom": "Custom"
      },
      "changeInValue": {
        "label": "Change in value",
        "tooltip": "Difference between the total value of all your assets at the beginning of the period that you selected and today."
      },
      "currentNetWorth": {
        "label": "Current net worth",
        "tooltip": "Value of all your assets minus value of all loans that you took."
      }
    }
  },
  "charts": {
    "last12months": "Account balances",
    "currentAllocation": "Current allocation (positive assets only)",
    "goToReports": "Go to Reports",
    "mostRecentTransactions": "Most recent transactions",
    "mostRecentQuotes": "Most recent valuations",
    "currentInformation": "Current info",
    "currentPositions": "Current holdings",
    "cannotDisplayNegative1": "The selected field combination yields negative values for the following combination of parameters: ",
    "cannotDisplayNegative2": ". This chart does not display negative values correctly. Please select a different set of parameters.",
    "rebalancing": "Rebalancing",
    "interest": "Interest",
    "principal": "Principal"
  },
  "addAccount": {
    "addNewAccount": "Add new account",
    "clickSubmitToContinue": "Click Submit to continue",
    "thatsAllWeNeed": "That's all we need",
    "errorCompleteStep": "Please complete the current step in order to proceed.",
    "back": "Back",
    "next": "Next",
    "cancel": "Cancel",
    "submit": "Submit",
    "pressNextToContinue": "Press >Next< to continue.",
    "pressSubmitToFinish": "Press >Submit< to finish.",
    "currentBalance": "Current balance",
    "D1": {
      "accountType": "Account type",
      "whatKindOfAccount": "What kind of account do you want to add? Select the option which is the best match for your account",
      "products": {
        "savings-account-giro": "Bank account for your daily expenditure and spending. You are able to withdraw and deposit money at any time.",
        "savings-account-tagesgeld": "A savings account where - in theory - you get slightly more interest than with your Girokonto, but are often limited as to when you can withdraw.",
        "savings-account-generic": "Bank account for your daily expenditure and spending. You are able to withdraw and deposit money at any time.",
        "time-deposit-festgeld": "Bank deposit which you cannot withdraw until it reaches its maturity date.",
        "time-deposit-generic": "Bank deposit which you cannot withdraw until it reaches its maturity date."
      }
    },
    "D2": {
      "automaticOrManual": "Automatic or manual updates",
      "updateAutomatically": "Would you like us to get your data automatically? You can change it later.",
      "automatic": {
        "label": "Automatic synchronisation",
        "description": "We will set up a secure connection to your bank and take care of retrieving the lastest transactions. You may need to use your bank app to confirm that process. Manual import is still possible."
      },
      "manual": {
        "label": "Manual updates",
        "description": "You can also use our intelligent data import to easily transfer data about your accounts. All you need to do is download the transactions from your bank website and upload them here."
      }
    },
    "D3": {
      "bank": "bank",
      "banks": "banks",
      "aBank": "a bank",
      "yourBank": "your bank",
      "broker": "broker",
      "brokers": "brokers",
      "aBroker": "a broker",
      "yourBroker": "your broker",
      "selectCountry": "Select country",
      "accountCountry": "Account country",
      "accountCountryBody": "In which country is your account (or the provider of your account) located?",
      "selectCountryFirst": "Select account country above first.",
      "select": "Select {{yourInstitutionType}}",
      "retrievingInstitutions": "We are preparing the list...",
      "nothingFound": "We cannot retrieve data from {{yourInstitutions}} in this country yet. You can add a bank manually by clicking the button below.",
      "noSlots": "You have reached the limit of foreign {{yourInstitutions}} that we can provide automatic updates for. You can add a bank manually by clicking the button below.",
      "noResultsFound": "No results found.",
      "findYourBank": "Find {{yourInstitutionType}} in the list to synchronise your data automatically.",
      "cannotFind": "If you can't find {{yourInstitutionType}} here, automatic data synchronisation is not yet possible. ",
      "addManualAccount": "Add {{anInstitutionType}} manually",
      "thisAccountManual": "This account will not be synchronised automatically. Data can be imported from a file or input manually.",
      "thisAccountManual2": "To enable automatic synchronisation, please select {{anInstitutionType}} in the list above.",
      "thisAccountManual3": "Press >Next< to continue.",
      "intelligentImport": "Find your bank in the list so that our intelligent import knows what data structure to expect.",
      "couldNotFindAnything": "We couldn't find anything with that term. Please try again.",
      "youHaveSelected": "You have selected",
      "yourSelection": "Your selection",
      "details": "Details of the selected bank",
      "search": "Search"
    },
    "D4": {
      "account": "Account",
      "accountInfo": "Account information",
      "accountInfoManual": "Please provide some information about your account.",
      "accountInfoAutomatic": "We have retrieved this information about your account from your bank.",
      "accountInfoAutomatic2": " Uncheck >This account will be created< to stop it from being created in Monestry.",
      "connectedCashAccount": "Connected cash account",
      "whichCashAccount": "Which cash account do you use to buy and sell securities?",
      "whyDoWeAsk": "Why do we need a connected cash account?",
      "whyDoWeAskAnswer": "We calculate return from investment and income from capital based on data from cash accounts, which the stocks transactions are deducted from or credited to.",
      "notHaveAccount": "What if I don't have a separate cash account?",
      "notHaveAccountAnswer": "If your broker handles all cash transactions, we will create a 'technical account' for you so that you have a better picture of all the cash operations - just pick that option on the bottom of the form.",
      "whenIBuy": "When I buy securities, the money...",
      "existingAccount": "... is deducted from an existing bank account",
      "existingAccountSelect": "Select that bank account",
      "createTechnicalAccount": "... is deducted from a cash account at my brokerage firm",
      "createTechnicalAccountCurrency": "In what currency is this cash account?",
      "createAccountLater": "... is deducted from a bank account which I will set up later",
      "createAccountLaterDescription": "Once you set up that account, remember to update the linked account in this account.",
      "gettingAdditionalAccountInformation": "Getting additional account information...",
      "providerAllAccountsAdded": "No new accounts found. All accounts have been added.",
      "accountName": {
        "label": "Account name",
        "tooltip": "What name would you like to see for this account in the system?",
        "error": "Please enter a designation for this account."
      },
      "baseCurrency": {
        "label": "Base currency",
        "tooltip": "What currency does this account use?"
      },
      "iban": {
        "label": "IBAN",
        "tooltip": "This account's IBAN (account number)",
        "placeholder": "DE12345678901234567890"
      },
      "bic": {
        "label": "BIC",
        "tooltip": "International bank code (called >BIC<) for the bank where this account is at. Up to 11 characters long. Used to identify a bank in an international setting."
      },
      "bankName": {
        "label": "Bank name",
        "tooltip": "Name of the bank where this account is at.",
        "placeholder": "Sparkasse Bielefeld"
      },
      "brokerName": {
        "label": "Provider name",
        "tooltip": "Which broker is this account with?",
        "placeholder": "DepotX"
      },
      "accountNo": {
        "label": "Account number",
        "tooltip": "What is the number of your brokerage account?",
        "placeholder": "C12345678"
      },
      "isImported": {
        "label": "This account will be created.",
        "tooltip": "Untick this box to stop this account from being created.",
        "error": "You can only create {{num}} more accounts on your current plan.",
        "error1": "You can only create one more account on your current plan.",
        "error0": "You cannot create any more accounts on your current plan."
      },
      "connectedDepositMode": {
        "error": "Please choose how to link a deposit account to this one."
      }
    },
    "R1": {
      "accountInfo": "Account information",
      "provideInfo": "Provide some information about your property.",
      "oneAccount": "One account covers exactly one property - if you own more than one, create more accounts.",
      "whyDoWeAsk": "Why do we want to know?",
      "whyDoWeAskAnswer": "These are all the parameters used to calculate your property's value.",
      "doIHaveToFillThemAllOut": "Do I have to fill out all those fields?",
      "doIHaveToFillThemAllOutAnswer": "No, but the more information you provide, the better the automatic valuation will be.",
      "name": {
        "label": "Property name",
        "tooltip": "What name would you like to see for this property in the app?",
        "placeholder": "Apartment in Bielefeld",
        "error": "Please enter your designation for this property."
      },
      "originalPrice": {
        "label": "Purchase price",
        "tooltip": "How much did it cost you? You can change it later.",
        "placeholder": "456000",
        "error": "If purchase date is provided, we will also need a purchase price for this property. You can change it later."
      },
      "date": {
        "label": "Purchase date",
        "tooltip": "Since when do you own it? You can change it later.",
        "placeholder": "2015-05-15",
        "error": "If purchase price is entered, we will also need a purchase date for this property. You can change it later."
      },
      "automaticUpdate": {
        "label": "Update value automatically?",
        "tooltip": "Turn it on to allow automatic updates for this property's value. You can change it later.",
        "disabled": "Automatic value updates for your account are in use for other properties or not available."
      },
      "currency": {
        "label": "Currency",
        "tooltip": "What currency would you like to track this property's value in? Typically this is the currency of the country where the property is located. We will automatically convert that value to your base currency."
      },
      "kindOfProperty": "What kind of property is this?",
      "type": {
        "label": "Property type",
        "apartment": "Apartment",
        "house": "House"
      },
      "streetAddress": {
        "label": "Street and number",
        "tooltip": "Where is your property located?",
        "placeholder": "Hochstr. 12"
      },
      "zip": {
        "label": "Postal code",
        "tooltip": "What postal code does your property have?",
        "placeholder": "33602"
      },
      "city": {
        "label": "City / town",
        "tooltip": "What city / town / village is your property located at?",
        "placeholder": "Bielefeld"
      },
      "country": {
        "label": "Country",
        "tooltip": "What country is your property located in?",
        "placeholder": "Germany"
      },
      "livingArea": {
        "label": "Floor area",
        "tooltip": "How big is your property (in square meters)?",
        "placeholder": "88"
      },
      "rooms": {
        "label": "Rooms",
        "tooltip": "How many rooms does your property have?",
        "placeholder": "3"
      },
      "lotArea": {
        "label": "Lot area",
        "tooltip": "How big is the lot your property is located on (in square meters)?",
        "placeholder": "450"
      },
      "constructionYear": {
        "label": "Construction year",
        "tooltip": "When was your property built?",
        "placeholder": "2010"
      },
      "parking": {
        "label": "Private parking included?"
      }
    },
    "S1": {
      "accountInformation": "Account information",
      "description": "Provide some information about your account.",
      "whyDoWeAsk": "Why do we ask?",
      "whyDoWeAskAnswer": "We use data like account number, username, connected cash account to link cash transactions with your stock market transactions. You don&apos;t have to provide them, but our system may not be able to link the appropriate transactions and you may need to do that manually.",
      "name": {
        "label": "Account name",
        "tooltip": "What name would you like to see for this account in this app?",
        "placeholder": "DepotX brokerage account",
        "error": "Please enter your designation for this account."
      },
      "brokerName": {
        "label": "Provider name",
        "tooltip": "Which broker is this account with?",
        "placeholder": "DepotX"
      },
      "accountNo": {
        "label": "Account number",
        "tooltip": "What is the number of your brokerage account?",
        "placeholder": "C12345678"
      },
      "username": {
        "label": "Your username for this brokerage account",
        "tooltip": "The username you use to log in to this account.",
        "placeholder": "ab12345678"
      }
    },
    "S2": {
      "connectedCashAccount": "Connected cash account",
      "whichCashAccount": "Which cash account do you use to buy and sell securities?",
      "whyDoWeAsk": "Why do we need a connected cash account?",
      "whyDoWeAskAnswer": "We calculate return from investment and income from capital based on data from cash accounts, which the stocks transactions are deducted from or credited to.",
      "notHaveAccount": "What if I don't have a separate cash account?",
      "notHaveAccountAnswer": "If your broker handles all cash transactions, we will create a 'technical account' for you so that you have a better picture of all the cash operations - just pick that option on the bottom of the form.",
      "whenIBuy": "When I buy securities, the money...",
      "existingAccount": "... is deducted from an existing bank account",
      "existingAccountSelect": "Select that bank account",
      "createTechnicalAccount": "... is deducted from a cash account at my brokerage firm",
      "createTechnicalAccountCurrency": "In what currency is this cash account?",
      "createAccountLater": "... is deducted from a bank account which I will set up later",
      "createAccountLaterDescription": "As soon as the bank account is added, open the detail view of this account and link the bank account there."
    },
    "loans": {
      "given": "Loan given",
      "taken": "Loan taken",
      "provideSomeParameters": "Please provider some parameters which we will use to simulate future loan payments. You don't need to enter past changes, as they are reflected in the loan account transactions already.",
      "loanAmount": {
        "label": "Loan amount",
        "tooltip": "Enter the total amount of the loan (principal / capital only).",
        "error": "Please enter the loan amount."
      },
      "direction": {
        "label": "Loan taken or given?",
        "tooltip": "If you borrowed money, select 'taken'; otherwise select 'given'.",
        "error": "Please mark if this is a taken or given loan."
      },
      "period": {
        "month": "month",
        "quarter": "quarter",
        "year": "year",
        "label": "Installments repaid every",
        "placeholder": "Select period",
        "tooltip": "How often do you pay loan installments?",
        "error": "Please select the installment period."
      },
      "interestRate": {
        "text": "Interest rate and in some cases the installment amount may change over time. Use >Advanced< button if you wish to enter those changes now. You can also do it later.",
        "label": "Interest rate (%)",
        "tooltip": "Enter the annual interest rate for the loan.",
        "error": "Please enter the annual interest rate in %."
      },
      "endDate": {
        "text": "If you enter an end date, the simulation will calculate last installment as the entire outstanding debt (also know as a balloon payment or Restschuld). If you plan to take out a different loan, enter the end date here and create another loan account starting on the month of the last installment of this loan.",
        "label": "Loan contract end date",
        "tooltip": "When does the loan contract end? You can leave this field empty.",
        "error": "It must be a future date"
      },
      "calculateUntilEnd": {
        "label": "Simulate until completely repaid",
        "tooltip": "If you want to simulate the loan payments until the end of the loan term, select this option."
      },
      "advanced": "Advanced",
      "installmentCalc": "Is the loan installment the same every time? Select 'Annuity'. Is it lower every time (you pay back a fixed amount of capital every month with changing interest)? Select 'Fixed Principal'. If you only pay back the interest (capital is repaid at term's end), select 'Interest Only'. Don't forget to enter an end date so that we can calculate the last installment.",
      "type": {
        "annuity": "Annuity",
        "fixedPrincipal": "Fixed principal",
        "interestOnly": "Interest only",
        "label": "Installment type",
        "tooltip": "How is your installment calculated? See note on the left for explanation.",
        "error": "Please select the installment type."
      },
      "byInstallentAmount": "Enter the amount of each installment (annuity).",
      "byTilgungsrate": "Enter the percentage of the loan amount to be repaid annually ('Tilgunsrate').",
      "annuitySubtype": {
        "error": "Please select an option."
      },
      "annuitySubtypeAmount": {
        "label": "Installment amount",
        "tooltip": "Enter the amount for your fixed installment.",
        "error": "Please enter the amount for your fixed installment."
      },
      "annuitySubtypeTilgung": {
        "label": "Annual repayment rate (Tilgung) (%)",
        "tooltip": "Specify the percentage of the loan amount to be repaid annually.",
        "error": "Please enter the percentage of the loan amount to be repaid every year."
      },
      "fixedPrincipalSubtype": {
        "error": "Please select an option."
      },
      "byPrincipalRepaid": "Enter the amount of principal (capital) to be repaid in each period.",
      "fixedPrincipalSubtypeAmount": {
        "label": "Principal amount",
        "tooltip": "Enter the principal (capital) amount to be repaid in each period.",
        "error": "Please enter the amount to be repaid in each period."
      },
      "fixedPrincipalSubtypeTilgung": {
        "label": "Annual repayment rate (Tilgung) (%)",
        "tooltip": "Specify the percentage of the loan amount to be repaid annually.",
        "error": "Please enter the percentage of the loan amount to be repaid every year."
      },
      "connectedDepositAccount": "In order to track the loan payouts and payments, we need to know which account the money is coming from and going to. If you use more than one, you can add the other account later.",
      "connectedDepositMode": {
        "error": "Please choose how you'll connect the deposit account."
      },
      "existingAccount": "Existing account",
      "existingAccountSelect": "Select an existing account",
      "createAccountLater": "Create account later",
      "createAccountLaterDescription": "You can choose to connect a deposit account later.",
      "loanProject": "You can see the simulated future payments in Projects. If you already have a Project for this loan, select it here. If not, mark >Create new project<.",
      "connectedProjectId": {
        "label": "Connected project",
        "tooltip": "Select the project associated with this loan."
      },
      "projectCreateNew": "Create a new project",
      "bereitstellungszinsen": {
        "text": "Interest before complete loan payout (Bereitstellungszinsen)",
        "label": "Intial interest rate (%)",
        "tooltip": "Enter the interest rate charged on the undrawn portion of the loan."
      },
      "bereitstellungszinsenSinceDate": {
        "label": "Beginning on",
        "tooltip": "Specify the date from which the commitment interest will be calculated."
      },
      "interestAfterTerm": {
        "text": "Apply new interest after loan contract end date. This will enable 'simulate until completely repaid' option above and apply this interest rate from the specified date.",
        "label": "Interest after contract ends (%)",
        "tooltip": "Enter the interest rate applicable after the initial term ends."
      },
      "interestAfterTermSinceDate": {
        "label": "Beginning on",
        "tooltip": "Indicate the start date for the post-term interest rate."
      },
      "interestCalculationCutoff": "Does your bank calculate your installment on month beginning or on month end? If at month end, then all the extra repayments made in that month will already be reflected in the installment's interest to principal ratio. If month beginning, then they will only be reflected the following month.,",
      "interestCalcMonthEnd": {
        "label": "Interest is calculated at month end",
        "tooltip": "Toggle whether to calculate interest at the end of each month."
      }
    },
    "pension": {
      "accountInfo": "Add new account",
      "provideInfo": "Please provide a few details about your pension account.",
      "whyDoWeAsk": "Why do we want to know?",
      "whyDoWeAskAnswer": "We use these parameters to calculate your pension's value and simulate future payments.",
      "doIHaveToFillThemAllOut": "Do I have to fill out all those fields?",
      "doIHaveToFillThemAllOutAnswer": "We need some information to provide meaningful information to you. Fields marked 'optional' are not strictly required, but will be used to calculate other useful data for you.",
      "useATemplate": "Use a template!",
      "useATemplateAnswer": "The most common pension products have already been configured for you - just select them from the list.",
      "yourPensionContributions": "Contributions - when I save",
      "yourPensionPayouts": "Payouts - when I retire",
      "whatYouWillReceive": "How much are the payouts?",
      "whatYouWillReceiveInfo": "In the statement received from your provider there should be the amount of your expected pension. Fill it in below.",
      "whatYouWillReceiveInfo2": "You may fill out any one or more amounts, depending on what kind of amounts your provider gives you.",
      "whenIBuy": "When I buy securities, the money...",
      "existingAccount": "... is deducted from an existing bank account",
      "existingAccountSelect": "Select that bank account",
      "createAccountLater": "... is deducted from a bank account which I will set up later",
      "createAccountLaterDescription": "Once you set up that account, remember to update the linked account in this account.",
      "product": {
        "label": "Product type",
        "tooltip": "Select the type of pension product you have. If you can't find it in the list, select 'Custom'.",
        "pension-drv": {
          "name": "State pension (DRV)",
          "description": "State pension (e.g. Deutsche Rentenversicherung) is state-managed pension scheme in Germany. Your employer and you pay contributions, and the pension is paid out by the state. Pension payouts are generally taxable and get increased over time."
        },
        "pension-betriebliche": {
          "name": "Company pension",
          "description": "Company pension is a pension scheme offered by your employer. Contributions are deducted from your salary before taxes, sometimes with your employer contribution. Your pension is taxed when paid out."
        },
        "pension-riester": {
          "name": "Riester pension",
          "description": "Riester pension is a state-subsidised pension scheme in Germany. Contributions are tax-deductible and the pension is taxed when paid out. The minimal payout is guaranteed to be at least equal to sum of your contributions."
        },
        "pension-ruerup": {
          "name": "Rürup pension",
          "description": "Rürup pension is a state-subsidised pension scheme in Germany. Contributions are tax-deductible and the pension is taxed when paid out."
        },
        "custom": {
          "name": "Custom",
          "description": "Configure any other product."
        },
        "selected": "Selected"
      }
    },
    "objectsOfValue": {
      "accountInfo": "Add new account",
      "provideInfo": "Please provide a few details about your pension account.",
      "whyDoWeAsk": "Why do we want to know?",
      "whyDoWeAskAnswer": "We use these parameters to calculate your pension's value and simulate future payments.",
      "whenIBuy": "When I buy objects of value, the money...",
      "existingAccount": "... is deducted from an existing bank account",
      "existingAccountSelect": "Select that bank account",
      "createAccountLater": "... is deducted from a bank account which I will set up later",
      "createAccountLaterDescription": "Once you set up that account, remember to update the linked account in this account."
    },
    "metals": {
      "accountInfo": "Add new account",
      "provideInfo": "You can decide how you want to structure your precious metals assets by organising them into accounts. You can create multiple accounts for different types of metals or different storage locations.",
      "whenIBuy": "When I buy precious metals, the money...",
      "existingAccount": "... is deducted from an existing bank account",
      "existingAccountSelect": "Select that bank account",
      "createAccountLater": "... is deducted from a bank account which I will set up later",
      "createAccountLaterDescription": "Once you set up that account, remember to update the linked account in this account."
    },
    "unlistedShares": {
      "accountInfo": "Add new account",
      "provideInfo": "Please provide a few basic details to get started. Once the account has been created, you will be able to add more investments and valuations.",
      "whenIBuy": "When I make investments in my company, the money...",
      "existingAccount": "... is transferred from an existing bank account",
      "existingAccountSelect": "Select that bank account",
      "createAccountLater": "... is transferred from a bank account which I will set up later",
      "createAccountLaterDescription": "Once you set up that account, remember to update the linked account in this account.",
      "fxRate": "Exchange rate: {{fxRate}}.",
      "apply": "Apply"
    },
    "crypto": {
      "accountInfo": "Add new account",
      "provideInfo": "Please provide some basic information about your account. Once the account is created, you can add your actual cryptocurrencies.",
      "whenIBuy": "When I buy crypto assets, the money...",
      "existingAccount": "... is deducted from an existing bank account",
      "existingAccountSelect": "Select that bank account",
      "createAccountLater": "... is deducted from a bank account which I will set up later",
      "createAccountLaterDescription": "Once you set up that account, remember to update the linked account in this account.",
      "createTechnicalAccount": "... is deducted from a cash account at my brokerage / exchange",
      "createTechnicalAccountTooltip": "If you select this option, we will create a cash account automatically.",
      "createTechnicalAccountCurrency": "In what currency is this cash account?"
    }
  },
  "accountDetails": {
    "accountDetails": "Account details",
    "last12months": "Last 12 months (with transactions)",
    "last10years": "Last 10 years",
    "stats": "Stats",
    "goToReports": "Go to reports",
    "synchronisingChanges": "Synchronising changes...",
    "noConnectedAccount": "Link a deposit account",
    "initialSaldo": "Your bank did not provide any details for the initial transaction. If necessary, split it into smaller transactions and adjust dates.",
    "help": "Click to see help for this view.",
    "errorFieldRequired": "Field is required",
    "errorOneRowRequired": "At least one entry is required",
    "errorMustBePositive": "Must be positive",
    "errorMustBeNumber": "Must be a number",
    "multipleSelected": "You can select multiple.",
    "effectiveDate": "Effective date",
    "value": "Value",
    "estimateType": "Select estimate type",
    "quote": "Payout",
    "quoteInCurrency": "Payout in {{currency}}",
    "create": "Create",
    "buttons": {
      "duplicatesFound": "We found potential duplicates in your transactions. Please review them and uncheck the ones you don't need.",
      "editYourFile": "You can edit your file now. Click >Save< to save your changes or >Cancel< to go back.",
      "fileTransformed": "Your file has been modified to replicate the changes you made to it last time.",
      "fileTransformedUndo": "Undo changes",
      "firstUpload": "This is your first upload for this account. We will store your selection for future use.",
      "loading": "Processing...",
      "loadingTakesLong": "This process may take longer than 30 seconds.",
      "columnMatcherHeader": "Which column from your file goes where?",
      "columnMatcherInfo": "Drag the column headers to their correct places in the top row. Some headers may have already been placed for you. Hovering over the table icons will show a preview of your file. You don&apos;t have to use all the columns from your file.",
      "columnName": "Column name",
      "customColumnDescription": "Customised column",
      "dragToScroll": "Drag above to scroll",
      "occursInRows": "Occurs in rows",
      "headersInRow": {
        "label": "Headers in row:",
        "tooltip": "Which row in your file contains the column headers?"
      },
      "decimalChar": {
        "label": "Decimal character:",
        "tooltip": "Does your file use a comma or a dot as the decimal character?"
      },
      "readOnly": {
        "tooltip": "You can add future transactions to Projects and they will appear here.",
        "label": "This view is read-only in Project Mode."
      },
      "whatYouOwn": {
        "label": "What you own",
        "tooltip": "Display a list of purchases and sales of your investments."
      },
      "whatIsItWorth": {
        "label": "What is it worth",
        "tooltip": "Display changing value of your investments over time."
      },
      "payments": {
        "label": "Payments",
        "tooltip": "Display contributions and payouts."
      },
      "presentValue": {
        "label": "Value",
        "tooltip": "Display expected monthly payments as reported by your product provider."
      },
      "sync": {
        "tooltip": "Import transactions automatically."
      },
      "import": {
        "tooltip": "Import transactions from a file."
      },
      "table": {
        "label": "Table",
        "tooltip": "Display a list of transactions.",
        "tooltipActive": "Abandon changes and go back to table view."
      },
      "edit": {
        "label": "Edit",
        "tooltip": "Edit the transactions in a spreadsheet.",
        "tooltipActive": "Abandon changes and go back to table view."
      },
      "addNew": {
        "label": "Add new...",
        "tooltip": "Add new capital transaction or valuation.",
        "tooltipActive": "Abandon changes and go back to table view."
      },
      "settings": {
        "label": "Settings",
        "tooltip": "Display account settings."
      },
      "close": {
        "label": "Close",
        "tooltip": "Abandon changes and go back to Dashboard."
      },
      "save": {
        "label": "Save",
        "tooltip": "Permanently save this change."
      },
      "saveAndClose": {
        "label": "Save and close",
        "tooltip": "Save this change and go back to Import screen."
      },
      "cancel": {
        "label": "Cancel",
        "tooltip": "Cancel and go back to Table view."
      },
      "undo": {
        "label": "Undo",
        "tooltip": "Undo last change."
      },
      "redo": {
        "label": "Redo",
        "tooltip": "Repeat last change."
      },
      "uploadFiles": {
        "label": "Upload files",
        "tooltip": "Upload local files to Monestry."
      },
      "cancelFileUpload": {
        "label": "Cancel file upload",
        "tooltip": "Closes this dialog without uploading anything."
      },
      "resetColumns": {
        "label": "Reset columns",
        "tooltip": "Resets all mappings."
      },
      "editLoadedFile": {
        "label": "Edit imported file",
        "tooltip": "Edit the file you have uploaded."
      },
      "confirmAndContinue": {
        "label": "Confirm and continue",
        "tooltip": "Proceeds with importing the file according to mappings specified below."
      },
      "skipThisFile": {
        "label": "Skip this file",
        "tooltip": "Skips this file (without uploading it) and moves on to the next one."
      },
      "addAnotherOperation": {
        "label": "Add another operation",
        "tooltip": "You can add more columns to your custom column."
      },
      "newColumn": {
        "label": "Add new column",
        "tooltip": "Adds and edits new columns, based on two or more existing columns."
      },
      "columnProperties": {
        "label": "Properties",
        "tooltip": ""
      },
      "columnActions": {
        "label": "Actions",
        "tooltip": ""
      },
      "deleteThisColumn": {
        "label": "Delete this column",
        "tooltip": "Deletes this column and all its contents."
      },
      "historicalValueCalc": {
        "label": "Don't know purchase price?",
        "tooltip": "This button will estimate the purchase price based on the purchase date and attributes (weight, purity etc.). You can adjust it later."
      },
      "previous": {
        "label": "Previous",
        "tooltip": "Open details of the previous valuation."
      },
      "next": {
        "label": "Next",
        "tooltip": "Open details of the next valuation."
      }
    },
    "attributes": {
      "category": {
        "tooltip": "Category of this account"
      },
      "bankName": {
        "tooltip": "Bank providing this account"
      },
      "brokerName": {
        "tooltip": "Broker providing this account"
      },
      "iban": {
        "tooltip": "This account's IBAN (account number)"
      },
      "accountNo": {
        "tooltip": "This account's number",
        "stub": "Account number?"
      },
      "bic": {
        "tooltip": "BIC (routing code) of the bank where this account is at"
      },
      "connectedCashAccount": {
        "tooltip": "Bank account where payments to and from this account are made.",
        "error": "Error: no connected cash account found"
      },
      "streetAddress": {
        "tooltip": "Street address of your property"
      },
      "floorArea": {
        "tooltip": "Floor area of your property"
      },
      "numberOfRooms": {
        "tooltip": "Number of rooms in your property"
      },
      "lotArea": {
        "tooltip": "Area of the lot your property is on"
      },
      "constructionYear": {
        "tooltip": "Construction year"
      },
      "garage": {
        "tooltip": "Is there a garage or a dedicated parking space?"
      },
      "currency": {
        "tooltip": "Currency used for tracking this property's value"
      },
      "multiple": "connected accounts"
    },
    "columns": {
      "standardTooltip": "Click to sort by this column or reverse the sorting order.",
      "emptyString": "",
      "readOnly": "(read-only)",
      "category": "category",
      "date": "Date",
      "Date (Day)": "Date",
      "Date (Month)": "Date",
      "Date (Year)": "Date",
      "Income from capital": "Income from capital",
      "accountName": "Account",
      "displayName": "Name",
      "total": "Total",
      "otherParty": "Other party",
      "otherPartyAccount": "Other party account",
      "symbol": "Symbol",
      "description": "Description",
      "amount": {
        "label": "Amount",
        "tooltip": "Transaction amount in your account currency. If you provide currency and the original currency amount, this field will be calculated automatically."
      },
      "label": {
        "label": "Label",
        "tooltip": "What kind of transaction is this? These labels make it possible to calculate your net worth and other statistics."
      },
      "quantity": {
        "label": "Quantity",
        "tooltip": "Number of assets bought (positive number) or sold (negative number)."
      },
      "principal": {
        "label": "Principal",
        "tooltip": "Principal part of loan installment (i.e. repaid capital)."
      },
      "interest": {
        "label": "Interest",
        "tooltip": "Interest part of loan installment (i.e. interest paid for this period)."
      },
      "iban": {
        "label": "IBAN",
        "tooltip": "Other party account's IBAN (account number)"
      },
      "transactionAmount": {
        "label": "Amount",
        "tooltip": "Amount of shares bought or sold"
      },
      "transactionPrice": {
        "label": "Unit price",
        "tooltip": "Price per share, in the currency of the transaction"
      },
      "transactionCurrency": {
        "label": "Currency",
        "tooltip": "Currency of the transaction"
      },
      "type": "Type",
      "fxCurrency": {
        "label": "Original currency",
        "tooltip": "Original currency of the transaction (optional)"
      },
      "fxAmount": {
        "label": "Original amount",
        "tooltip": "Transaction amount in original currency (optional)"
      },
      "fxAmountInterest": {
        "label": "Original interest amount",
        "tooltip": "Transaction interest amount in original currency (optional)"
      },
      "fxRate": {
        "label": "Exchange rate",
        "tooltip": "Exchange rate between original currency and your account currency (calculated automatically)"
      },
      "value": "Value",
      "rebasedOriginalValue": "Value\n(transaction\ncurrency)",
      "rebasedValue": "Value\n(base\ncurrency)",
      "rebasedAmount": {
        "label": "Amount\nafter\nsplits",
        "tooltip": "If there were any splits between the transaction date and today, this is the amount of shares which these shares are now."
      },
      "assetId": {
        "label": "Asset",
        "tooltip": "Which asset is this quote for?"
      },
      "assetName": {
        "label": "Asset name",
        "tooltip": "How would you like to refer to this asset?"
      },
      "assetMetal": {
        "label": "Metal",
        "tooltip": "What precious metal is this? Pick from a list."
      },
      "assetPurity": {
        "label": "Purity",
        "tooltip": "Different forms of precious metal assets have different purities, e.g. .999 for gold. Enter the purity of your asset in parts per thousand (999 or 965)."
      },
      "assetAdditionalValue": {
        "label": "Extra value",
        "tooltip": "Some coins or other assets have additional value due to their rarity or artistic significance. Enter the extra value of your asset in percent (this will be added to the pure metal value). If the metal alone is worth 100 and additional value is 20%, then we will calculate the value of this coin to be 120. When metal price increases, we will keep adding the 20% for this asset."
      },
      "assetWeight": {
        "label": "Weight",
        "tooltip": "How much does this asset weigh? Please enter weight in grams."
      },
      "transactionValue": {
        "label": "Transaction sum",
        "tooltip": "Optional. Quantity purchased / sold * price per unit. If you enter both those values, we will calculate it automatically."
      },
      "transactionCurrencyValue": {
        "label": "Original sum",
        "tooltip": "Optional. Quantity purchased / sold * price per unit in original transation currency. If you enter both those values, we will calculate it automatically."
      },
      "rebasedOriginalPrice": {
        "label": "Price\nafter\nsplits",
        "tooltip": "When stocks split, their price is divided as well. This is the hypothetical price per share of the transaction shares after considering all the splits that happened since."
      },
      "pricePerSquareMetre": "Price per m2",
      "originalPricePerSquareMetre": "Price\n(original\ncurrency)\nper m2",
      "transactionType": {
        "label": "Transaction type",
        "tooltip": "Purchase or sale of the property."
      },
      "realEstateTransactionPrice": {
        "label": "Price\n(base\ncurrency)",
        "tooltip": "Shows the actual sale or purchase price, in your base currency. You can provide this price or the original price, the other will be calculated automatically."
      },
      "realEstateOriginalPrice": {
        "label": "Price\n(original\ncurrency)",
        "tooltip": "Shows the actual sale or purchase price, in the original currency. You can provide this price or the transaction price, the other will be calculated automatically."
      },
      "source": {
        "label": "Source",
        "tooltip": "Shows if the valuation comes from the valuation provider or entered manually. Click to sort by this column or reverse the sorting order."
      },
      "price": {
        "label": "Value\n(base\ncurrency)",
        "tooltip": "Shows what price would this property be sold for at a given date, in your base currency. Click to sort by this column or reverse the sorting order.",
        "tooltipGrid": "If you provide original currency and price, this column will be calculated automatically."
      },
      "priceShort": {
        "label": "Price"
      },
      "currency": "Original\ncurrency",
      "originalPrice": {
        "label": "Value\n(original\ncurrency)",
        "tooltip": "Shows what price would this property be sold for at a given date, in the original currency. Click to sort by this column or reverse the sorting order.",
        "tooltipGrid": "If you provide original currency and your base currency price, this column will be calculated automatically."
      },
      "size": {
        "label": "Quantity",
        "tooltip": "Amount of still unsold securities from the transaction which took place at the specified date. Click to sort by this column or reverse the sorting order."
      },
      "split": {
        "label": "Split",
        "tooltip": "Proportion of old stocks to new stocks."
      },
      "openPositionSize": {
        "label": "Remaining quantity",
        "tooltip": "Amount of still unsold securities from the transaction which took place at the specified date. Click to sort by this column or reverse the sorting order."
      },
      "purchaseValue": {
        "label": "Purchase",
        "tooltip": "Value when purchasing the securities in your base currency. Click to sort by this column or reverse the sorting order."
      },
      "purchaseValueFull": {
        "label": "Purchase value",
        "tooltip": "Value when purchasing the securities in your base currency. Click to sort by this column or reverse the sorting order."
      },
      "baselineValue": {
        "label": "Baseline /",
        "tooltip": "Value of the securities in your base currency at the baseline date that you selected. Click to sort by this column or reverse the sorting order."
      },
      "currentValue": {
        "label": "Current value",
        "tooltip": "Current value of the securities in your base currency. Click to sort by this column or reverse the sorting order."
      },
      "purchasePrice": {
        "label": "Purchase /",
        "tooltip": "Price when purchasing the securities in your base currency. Click to sort by this column or reverse the sorting order."
      },
      "valueTransactionCurrency": {
        "label": "Value\n(transaction\ncurrency)",
        "tooltip": "Optional. Value of the asset in the original transaction currency. Click to sort by this column or reverse the sorting order."
      },
      "valueAccountCurrency": {
        "label": "Value",
        "tooltip": "Optional. Value of the asset in the account currency. Click to sort by this column or reverse the sorting order."
      },
      "valueBaseCurrency": {
        "label": "Value\n(base\ncurrency)",
        "tooltip": "Optional. Value of the asset in your overall base currency. Click to sort by this column or reverse the sorting order."
      },
      "baselinePrice": {
        "label": "Baseline /",
        "tooltip": "Price of the securities in your base currency at the baseline date that you selected. Click to sort by this column or reverse the sorting order."
      },
      "currentPrice": {
        "label": "Current price",
        "tooltip": "Current price of the securities in your base currency. Click to sort by this column or reverse the sorting order."
      },
      "purchaseCurrentValuePrice": {
        "label": "Value / price",
        "tooltip": "Price and value when purchasing the securities as well as currently, in your base currency. Click to sort by this column or reverse the sorting order."
      },
      "purchaseValuePrice": {
        "label": "Purchase value / price",
        "tooltip": "Price and value when purchasing the securities in your base currency. Click to sort by this column or reverse the sorting order."
      },
      "baselineValuePrice": {
        "label": "Baseline value / price",
        "tooltip": "Price and value of the securities in your base currency at the baseline date that you selected. Click to sort by this column or reverse the sorting order."
      },
      "currentValuePrice": {
        "label": "Current value / price",
        "tooltip": "Current price and value of the securities in your base currency. Click to sort by this column or reverse the sorting order."
      },
      "dividendsSincePurchase": {
        "label": "Dividends / fees since purchase",
        "tooltip": "Sum of all dividends and fees incurred for those securities since purchase + account costs in proportion to open position value. Click to sort by this column or reverse the sorting order."
      },
      "dividends": {
        "label": "Payouts / fees",
        "tooltip": "Sum of all dividends and fees incurred for those securities since purchase. Click to sort by this column or reverse the sorting order."
      },
      "roi": {
        "label": "ROI total / ROI per year",
        "tooltip": "Return on investment for your securities - total (since purchase until now) and annualised (average growth per year since purchase until now). Includes dividends and fees. Click to sort by this column or reverse the sorting order."
      },
      "roiShort": {
        "label": "ROI total / annual",
        "tooltip": "Return on investment for your securities - total (since purchase until now) and annualised (average growth per year since purchase until now). Includes dividends and fees. Click to sort by this column or reverse the sorting order."
      },
      "roiOnly": {
        "label": "ROI",
        "tooltip": "Return on investment for your securities - total (since purchase until now). Includes dividends and fees. Click to sort by this column or reverse the sorting order."
      },
      "payoutsSincePurchase": {
        "label": "Payouts since purchase",
        "tooltip": "Payouts for assets since their purchase. Click to sort by this column or reverse the sorting order."
      },
      "feesSincePurchase": {
        "label": "Fees since purchase",
        "tooltip": "Fees related to assets since their purchase. Click to sort by this column or reverse the sorting order."
      },
      "costsSincePurchase": {
        "label": "Costs since purchase",
        "tooltip": "Costs related to assets since their purchase. Click to sort by this column or reverse the sorting order."
      },
      "roiAnnual": {
        "label": "Annual ROI",
        "tooltip": "Return on investment for your securities - annualised (average growth per year since purchase until now). Includes dividends and fees. Click to sort by this column or reverse the sorting order."
      },
      "keepFlag": {
        "label": "Keep?",
        "tooltip": "Entferne Markierung bei den Zeilen, die nicht importiert werden sollen."
      },
      "payoutOnlyContributionsToDate": {
        "label": "Pension on contributions\nto-date",
        "tooltip": "Pension that you would receive if you stopped paying contributions today."
      },
      "payoutOnlyContributionsToDateIndexed": {
        "label": "Pension on contributions\nto-date (with interest)",
        "tooltip": "Pension that you would receive if you stopped paying contributions today, but with interest applied to them until pension payout phase."
      },
      "payoutAllPlannedContributions": {
        "label": "Pension on all\nfuture contributions",
        "tooltip": "Pension that you would receive if you keep paying contributions until pension payout phase."
      },
      "payoutAllPlannedContributionsIndexed": {
        "label": "Pension on all\nfuture contributions\n(with interest)",
        "tooltip": "Pension that you would receive if you keep paying contributions until pension payout phase, but with interest applied to them until pension payout phase."
      },
      "note": {
        "label": "Note",
        "tooltip": "Valuation details"
      },
      "proceeds": {
        "label": "Proceeds",
        "tooltip": "Income from this asset"
      }
    },
    "deposits": {
      "labels": {
        "noLabel": {
          "long": "No label",
          "short": "No label"
        },
        "investment-fees": {
          "long": "Transaction fees",
          "short": "Fees"
        },
        "investment-costs": {
          "long": "Account costs",
          "short": "Costs"
        },
        "investment-interest": {
          "long": "Account interest",
          "short": "Interest"
        },
        "investment-dividend": {
          "long": "Dividend",
          "short": "Dividend"
        },
        "investment-transfer": {
          "long": "Transfer",
          "short": "Transfer"
        },
        "investment-rent": {
          "long": "Rent income",
          "short": "Rent"
        },
        "investment-purchase": {
          "long": "Asset purchase",
          "short": "Asset purchase"
        },
        "investment-sale": {
          "long": "Asset sale",
          "short": "Asset sale"
        },
        "investment-payout": {
          "long": "Loan payout",
          "short": "Loan payout"
        },
        "investment-loanInterest": {
          "long": "Loan repayment (interest)",
          "short": "Loan interest"
        },
        "investment-loanPrincipal": {
          "long": "Loan repayment (principal)",
          "short": "Loan principal"
        },
        "investment-pension": {
          "long": "Pension payout",
          "short": "Pension"
        },
        "investment-contribution": {
          "long": "Pension contribution",
          "short": "Contribution"
        }
      },
      "markThisTransaction": "Mark this transaction as",
      "noTransactions": "No transactions found on accounts connected to this one.",
      "noTransactionsWithin": "No similar sized transactions found within 7 days of this transaction's date on accounts connected to this one.",
      "noAccounts": "No connected accounts found.",
      "noAssets": "No assets found on connected accounts.",
      "addTag": "Add tag...",
      "whichItem": "Which item does it apply to?",
      "createMatchingTransaction": "Create a matching transaction in the asset account",
      "transactionCurrencyMismatch": "This transaction's original currency needs to be the same as the asset account currency."
    },
    "pension": {
      "labels": {
        "noLabel": {
          "long": "No label",
          "short": "No label"
        },
        "pension-purchase": {
          "long": "Pension purchase",
          "short": "Purchase"
        },
        "pension-contribution": {
          "long": "Pension contribution",
          "short": "Contribution"
        },
        "pension-payout": {
          "long": "Pension payout",
          "short": "Payout"
        }
      },
      "import": {
        "title": "Which product to use?",
        "prompt": "You seem to be importing data from rvuebersicht.de. There are more than 1 products in this file. Please select the right entry for this account.",
        "ignore": "Ignore",
        "cancel": "Cancel",
        "baseCurrencyDialog": {
          "title": "Currency conversion",
          "prompt": "The currency of the imported data is different from the base currency of this account. How should we convert this currency? As it is difficult to predict the future exchange rate, we suggest to use today's.",
          "fxRate": "Exchange rate to use",
          "todayFxIs": "Today's exchange rate is",
          "apply": "Apply"
        }
      }
    },
    "contextMenu": {
      "addEmptyRowAbove": "Add empty row(s) above",
      "addEmptyRowBelow": "Add empty row(s) below",
      "addEmptyColumnAbove": "Add empty column(s) right",
      "addEmptyColumnBelow": "Add empty column(s) left",
      "duplicateRow": "Duplicate row",
      "duplicateColumn": "Duplicate column",
      "deleteRow": "Delete row",
      "deleteColumn": "Delete column",
      "cut": "Cut",
      "copy": "Copy",
      "paste": "Paste",
      "download": "Export everything to file",
      "downloadSelected": "Export selection to file"
    },
    "splitMenu": {
      "split": "Split",
      "splitHeader": "Split this transaction into two or more transactions",
      "splitInfo": "Remember to split transaction fees and purchase / sale transaction in the connected deposit account.",
      "splitExistingDetected": "This transaction has been split already, you can edit the existing splits above.",
      "copyAsSimulated": "Copy as simulated",
      "copyAsSimulatedInfo": "Create a simulated transaction based on this one. You can adjust all parameters in the next screen.",
      "showInProject": "Link to project",
      "showInProjectInfo": "You can show this actual transacton in a Project.",
      "splitAndLabelLoan": "Loans: split, label + link",
      "splitAndLabelLoanInfo": "Split loan installment into interest and capital parts, label them and link to the loan project.",
      "splitAndLabelLoanInfo2": "We need to split the loan installment into those two parts, because they impact the income from capital differently. This process will also label both transactions and can also create the loan account transaction here.",
      "createLoanTransaction": "Create a matching transaction in the loan account",
      "modifyLoanTransaction": "Modify the loan transaction to match your changes",
      "selectLoanTransaction": "Select matching loan transaction",
      "selectLoanTransactionInfo": "Displays all transactions with similar date and amount on the connected loan account.",
      "selectLoanTransactionPrompt": "Select...",
      "createLoanTransactionError": "We need a matching loan transaction to proceed. Either select >Create...< checkbox or find the matching one in >Select matching...< dropdown.",
      "calculateExpectedSplitInfo": "If there is no information about the interest and principal parts in transaction description, we can calculate them based on the loan parameters.",
      "delete": "Delete",
      "pressSaveToDelete": "Press >Save< to delete this transaction.",
      "pick": "Pick a project",
      "error": "Please verify your entry.",
      "amountToAllocate": "Amount left to allocate",
      "allocatedTooMuch": "Allocated too much by",
      "amountToAllocateInterest": "Interest left to allocate",
      "allocatedTooMuchInterest": "Allocated too much interest by",
      "selectLoanAccount": "Select loan account"
    },
    "settings": {
      "symbol": "Symbol",
      "dividend": "Dividend (% per year)",
      "growthRate": "Growth rate (% per year)",
      "add": "Add",
      "simulatedAccountGrowth": {
        "heading": "Simulated account growth",
        "description": "This value will be used to simulate future growth of all your stocks in this account account. See this blog entry for more information.",
        "fieldLabel": "Growth rate",
        "dividendPayments": "You can also simulate future dividend payments - across all stocks accounts. Enter initial dividend per share per year, it will be increased annually by the above growth rate.",
        "dividendTooltip": "Dividend will be calculated as % of current value of the asset."
      },
      "spread": {
        "description": "We calculate up-to-date value of your precious metal assets based on the realistic price you will get for your asset when selling it.",
        "description2": "This price is lower than the market price due to the margin of the buyer (called 'spread'). We recommend a conservative spread of 10% of the market price, but you can change it here. Spread will be deducted from the current market value of your metal assets.",
        "label": "Spread (%)",
        "error": "Please provide a spread percentage"
      },
      "autoImportPretransformation": {
        "heading": "Automatic file import",
        "body": "We can automatically re-apply changes that you made to the files imported for this account next time you import a file.",
        "label": "Automatically apply changes"
      }
    },
    "loanIndicators": {
      "interestPaid": "Paid",
      "interestPaidToPrincipalPaid": "Vs principal paid",
      "principalRemaining": "Remaining principal",
      "interestRemaining": "Remaining interest",
      "history": "History",
      "loanRepaid": "Loan repaid",
      "interest": "Interest",
      "balance": "Balance"
    },
    "pensionIndicators": {
      "presentValueChanges": "Expected payout changes",
      "roi": "Return on investment",
      "sumContributions": "Contributions",
      "presentValue": "Product value"
    },
    "unlistedShares": {
      "wizard": {
        "transactions": {
          "header": "What kind of transaction shall we add?",
          "moreShares": "Buy more shares",
          "moreCapital": "Increase equity capital (without emitting new shares)",
          "sharesSale": "Sell shares",
          "capitalDisbursement": "Receive equity capital as payout (capital disbursement)",
          "error": "Please pick one option",
          "formHeader": "Transaction details",
          "submit": "Save",
          "noConnectedDeposit": "Connect a deposit account first (use the button above, just below company name).",
          "clickToOpenDeposit": "In order to pay equity capital in or out without emitting or buying back shares, go to the connected deposit account and label your transaction as 'capital increase' or 'capital disbursement':",
          "quantity": {
            "label": "Amount of shares",
            "tooltip": "How many shares did you buy or sell?",
            "error": "Please provide a number of shares"
          },
          "upac": {
            "label": "Price per share",
            "tooltip": "Price per share in the account currency",
            "error": "Please provide a price per share"
          },
          "capitalAmount": {
            "label": "Capital increase amount",
            "tooltip": "By how much is the equity capital increased?",
            "error": "Please provide a capital increase amount"
          },
          "capitalDisbursementAmount": {
            "label": "Capital disbursement amount",
            "tooltip": "How much equity capital is paid out?",
            "error": "Please provide a capital disbursement amount"
          },
          "dateUserInput": {
            "label": "Transaction date",
            "tooltip": "When did the transaction take place?",
            "error": "Please provide a transaction date"
          }
        },
        "valuation": {
          "title": "Company valuation wizard",
          "description": "Start by selecting your company's lifecycle stage below or enter the valuation manually and click save.",
          "score": {
            "-2": {
              "label": "Much less",
              "tooltip": "Your company is in a significantly worse position than the market / its peers"
            },
            "-1": {
              "label": "Less",
              "tooltip": "Your company is in a worse position than the market / its peers"
            },
            "0": {
              "label": "Neutral",
              "tooltip": "Your company is in a similar position to the market / its peers"
            },
            "1": {
              "label": "More",
              "tooltip": "Your company is in a better position than the market / its peers"
            },
            "2": {
              "label": "Much more",
              "tooltip": "Your company is in a significantly better position than the market / its peers"
            }
          },
          "stage": {
            "label": "Stage",
            "tooltip": "The valuation methods that we can apply depend on the stage of your company. Select the stage that best describes your company at present.",
            "idea": {
              "label": "Idea",
              "tooltip": "The company is in the idea stage. It has not yet been founded."
            },
            "prototype": {
              "label": "Prototype",
              "tooltip": "The company has a prototype or the work is very advanced. Market launch has not happened yet."
            },
            "mvp": {
              "label": "MVP",
              "tooltip": "The company has a minimum viable product and is testing it on the market."
            },
            "expansion": {
              "label": "Expansion",
              "tooltip": "The company is growing rapidly and has a stable customer base."
            },
            "market": {
              "label": "Market",
              "tooltip": "The company is well established and has a strong customer base."
            }
          },
          "valuationMethod": {
            "label": "Available valuation methods",
            "tooltip": "Select the methods that you would like to use to value your company. You can select multiple methods."
          },
          "info": "Compare your company to similar companies in the market. The valuation methods will be applied based on the information you provide.",
          "insufficientData": "More data needed",
          "insufficientDataTooltip": "We need the following fields to calculate the valuation: {{fields}}.",
          "required": "Please provide both date and valuation.",
          "ideaStrength": {
            "label": "Idea strength",
            "tooltip": "The strength of the idea or business concept behind the company"
          },
          "prototypeProgress": {
            "label": "Prototype progress",
            "tooltip": "The progress made in developing the prototype of the product ('much less' if no prototype exists, 'much more' if a fully tested prototype exists)"
          },
          "teamCapability": {
            "label": "Team capability",
            "tooltip": "The capability and experience of the team members"
          },
          "marketingRelationships": {
            "label": "Marketing relationships",
            "tooltip": "The strength and extent of marketing / supply chain relationships"
          },
          "marketTraction": {
            "label": "Market traction",
            "tooltip": "Success in bringing product to the market / sales growth"
          },
          "competitiveAdvantage": {
            "label": "Competitive advantage",
            "tooltip": "Is there any kind of advantage this company has against its competitors?"
          },
          "similarStartupValuationsArray": {
            "label": "Similar company valuations",
            "tooltip": "Approximate valuations for similar companies"
          },
          "technologyRisk": {
            "label": "Technology confidence",
            "tooltip": "Comparing to similar companies, is the technology used by this company more or less risky?"
          },
          "ipStrength": {
            "label": "IP strength",
            "tooltip": "Does the company hold all key patents / IP necessary for launch and further growth?"
          },
          "totalAdressableMarketSize": {
            "label": "Total addressable market size",
            "tooltip": "The total size of the market that the company can address"
          },
          "marketGrowthRate": {
            "label": "Market growth rate",
            "tooltip": "The growth rate of the market in which the company operates"
          },
          "marketEntryBarriers": {
            "label": "Market entry barriers",
            "tooltip": "How difficult (because of capital, tools, IP etc.) is it to start a company in this market?"
          },
          "needForMoreInvestment": {
            "label": "Need for more investment",
            "tooltip": "How much more investment is needed to bring the company to the next level?"
          },
          "legalRisk": {
            "label": "Legal stability",
            "tooltip": "The more legal (legislation, patents etc.) risks the company may face, the smaller the stability."
          },
          "internationalRisk": {
            "label": "International stability",
            "tooltip": "The risks associated with expanding operations internationally"
          },
          "marketRisk": {
            "label": "Market stability",
            "tooltip": "Is the market in which the company operates stable ('more') or volatile ('less')?"
          },
          "financialPerformance": {
            "label": "Financial performance",
            "tooltip": "The financial performance of the company (profitability)"
          },
          "assetsArray": {
            "label": "Assets",
            "tooltip": "An array of the company's assets"
          },
          "intangiblesArray": {
            "label": "Intangibles",
            "tooltip": "An array of the company's intangible assets"
          },
          "liabilitiesArray": {
            "label": "Liabilities",
            "tooltip": "An array of the company's liabilities"
          },
          "targetRaisedCapital": {
            "label": "Capital to raise",
            "tooltip": "What capital is your company currently looking to raise?"
          },
          "cashFlowArray": {
            "label": "Cash flows",
            "namePlaceholder": "Year",
            "tooltip": "What are the expected cash flows of the company?"
          },
          "discountRate": {
            "label": "Discount rate (in %)",
            "tooltip": "The discount rate used in the DCF method (cost of capital for your company, i.e. approx. what interest is the company paying on loans)"
          },
          "longTermGrowthRate": {
            "label": "Stable growth rate (in %)",
            "tooltip": "Long-term, sustainable growth rate once cash flows are stable (should be conservative /2-4%/ and lower than the discount rate). Will be used to calculate the terminal value.",
            "error": "Stable growth rate must be lower than discount rate for this calculation."
          },
          "finalQuote": {
            "label": "Final valuation",
            "tooltip": "What valuation would you like to save, given the information provided?",
            "error": "Please provide a final valuation",
            "description": "Determine the final valuation of the company based on the information provided.",
            "save": "Save",
            "deleteThisQuote": "Delete this valuation"
          },
          "percentOwned": {
            "label": "Percentage owned",
            "tooltip": "What percentage of the company do you own?",
            "error": "Please provide a percentage owned"
          },
          "methods": {
            "berkus": {
              "label": "Berkus",
              "tooltip": "A method evaluating startups based on idea, prototype, team, market traction, and competitive advantage."
            },
            "scorecard": {
              "label": "Scorecard",
              "tooltip": "A method assessing startups using a scoring system to compare various aspects such as team, product, and market."
            },
            "riskFactor": {
              "label": "Risk Factor",
              "tooltip": "A method evaluating the risks involved in a startup, including technology, market, and team risks."
            },
            "valuationByStage": {
              "label": "Valuation by Stage",
              "tooltip": "A method determining the valuation of a startup based on its current stage of development."
            },
            "replacementCost": {
              "label": "Replacement Cost",
              "tooltip": "A method calculating the cost to replace the company's assets and operations."
            },
            "bookValue": {
              "label": "Book Value",
              "tooltip": "A method assessing the net asset value of a company by subtracting liabilities from assets."
            },
            "fiveTimesYourRise": {
              "label": "Five Times Your Rise",
              "tooltip": "A method multiplying the target capital raise by five to estimate the company's value."
            },
            "dcf": {
              "label": "DCF",
              "tooltip": "A Discounted Cash Flow method evaluating a company based on its projected future cash flows."
            }
          }
        }
      }
    },
    "cryptoAssetMappingDialog": {
      "header": "Automatic price updates",
      "prompt": "Cryptocurrencies are decentralised. To make sure we are getting the right price for your assets, we need to confirm we found the right one. Please check the following mappings and click >Save<. You may leave some of them empty if you don't want to update the price for this asset.",
      "asset": "Crypto symbol",
      "status": "Prices found",
      "headerAssetDetails": "Find prices for:",
      "promptAssetDetails": "These are cryptocurrencies for which we can update prices automatically. Select the one you own or search for a new keyword and click >OK<. You may leave all items unselected if they don't match your assets.",
      "search": "Search prices for:",
      "symbol": "Symbol",
      "name": "Name",
      "marketCap": "Market size",
      "liquidity": "Liquidity",
      "price": "Current price",
      "page": "Page",
      "pageOf": "of",
      "next": "Next",
      "previous": "Previous",
      "noResults": "No prices found"
    },
    "filterDialog": {
      "filter": "Filter",
      "prompt": "Select only cells which fulfill the following conditions:",
      "is": "is",
      "isNot": "is not",
      "contains": "contains",
      "doesNotContain": "does not contain",
      "startsWith": "starts with",
      "doesNotStartWith": "does not start with",
      "endsWith": "ends with",
      "doesNotEndWith": "does not end with",
      "greaterThan": "greater than",
      "greaterThanOrEqual": "greater than or equal to",
      "lessThan": "less than",
      "lessThanOrEqual": "less than or equal to",
      "equalTo": "equal to",
      "blank": "blank",
      "phrase": "phrase",
      "addMore": "Add more",
      "cancel": "Cancel",
      "rulesErrorLength": "Value may not exceed 200 characters.",
      "rulesErrorNumber": "Value must be a number."
    },
    "gettingStarted": {
      "heading": "Getting started",
      "subheading": "Fill your new account with data",
      "intro": "It is easy to get started and it is up to you to decide how to do that.\nRemember: everything you do can be updated later.",
      "features": [
        {
          "name": "Enter the current saldo",
          "description": "Click >Edit<, enter the current account saldo and save. You can add more details later."
        },
        {
          "name": "Import transactions from file",
          "description": "Download a transaction extract file from your bank's online banking. Once you have it, press the >Import< button and follow our step-by-step instructions."
        },
        {
          "name": "Get transactions automatically",
          "description": "If there is a technical link, you can get your transactions automatically. Just press the >Sync< button and we will do the rest."
        }
      ]
    }
  },
  "settings": {
    "Settings": "Settings",
    "Select a tab": "Select a tab",
    "preferences": {
      "header": "Preferences",
      "baseCurrency": "Your base currency",
      "preferredLanguage": "Preferred language",
      "baselineDate": "Baseline date",
      "expectedDateFormats": "Import date formats",
      "expectedDateFormatsInfo": "Data formats in your imported files may differ. Please update the formats you expect to see in your files, so that we may use them to convert the dates to our internal format. We will try to apply them one by one until we see a proper date, starting from the topmost one and moving down.",
      "expectedDateFormatsAllowed": "You can use standard date formatting characters, like YYYY, MM, DD etc. For example, 'YYYY-MM-DD' or 'DD.MM.YYYY'. We support",
      "expectedDateFormatsAllowedLink": "all these formats.",
      "expectedDateFormatsError": "Please remove unsupported characters."
    },
    "simulations": {
      "header": "Simulations",
      "info": "The parameters below will be used in the simulations to calculate the future growth of your assets.",
      "slider": "Slider",
      "setDates": "Set the start and end dates for all your projects, simulations and the Slider.",
      "startDate": "Start date",
      "endDate": "End date",
      "inflation": "Inflation",
      "inflationEnabledDescription": "If enabled, the system will automatically increase all inflows and outflows by the specified percentage every year. This will not apply to inflows and outflows for which an individual increase rate is defined.",
      "disabled": "Disabled",
      "enabled": "Enabled",
      "simulatedInflationRate": "Simulated inflation rate (%)",
      "averageInflation": "Average inflation 1982-2022 across all OCED countries was 3.55%, 2.95% in the Eurozone and 1.89% in Germany.",
      "source": "Source ",
      "stockMarketGrowthRate": "Category growth rates",
      "simpleMode": "Simple mode: there is one overall growth rate which applies to all your assets (all accounts in the category).",
      "simpleMode2": "Research shows that long-term growth rates for the stock market from historical data are around 5-6% per year.",
      "advancedMode": "Expert mode: you can define individual growth rates for each asset. It will apply to this asset across all your accounts and non-isolated projects in the same category.",
      "advancedMode2": "If we cannot find an individual growth rate for an asset, we will apply the general growth rate (like in simple mode).",
      "simple": "Simple",
      "advanced": "Expert",
      "growthRate": "Category growth rate (%)",
      "stockDividends": "Stock dividends",
      "stockDividendsDescription": "Simple mode: there is one simulated dividend rate (in %) for all stocks (across all accounts). It will only be applied to stocks which yielded a dividend at least once in the past.",
      "stockDividendsDescription2": "Expert mode: set individual dividend rates (in %) for each stock. The app will only apply dividends to stocks defined here across your stocks accounts.",
      "dividendYield": "Dividend yield (%)",
      "symbol": "Symbol",
      "dividendPerYear": "Dividend (%)",
      "add": "Add",
      "save": "Save",
      "cancel": "Cancel"
    },
    "account": {
      "header": "Account",
      "saveChanges": "Save changes",
      "2fa": "Two-factor authentication",
      "changePassword": "Change password",
      "qrCode1": "Scan the QR code with your preferred authenticator app (such as",
      "qrCode2": "or",
      "manualEntry": "If you prefer to enter the code manually, use the following code:",
      "Enable": "Enable",
      "Enabled": "Enabled",
      "Email address": "Email address",
      "emailInfo": "Please have your email account ready when changing your email -- we will send you a confirmation code.",
      "pleaseEnterCode": "Please enter the confirmation code from your email.",
      "enterAuthCode": "Enter the code from your authenticator app once you are done:",
      "Confirm": "Confirm",
      "Submit": "Submit",
      "Username": "Username",
      "Password": "Password",
      "Enter your current password": "Enter your current password",
      "Please enter your current password.": "Please enter your current password.",
      "The new password cannot be the same as the current password.": "The new password cannot be the same as the current password.",
      "Enter new password": "Enter new password",
      "Repeat new password": "Repeat new password"
    },
    "billing": {
      "header": "Subscription",
      "title": "Subscription",
      "leadingText1": "We take your privacy seriously, therefore only our specialised payment provider has access to your payment data.",
      "leadingText2": "Please select one of the links below to manage your subscription directly with our provider Stripe. All links open in a new tab.",
      "currentSubscription": "Your current subscription",
      "upgradePlan": "Upgrade your plan",
      "paymentMethod": "Payment method",
      "downloadInvoices": "Download invoices",
      "cancelSubscription": "Cancel subscription"
    }
  },
  "alerts": {
    "internalError": {
      "type": "error",
      "header": "Error",
      "body": "The operation could not be completed.",
      "button": "OK"
    },
    "loginFailed": {
      "type": "error",
      "header": "Login failed",
      "body": "We could not log you on at this time. Please try again or contact us if the problem persists.",
      "button": "OK"
    },
    "userRegistrationSuccessful": {
      "type": "success",
      "header": "Registration complete",
      "body": "Your account has been successfully created. For reasons of security you will be asked to log in.",
      "button": "OK"
    },
    "userRegistrationSuccessfulWithPassword": {
      "type": "success",
      "header": "Registration complete",
      "body": "Your account has been successfully created. Click OK to proceed to your Dashboard.",
      "button": "OK"
    },
    "userConfirmationNotPossible": {
      "type": "error",
      "header": "Registration failed",
      "body": "Unfortunately we could not complete your registration at this time. Please contact our support at support@monestry.de.",
      "button": "OK"
    },
    "pressOkToContinue": {
      "type": "warning",
      "header": "Error",
      "body": "Press OK to continue.",
      "button": "OK"
    },
    "formIncomplete": {
      "type": "error",
      "header": "Form incomplete",
      "body": "Please fill out all the mandatory fields.",
      "button": "Close"
    },
    "nothingToSave": {
      "type": "success",
      "header": "Nothing to save",
      "body": "We found no changes, so saving was not necessary.",
      "button": "Close"
    },
    "localValidationFailed": {
      "type": "error",
      "header": "We found some errors",
      "body": "Please have a look at the spreadsheet again. The errors are indicated by red corners.",
      "button": "Close"
    },
    "aboutToSave": {
      "type": "warning",
      "header": "Your data are about to change",
      "body": "You are about to permanently change your data. Press OK to confirm or Cancel to abort.",
      "button": "OK",
      "button2": "Cancel"
    },
    "saveCompleted": {
      "type": "success",
      "header": "Data saved successfully",
      "body": "Your data have been saved.",
      "button": "Close"
    },
    "saveFailed": {
      "type": "error",
      "header": "Save failed",
      "body": "Unfortunately we could not save your changes. Please have a look at the error messages in comments.",
      "button": "Close"
    },
    "fileUploadSuccessful": {
      "type": "success",
      "header": "Success",
      "body": "File uploaded successfully.",
      "button": "Close"
    },
    "fileUploadFailed": {
      "type": "error",
      "header": "Unable to upload file",
      "body": "Unfortunately we could not upload the file. Please try again later.",
      "button": "Close"
    },
    "aboutToLoseData": {
      "type": "warning",
      "header": "Changes you made will be lost",
      "body": "You are about to exit Edit mode. Press OK to confirm or Cancel to abort.",
      "button": "OK",
      "button2": "Cancel"
    },
    "abandonUpload": {
      "type": "warning",
      "header": "Changes you made will be lost",
      "body": "You are about to abandon this file upload. Press OK to confirm or Cancel to abort.",
      "button": "OK",
      "button2": "Cancel"
    },
    "youAreAboutToDeleteAccount": {
      "type": "warning",
      "header": "Your data will be deleted",
      "body": "You are about to delete this account along with all its data. Press OK to confirm or Cancel to abort.",
      "button": "OK",
      "button2": "Cancel"
    },
    "youAreAboutToDeleteProject": {
      "type": "warning",
      "header": "This project will be permanently deleted",
      "body": [
        "You are about to delete this project along with all its planned transactions.",
        "Past transactions will not be deleted.",
        "Press OK to confirm or Cancel to abort."
      ],
      "button": "OK",
      "button2": "Cancel"
    },
    "youAreAboutToDeleteTransaction": {
      "type": "warning",
      "header": "This transaction will be permanently deleted",
      "body": "You are about to delete this transaction. Press OK to confirm or Cancel to abort.",
      "button": "OK",
      "button2": "Cancel"
    },
    "youAreAboutToDeleteValuation": {
      "type": "warning",
      "header": "This valuation will be permanently deleted",
      "body": "You are about to delete this valuation. Press OK to confirm or Cancel to abort.",
      "button": "OK",
      "button2": "Cancel"
    },
    "closeAccountDetails": {
      "type": "warning",
      "header": "Changes may be lost",
      "body": "If you made any changes, they will be lost. Continue?",
      "button": "OK",
      "button2": "Cancel"
    },
    "Invalid currency code": {
      "type": "error",
      "header": "Invalid currency code",
      "body": "Please enter a valid currency code.",
      "button": "Close"
    },
    "Transaction date must be in a date format": {
      "type": "error",
      "header": "Invalid transaction date",
      "body": "Transaction date must be in a date format.",
      "button": "Close"
    },
    "Transaction date is required": {
      "type": "error",
      "header": "Missing transaction date",
      "body": "Date is required for all transactions.",
      "button": "Close"
    },
    "Transaction party must be shorter than 100 characters": {
      "type": "error",
      "header": "Transaction party name too long",
      "body": "Transaction party must be shorter than 100 characters.",
      "button": "Close"
    },
    "Transaction party is required": {
      "type": "error",
      "header": "Missing transaction party.",
      "body": "A transaction party (sender or receiver) is required for all transactions.",
      "button": "Close"
    },
    "Transaction description must be shorter than 1000 characters": {
      "type": "error",
      "header": "Transaction description too long",
      "body": "Transaction description must be shorter than 1000 characters.",
      "button": "Close"
    },
    "Transaction description is required": {
      "type": "error",
      "header": "Missing transaction description",
      "body": "Transaction description is required for all transactions.",
      "button": "Close"
    },
    "Amount must be a number": {
      "type": "error",
      "header": "Amount must be a number",
      "body": "All transaction amounts must be numbers and must not contain letters.",
      "button": "Close"
    },
    "Amount is required": {
      "type": "error",
      "header": "Missing transaction amount",
      "body": "Transaction amount is mandatory for all transactions.",
      "button": "Close"
    },
    "Currency code is required": {
      "type": "error",
      "header": "Missing currency code",
      "body": "Currency code is mandatory for all transactions",
      "button": "Close"
    },
    "Foreign currency amount must be a number": {
      "type": "error",
      "header": "Foreign currency amount must be a number",
      "body": "All foreign currency amounts must be numbers and must not contain letters. Please enter foreign currency code in the Foreign Currency Symbol field",
      "button": "Close"
    },
    "Foreign exchange currency is required when a foreign currency amount is specified": {
      "type": "error",
      "header": "Foreign currency and transaction amount mismatch",
      "body": "If a foreign currency or a transaction amount are part of any transaction, then both of them must be specified in every such transaction.",
      "button": "Close"
    },
    "Currency code must be exactly 3 characters long": {
      "type": "error",
      "header": "Currency code error",
      "body": "Currency code must be exactly 3 characters long.",
      "button": "Close"
    },
    "newPasswordSuccess": {
      "type": "success",
      "header": "Success",
      "body": "Password has been changed. Please log in with your new password.",
      "button": "Close"
    },
    "youAreAboutToDeleteTemplateTransactions": {
      "type": "warning",
      "header": "Warning",
      "body": "All template transactions will be removed from your project. Do you want to continue?",
      "button": "OK",
      "button2": "Cancel"
    },
    "addTransactionManually": {
      "type": "warning",
      "header": "Warning",
      "body": "Initial purchase transaction could not be created, please add it manually.",
      "button": "OK",
      "button2": "Cancel"
    },
    "infoRedirectToProvider": {
      "type": "info",
      "header": "Log in to your bank",
      "body": [
        "We will redirect you to a secure webpage of our provider. Please log in to your bank there to approve the connection.",
        "After you have finished, you will be redirected back to Monestry.",
        "You may need to log in more than once, as there may be several ways of accessing your bank's data."
      ],
      "button": "OK",
      "button2": "Cancel"
    },
    "infoNoNewAccounts": {
      "type": "info",
      "header": "No new accounts",
      "body": "All your accounts from this bank seem to be already set up. Select a different bank.",
      "button": "OK"
    },
    "expiredCredentialsError": {
      "type": "info",
      "header": "Time to renew account access",
      "body": [
        "Access to {{accountName}} needs to be renewed. Click OK to go to your bank now.",
        "If you linked more than one account from this bank, you will only have to do it once."
      ],
      "button": "OK",
      "button2": "Cancel"
    },
    "signUpForNewsletter": {
      "type": "info",
      "header": "Thanks for signing up!",
      "body": [
        "We will typically send you one email every 1-2 months about news related to our app.",
        "We need to send you an email with a confirmation link. You can unsubscribe at any time by clicking a link in the email."
      ],
      "button": "OK",
      "button2": "Cancel"
    },
    "shareReportLinkSuccess": {
      "type": "success",
      "header": "Your link and passcode",
      "button": "OK"
    },
    "renewAccountAccessNoIbansFound": {
      "type": "error",
      "header": "IBANs not found",
      "body": [
        "Your bank sent us the following account numbers (IBANs): {{providerIbans}}.",
        "You have set up the following international accounts for automatic updates: {{monestryIbans}}.",
        "Please check if your accounts in Monestry are correctly set up and adjust them if necessary."
      ],
      "button": "OK"
    },
    "providerAccountsRemoved": {
      "type": "info",
      "header": "Accounts removed",
      "body": [
        "We did not receive data for {{list}} from your bank. Those accounts seem to have been removed.",
        "We have updated them to manually managed accounts."
      ],
      "button": "OK"
    },
    "aboutToReceiveRealEstateValuation": {
      "type": "info",
      "header": "Your first valuation",
      "body": [
        "We are about to calculate the valuation for your property. Make sure you have entered all the known details in the previous screen, as this impacts how good the valuation will be.",
        "Press OK to confirm or Cancel to abort."
      ],
      "button": "OK",
      "button2": "Cancel"
    }
  },
  "messages": {
    "dataUpdatedSuccessfully": {
      "type": "success",
      "header": "Success",
      "body": "Data have been updated."
    },
    "accountCreatedSuccessfully": {
      "type": "success",
      "header": "Success",
      "body": "Account has been created."
    },
    "accountUpdatedSuccessfully": {
      "type": "success",
      "header": "Success",
      "body": "Account has been updated."
    },
    "profileUpdatedSuccessfully": {
      "type": "success",
      "header": "Success",
      "body": "Profile updated successfully."
    },
    "profileCouldNotBeUpdated": {
      "type": "error",
      "header": "Error",
      "body": "Profile could not be updated."
    },
    "accountDeletedSuccessfully": {
      "type": "success",
      "header": "Success",
      "body": "Account has been deleted."
    },
    "dataUpdatedWithErrors": {
      "type": "error",
      "header": "Error",
      "body": "Data could not be updated."
    },
    "accountCouldNotBeCreated": {
      "type": "error",
      "header": "Error",
      "body": "Your account could not be created."
    },
    "accountCouldNotBeUpdated": {
      "type": "error",
      "header": "Error",
      "body": "Your account could not be updated."
    },
    "dataUpdateProblems": {
      "type": "warning",
      "header": "Warning",
      "body": "Some of your transactions may not have been updated."
    },
    "dataUpdateError": {
      "type": "error",
      "header": "Error",
      "body": "We could not update your data. Please check your network connection."
    },
    "projectUpdateError": {
      "type": "error",
      "header": "Error",
      "body": "We could not update your project. Please check your network connection."
    },
    "dataUpdateErrorContents": {
      "type": "error",
      "header": "Error",
      "body": "We could not update your data. Please check the form values and try again."
    },
    "checkYourData": {
      "type": "error",
      "header": "Error",
      "body": "Data could not be saved. Error messages are in cell comments."
    },
    "fileUploadAbortedSuccessfully": {
      "type": "success",
      "header": "Success",
      "body": "File upload cancelled. Data have not been changed."
    },
    "surveySendingError": {
      "type": "error",
      "header": "Survey sending error",
      "body": "We could not send your data."
    },
    "surveySentSuccessfully": {
      "type": "success",
      "header": "Thank you!",
      "body": "Survey has been sent successfully."
    },
    "transactionNotSelected": {
      "type": "error",
      "header": "No transaction selected",
      "body": "Please select a transaction to link the fee to."
    },
    "symbolNotSelected": {
      "type": "error",
      "header": "No stock selected",
      "body": "Please select the stock and account combination which generated the dividend."
    },
    "incorrectPassword": {
      "type": "error",
      "header": "Incorrect password",
      "body": "The current password was incorrect."
    },
    "attemptLimitExceeded": {
      "type": "error",
      "header": "Too many attempts",
      "body": "The system detected several password change attempts within the last few minutes. For security reasons it is now not possible to change your password. Please try again in 15 minutes."
    },
    "fileTooLarge": {
      "type": "error",
      "header": "File too large",
      "body": "We can only process files smaller than 5MB."
    },
    "fileUploadError": {
      "type": "warning",
      "header": "File upload failed",
      "body": "Some files failed to upload."
    },
    "noDataForDateFilter": {
      "type": "error",
      "header": "No data found",
      "body": "There are no data for this period. Back to previously selected period."
    },
    "transactionCreatedSuccess": {
      "type": "success",
      "header": "Success",
      "body": "Transaction created successfully."
    },
    "transactionCreatedError": {
      "type": "error",
      "header": "Error",
      "body": "Transaction could not be created."
    },
    "applyProjectTemplateSuccess": {
      "type": "success",
      "header": "Success",
      "body": "Template applied successfully."
    },
    "applyProjectTemplateError": {
      "type": "error",
      "header": "Error",
      "body": "We could not apply the template."
    },
    "noLinkedAccount": {
      "type": "error",
      "header": "Error",
      "body": "Your asset account has no linked bank account. Update your account details first."
    },
    "errorUpdatingStripe": {
      "type": "error",
      "header": "Email change incomplete",
      "body": "Your email was changed, but we could not update your payment contact email. Please reach out to our support."
    },
    "existingBankConnectionDetected": {
      "type": "info",
      "header": "Bank login already exists",
      "body": "You have already logged in to this bank. Bypassing bank login."
    },
    "newsletterSubscriptionSuccess": {
      "type": "success",
      "header": "Success",
      "body": "You have successfully registered to our newsletter."
    },
    "newsletterSubscriptionError": {
      "type": "error",
      "header": "Error",
      "body": "We could not register you to our newsletter."
    },
    "noMoreAccountsAllowed": {
      "type": "error",
      "header": "Error",
      "body": "You have reached the maximum number of accounts allowed for your subscription plan."
    },
    "inconsistentAssetTransactions": {
      "type": "warning",
      "header": "Warning",
      "body": "The asset transactions for {{displayName}} in account {{accountName}} are not in the same currency."
    },
    "moreThan1ConnectedAccountFound": {
      "type": "warning",
      "header": "Warning",
      "body": "This asset account is linked to more than 1 deposit account."
    },
    "gridGetPurchaseValuesNoRows": {
      "type": "warning",
      "header": "No suitable rows found",
      "body": "Provide asset details (weight, purity...) or remove price."
    },
    "generalError": {
      "type": "error",
      "header": "Error",
      "body": "The application encountered an error. Please try again later."
    },
    "validationError": {
      "type": "error",
      "header": "Error",
      "body": "Data incorrect - validation failed."
    },
    "renewAccountAccessSuccess": {
      "type": "success",
      "header": "Success",
      "body": "Account access renewed successfully."
    },
    "renewAccountAccessError": {
      "type": "error",
      "header": "Error",
      "body": "We were unable to renew your account access. Please contact our support team."
    }
  },
  "validationErrors": {
    "genericError": "This value cannot be saved."
  },
  "schema": {
    "transactions": "Transactions",
    "accounts": "Accounts",
    "projects": "Projects",
    "reports": "Reports",
    "blog": "Blog",
    "pleaseEnterValue": "Please enter a new value for this attribute",
    "transactionTypes": {
      "sale": "sale",
      "purchase": "purchase",
      "split": "split"
    },
    "deposits": {
      "transaction": {
        "ownName": "Transaction",
        "date": {
          "label": "Date",
          "description": "Date of the transaction."
        },
        "otherParty": {
          "label": "Transaction party",
          "description": "Sender or receipient of the transaction amount."
        },
        "otherPartyAccount": {
          "label": "Account number",
          "description": "IBAN (account no.) of the sender or receipient."
        },
        "description": {
          "label": "Description",
          "description": "Description of the transaction."
        },
        "amount": {
          "label": "Amount",
          "description": "This transaction's amount of money in your base currency."
        },
        "accountCurrencyAmount": {
          "label": "Amount",
          "description": "This transaction's amount of money in account currency."
        },
        "currency": {
          "label": "Account currency",
          "description": "This account's currency."
        },
        "fxAmount": {
          "label": "Foreign currency amount",
          "description": "Optional. If the transaction was conducted in a foreign currency, this is its original amount."
        },
        "fxCurrency": {
          "label": "Foreign currency symbol",
          "description": "Optional. If the transaction was conducted in a foreign currency, this is its three-letter symbol."
        },
        "simulated": {
          "label": "Simulated transaction",
          "description": "Values in this column are true (checked) if the transaction is simulated."
        },
        "incomeFromInterest": {
          "label": "Income from capital",
          "description": "Values in this column are true (checked) if this transaction is an income from capital (interests, dividends etc.)."
        }
      },
      "account": {
        "ownName": "Bank account",
        "name": "Bank name",
        "country": "Country",
        "accountName": {
          "label": "Account name",
          "tooltip": "What name would you like to see for this account in the system?",
          "error": "Please enter a designation for this account."
        },
        "baseCurrency": {
          "label": "Base currency",
          "tooltip": "What currency does this account use?"
        },
        "iban": {
          "label": "IBAN",
          "tooltip": "This account's IBAN (account number)",
          "placeholder": "DE12345678901234567890"
        },
        "bic": {
          "label": "BIC",
          "tooltip": "International bank code (called >BIC<) for the bank where this account is at. Up to 11 characters long. Used to identify a bank in an international setting."
        },
        "bankName": {
          "label": "Bank name",
          "tooltip": "Name of the bank where this account is at.",
          "placeholder": "Sparkasse Bielefeld"
        },
        "syncType": {
          "label": "Synchronisation",
          "tooltip": "How would you like to sync this account?",
          "placeholder": "Select sync type"
        },
        "depositType": {
          "label": "Account type",
          "tooltip": "What kind of account is this?",
          "placeholder": "Select account type"
        },
        "countryCode": {
          "label": "Country",
          "tooltip": "What country is this account in?",
          "placeholder": "Select country"
        },
        "category": {
          "label": "Category",
          "tooltip": "What category does this account belong to?",
          "placeholder": "Select category"
        },
        "parameters": {
          "syncType": {
            "manual": "manual",
            "automatic": "automatic"
          },
          "depositType": {
            "savings-account": "checking",
            "time-deposit": "savings"
          }
        }
      }
    },
    "stocks": {
      "transaction": {
        "date": {
          "label": "Date",
          "description": "Date of the transaction."
        },
        "displaySymbol": {
          "label": "Security symbol*",
          "description": "Symbol of traded security, such as ISIN, WKN or CUSIP. Optional."
        },
        "displayName": {
          "label": "Security name",
          "description": "Stock name."
        },
        "transactionAmount": {
          "label": "Amount",
          "description": "Quantity of securities bought or sold."
        },
        "transactionPrice": {
          "label": "Price (base currency)",
          "description": "Unit price paid, in your base currency."
        },
        "transactionCurrency": {
          "label": "Currency",
          "description": "Currency of the transaction."
        },
        "transactionOriginalPrice": {
          "label": "Price (transaction currency)",
          "description": "Unit price paid, in transactioion currency."
        }
      },
      "account": {
        "brokerName": {
          "label": "Provider name",
          "tooltip": "Which broker is this account with?",
          "placeholder": "DepotX"
        },
        "accountNo": {
          "label": "Account number",
          "tooltip": "What is the number of your brokerage account?",
          "placeholder": "C12345678"
        },
        "username": {
          "label": "Username",
          "tooltip": "What is your username for this account?",
          "placeholder": "user01"
        },
        "syncType": {
          "label": "Synchronisation",
          "tooltip": "How would you like to sync this account?",
          "placeholder": "Select sync type"
        },
        "ownCurrentAccount": {
          "label": "Cash account IBAN",
          "tooltip": "Which current account is this brokerage account linked to?",
          "placeholder": "Select current account"
        },
        "countryCode": {
          "label": "Country",
          "tooltip": "What country is this account in?",
          "placeholder": "Select country"
        },
        "connectedDepositAccounts": {
          "label": "Connected bank account(s)",
          "tooltip": "Which bank account(s) receive proceeds from this account's assets?"
        }
      }
    },
    "meta-comment": "the entries below are realEstate quotes, but this is only used in import of quotes, so leaving it be for now",
    "realEstate": {
      "transaction": {
        "date": {
          "label": "Date",
          "description": "Date of the valuation."
        },
        "currency": {
          "label": "Currency",
          "description": "The currency of the valuation."
        },
        "quote": {
          "label": "Price",
          "description": "Valuation in that currency."
        },
        "quoteBaseCurrency": {
          "label": "Price (base currency)",
          "description": "Valuation in your base currency."
        },
        "note": {
          "label": "Note",
          "description": "Optional. Any valuation details you may want to record."
        }
      },
      "account": {
        "kindOfProperty": "What kind of property is this?",
        "connectedDepositAccounts": {
          "label": "Connected bank account(s)",
          "tooltip": "Which bank account(s) receive proceeds from this account's assets?"
        },
        "valuationParameters": {
          "type": {
            "label": "Building or apartment"
          },
          "subtype": {
            "label": "Property type",
            "tooltip": "What kind of building / apartment is this?"
          },
          "streetAddress": {
            "label": "Street and number",
            "tooltip": "Where is your property located?",
            "placeholder": "Hochstr. 12"
          },
          "zip": {
            "label": "Postal code",
            "tooltip": "What postal code does your property have?",
            "placeholder": "33602",
            "error": "Please provide a postal code."
          },
          "district": {
            "label": "District / Town",
            "tooltip": "If your property is in a Stadtkreis, enter the name of the district (Stadtteil). Otherwise enter the name of the town or village.",
            "placeholder": "Mitte"
          },
          "city": {
            "label": "Stadtkreis / Landkreis",
            "tooltip": "Name of the Stadtkreis (city) or Landkreis (county) your property is located in.",
            "placeholder": "Bielefeld",
            "error": "Please provide Stadtkreis or Landkreis name."
          },
          "countryCode": {
            "label": "Country",
            "tooltip": "What country is your property located in?",
            "placeholder": "Germany",
            "error": "Please provide a country."
          },
          "completeArea": {
            "label": "Complete floor area",
            "tooltip": "How big is your entire property (in m²)?",
            "placeholder": "100"
          },
          "livingArea": {
            "label": "Floor area",
            "tooltip": "How big is the living area of your property (in m²)?",
            "placeholder": "88",
            "error": "Please indicate how large your property is."
          },
          "floor": {
            "label": "Floor",
            "tooltip": "On which floor is your property located?",
            "placeholder": "3"
          },
          "totalFloors": {
            "label": "Building floors",
            "tooltip": "How many floors in total does the building have?",
            "placeholder": "4"
          },
          "rooms": {
            "label": "Rooms",
            "tooltip": "How many rooms does your property have?",
            "placeholder": "3"
          },
          "flooring": {
            "label": "Flooring",
            "tooltip": "What kind of flooring does your property have?"
          },
          "interiorLevel": {
            "label": "Interior standard",
            "tooltip": "How would you rate the interior of your property?"
          },
          "condition": {
            "label": "Current condition",
            "tooltip": "How would you rate the current condition of your property?"
          },
          "lotArea": {
            "label": "Lot area",
            "tooltip": "How big is the lot your property is located on (in square meters)?",
            "placeholder": "450"
          },
          "constructionYear": {
            "label": "Construction year",
            "tooltip": "When was your property built?",
            "placeholder": "2010"
          },
          "energyClass": {
            "label": "Energy consumption",
            "tooltip": "Enter the amount of required kWh per m² per year. If you only know the energy efficiency class, look up the average value for that class and enter it here.",
            "placeholder": 60
          },
          "heatingType": {
            "label": "Heating type",
            "tooltip": "What kind of heating does your property have?"
          },
          "floorHeating": {
            "label": "Underfloor heating?",
            "tooltip": "Does the property have underfloor heating?"
          },
          "lastRenovationYear": {
            "label": "Year of last renovation",
            "tooltip": "When was the property last renovated?",
            "placeholder": "2020"
          },
          "privateParkingSpaces": {
            "label": "Parking spots",
            "tooltip": "How many private parking spots are available for your property?",
            "placeholder": 1
          },
          "garden": {
            "label": "Garden?",
            "tooltip": "Does the property have a garden?"
          },
          "bathTub": {
            "label": "Bathtub?",
            "tooltip": "Does the property have a bathtub?"
          },
          "bathWithWindows": {
            "label": "Bath with windows?",
            "tooltip": "Does the property have a bathroom with windows?"
          },
          "fullHeightWindows": {
            "label": "Full-height windows?",
            "tooltip": "Does the property have full-height windows?"
          }
        },
        "parameters": {
          "type": {
            "apartment": "Apartment",
            "building": "Building"
          },
          "subtype": {
            "building": {
              "farm": "Farm",
              "special-building": "Special Building",
              "bungalow": "Bungalow",
              "castle": "Castle",
              "semi-detached": "Semi-Detached",
              "detached": "Detached",
              "apartment-building": "Apartment Building",
              "terraced": "Terraced",
              "terraced-corner": "Terraced Corner",
              "villa": "Villa",
              "other": "Other"
            },
            "apartment": {
              "top-floor": "Top Floor",
              "ground-floor": "Ground Floor",
              "other-floor": "Other Floor",
              "high-ground-floor": "High Ground Floor",
              "loft": "Loft",
              "two-levels": "Two Levels",
              "penthouse": "Penthouse",
              "souterrain": "Souterrain",
              "with-balcony": "With Balcony",
              "other": "Other"
            }
          },
          "interiorLevel": {
            "simple": "Simple",
            "normal": "Normal",
            "high": "High",
            "luxus": "Luxury"
          },
          "condition": {
            "new": "New",
            "newly-renovated": "Newly Renovated",
            "well-maintained": "Well Maintained",
            "modernized": "Modernized",
            "flexible": "Flexible",
            "like-new": "Like New",
            "needs-renovation": "Needs Renovation",
            "refurbished": "Refurbished",
            "completely-renovated": "Completely Renovated",
            "needs-repair": "Needs Repair"
          },
          "heatingType": {
            "gas": "Gas",
            "oil": "Oil",
            "electricity": "Electricity",
            "district": "District",
            "wood": "Wood",
            "coal": "Coal",
            "solar": "Solar",
            "block": "Block",
            "stock": "Stock",
            "floor": "Floor",
            "nachtspeicher": "Storage Heating",
            "wärmekraft": "Thermal Power",
            "central": "Central"
          },
          "flooring": {
            "carpet": "Carpet",
            "parquet": "Parquet",
            "tiles": "Tiles",
            "laminate": "Laminate",
            "floorboards": "Floorboards"
          }
        }
      }
    },
    "loans": {
      "account": {
        "parameters": {
          "type": {
            "annuity": "Fixed rate (annuity)",
            "fixedPrincipal": "Variable rate (fixed principal)",
            "interestOnly": "Interest only"
          },
          "period": {
            "month": "Monthly",
            "quarter": "Quarterly",
            "annual": "Yearly"
          },
          "direction": {
            "received": "Loan taken",
            "granted": "Loan granted"
          }
        },
        "type": {
          "label": "Loan type",
          "tooltip": "What kind of loan is this? Fixed rate, fixed capital repayment etc.",
          "placeholder": "Select loan type",
          "error": "Loan type is incorrect."
        },
        "direction": {
          "label": "Taken or granted?",
          "tooltip": "Did you borrow money (loan taken) or lent it (loan granted)?",
          "error": "Loan taken/granted is incorrect."
        },
        "loanAmount": {
          "label": "Loan amount (currency)",
          "tooltip": "How much money did you borrow?",
          "placeholder": "-100000",
          "error": "Please enter the loan amount."
        },
        "interestRate": {
          "label": "Interest rate",
          "tooltip": "What is the interest rate of your loan? Changes during loan term are possible.",
          "error": "Please enter the interest rate."
        },
        "period": {
          "label": "Installment frequency",
          "tooltip": "How often do you do repayments? Changes during loan term are possible.",
          "error": "Please enter the loan period."
        },
        "iban": {
          "label": "IBAN",
          "tooltip": "This account's (loan's account) IBAN (account number)",
          "placeholder": "DE12345678901234567890"
        },
        "bankName": {
          "label": "Bank name",
          "tooltip": "This account's (loan's account) IBAN (account number)",
          "placeholder": "DE12345678901234567890"
        },
        "percentRepaidAnnually": {
          "label": "Repayment rate (Tilgung)",
          "tooltip": "How much of the loan do you repay annually? Changes during loan term are possible.",
          "error": "Please enter the repayment rate."
        },
        "contractEndDate": {
          "label": "Loan end date",
          "tooltip": "When does the loan contract end?",
          "error": "Please enter the contract end date."
        },
        "connectedDepositAccounts": {
          "label": "Connected bank account(s)",
          "tooltip": "Which bank account(s) send and receive payments for this loan?"
        },
        "interestCalcMonthEnd": {
          "label": "Interest calculation at month end?",
          "tooltip": "Does your bank calculate your payments at the end of the month (yes) or at its beginning?"
        },
        "connectedProjectId": {
          "label": "Connected project",
          "tooltip": "Which project shows the simulated repayment schedule for this loan?"
        }
      },
      "transaction": {
        "ownName": "Transaction",
        "date": {
          "label": "Date",
          "description": "Date of the transaction."
        },
        "otherParty": {
          "label": "Transaction party",
          "description": "Sender or receipient of the transaction amount."
        },
        "otherPartyIban": {
          "label": "Account number",
          "description": "IBAN (account no.) of your loan account."
        },
        "description": {
          "label": "Description",
          "description": "Description of the transaction."
        },
        "quantity": {
          "label": "Principal",
          "description": "This repayment's principal part in your account currency."
        },
        "quantityInterest": {
          "label": "Interest",
          "description": "This repayment's interest part in your account currency."
        }
      }
    },
    "pension": {
      "account": {
        "parameters": {
          "useSpecialQuote": {
            "payoutOnlyContributionsToDate": "Pension on contributions to-date",
            "payoutOnlyContributionsToDateIndexed": "Pension on contributions to-date (with interest)",
            "payoutAllPlannedContributions": "Pension on all future contribtuions",
            "payoutAllPlannedContributionsIndexed": "Pension on all future contributions (with interest)"
          },
          "contributionsFrequency": {
            "0": "One-time",
            "1": "Monthly",
            "3": "Quarterly",
            "12": "Yearly"
          },
          "payoutsFrequency": {
            "0": "One-time",
            "1": "Monthly",
            "3": "Quarterly",
            "12": "Yearly"
          },
          "frequencyUnit": {
            "0": "indefinitely",
            "1": "months",
            "12": "years"
          }
        },
        "name": {
          "label": "Pension product name",
          "tooltip": "What is the name of this pension product?",
          "placeholder": "My Riester pension",
          "error": "Please provide product name."
        },
        "currency": {
          "label": "Account currency",
          "tooltip": "What currency does this pension product use for contributions and payouts?"
        },
        "connectedDepositAccounts": {
          "label": "Connected bank account(s)",
          "tooltip": "Which bank account(s) send and receive payments for this pension product?"
        },
        "connectedDepositMode": {
          "error": "Please select an option here."
        },
        "productPurchaseDate": {
          "label": "Purchase date",
          "tooltip": "When did you purchase this product? This date does not have to be very precise, it is used to show you how the present value of this product has been changing over time.",
          "error": "Please enter an (approximate) purchase date."
        },
        "initialQuotes": {
          "label": "Original payout estimates",
          "tooltip": "What were the initial payout estimates that were given to you when you signed the contract?",
          "error": "Original payout estimates missing"
        },
        "contributionsSelfPaid": {
          "label": "Contributions self-paid?",
          "tooltip": "Set this to YES if you pay the contributions. If it is your employer, set it to NO.",
          "error": "Contributions self-paid require a value"
        },
        "contributionsAmounts": {
          "label": "Contribution amount",
          "placeholder": 100,
          "tooltip": "Enter the current contribution amount (if self-paid). You can set different amounts for different periods later on.",
          "error": "Please enter an (approximate) contribution amount."
        },
        "contributionsFrequency": {
          "label": "Contribution frequency",
          "tooltip": "How often do you pay contributions to this product?",
          "error": "Contribution frequency is not set"
        },
        "contributionsDeductible": {
          "label": "Are contributions tax-deductible?",
          "tooltip": "Do you receive a tax benefit on this product?",
          "error": "Contribution deductible information is not set"
        },
        "payoutsPhaseStart": {
          "label": "Payout phase start",
          "tooltip": "When is the first payout to take place? We only need an approximate date here. We use it to simulate future payouts. You can change it later.",
          "error": "Please enter the (approximate) payout phase start date."
        },
        "payoutsFrequency": {
          "label": "Payout frequency",
          "tooltip": "Is it a monthly, quarterly, annual or one-time payout?",
          "error": "Payout frequency not set"
        },
        "payoutsDurationUserInput": {
          "label": "Payout paid for how long?",
          "tooltip": "For how long will the payouts be provided? Select 'indefinitely' from the menu if there is no end date.",
          "error": "Payout duration not set"
        },
        "payoutsDuration": {
          "label": "Payout duration",
          "tooltip": "For how long will the payouts be provided? Enter '0' if indefinitely, otherwise enter a number of months (12 for one year, 120 for ten years etc.).",
          "error": "Payout duration not set"
        },
        "payoutsTaxable": {
          "label": "Taxable payout?",
          "tooltip": "Is the payout taxable?",
          "error": "Payout taxable not provided."
        },
        "payoutsIndexed": {
          "label": "Payout growth",
          "tooltip": "Will the payout amount be adjusted over time? We will use inflation or the provided product interest rate to calculate it.",
          "error": "Payout growth not set."
        },
        "useSpecialQuote": {
          "label": "Current value calculation",
          "tooltip": "Which pension estimate should we use to calculate the present value of this pension product?",
          "error": "Pension estimate not provided."
        },
        "useInflationUserInput": {
          "label": "Use inflation for present value?",
          "tooltip": "We normally use inflation rate to calculate present value of this product. Unselect this box to enter your own value for the calculation.",
          "error": "Inflation rate not set."
        },
        "discountRate": {
          "label": "Product interest rate",
          "tooltip": "Interest rate used to calculate present value of this product as well as it's future growth, if any. Leave it open to use the inflation rate specified in settings.",
          "error": "Product interst rate not set."
        },
        "fxRate": {
          "label": "Today's exchange rate of {{accountCurrency}} to {{baseCurrency}}:",
          "tooltip": "To keep things simple, we recommend using current exchange rate. Feel free to use any exchange rate you feel is better suited for the long term.",
          "apply": "Calculate payouts in {{baseCurrency}}"
        }
      },
      "transaction": {
        "date": {
          "label": "Date",
          "description": "On what date did the provider communicate these new values?"
        },
        "currency": {
          "label": "Currency",
          "description": "The currency of the valuation."
        },
        "payoutOnlyContributionsToDate": {
          "label": "Pension on contributions\nto-date",
          "description": "Pension that you would receive if you stopped paying contributions today."
        },
        "payoutOnlyContributionsToDateIndexed": {
          "label": "Pension on contributions\nto-date (with interest)",
          "description": "Pension that you would receive if you stopped paying contributions today, but with interest applied to them until pension payout phase."
        },
        "payoutAllPlannedContributions": {
          "label": "Pension on all\nfuture contributions",
          "description": "Pension that you would receive if you keep paying contributions until pension payout phase."
        },
        "payoutAllPlannedContributionsIndexed": {
          "label": "Pension on all\nfuture contributions\n(with interest)",
          "description": "Pension that you would receive if you keep paying contributions until pension payout phase, but with interest applied to them until pension payout phase."
        }
      }
    },
    "objectsOfValue": {
      "account": {
        "connectedDepositAccounts": {
          "label": "Connected bank account(s)",
          "tooltip": "Which bank account(s) receive proceeds from this account's assets?"
        },
        "name": {
          "label": "Object name",
          "tooltip": "Under what name shall we display this object?",
          "placeholder": "Mercedes 450 SEL",
          "error": "Please provide the object name."
        },
        "currency": {
          "label": "Account currency",
          "tooltip": "What currency does this pension product use for contributions and payouts?"
        },
        "userInputPurchaseDate": {
          "label": "Purchase date",
          "tooltip": "When did you purchase this object?",
          "error": "Please enter the purchase date."
        },
        "userInputPurchaseValueAccountCurrency": {
          "label": "Purchase price in {{currency}}",
          "tooltip": "How much did you pay for this object?",
          "error": "Please enter the purchase value."
        },
        "userInputPurchaseValueBaseCurrency": {
          "label": "Purchase price in {{currency}}",
          "tooltip": "How much did you pay for this object?",
          "error": "Please enter the purchase value."
        },
        "fxRate": {
          "label": "Exchange rate of {{accountCurrency}} to {{baseCurrency}} on {{date}} was",
          "tooltip": "For your convenience we looked up the exchange rate on the day you indicated.",
          "apply": "Calculate purchase price in {{baseCurrency}}"
        },
        "quantity": {
          "label": "Quantity purchased",
          "tooltip": "If applicable here, how many objects did you buy initially? Default value is 1."
        }
      }
    },
    "metals": {
      "account": {
        "connectedDepositAccounts": {
          "label": "Connected bank account(s)",
          "tooltip": "Which bank account(s) receive proceeds from this account's assets?"
        },
        "name": {
          "label": "Account name",
          "tooltip": "How would you like to call this account?",
          "placeholder": "Bank deposit box",
          "error": "Please provide the account name"
        },
        "currency": {
          "label": "Account currency",
          "tooltip": "In which currency shall we track the assets in this account?"
        }
      },
      "transaction": {
        "parameters": {
          "assetMetal": {
            "gold": "gold",
            "silver": "silver",
            "platinum": "platinum",
            "palladium": "palladium",
            "other": "other"
          }
        }
      }
    },
    "unlistedShares": {
      "account": {
        "connectedDepositAccounts": {
          "label": "Connected bank account(s)",
          "tooltip": "Which bank account(s) receive proceeds from this account's assets?",
          "error": "Please select an option"
        },
        "connectedDepositMode": {
          "error": "Please select an option"
        },
        "name": {
          "label": "Company name",
          "tooltip": "What is the name of this company?",
          "placeholder": "My company",
          "error": "Please provide the company name"
        },
        "currency": {
          "label": "Currency",
          "tooltip": "In which currency shall we track your investments in this company?"
        },
        "date": {
          "label": "Company start date",
          "tooltip": "When did you create the company? An approximate date is sufficient.",
          "error": "Please enter a date"
        },
        "upac": {
          "label": "Starting capital",
          "tooltip": "How much starting capital did you invest in the company?",
          "placeholder": 25000,
          "error": "Please enter a value"
        },
        "upbc": {
          "label": "Starting capital (base currency)",
          "tooltip": "How much starting capital is that in your overall base currency?",
          "placeholder": 25000,
          "error": "Please enter a value"
        },
        "quantity": {
          "label": "Number of shares",
          "tooltip": "How many shares did you assume? If not sure, enter 1.",
          "placeholder": 1,
          "error": "Please enter a value"
        }
      }
    },
    "crypto": {
      "account": {
        "connectedDepositAccounts": {
          "label": "Connected bank account(s)",
          "tooltip": "Which bank account(s) receive proceeds from this account's assets?"
        },
        "name": {
          "label": "Account name",
          "tooltip": "How would you like to call this account?",
          "placeholder": "Bank deposit box",
          "error": "Please provide the account name"
        },
        "brokerName": {
          "label": "Provider name",
          "tooltip": "Which exchange or broker is this account with?",
          "placeholder": "Binance"
        },
        "accountNo": {
          "label": "Account idenfitier",
          "tooltip": "What is the identifier of your account?",
          "placeholder": "C12345678"
        }
      }
    }
  },
  "projects": {
    "newProject": "New Project",
    "interestPayment": "Interest payment on ",
    "dividendPayment": "Dividend payment",
    "simulated": "Simulated",
    "purchase": "Purchase",
    "sale": "Sale",
    "baseCurrency": "Project base currency",
    "progressIndicator": "Preferred progress indicator",
    "roi": "ROI",
    "roiSliderDate": "ROI now",
    "roiNextGoal": "ROI at goal",
    "duration": "Duration",
    "goal": "Goal",
    "noInvestment": "No data",
    "visible": "Visible outside of this project?",
    "planned": "Planned",
    "actual": "Actual gain",
    "yes": "yes",
    "noDraftMode": "no",
    "delete": "Delete project",
    "addGoal": "Add goal",
    "addGoalDescription": "Define a goal for this project.",
    "deleteGoal": "Delete goal",
    "name": "Goal title",
    "transactions": "Transactions",
    "assets": {
      "label": "Project assets",
      "tooltip": "Bank accounts and assets used in the project are displayed here."
    },
    "add": "Add transaction",
    "addQuote": "Add future value",
    "addQuoteDescription": "Define the future value of any asset in this project.",
    "addQuoteButtonDisabled": "You need to add at least one asset to this project before you can add a future value.",
    "addQuoteButtonEnabled": "Add future value of a project asset",
    "selectDepositAccount": {
      "header": "Select deposit account",
      "prompt": "Which deposit account should we use for the proceeds and costs of this asset?"
    },
    "inflow": {
      "labelTop": "Inflow",
      "labelBottom": "Receive money"
    },
    "outflow": {
      "labelTop": "Outflow",
      "labelBottom": "Spend money"
    },
    "transfer": {
      "labelTop": "Transfer",
      "labelBottom": "between accounts"
    },
    "buy": {
      "labelTop": "Buy",
      "labelBottom": "assets | deposits"
    },
    "sell": {
      "labelTop": "Sell",
      "labelBottom": "assets | deposits"
    },
    "newAccount": {
      "label": "New",
      "description": "New account"
    },
    "applyTemplate": "Apply project template",
    "removeTemplate": "Remove project template",
    "addTransaction": "Add transaction",
    "growthRates": "Asset growth rates",
    "growthRatesDescription": "You can define individual growth rates for each asset. They will apply to this asset in this project only.",
    "growthRatesNonIsolated": "These growth rates also apply to assets outside of this project. Switch project to isolated mode to define project-specific growth rates.",
    "transactionTypes": "Transaction types",
    "transactionDetails": "Transaction details",
    "date": "Date",
    "transactionDate": {
      "label": "Transaction date",
      "tooltip": "Always rounded down to the 1st day of current month. Adjust order of transactions within one month by dragging transactions in the correct sequence in the Project Details view.",
      "error": "Date must be a future date. Use Account Details view to add a past transaction."
    },
    "recurringTransaction": {
      "label": "Should this transaction repeat regularly?"
    },
    "indexed": {
      "label": "Should the amount be increased every year?",
      "byInflation": "increase by inflation rate (link)",
      "increaseBy": "increase by"
    },
    "repeating": {
      "label": "Repeating pattern",
      "every": "Repeat every",
      "month": "months",
      "year": "years",
      "endBy": "Repetition should end",
      "never": "never",
      "after": "after",
      "afterError": "Maximum number of periods is 999.",
      "occurrences": "occurrences",
      "on": "on"
    },
    "synchronising": "Synchronising...",
    "save": "Save",
    "cancel": "Cancel",
    "deleteQuote": "Delete this future value",
    "amount": {
      "label": "Amount",
      "inflowsCanOnlyBePositive": "Inflows can only be positive. Use Outflows for negative transactions.",
      "outflowsCanOnlyBeNegative": "Outflows can only be negative. Use Inflows for positive transactions.",
      "transfersCanOnlyBePositive": "Transfers should always be positive. To define a money flow in the opposite direction, swap the To and From accounts around."
    },
    "baseCurrencyAmount": {
      "label": "Amount in"
    },
    "exchangeRate": {
      "label": "FX rate to ",
      "mustBeGreaterThanZero": "Exchange rate must be greater than zero."
    },
    "switchToAmount": "Switch to amount",
    "switchToFxRate": "Switch to FX rate",
    "currency": "Currency",
    "account": {
      "label": "Transaction will be deducted from this account"
    },
    "accountTo": {
      "label": "Transaction will appear on this account",
      "error": "You cannot transfer money to the same account."
    },
    "transactionDescription": {
      "label": "Transaction description will be",
      "placeholder": "Salary",
      "required": "Please enter a description",
      "length": "Description must be at least 2 characters long"
    },
    "assetAccount": {
      "label": "New asset will appear on this account",
      "error": "Connected deposit account for this account is not defined."
    },
    "assetContributionLabel": {
      "label": "New contribution will appear on this account",
      "error": "Connected deposit account for this account is not defined."
    },
    "designatedDepositConnectedAccountIndex": {
      "label": "Bank account for this transaction",
      "error": "Error."
    },
    "assetName": {
      "tooltip": "This is just a placeholder information which is used for simulations.",
      "label": "Asset name",
      "orISIN": "or ISIN / WKN code",
      "placeholder": "Tesla stocks"
    },
    "assetSymbol": {
      "tooltip": "Pick an existing asset from the list or create a new one. You can use a placeholder name, as this it is only used for simulations.",
      "label": "Stock name / ISIN / WKN",
      "placeholder": "DE0000A1B2C3"
    },
    "assetMetal": {
      "label": "Metal",
      "error": "Please select a metal."
    },
    "assetWeight": {
      "label": "Weight (g)",
      "error": "Please enter weight."
    },
    "assetPurity": {
      "label": "Purity (‰)",
      "error": "Please enter purity in parts per 1000 (e.g. 999 or 965)."
    },
    "assetAdditionalValue": {
      "label": "Extra value (%)",
      "tooltip": "Enter a percentage by which to increase this asset's value due to its historical or artistic value."
    },
    "assetAmount": {
      "label": "How many pieces of asset to",
      "buy": "buy",
      "sell": "sell"
    },
    "depositAmount": {
      "label": "Amount"
    },
    "deposit": "deposit",
    "assetUnitPrice": {
      "label": "Price per unit",
      "error": "Asset unit price must be greater than 0."
    },
    "assetContribution": {
      "label": "Amount",
      "error": "Contribution must be greater than 0."
    },
    "assetUnitPriceBaseCurrency": {
      "label": "Price in ",
      "error": "Asset unit price must be greater than 0."
    },
    "quote": {
      "label": "Price"
    },
    "quoteBaseCurrency": {
      "label": "Price in ",
      "error": "Asset unit price must be greater than 0."
    },
    "todaysPrice": "Current price of {{displayName}} is",
    "selectAnOption": "Select an option",
    "createNewAccount": "Create new account",
    "errorNotAllFields": "Please fill out all the fields.",
    "templateInitialisationError": "Error while initialising project template.",
    "quoteAlreadyExists": "Future value for this asset and date already exists. It will be overwritten if you click Save.",
    "quoteProjectWillBeChanged": "When you click Save, this future value will become part of this project. It will be deleted if you delete the project.",
    "isolatedWarning": "Transactions and future values are not visible outside of this project",
    "collisions": "Which future values are we keeping?",
    "collisionsDescription": "You are switching an isolated project to a global project. This project has some future values that are already defined in other projects. You can either keep the values from this project, or keep the values from the other projects.",
    "asset": "Asset",
    "thisProjectQuote": "This project",
    "globalQuote": "Other projects",
    "allCollisionsMustBeResolved": "Please select one of the values in each row.",
    "recurring": "Recurring transactions",
    "simulatedDividends": "Simulated dividends",
    "simulatedInterest": "Simulated interest",
    "simulatedQuotes": "Future values",
    "applyTemplateDescription": "Apply a set of transactions and future values to your project. You can then edit them as you wish. If you don't like the effect, you can remove the entire template with one click.",
    "templates": {
      "select": "Select",
      "investToRent": {
        "name": "Buy property to rent out with a mortgage",
        "description": "You want to buy a property to rent out. You will take out a mortgage to finance the purchase. You will rent out the property and use the rental income to pay off the mortgage. You will also pay property taxes and other expenses. You want to see how the property will perform over time.",
        "role1": "Money for downpayment",
        "transaction1": "First incoming transfer.",
        "transaction2": "Second incoming transfer."
      }
    }
  },
  "invitation": {
    "title": "Earn free months",
    "subtitle.top": "Your recommendation",
    "subtitle.bottom": "means a lot to us!",
    "description": "Your friends, family, and colleagues could also benefit from using Monestry. When they purchase a Monestry subscription, both of you will receive a free month of the plan you have.",
    "paragraphs.0.name": "How does it work?",
    "paragraphs.0.description": "Share the information below with someone. When they purchase a Monestry subscription, both of you will receive an additional month of your plan for free.",
    "paragraphs.1.name": "Registration code is important.",
    "paragraphs.1.description": "The person must register using the link below or the registration code, so that we know to award a free month to you as well.",
    "paragraphs.2.name": "Your personalized invitation.",
    "paragraphs.2.description": "Click the button below to copy the invitation text to your clipboard. You can then paste it into an email or a message.",
    "invitationText": "Check out this website!",
    "invitationText0": "I'd like to invite you to try out Monestry, a trusted financial planning app! Check it out here: http://www.monestry.de/features.",
    "invitationText1": "It's free to try out for 30 days, and if you decide to subscribe, you'll get an additional month for free.",
    "invitationText2": "Use this link to register: https://www.monestry.de/register?registrationCode={{code}} or enter code {{code}}",
    "copyButton.share": "Share",
    "copyButton.copy": "Copy"
  },
  "rebalancing": {
    "Currency": "Currency",
    "Back to calculation": "Back to calculation",
    "Show required transactions": "Show required transactions",
    "Recalculate": "Recalculate",
    "Required transactions": "Required transactions",
    "Save": "Save",
    "Cancel": "Cancel"
  },
  "reporting": {
    "yourReports": "Your reports",
    "selectReport": "Select report",
    "myReport": "My report",
    "save": "Save",
    "cancel": "Cancel",
    "delete": "Delete",
    "new": "New",
    "edit": "Edit",
    "transactions": "Transactions",
    "duplicate": "Duplicate",
    "balances": "Balances",
    "history": "History",
    "howToUseReports": "How to use reports",
    "switchToProjects": "Switch to project data",
    "switchToDashboard": "Switch to dashboard data",
    "tooltips": {
      "new": "Create a new report",
      "edit": "Edit the selected report",
      "delete": "Delete the selected report",
      "duplicate": "Make a copy of the selected report"
    }
  }
}