/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable no-redeclare */
/* eslint-disable block-scoped-var */
/* eslint-disable no-var */
/* eslint-disable vars-on-top */
/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
import jspreadsheet from '@monestry-dev/jspreadsheet-ce';

const formulajsPatch = require('./jspreadsheetFormulaPatch');

export default function executeFormulaPatch(expression, x, y, obj) {
  const jexcel = obj;

  const formulaResults = [];
  const formulaLoopProtection = [];

  // Execute formula with loop protection
  const execute = function execute(expression, x, y, jexcel) {
    // Parent column identification
    const parentId = jexcel.getColumnNameFromId([x, y]);

    // Code protection
    if (formulaLoopProtection[parentId]) {
      console.error('Reference loop detected');
      return '#ERROR';
    }

    formulaLoopProtection[parentId] = true;

    // Convert range tokens
    const tokensUpdate = function tokensUpdate(tokens) {
      for (let index = 0; index < tokens.length; index++) {
        const f = [];
        const token = tokens[index].split(':');
        const e1 = jexcel.getIdFromColumnName(token[0], true);
        const e2 = jexcel.getIdFromColumnName(token[1], true);

        if (e1[0] <= e2[0]) {
          var x1 = e1[0];
          var x2 = e2[0];
        } else {
          var x1 = e2[0];
          var x2 = e1[0];
        }

        if (e1[1] <= e2[1]) {
          var y1 = e1[1];
          var y2 = e2[1];
        } else {
          var y1 = e2[1];
          var y2 = e1[1];
        }

        for (let j = y1; j <= y2; j++) {
          for (let i = x1; i <= x2; i++) {
            f.push(jexcel.getColumnNameFromId([i, j]));
          }
        }

        expression = expression.replace(tokens[index], f.join(','));
      }
    };

    // Range with $ remove $
    expression = expression.replace(/\$?([A-Z]+)\$?([0-9]+)/g, '$1$2');

    // eslint-disable-next-line no-useless-escape
    var tokens = expression.match(/([A-Z]+[0-9]+)\:([A-Z]+[0-9]+)/g);
    if (tokens && tokens.length) {
      tokensUpdate(tokens);
    }

    // Get tokens
    var tokens = expression.match(/([A-Z]+[0-9]+)/g);

    // Direct self-reference protection
    if (tokens && tokens.indexOf(parentId) > -1) {
      console.error('Self Reference detected');
      return '#ERROR';
    }
    // Expressions to be used in the parsing
    const formulaExpressions = {};

    if (tokens) {
      for (let i = 0; i < tokens.length; i++) {
        // Keep chain
        if (!obj.formula[tokens[i]]) {
          obj.formula[tokens[i]] = [];
        }
        // Is already in the register
        if (obj.formula[tokens[i]].indexOf(parentId) < 0) {
          obj.formula[tokens[i]].push(parentId);
        }

        // Do not calculate again
        // if (eval(`typeof(${tokens[i]}) == "undefined"`)) {
        if (!(tokens[i] in window)) {
          // Coords
          const position = jexcel.getIdFromColumnName(tokens[i], 1);
          // Get value
          if (typeof obj.options.data[position[1]] !== 'undefined' && typeof obj.options.data[position[1]][position[0]] !== 'undefined') {
            var value = obj.options.data[position[1]][position[0]];
          } else {
            var value = '';
          }
          // Get column data
          if (`${value}`.substr(0, 1) == '=') {
            if (formulaResults[tokens[i]]) {
              value = formulaResults[tokens[i]];
            } else {
              value = execute(value, position[0], position[1]);
              formulaResults[tokens[i]] = value;
            }
          }
          // Type!
          if (`${value}`.trim() == '') {
            // Null
            formulaExpressions[tokens[i]] = null;
          } else if (value == Number(value) && obj.options.autoCasting == true) {
            // Number
            formulaExpressions[tokens[i]] = Number(value);
          } else {
            // Trying any formatted number
            const number = obj.parseNumber(value, position[0]);
            if (obj.options.autoCasting == true && number) {
              formulaExpressions[tokens[i]] = number;
            } else {
              formulaExpressions[tokens[i]] = `"${value}"`;
            }
          }
        }
      }
    }

    // Convert formula to javascript
    try {
      // var res = jexcel.formula(expression.substr(1), formulaExpressions, x, y, obj);
      var res = formulajsPatch(expression.substr(1), formulaExpressions, x, y, obj);
    } catch (e) {
      var res = '#ERROR';
      console.log(e);
    }

    return res;
  };

  return execute(expression, x, y, jspreadsheet);
}
