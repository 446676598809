/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
/* eslint-disable object-curly-newline */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import FullPageOverlayContainer from '../../elements/FullPageOverlayContainer';
import Button from '../../elements/Button';
import Dropdown from '../../elements/Dropdown';
import Table from '../accountDetails/Table';
import { assetBalancesArray } from '../../redux/reducers/data';
import calculateRebalancing from './calculateRebalancing';
import InputFieldWrapper from './InputFieldWrapper';
import OutputPane from './OutputPane';
import { convertCurrency } from '../../misc/ecbCurrencyRates';

function convertDataToTableFormat(dimensionField, data, dimensionItemsArray, t) {
  return (
    data
      // remove rows with zero amount or erroneous
      .map((row) => ({ ...row, [row[dimensionField]]: row.current.valueBaseCurrency })) // e.g. { ..., EUR: 1234.24 } for assetCurrency
      // add category summary rows
      // data is pre-sorted by category, so if we encounter a new category, add a new row with just the category ("header row"); CAVEAT: it is added to assetId column for now
      .reduce((acc, curr, _, myself) => {
        // at the very beginning add a summary row for the total (to show distribution by currency or other dimension field)
        if (acc.length === 0) {
          const totalSum = myself.reduce((sum, row) => sum + row.current.valueBaseCurrency, 0);
          const totalRow = { category: null, assetId: null, categoryLabel: 'TOTAL', categoryTotal: totalSum, categoryPercent: 1, isTotalRow: true, tableLevel: 'total' };
          dimensionItemsArray.forEach((dimensionItem) => {
            totalRow[dimensionItem] = myself.filter((row) => row[dimensionField] === dimensionItem).reduce((sum, row) => sum + row.current.valueBaseCurrency, 0);
            totalRow[`${dimensionItem}Percent`] = totalRow[dimensionItem] / totalSum;
          });
          acc.push(totalRow);
        }
        // add category row (if not there yet)
        if (acc.filter((x) => x.category === curr.category && x.isSummaryRow === true).length === 0) {
          const totalSum = acc.find((row) => row.categoryLabel === 'TOTAL')?.categoryTotal;
          const categorySum = myself.filter((row) => row.category === curr.category).reduce((sum, row) => sum + row.current.valueBaseCurrency, 0);
          const categoryRow = {
            category: curr.category,
            categoryLabel: t(`dashboard.categories.${curr.category}`),
            isSummaryRow: true,
            categoryTotal: categorySum,
            categoryPercent: categorySum / totalSum,
            tableLevel: 'category',
          };
          dimensionItemsArray.forEach((dimensionItem) => {
            categoryRow[dimensionItem] = myself.filter((row) => row.category === curr.category && row[dimensionField] === dimensionItem).reduce((sum, row) => sum + row.current.valueBaseCurrency, 0);
            categoryRow[`${dimensionItem}Percent`] = categoryRow[dimensionItem] / categorySum;
          });
          categoryRow.rowTotal = myself.filter((row) => row.category === curr.category).reduce((sum, row) => sum + row.current.valueBaseCurrency, 0);
          acc.push(categoryRow);
        }
        // add asset row
        acc.push({
          ...curr,
          displayLabel: curr.category === 'stocks' ? `${curr.displayName} (${curr.displaySymbol})` : curr.displayName,
          categoryTotal: curr.current.valueBaseCurrency,
          isSummaryRow: false,
          tableLevel: 'bottom',
        });
        return acc;
      }, [])
  ); // initial reduce value
}

/**
 * Logic overview:
 * - current data come from assetBalancesArray
 * - in the first pass, the component takes current data as target data
 * - after every "Recalculate" the system calculates changes in portfolio-wide allocation and within every category-wide allocation (currency)
 * - resulting from that calculation are factors for each category and currency within a category, by which the component will multiply the current values to get the target values
 * - target data are updated and presented to user
 */

export default function Rebalancing({ closeCallback }) {
  const [targetAllocation, setTargetAllocation] = useState([]); // #01 target allocation for rebalancing ({ })
  // when this is set to true, InputField will update all NULL targetAllocations to the actual form value; it is reset once targetAllocation gets recalculated (in a useEffect)
  const [recalculatePressed, setRecalculatePressed] = useState(false); // #02
  const [outputMode, setOutputMode] = useState(false); // #03 shows / hides the Output panel

  // when state changes, update ref
  useEffect(() => {
    const event = new CustomEvent('rebalancingOutputMode', { detail: outputMode });
    document.dispatchEvent(event);
  }, [outputMode]);

  const initialTableLayoutCurrent = [
    [
      { id: 'categoryPercent', highlight: false, type: 'percentage', textAlign: 'text-right', hideHeaderLabel: true },
      { id: 'categoryTotal', highlight: false, type: 'currency0Decimals', textAlign: 'text-right', usesHeader: 'total', classNamesBody: 'font-normal text-xs' },
    ],
    [
      { ifSummaryRowDisplay: true, id: 'categoryLabel', highlight: true, type: 'string', textAlign: 'text-left', hideHeaderLabel: true },
      { ifSummaryRowDisplay: false, id: 'displayLabel', highlight: true, type: 'string', textAlign: 'text-left', usesHeader: 'category', classNamesBody: 'text-xs' },
    ],
  ];

  const initialTableLayoutTarget = [
    [
      {
        id: 'categoryPercent',
        highlight: false,
        type: 'percentage',
        textAlign: 'text-right',
        hideHeaderLabel: true,
        // display InputField when isSummaryRow is present; display it _instead_ of the categoryPercent column as long as isSummaryRow is present
        // passSetTarget... is a wrapper that passes setTargetAllocation to component before rendering it (it is not possible to declare InputField in Rebalancing scope)
        displayElement: { displayInstead: true, displayInsteadWhenField: 'isSummaryRow', element: InputFieldWrapper(targetAllocation, setTargetAllocation, recalculatePressed), field: 'isSummaryRow' },
      },
      { id: 'categoryTotal', highlight: false, type: 'currency0Decimals', textAlign: 'text-right', usesHeader: 'total', classNamesBody: 'font-normal text-xs' },
    ],
    [
      { ifSummaryRowDisplay: true, id: 'categoryLabel', highlight: true, type: 'string', textAlign: 'text-left', hideHeaderLabel: true },
      { ifSummaryRowDisplay: false, id: 'displayLabel', highlight: true, type: 'string', textAlign: 'text-left', usesHeader: 'category', classNamesBody: 'text-xs' },
    ],
  ];

  const [tableState, setTableState] = useState({
    sortBy: 'date',
    sortDirectionAsc: true,
    colWidths: [null, null, null, null],
    tableHeight: 300,
  }); // #05
  const [dimensionItems, setDimensionItems] = useState([]); // #06 holds COLUMNS in Target, i.e. all distinct members of the dimension being displayed (e.g. all currencies or all markets)
  const [tableLayoutCurrent, setTableLayoutCurrent] = useState(initialTableLayoutCurrent); // #07
  const [tableLayoutTarget, setTableLayoutTarget] = useState(initialTableLayoutTarget); // #08
  const [currentData, setCurrentData] = useState([]); // #09 data for the current view (should be changed when currencies are recalculated)
  const [rawRebalancingResults, setRawRebalancingResults] = useState([]); // #10 raw results from calculateRebalancing (before converting to table format)
  const [targetData, setTargetData] = useState([]); // #11 data displayed by the Target Table component
  // ↓ which field to use for dimension (e.g. currency, market, etc.); this field must be provided in currentData (and targetData, but that should happen automatically)
  // const [dimensionField, setDimensionField] = useState('transactionCurrency'); // this can become state when needed (transactionCurrency is deprecated)
  const dimensionField = 'assetCurrency'; // this can become state when needed
  const [fxRates, setFxRates] = useState([]); // #12

  const { t } = useTranslation('app');

  // get positions from store
  const selectAssetPositions = useSelector(assetBalancesArray); // if filter is on this, then there is a maxDepthExceeded problem
  const assetPositionsFiltered = selectAssetPositions.filter((row) => row.current.quantity && row.current.quantity > 0);
  const baseCurrency = useSelector((state) => state.user.profile.settings.baseCurrency);

  // when selector data change, first compute currencies (or other table columns) and "current" table layout for "current" view
  // also trigger recalculation when user changes dimensionField through the dropdown
  useEffect(() => {
    // select all distinct table columns in dataView
    console.log('DEBUG assetPositionsFiltered', assetPositionsFiltered);
    setDimensionItems([...new Set(assetPositionsFiltered.map((row) => row[dimensionField]))]);
    setTableLayoutCurrent([
      ...initialTableLayoutCurrent,
      ...(dimensionItems || []).map((dimensionItem) => [
        { id: `${dimensionItem}Percent`, type: 'percentage', usesHeader: dimensionItem, textAlign: 'text-right' },
        { id: dimensionItem, type: 'currency0Decimals', hideHeaderLabel: true, textAlign: 'text-right', classNamesBody: 'font-normal text-xs' },
      ]),
    ]);
  }, [selectAssetPositions, dimensionField]);

  // once you are done computing currencies, make sure current data are updated
  // (it could have been done together with the useEffect above, but sometimes the currencies are not yet computed there)
  useEffect(() => {
    setCurrentData(convertDataToTableFormat(dimensionField, assetPositionsFiltered, dimensionItems, t));
  }, [selectAssetPositions, dimensionItems]);

  // get FX currencies for all currencies in assetPositionsFiltered (they will be used by OutputPane later on)
  useEffect(() => {
    async function fetchFxRates(currList) {
      const promises = currList.map((currency) => convertCurrency(1, currency, baseCurrency, new Date().valueOf()));
      const results = (await Promise.all(promises)).flat();
      setFxRates(results.map((row, idx) => ({ currency: currList[idx], rate: row })));
    }

    const currencies = [...new Set(assetPositionsFiltered.map((row) => row.assetCurrency || row.accountCurrency))];

    fetchFxRates(currencies);
  }, [selectAssetPositions]);

  // dynamically compute table layout for target view (once targetData is calculated)
  useEffect(() => {
    setTableLayoutTarget([
      ...initialTableLayoutTarget,
      ...(dimensionItems || []).map((dimensionItem) => [
        {
          id: `${dimensionItem}Percent`,
          type: 'percentage',
          usesHeader: dimensionItem,
          textAlign: 'text-right',
          displayElement: {
            displayInstead: true,
            displayInsteadWhenField: 'isSummaryRow',
            element: InputFieldWrapper(targetAllocation, setTargetAllocation, recalculatePressed),
            field: 'isSummaryRow',
          },
        },
        { id: dimensionItem, type: 'currency0Decimals', hideHeaderLabel: true, textAlign: 'text-right', classNamesBody: 'font-normal text-xs' },
      ]),
    ]);
    // set the recalculated flag to false, so that InputField will not update targetAllocation with the form values
    setRecalculatePressed(false);
  }, [targetData]);

  // used by Recalculate button and whenever currentData change
  function handleRecalculate() {
    // calculateRebalancing uses 'yield' command to return intermediate results (targetAllocation) when called via f.next(); it returns a { value: <something>, done: true } object
    // console.info('input to calculateRebalancing', assetPositionsFiltered, targetAllocation);
    const f = calculateRebalancing(assetPositionsFiltered, targetAllocation);

    const newTargetAllocation = f.next().value; // this .next() gets the first value from calculateRebalancing (targetAllocation)
    console.info('Rebalancing: returned new target allocation', newTargetAllocation);
    setTargetAllocation(newTargetAllocation);

    const results = f.next().value; // this .next() gets the final value from calculateRebalancing (updated positions)
    console.info('Rebalancing: returned new target data (updated positions)', results);
    setRawRebalancingResults(results);
    const convertedResults = convertDataToTableFormat(
      dimensionField,
      results.map((row) => ({ ...row, current: { valueBaseCurrency: row.targetAmount } })),
      dimensionItems,
      t,
    );
    console.info('Rebalancing: new target data converted to table format', convertedResults);
    setTargetData(convertedResults);
  }

  // recalculate button additionally updates targetAllocation with form values (which are displayed to the user)
  // we cannot do it earlier because the categories may not get loaded at the same time (and after realEstate loads first, it would be set to 100%)
  function handleRecalculateButton() {
    setRecalculatePressed(true); // triggers useEffect in InputField, which transfers all field values to targetAllocation
    handleRecalculate();
  }

  // handles target calculation
  // if selectAssetPositions (once currentData is calculated though) or user presses Recalculate button, recalculate the targetData
  useEffect(() => {
    handleRecalculate();
  }, [currentData]);

  function handleOutputMode() {
    setOutputMode(!outputMode);
  }

  return (
    <FullPageOverlayContainer id="rebalancing-parent" closeCallback={closeCallback}>
      <div className="px-4 md:px-0 pt-8 pb-16 space-y-4">
        <header aria-label="rebalancing header">
          <h1 className="font-bold text-gray-900 text-xl xs:text-2xl sm:text-3xl">Rebalancing</h1>
        </header>
        <menu className="pt-2 flex justify-start items-center space-x-6" aria-label="rebalancing menu">
          {outputMode ? (
            <Button text={t('rebalancing.Back to calculation')} withAccent={false} size="xl" onClick={handleOutputMode} />
          ) : (
            <Button text={t('rebalancing.Show required transactions')} withAccent size="xl" onClick={handleOutputMode} />
          )}
          <div className="flex flex-col sm:flex-row items-center">
            <div className="-mb-1 pr-2 block text-sm font-medium text-gray-700">Rebalance by</div>
            <Dropdown list={[t('rebalancing.Currency')]} value={t('rebalancing.Currency')} optional={false} />
          </div>
        </menu>
        <section className="pt-8 w-full overflow-y-visible scrollbar-thin">
          <div className="w-full grid grid-cols-1 xl:grid-cols-2 gap-8" id="rebalancing-grid-container">
            {!outputMode && (
              <div className="flex flex-col items-center" id="rebalancing-current-parent">
                <h2 className="font-bold text-gray-900 text-lg xs:text-xl" aria-label="current allocation">
                  Current
                </h2>
                <Table data={currentData} tableLayout={tableLayoutCurrent} tableState={tableState} setTableState={setTableState} groupByColumn="category" />
              </div>
            )}
            <div className="flex flex-col items-center relative" id="rebalancing-target-parent">
              <div className="w-full grid grid-cols-3">
                <h2 className="text-center col-start-2 font-bold text-gray-900 text-lg xs:text-xl" aria-label="target allocation">
                  Target
                </h2>
                <div className="justify-self-end">{!outputMode && <Button text={t('rebalancing.Recalculate')} withAccent={false} size="lg" onClick={handleRecalculateButton} />}</div>
              </div>
              {/* <div style={{ fontFamily: 'monospace', whiteSpace: 'pre' }}>{JSON.stringify(cav, null, 2)}</div> */}
              <Table data={targetData} tableLayout={tableLayoutTarget} tableState={tableState} setTableState={setTableState} groupByColumn="category" />
            </div>
            {outputMode && <OutputPane rawRebalancingResults={rawRebalancingResults} fxRates={fxRates} />}
          </div>
        </section>
      </div>
    </FullPageOverlayContainer>
  );
}
Rebalancing.propTypes = {
  closeCallback: PropTypes.func.isRequired,
};
