import {
  depositTransaction,
  stockTransaction,
  realEstateTransaction,
  loanTransaction,
  pensionTransaction,
  objectsOfValueTransaction,
  metalsTransaction,
  unlistedSharesTransaction,
  cryptoTransaction,
} from '@monestry-dev/schema';
// eslint-disable-next-line import/no-cycle
import { postData, postAccount } from '../reducers/data';

import { PREUPDATE_PROJECT_ORDER_SEQUENCE, UPDATE_PROJECT_ORDER_SEQUENCE_SUCCESS, UPDATE_PROJECT_ORDER_SEQUENCE_FAIL } from './types';

export * from './data/stocks';

export const postNewProjectSortingOrder = (payload, final = false) => (dispatch) => {
  // this is triggered when a user rearranges the order of transactions in a project
  // payload will be two transactions with updated sortingOrderWithinMonth fields
  // they will not necessarily come from the same category (!)

  // expected payload is an array with two full, canonical transactions
  // which will be uploaded to the state verbatim
  // final is a boolean indicating whether this is the final update
  // some updates are temporary and are not meant to be reflected in the database

  // the plan is to update the state first in this case (user expects a quick action)
  // and sent the postData orders to the server in the background, without error notifications if it fail
  // if this results to be buggy, we will need to add a spinner after each move (in the date component)

  // update state with the new sorting order
  dispatch({
    type: PREUPDATE_PROJECT_ORDER_SEQUENCE,
    payload,
  });

  // do postData depending on category(!) (no post-postData state update anymore)
  // error behaviour: no error notification, just a console.log

  if (final) {
    postData({ data: payload, category: 'deposits', accountId: payload[0].accountId })
    // returns { status: 'noerrors', accounts: [{account-object}] with importFlags }
    // we are not updating the state here, as the state has been pre-updated above
      .then(
        (data) => {
          dispatch({
            type: UPDATE_PROJECT_ORDER_SEQUENCE_SUCCESS,
            payload: {},
          });

          if (data.status !== 'noerrors') console.error('postNewProjectSortingOrder: postData returned errors in data.status');

          return Promise.resolve();
        },
        (error) => {
          // handles promise rejection
          console.error('postNewProjectSortingOrder: postData returned errors', error);
          return Promise.reject();
        },
      );
  }
};

// dispatches postData request to the correct category service
// payload is an array of transactions with the extra category flag [ { ...transaction-object, category: 'category-name' } ]
// if importFlag is missing in the transaction-object, it will be by default interpreted as POST / PUT
export function postMixedData(payload, cancelSignal = { aborted: false }) {
  payload.forEach((item) => {
    if (!item.category) console.error('postData wrapper: transaction is missing a category attribute:', item);
  });

  // get payload to an object { deposits: { accountId: [transaction-objects], accountId2: [transaction-objects] }, stocks: { ... } }
  const payloadByCategory = payload.reduce(
    (acc, item) => ({
      ...acc,
      [item.category]: {
        ...acc[item.category],
        [item.accountId]: [...((acc[item.category] || {})[item.accountId] || []), item],
      },
    }),
    {},
  );

  return (dispatch) => {
    // dispatch data to the correct category service by category, in bulk
    // postData accepts only data per account afair
    Object.keys(payloadByCategory).forEach((item) => {
      Object.keys(payloadByCategory[item]).forEach((accountId) => {
        dispatch(postData({ data: payloadByCategory[item][accountId], category: item, accountId, cancelSignal }));
      });
    });
  };
}

// dispatches putAccount request to the correct category service
// payload is a SINGLE account object with the extra category flag { ...account-object, category: 'category-name' }
// if importFlag is missing in the transaction-object, it will be by default interpreted as POST / PUT
export function putMixedAccount(payload) {
  if (!payload.category) console.error('postAccount wrapper: account object is missing a category attribute:', payload);
  console.error('putMixedAccount', payload);

  return (dispatch) => {
    // dispatch data to the correct category service by category, in bulk
    postAccount({ data: payload, category: payload.category });
  };
}

// for each transaction in the payload, cast and valudate schema
// payload is the following object: { category: 'deposits', transaction: {transaction-object} }
// returns { category: 'deposits', transaction: {transaction-object}, validation: true / false }
// on validation error throws an error; error.errors contain error messages
export function validateSchema(payload) {
  function validateTransaction(transaction, category) {
    // validate the transaction object, returns true if valid, false if not
    let schema;
    switch (category) {
      case 'deposits':
        schema = depositTransaction;
        break;
      case 'stocks':
        schema = stockTransaction;
        break;
      case 'realEstate':
        schema = realEstateTransaction;
        break;
      case 'loans':
        schema = loanTransaction;
        break;
      case 'pension':
        schema = pensionTransaction;
        break;
      case 'objectsOfValue':
        schema = objectsOfValueTransaction;
        break;
      case 'metals':
        schema = metalsTransaction;
        break;
      case 'unlistedShares':
        schema = unlistedSharesTransaction;
        break;
      case 'crypto':
        schema = cryptoTransaction;
        break;
      default:
        throw new Error(`User-defined error in validateSchema: no schema found for category ${category}`);
    }
    const castTransaction = schema.cast(transaction);
    return schema.validateSync(castTransaction, { abortEarly: false });
  }

  const validatedTransaction = validateTransaction(payload.transaction, payload.category);
  return {
    category: payload.category,
    transaction: validatedTransaction,
    validation: validatedTransaction !== null,
  };
}
