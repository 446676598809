import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDroppable } from '@dnd-kit/core';

export default function DataModelColumn({ col, idx, children, lastInRow, category }) {
  const { isOver, setNodeRef } = useDroppable({
    id: col,
  });

  const { t } = useTranslation(['app']);

  let bgColor;
  if (children[0]) {
    if (children[0].props?.obj?.bgColour) {
      bgColor = children[0].props.obj.bgColour.replace(/\d00/, '100');
    }
  } else {
    bgColor = isOver ? 'bg-gray-100' : 'bg-white';
  }

  return (
    <div
      id={`dataModel-column-${col}`}
      className={`
        ${idx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : ''}
        ${lastInRow ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tl-none' : ''}
        ${bgColor} text-gray-500 p-4 shrink-0 grow-0 basis-52 flex flex-col justify-between transition-colors duration-200`}
      // eslint-disable-next-line no-return-assign
      ref={setNodeRef}
    >
      <div className="pb-4">
        <p className="text-lg font-bold leading-none pb-2">{t(`app:schema.${category}.transaction.${col}.label`)}</p>
        <p className="text-xs text-gray-400 leading-snug">{t(`app:schema.${category}.transaction.${col}.description`)}</p>
      </div>
      <div className="hidden">
        {/* Hidden, it is here to make sure all necessary columns are pre-compiled. */}
        <div className="bg-brandGreen-100" />
        <div className="bg-brandRed-100" />
        <div className="bg-brandYellow-100" />
        <div className="bg-brandViolet-100" />
        <div className="bg-brandBlue-100" />
        <div className="bg-brandDarkBlue-100" />
      </div>
      <div className="">{children}</div>
    </div>
  );
}
DataModelColumn.propTypes = {
  col: PropTypes.string.isRequired,
  idx: PropTypes.number.isRequired,
  children: PropTypes.node,
  lastInRow: PropTypes.bool,
  category: PropTypes.string.isRequired,
};
DataModelColumn.defaultProps = {
  children: null,
  lastInRow: false,
};
