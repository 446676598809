/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */ // we can "afford" that, because under createSlice there is Immer which does the magic
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Auth, API } from 'aws-amplify';
import dayjs from 'dayjs';
import getQuotes from './sharedThunks/getQuotes';
import { setMessage } from '../actions/message';
import { calculateDatesAndAssetIds } from '../actions/data/helpers';
import { globalTransactionViewPerCategoryWithIsolated } from './globalSelectors/overarching';
import { globalAccountsView } from './globalSelectors';
import { SET_APP_SIGNAL, CLEAR_APP_SIGNAL } from '../actions/types';
import i18n from '../../i18n';
import { getProjects } from '../actions/projects';
import { settingsStocksAndCrypto as defaultSettings } from '../../elements/config';

const utc = require('dayjs/plugin/utc');

dayjs.extend(utc);

// status 'inactive' is relevant for postFile being exposed to the endpoint
// Dashboard has to wait until we know that all getData have run (they end either in success or in error)
const initialState = {
  deposits: {
    status: 'loading',
    accounts: [],
    transactions: [],
    simulatedTransactions: [],
    batchIds: null,
  },
  stocks: {
    status: 'loading',
    accounts: [],
    transactions: [],
    simulatedTransactions: [],
    settings: {},
    batchIds: null,
  },
  realEstate: {
    status: 'loading',
    accounts: [],
    transactions: [],
    simulatedTransactions: [],
  },
  loans: {
    status: 'loading',
    accounts: [],
    transactions: [],
    simulatedTransactions: [],
    batchIds: null,
  },
  pension: {
    status: 'loading',
    accounts: [],
    transactions: [],
    simulatedTransactions: [],
    batchIds: null,
  },
  objectsOfValue: {
    status: 'loading',
    accounts: [],
    transactions: [],
    simulatedTransactions: [],
    batchIds: null,
  },
  metals: {
    status: 'loading',
    accounts: [],
    transactions: [],
    simulatedTransactions: [],
    batchIds: null,
  },
  unlistedShares: {
    status: 'loading',
    accounts: [],
    transactions: [],
    simulatedTransactions: [],
    batchIds: null,
  },
  crypto: {
    status: 'loading',
    accounts: [],
    transactions: [],
    simulatedTransactions: [],
    batchIds: null,
  },
};

function parseIf(exp) {
  return typeof exp === 'string' ? JSON.parse(exp) : exp;
}

async function prepCallToApi(body) {
  const session = await Auth.currentSession();
  const myInit = {
    body,
    headers: {
      'Content-Type': 'application/json',
      Authorization: session.idToken.jwtToken,
    },
  };
  return myInit;
}

// runs on transactions received from APIs, just before they are added to the store
function getTransactionTransformationsCallback(category) {
  return function addGenericTransactionsFieldsCallback(item) {
    // TODO this needs to be also reflected in the backend database at some point; this is a workaround for PRD-1410
    // i.e. the currency conversion should probably me moved out of the category backend and into quotes for arch consistency (for current fx value)
    if (category === 'deposits') {
      return {
        ...item,
        originalUnitPrice: 1,
        unitPrice: 1,
        tags: parseIf(item.tags),
        // new universal selectors
        category,
        assetId: item.currency, // logic: this is the currency in which the money is held
        // at the moment when the transaction gets created, the amount is already in account currency
        // we need to show user the original _value_ i.e. quantity * uptc (we never show quantity alone)
        quantity: item.accountCurrencyAmount,
        accountCurrency: item.currency,
        upac: 1,
        upbc: item.accountCurrencyAmount ? item.amount / item.accountCurrencyAmount : 1, // if the accountCurrencyAmount is 0, then upbc does not matter
        uptc: item.fxAmount && item.accountCurrencyAmount ? item.fxAmount / item.accountCurrencyAmount : 1, // check if there is accountCurrencyAmount, because it can be 0
        transactionCurrency: item.fxCurrency || item.currency,
        transactionType: item.accountCurrencyAmount >= 0 ? 'purchase' : 'sale',
      };
    }
    if (category === 'stocks' || category === 'crypto') {
      const { linkedTransactions } = item;
      const parsedLT = parseIf(linkedTransactions) || [];
      let newLT;
      if (parsedLT.length > 0) {
        // TODO move to backend remaps rebasedAmount and rebasedPrice to quantity and upbc / uptc
        newLT = parsedLT.map((lt) => {
          if (lt.tags.type === 'purchase') {
            return { ...lt, tags: { ...lt.tags, quantity: lt.tags?.rebasedAmount, upbc: lt.tags?.rebasedPrice } };
          }
          return lt;
        });
      }
      return {
        ...item,
        originalUnitPrice: item.rebasedOriginalPrice, // should be always populated by backend
        unitPrice: item.rebasedPrice, // should be always populated by backend
        amount: item.amount || item.rebasedAmount,
        tags: parseIf(item.tags),
        linkedTransactions: newLT,
        // new universal selectors
        category,
        assetId: item.figi,
        quantity: item.rebasedAmount, // should be always populated by backend
        upac: item.rebasedOriginalPrice,
        accountCurrency: item.transactionCurrency, // see wiki
        upbc: item.rebasedPrice,
        uptc: item.rebasedOriginalPrice,
        transactionCurrency: item.transactionCurrency,
      };
    }
    if (category === 'realEstate') {
      return {
        ...item,
        amount: item.amount || item.rebasedAmount,
        originalUnitPrice: item.rebasedOriginalPrice || item.originalPrice || 1,
        unitPrice: item.rebasedPrice || item.price || 1,
        tags: parseIf(item.tags),
        // new universal selectors
        category,
        assetId: item.accountId, // changed by PRD-164, as quotes currently only support providerAssetId
        quantity: Number(item.amount),
        // quantitySold, quantityOpen added in realEstateTransactions
        upac: item.originalPrice,
        accountCurrency: item.currency,
        upbc: item.price,
        uptc: item.originalPrice,
        transactionCurrency: item.currency,
        transactionType: Number(item.amount) >= 0 ? 'purchase' : 'sale',
      };
    }
    if (category === 'loans') {
      return {
        ...item,
        category,
        quantity: Number(item.quantity),
        quantityInterest: Number(item.quantityInterest),
        upac: Number(item.upac),
        upbc: Number(item.upbc),
        uptc: Number(item.uptc),
        assetCurrency: item.accountCurrency,
        assetId: item.accountCurrency,
        // adding transactionType in loanTransactions, as we need account object to decide that
      };
    }
    if (category === 'pension') {
      return {
        ...item,
        category,
        quantity: Number(item.quantity),
        quantityInterest: Number(item.quantityInterest),
        upac: Number(item.upac),
        upbc: Number(item.upbc),
        uptc: Number(item.uptc),
        assetCurrency: item.accountCurrency,
        assetId: item.accountId,
      };
    }
    if (category === 'objectsOfValue') {
      return {
        ...item,
        category,
        quantity: Number(item.quantity),
        upac: Number(item.upac),
        upbc: Number(item.upbc),
        uptc: Number(item.uptc),
        assetCurrency: item.accountCurrency,
        assetId: item.accountId,
      };
    }
    if (category === 'metals') {
      return {
        ...item,
        category,
        quantity: Number(item.quantity),
        upac: Number(item.upac),
        upbc: Number(item.upbc),
        uptc: Number(item.uptc),
        assetCurrency: item.accountCurrency,
        // this field is calculated here; it is used in getQuotes API to calculate the current value of the asset based on pure metal market prices and its metal contents
        providerAssetId: { assetMetal: item.assetMetal, assetMetalWeight: item.assetWeight * (item.assetPurity / 1000) },
      };
    }
    if (category === 'unlistedShares') {
      return {
        ...item,
        category,
        quantity: Number(item.quantity),
        upac: Number(item.upac),
        upbc: Number(item.upbc),
        uptc: Number(item.uptc),
        assetCurrency: item.accountCurrency,
        assetId: item.accountId,
      };
    }
    return item;
  };
}

function getAccountTransformationCallback(category, state) {
  return function accountTransformationsCallback(a) {
    if (category === 'loans') {
      return {
        ...a,
        category,
        loanAmount: Number(a.loanAmount),
        tags: parseIf(a.tags),
        period: parseIf(a.period),
        percentRepaidAnnually: parseIf(a.percentRepaidAnnually),
        interestRate: parseIf(a.interestRate),
        connectedDepositAccounts: parseIf(a.connectedDepositAccounts),
      };
    }
    if (category === 'pension') {
      return {
        ...a,
        category,
        initialQuotes: parseIf(a.initialQuotes),
        contributionsAmounts: parseIf(a.contributionsAmounts),
        tags: parseIf(a.tags),
        connectedDepositAccounts: parseIf(a.connectedDepositAccounts),
      };
    }
    return {
      ...a,
      category,
      tags: parseIf(a.tags),
      ...(a.valuationParameters && { valuationParameters: parseIf(a.valuationParameters) }),
      importFileMappings: parseIf(a.importFileMappings),
      ...(category !== 'deposits' && { connectedDepositAccounts: parseIf(a.connectedDepositAccounts) }), // changed by PRD-164, as quotes currently only support providerAssetId
    };
  };
}

async function handleExpiredCredentialsError({ errorAccountObject, dispatch, getState, category }) {
  console.log('handleExpiredCredentialsError for account', errorAccountObject);

  const state = getState();
  const account = globalAccountsView(state).find((a) => a.id === errorAccountObject.accountId);
  console.log('handleExpiredCredentialsError: account', account);
  if (!account) return;

  const providerInstitutionId = account.tags.provider?.finapi?.providerInstitutionId || account.tags.provider?.gocardless?.providerInstitutionId;
  const webFormUrl = errorAccountObject.message?.webForm?.url;

  const redirectUrl = `${window.location.origin}/addAccountCallback`;
  const abortUrl = `${window.location.origin}/${i18n.language}/app/dashboard`;
  // show dialog to user
  window.dispatchEvent(
    new CustomEvent('setAlert', {
      detail: {
        id: 'expiredCredentialsError',
        args: { accountName: account.name },
        caller: 'getData',
        callbackOk: async () => {
          console.log('handleExpiredCredentialsError: starting call to API');
          const requestPayload = await prepCallToApi({
            country: account.countryCode,
            userLanguage: i18n.language,
            institutionId: providerInstitutionId,
            redirectUrl,
            abortUrl,
          });
          try {
            // finApi already provides webFormUrl in error response, so we can use that
            if (webFormUrl) {
              // create localStorage object (to be reopened after coming back from webform in AddAccountCallbackReceiver / Dashboard)
              // no need to store any additional parameters for finApi, because existing providerAccountIds remain unchanged
              localStorage.setItem('renewAccountAccess', JSON.stringify({ accountId: errorAccountObject.accountId, category }));
              // navigate to the web form
              window.location.href = `${webFormUrl}?redirectUrl=${encodeURIComponent(redirectUrl)}&errorRedirectUrl=${encodeURIComponent(abortUrl)}&abortRedirectUrl=${encodeURIComponent(abortUrl)}`;
            } else {
              // returns { url, processId } or { url: null, processId: null, bankConnectionId, accounts } in the case handled just below
              const response = await API.post('myAPI', 'deposits/webform', requestPayload);
              console.log('handleExpiredCredentialsError: response from API', response);

              // create localStorage object (to be reopened after coming back from webform in AddAccountCallbackReceiver / Dashboard);
              // GC creates a new End-user Agreement ID + requsition ID with new providerAccountIds every time the EUA expires;
              // we need to re-map our accountIds from old to new providerAccountIds by getting the new ones and mapping their IBANs to our IBANs in AddAccountCallbackReceiver;
              // (we can retrieve the new providerAccountIds from the getAccountDetails service by processId (which is GC's requsition ID))
              localStorage.setItem('renewAccountAccess', JSON.stringify({ accountId: errorAccountObject.accountId, category, reconcileAccounts: true, processId: response.processId }));

              window.location.href = response.url;
            }
          } catch (err) {
            console.error('reducers > data > handleExpiredCredentialsError > error in API.post', err);
          }
        },
      },
    }),
  );
}

export const updateStoreDataWrapper = createAsyncThunk('data/updateStoreDataWrapper', async (payload, { dispatch }) => {
  // updateDepositAccountIds: Array<deposit accountId>
  // if delete asset account or delete asset transaction had to remove a tag from a linked deposit transaction, we need to refresh the deposit account with that transaction
  // so that the change is visible in the frontend
  const { updateDepositAccountIds } = payload;
  if ((updateDepositAccountIds || []).length > 0) {
    updateDepositAccountIds.forEach((updateDepositAccountId) => {
      // eslint-disable-next-line no-use-before-define
      dispatch(getDataByAccount({ category: 'deposits', accountId: updateDepositAccountId }));
    });
  }

  // once done that, update store data through the common reducer as usual
  dispatch({ type: 'data/updateStoreData', payload });
});

// this retrieves stocks and cypto settings, as they share a backend, and splits them into the correct categories based on the argument
export const getStocksSettings = createAsyncThunk('data/getStocksSettings', async (category, { dispatch }) => {
  const payload = await prepCallToApi(null);
  const response = await API.get('myAPI', 'stocks/settings', payload);
  // parsing: when set automatically via terraform this is an object already, when set manually it is a string
  const responseParsed = parseIf(response);

  const { crypto: responseCryptoSettings, ...responseStocksSettings } = responseParsed;
  const { crypto: defaultCryptoSettings, ...defaultStocksSettings } = defaultSettings;

  if (category === 'crypto') {
    return {
      ...defaultCryptoSettings, // making sure all default fields are available
      ...responseCryptoSettings, // spreading the actual user-defined settings on top of that
    };
  }

  return {
    ...defaultStocksSettings, // making sure all default fields are available
    ...responseStocksSettings, // spreading the actual user-defined settings on top of that
  };

  // state updated in getDataByCategory
});

// 240625: seems not to be in use
// data is a property within stock.settings; send other properties otherwise they won't be updated
// export const postStocksSettings = createAsyncThunk('data/postStocksSettings', async ({ data }, { dispatch, getState }) => {
//   const currentSettings = getState().data.stocks.settings; // FIXME (if reactivated)
//   const payload = await prepCallToApi({ ...currentSettings, ...data });
//   const response = await API.post('myAPI', 'stocks/settings', payload);
//   dispatch({ type: 'data/updateStoreData', payload: { category: 'stocks', settings: parseIf(response) } });
//   TODO if this is ever active, it needs to dispatch thunk updateStoreDataWrapper instead
//   return response; // returns { settings: [], status: 'noerrors' }
// });

// data is a property within stock.settings; send other properties otherwise they won't be updated
// this has to handle crypto settings, which are stored in .crypto property of settings with the same structure as stock settings
// this will SPREAD data on top of the existing settings, so only the properties that are sent will be updated
// this can be called with category === null to update both stocks and crypto settings at the same time
export const putStocksSettings = createAsyncThunk('data/putStocksSettings', async ({ data, category }, { dispatch, getState }) => {
  const stocksSettings = getState().data.stocks.settings;
  const cryptoSettings = getState().data.crypto.settings;

  let dataAdjusted;
  if (category === 'crypto') {
    // if this is called from crypto, then spread 'data' over the .crypto property only
    dataAdjusted = { ...stocksSettings, crypto: { ...cryptoSettings, ...data } };
  } else {
    // if this is called from stocks or with category === null
    // Planning provides only the parameters that have changed, therefore need to re-spread both stocks and crypto settings from store
    // and then spread 'data' (new changes) over them, both in stocks and in crypto (the latter can be undefined, that's ok)
    dataAdjusted = { ...stocksSettings, ...data, crypto: { ...cryptoSettings, ...data.crypto } };
  }

  const payload = await prepCallToApi(dataAdjusted);
  const response = await API.put('myAPI', 'stocks/settings', payload);
  const parsedResponse = parseIf(response);
  // this will return the entire settings object, i.e. { growthRateAdvancedMode, growthRate, ..., crypto: { growthRateAdvancedMode, growthRate, ... }}

  const { crypto, ...responseStocksSettings } = parsedResponse;
  // we got a response for both categories in one object, but depending on what category did we get called with (stocks, crypto, null = both) we will update the store with the correct category
  if (category === 'crypto' || category === null) {
    dispatch(updateStoreDataWrapper({ category: 'crypto', settings: crypto }));
  }
  if (category === 'stocks' || category === null) {
    dispatch(updateStoreDataWrapper({ category: 'stocks', settings: responseStocksSettings }));
  }

  return response;
});

function generatePensionPurchaseTransaction(accounts) {
  const returnArray = [];
  accounts.forEach((account) => {
    returnArray.push({
      date: account.productPurchaseDate,
      assetId: account.id,
      accountCurrency: account.currency,
    });
  });
  return returnArray;
}

// used ONLY by AddAccountCallbackReceiver to update the accounts in the store with the new providerAccountIds
// caution: this DOES NOT update category status, because AddAccountCallbackReceiver will navigate to Dashboard, which has to get data for all 'idle' categories (so deposits must be 'idle')
export const getAccountsByCategory = createAsyncThunk('data/getAccountsByCategory', async (category, { dispatch }) => {
  let data;
  try {
    const payload = await prepCallToApi(null);
    data = await API.get('myAPI', `${(category === 'crypto' ? 'stocks' : category).toLowerCase()}/data`, payload); // crypto is a virtual category --> backend handled by stocks api

    const transformedData = data;
    transformedData.accounts = transformedData.accounts
      .filter((a) => (category === 'crypto' ? a?.subcategory === 'crypto' : a?.subcategory !== 'crypto')) // if this is crypto, stocks api returns also stock accounts, so we need to filter them out
      .map(getAccountTransformationCallback(category));

    const returnPayload = {
      accounts: transformedData.accounts,
      category,
    }; // returns { accounts: [], category: 'deposits' }
    return returnPayload;
  } catch (err) {
    console.log('error in getAccountsByCategory', err.response.data);
    dispatch(setMessage('dataUpdateError'));
  }
  return true;
});

// replaces all transactions / account within a category
export const getDataByCategory = createAsyncThunk('data/getDataByCategory', async (category, { dispatch, getState }) => {
  let data;
  let quotes;
  let fxRates;
  let settings;
  let payload;
  try {
    // update category loading status (Dashboard needs to know when the data is ready to expose the postFile function to mobile app)
    dispatch({ type: 'data/updateCategoryStatus', payload: { category, status: 'loading' } });

    payload = await prepCallToApi(null);
    data = await API.get('myAPI', `${(category === 'crypto' ? 'stocks' : category).toLowerCase()}/data`, payload); // crypto is a virtual category --> backend handled by stocks api
  } catch (err) {
    // handle retry in case of timeout
    if (err.response.status === 504) {
      // retry in retryMode, which bypasses the backgroundUpdate check in the backend
      try {
        data = await API.get('myAPI', `${(category === 'crypto' ? 'stocks' : category).toLowerCase()}/data?retryMode=1`, payload); // crypto is a virtual category --> backend handled by stocks api
      } catch (err2) {
        // if it throws error again, then we have to handle it as a regular error
        console.log('Redux: data reducer: retry of API.get error in category:', category, 'error message:', err2.response.data);
        dispatch(setMessage('dataUpdateError'));
        dispatch({ type: 'data/updateCategoryStatus', payload: { category, status: 'error' } });
      }
    } else {
      // handle non-504 errors here
      console.log('Redux: data reducer: API.get error in category:', category, 'error message:', err.response.data);
      dispatch(setMessage('dataUpdateError'));
      dispatch({ type: 'data/updateCategoryStatus', payload: { category, status: 'error' } });
    }
  }

  try {
    const transformedData = data;
    transformedData.accounts = transformedData.accounts
      .filter((a) => (category === 'crypto' ? a?.subcategory === 'crypto' : a?.subcategory !== 'crypto')) // if this is crypto, stocks api returns also stock accounts, so we need to filter them out
      .map(getAccountTransformationCallback(category));
    const accountIds = transformedData.accounts.map((a) => a.id);
    transformedData.transactions = transformedData.transactions
      // if this is crypto / stocks, only keep transactions belonging to crypto /stocks accounts
      // (the api will return mixed transactions and they have to be separated)
      .filter((t) => (['crypto', 'stocks'].includes(category) ? accountIds.includes(t.accountId) : true))
      .map(getTransactionTransformationsCallback(category));

    // once we have transactions, we can get quotes (crypto shares Stocks API backend)
    if (category === 'stocks' || category === 'crypto') {
      quotes = dispatch(getQuotes(calculateDatesAndAssetIds(transformedData.transactions.filter((tx) => ['purchase', 'sale'].includes(tx.transactionType)))));
      settings = dispatch(getStocksSettings(category));
    } else if (category === 'pension') {
      // for pension we need to consider the automatically generated purchase transaction based on whatever is in the account
      const purchaseTransactions = generatePensionPurchaseTransaction(transformedData.accounts);
      quotes = dispatch(getQuotes(calculateDatesAndAssetIds(transformedData.transactions.concat(purchaseTransactions))));
    } else {
      quotes = dispatch(getQuotes(calculateDatesAndAssetIds(transformedData.transactions))); // once we have transactions, we can get quotes
    }
    const promisesResponse = await Promise.all([quotes, settings, fxRates]);
    settings = promisesResponse[1]?.payload;

    const returnPayload = {
      ...transformedData,
      transactions: transformedData.transactions.filter((t) => !t.isSimulated),
      simulatedTransactions: transformedData.transactions.filter((t) => !!t.isSimulated),
      settings,
      category,
    }; // returns { transactions: [], simulatedTransactions: [], accounts: [], status: 'noerrors', category: 'deposits' }
    await dispatch(updateStoreDataWrapper(returnPayload));

    // CAUTION: this is repeated in getDataByAccount
    // handle special case of provider access expiring -- if there are any objects { accountId, code, message } (code being our own errorCode) in data.errors, it means we need to refresh the access
    const errorAccounts = data.errors;
    if (errorAccounts && errorAccounts.length > 0) {
      // kick off the process for the first account in the list; that will take the user to the webform and back to dashboard
      // dashboard will run getData again and if the error still persists for another bank connection, it will be taken from here again
      handleExpiredCredentialsError({
        errorAccountObject: errorAccounts[0],
        dispatch,
        getState,
        category,
      });
    }

    dispatch({ type: 'data/updateCategoryStatus', payload: { category, status: 'success' } });
  } catch (err) {
    console.log('Redux: data reducer: post-process error in category', category, 'error message:', err.response.data);
    dispatch(setMessage('dataUpdateError'));
    dispatch({ type: 'data/updateCategoryStatus', payload: { category, status: 'error' } });
  }
});

// UPSERTs transactions and accounts within a category
export const getDataByAccount = createAsyncThunk('data/getDataByAccount', async ({ category, accountId }, { dispatch, getState }) => {
  if (!category || !accountId) return;

  // update category loading status (Dashboard needs to know when the data is ready to expose the postFile function to mobile app)
  dispatch({ type: 'data/updateAccountStatus', payload: { category, accountId, status: 'loading' } });

  let response;
  let transformedData;
  let quotes;
  let fxRates;
  let settings;
  let promisesResponse;
  let data;
  let payload;
  try {
    payload = await prepCallToApi(null);
    response = await API.get('myAPI', `${(category === 'crypto' ? 'stocks' : category).toLowerCase()}/data/accounts/${accountId}`, payload);
  } catch (err) {
    // handle retry in case of timeout
    if (err.response.status === 504) {
      // retry in retryMode, which bypasses the backgroundUpdate check in the backend
      try {
        data = await API.get('myAPI', `${(category === 'crypto' ? 'stocks' : category).toLowerCase()}/data?retryMode=1`, payload); // crypto is a virtual category --> backend handled by stocks api
      } catch (err2) {
        // if it throws error again, then we have to handle it as a regular error
        console.log('Redux: data reducer: retry of API.get error in category:', category, 'error message:', err2.response.data);
        dispatch(setMessage('dataUpdateError'));
        dispatch({ type: 'data/updateCategoryStatus', payload: { category, status: 'error' } });
      }
    } else {
      // handle non-504 errors here
      console.log('Redux: data reducer: API.get error in category:', category, 'error message:', err.response.data);
      dispatch(setMessage('dataUpdateError'));
      dispatch({ type: 'data/updateAccountStatus', payload: { category, accountId, status: 'error' } });
    }
  }

  try {
    transformedData = response;
    transformedData.accounts = transformedData.accounts
      // if this is crypto, stocks api returns also stock accounts, so we need to filter them out
      .filter((a) => (category === 'crypto' ? a?.subcategory === 'crypto' : a?.subcategory !== 'crypto'))
      .map(getAccountTransformationCallback(category));
    const accountIds = transformedData.accounts.map((a) => a.id);
    transformedData.transactions = transformedData.transactions
      // if this is crypto, only keep transactions belonging to crypto accounts (the api will return also stock transactions and they have no subcategory field)
      .filter((t) => (category === 'crypto' ? accountIds.includes(t.accountId) : true))
      .map(getTransactionTransformationsCallback(category));

    // once we have transactions, we can get quotes
    if (category === 'stocks' || category === 'crypto') {
      quotes = dispatch(getQuotes(calculateDatesAndAssetIds(transformedData.transactions.filter((tx) => ['purchase', 'sale'].includes(tx.transactionType)))));
      settings = dispatch(getStocksSettings(category));
    } else if (category === 'pension') {
      // for pension we need to consider the automatically generated purchase transaction based on whatever is in the account
      const purchaseTransactions = generatePensionPurchaseTransaction(transformedData.accounts);
      quotes = dispatch(getQuotes(calculateDatesAndAssetIds(transformedData.transactions.concat(purchaseTransactions))));
    } else {
      quotes = dispatch(getQuotes(calculateDatesAndAssetIds(transformedData.transactions))); // once we have transactions, we can get quotes
    }
    promisesResponse = await Promise.all([quotes, settings, fxRates]); // index 0 is quotes
    // eslint-disable-next-line prefer-destructuring
    settings = promisesResponse[1]?.payload;

    // CAUTION: this is repeated in getDataByCategory
    // handle special case of provider access expiring -- if there are any objects { accountId, code, message } (code being our own errorCode) in data.errors, it means we need to refresh the access
    const errorAccounts = response.errors;
    if (errorAccounts && errorAccounts.length > 0) {
      // kick off the process for the first account in the list; that will take the user to the webform and back to dashboard
      // dashboard will run getData again and if the error still persists for another bank connection, it will be taken from here again
      handleExpiredCredentialsError({
        errorAccountObject: errorAccounts[0],
        dispatch,
        getState,
        category,
      });
    }
  } catch (err) {
    console.error('error in getDataByAccount (raw)', err, JSON.stringify(err, null, 2));
    dispatch(setMessage('dataUpdateError'));
    dispatch({ type: 'data/updateAccountStatus', payload: { category, accountId, status: 'error' } });
  }

  const returnPayload = {
    ...response,
    transactions: transformedData.transactions.filter((t) => !t.isSimulated),
    simulatedTransactions: transformedData.transactions.filter((t) => !!t.isSimulated),
    settings,
    accountId,
    category,
  }; // returns { transactions: [], simulatedTransactions: [], accounts: [], status: 'noerrors', category: 'deposits', accountId: ... }
  dispatch(updateStoreDataWrapper(returnPayload));
  dispatch({ type: 'data/updateAccountStatus', payload: { category, accountId, status: 'success' } });
});

/**
 * @param {object} data - { data: [ transaction-object ], category: 'deposits', accountId: ... }
 * @param {object} cancelSignal - { aborted: false }
 */
export const postData = createAsyncThunk('data/postData', async ({ data, category, accountId, cancelSignal = { aborted: false } }, { dispatch }) => {
  let returnPayload;
  try {
    // cancelSignal is used by Prep to run unmount cleanup; when the component is re-rendered, the useEffect cleanup sets aborted to true
    // if the request is aborted, the dispatches are not run; if aborting functionality is not required, it can be omitted
    const { aborted } = cancelSignal;

    const payload = await prepCallToApi(data);
    const response = !aborted ? await API.post('myAPI', `${(category === 'crypto' ? 'stocks' : category).toLowerCase()}/transactions`, payload) : { transactions: [], status: 'noerrors', category };

    const { transactions } = response;
    const transformedTransactions = transactions.map(getTransactionTransformationsCallback(category));

    returnPayload = {
      ...response,
      transactions: transformedTransactions.filter((t) => !t.isSimulated),
      simulatedTransactions: transformedTransactions.filter((t) => !!t.isSimulated),
      category,
      accountId,
    }; // returns { transactions: [], simulatedTransactions: [], accounts: [], status: 'noerrors', category: 'deposits', accountId: ... }

    if (
      !aborted
      // eslint-disable-next-line max-len
      && category !== 'deposits'
      && category !== 'loans'
    ) {
      await dispatch(getQuotes(calculateDatesAndAssetIds(transformedTransactions.map(getTransactionTransformationsCallback(category))))); // once we have transactions, we can get quotes
    }

    if (!aborted) dispatch(updateStoreDataWrapper(returnPayload));
  } catch (err) {
    console.log('error in postData', err);
    dispatch(setMessage('dataUpdateError'));
    throw err;
  }
  return returnPayload;
});

// dispatches postData request to the correct category service
// payload is an array of transactions with the extra category flag [ { ...transaction-object, category: 'category-name' } ]
// if importFlag is missing in the transaction-object, it will be by default interpreted as POST / PUT
export const postMixedData = createAsyncThunk('data/postMixedData', async ({ payload, cancelSignal = { aborted: false } }, { dispatch, getState }) => {
  // cancelSignal is used by Prep to run unmount cleanup; when the component is re-rendered, the useEffect cleanup sets aborted to true
  // if the request is aborted, the dispatches are not run; if aborting functionality is not required, it can be omitted
  const { aborted } = cancelSignal;

  const updatedPayload = payload.map((item) => {
    if (!item.category) {
      console.error('postData wrapper: transaction is missing a category attribute:', item);
      throw new Error('postMixedData: detected a transaction with no category attribute.');
    }
    if (!item.accountId) {
      // running the view in projectMode, because we need to see the isolated transactions (in case we are peforming a delete op on an isolated project)
      const { accountId } = globalTransactionViewPerCategoryWithIsolated(getState(), item.category).find((t) => t.id === item.id);
      return { ...item, accountId };
    }
    return item;
  });

  // get payload to an object { deposits: { accountId: [transaction-objects], accountId2: [transaction-objects] }, stocks: { ... } }
  const payloadByCategory = updatedPayload.reduce(
    (acc, item) => ({
      ...acc,
      [item.category]: {
        ...acc[item.category],
        [item.accountId]: [...((acc[item.category] || {})[item.accountId] || []), item],
      },
    }),
    {},
  );

  // dispatch data to the correct category service by category, in bulk
  // postData accepts only data per account afair
  const promises = [];
  if (!aborted) {
    Object.keys(payloadByCategory).forEach((item) => {
      Object.keys(payloadByCategory[item]).forEach((accountId) => {
        promises.push(dispatch(postData({ data: payloadByCategory[item][accountId], category: item, accountId })));
      });
    });
    await Promise.all(promises);
  }
});

export const postFileImportData = createAsyncThunk('data/postFileImportData', async ({ data, category, accountId }, { dispatch }) => {
  // only keep transactions from source = 'import' which have a keepFlag of true
  const transactionToPost = data.filter((t) => t.source === 'import' && t.keepFlag === true).map(({ source, keepFlag, isDuplicate, ...t }) => t);

  await dispatch(postData({ data: transactionToPost, category, accountId }));
});

export const postAccount = createAsyncThunk('data/postAccount', async ({ data, category, cancelSignal = { aborted: false } }, { dispatch }) => {
  // cancelSignal is used by Prep to run unmount cleanup; when the component is re-rendered, the useEffect cleanup sets aborted to true
  // if the request is aborted, the dispatches are not run; if aborting functionality is not required, it can be omitted
  const { aborted } = cancelSignal;

  if (!aborted) {
    dispatch({
      // used by ColumnMatcher and by AddNewTile
      type: SET_APP_SIGNAL,
      payload: { callerId: `post${category}Account`, message: 'loading' },
    });
  }

  try {
    const payload = await prepCallToApi({ ...data, importFlag: 'post' });
    // crypto is a virtual category --> backend handled by stocks api, but we need to provide a subcategory for stocks api to know that this is crypto
    if (category === 'crypto') payload.body.subcategory = 'crypto';
    const response = !aborted
      ? await API.post('myAPI', `${(category === 'crypto' ? 'stocks' : category).toLowerCase()}/accounts`, payload) // crypto is handled by stocks api
      : { accounts: [], status: 'noerrors', category };
    // returns { accounts: [], status: 'noerrors', category: 'deposits' }

    const transformedData = response;
    transformedData.accounts = transformedData.accounts
      .filter((a) => (category === 'crypto' ? a?.subcategory === 'crypto' : true)) // if this is crypto, stocks api returns also stock accounts, so we need to filter them out
      .map(getAccountTransformationCallback(category));
    const account = response.accounts[0];
    const accountId = account.id;

    if (!aborted) dispatch(updateStoreDataWrapper({ ...transformedData, accountId, category }));
    if (!aborted) {
      dispatch({
        // used by ColumnMatcher and by AddNewTile
        type: CLEAR_APP_SIGNAL,
        payload: { callerId: `post${category}Account` },
      });
    }
    if (!aborted) {
      // dispatch(getDataByAccount({ category, accountId })); // FIX 240709 what is the point of doing that here if we post the account? it will be empty
      // WARNING only deposits and stocks (?) may have this implemented in backend
      dispatch(getProjects()); // needed for loans (connectedProjectId dialog)

      if (!aborted && category === 'pension') {
        // get initial quote which has been created by postAccount in Pension API (dummy transaction created just for calculateDatesAndAssetIds)
        // only get dates from the calculateDatesAndAssetIds, assetIds are provided separately
        const { dates } = calculateDatesAndAssetIds([{ date: account.productPurchaseDate, assetId: accountId, transactionCurrency: account.currency }]);
        await dispatch(getQuotes({ dates, assets: [{ assetId: accountId, providerAssetId: null, currency: account.currency, category }] }));
      }
    } // get the newly created account (and its transactions) from the API'})
    if (aborted) console.log('postAccount: request was aborted');
    return transformedData;
  } catch (err) {
    // handle no more slots
    dispatch({
      // used by ColumnMatcher and by AddNewTile
      type: CLEAR_APP_SIGNAL,
      payload: { callerId: `post${category}Account` },
    });
    if (err.response.status === 507) {
      dispatch(setMessage('noSlots'));
      console.error('Redux: data > postAccount: error while creating the new account (not enough slots available)', err.message);
      throw new Error('noSlotsAvailable: postAccount: error while creating the new account (not enough slots available)'); // 240221 adding this so that Prep sees postAccount failing
      // TODO this should now show up at dispatch in the component and can be handled based on split(':')[0] === 'noSlotsAvailable'
    } else {
      dispatch(setMessage('accountCouldNotBeCreated'));
      console.error('Redux: data > postAccount: error while creating the new account', err.message);
      throw new Error('postAccount: error while creating the new account'); // 240221 adding this so that Prep sees postAccount failing
    }
  }
});

export const putAccount = createAsyncThunk('data/putAccount', async ({ data, category }, { dispatch }) => {
  const payload = await prepCallToApi({ ...data, importFlag: 'put' }); // if importFlag is missing, the API assumes POST
  const response = await API.put('myAPI', `${(category === 'crypto' ? 'stocks' : category).toLowerCase()}/accounts`, payload);

  const transformedData = response;
  transformedData.accounts = transformedData.accounts
    .filter((a) => (category === 'crypto' ? a?.subcategory === 'crypto' : true)) // if this is crypto, stocks api returns also stock accounts, so we need to filter them out
    .map(getAccountTransformationCallback(category));
  const accountId = response.accounts[0].id;

  dispatch(updateStoreDataWrapper({ ...transformedData, accountId, category }));

  if (category === 'deposits') {
    dispatch({
      // used by ColumnMatcher
      type: SET_APP_SIGNAL,
      payload: { callerId: `put${category}Account`, message: 'success' },
    });
  }
});

export const deleteAccount = createAsyncThunk('data/deleteAccount', async ({ accountId, category }, { dispatch }) => {
  const payload = await prepCallToApi({ id: accountId }); // API expects an account object
  const response = await API.del('myAPI', `${(category === 'crypto' ? 'stocks' : category).toLowerCase()}/accounts`, payload);
  dispatch(updateStoreDataWrapper({ ...response, accountId, category }));
  // returns { accounts: [], status: 'noerrors', category: 'deposits' }
  dispatch(setMessage('accountDeletedSuccessfully'));
});

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    hydrate(state, action) {
      // Merge the persisted state into the current state
      return {
        ...state, // Keep the existing state
        ...action.payload, // Overwrite with the values from the persisted state
      };
    },
    updateCategoryStatus(state, action) {
      // expects payload = { category: 'deposits', status: 'loading' } ('deposits' should be replaced by the correct category)
      state[action.payload.category].status = action.payload.status;
    },
    updateAccountStatus(state, action) {
      // expects payload = { category: 'deposits', accountId: 'account1', status: 'loading' } ('deposits' should be replaced by the correct category)
      state[action.payload.category].accounts.find((a) => a.id === action.payload.accountId).status = action.payload.status;
    },
    updateStoreData(state, action) {
      const { category, transactions, simulatedTransactions, accounts, settings } = action.payload;
      // remove from state all items whose ids are in payload add all those items from payload except those which are to be deleted
      if (transactions) {
        state[category].transactions = [...state[category].transactions.filter((i) => !transactions.some((p) => p.id === i.id)), ...transactions.filter((t) => t.importFlag !== 'delete')];
        // if this is for accounts, insert all cross-category common required fields ; parse tags and importFileMappings to objects
      }

      if (simulatedTransactions) {
        state[category].simulatedTransactions = [
          ...state[category].simulatedTransactions.filter((i) => !simulatedTransactions.some((p) => p.id === i.id)),
          ...simulatedTransactions.filter((t) => t.importFlag !== 'delete'),
        ].map(getTransactionTransformationsCallback(category));
      }

      if (accounts) {
        state[category].accounts = [...state[category].accounts.filter((xa) => !accounts.some((na) => na.id === xa.id)), ...accounts.filter((t) => t.importFlag !== 'delete')];
      }

      if (settings) {
        state[category].settings = settings;
      }
    },
  },
  extraReducers: {},
});

export default dataSlice.reducer;

export const { updateStoreData } = dataSlice.actions;

// export all objects from the submodules
export * from './globalSelectors/overarching';
export * from './globalSelectors/deposits';
export * from './globalSelectors/stocks';
export * from './globalSelectors/realEstate';
export * from './globalSelectors/loans';
export * from './globalSelectors/pension';
export * from './globalSelectors/objectsOfValue';
export * from './globalSelectors/metals';
export * from './globalSelectors/unlistedShares';
export * from './globalSelectors/crypto';
export * from './globalSelectors';
