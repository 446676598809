export function parseNumeric(val, decimalChar = ',') {
  if (!val) return val;
  const theOtherChar = decimalChar === ',' ? '.' : ',';
  if (typeof val === 'string') {
    // remove the other character if it exists, then replace decimalChar with '.'
    return Number(
      val
        .replace(/[^\d,.+-]/g, '') // only leave digits, periods and commas
        .replace(theOtherChar, '')
        .replace(decimalChar, '.'),
    );
  }
  return Number(val);
}

/**
 * Apply filters from filterState to the data.
 *
 * @param {array} data - native Grid format (array of array)
 * @param {array} filterState - array of objects { verb, arg, value }, must match the number of columns in the data
 * @param {object} (optional) parsingMasks ({ decimalChar, dateFormat })
 * @param {number} (optional) headersInRow - number of header rows in the data (0 = no headers)
 * @returns array - array of row indices which are to be displayed, e.g. [0, 2, 3, 4, ...]
 */
export default function applyFilters(data, filterState, parsingMasks = {}, headersInRow = 0) {
  return data
    .map((row, rowIndex) => [...row, rowIndex]) // add rowIndex as last column (it cannot be the first column, because it messes up the column order for further code)
    .filter((row, rowIndex) => {
      if (rowIndex < headersInRow) return false;
      if (rowIndex === headersInRow) return true; // First row after headers, always include

      return filterState.every((filters, index) => {
        if (!filters || filters.length === 0) {
          return true; // No filters for this column, accept all rows
        }

        return filters.every((filter) => {
          const { verb, arg, val } = filter;
          const cellValueRaw = row[index];

          // console.log('DEBUG filter', verb, arg, 'val', val, 'cellValueRaw', cellValueRaw);

          if (verb === 'is' || verb === 'isNot') {
            // Replace decimal character if specified, then parse as Number
            const decimalChar = parsingMasks?.decimalChar || ',';

            const cellValue = parseNumeric(cellValueRaw, decimalChar);
            const numericValue = Number(val);
            console.log('DEBUG filter parsing', val, '->', numericValue, 'cellValue', cellValue);

            // If either cellValue or value is not a valid number, remove from filter
            if (Number.isNaN(cellValue) || Number.isNaN(numericValue)) {
              return false; // Skip this row for the current filter
            }

            let conditionMet = false;
            switch (arg) {
              case 'greaterThan':
                conditionMet = cellValue > numericValue;
                break;
              case 'greaterThanOrEqual':
                conditionMet = cellValue >= numericValue;
                break;
              case 'lessThan':
                conditionMet = cellValue < numericValue;
                break;
              case 'lessThanOrEqual':
                conditionMet = cellValue <= numericValue;
                break;
              case 'equalTo':
                conditionMet = cellValue === numericValue;
                break;
              case 'blank':
                conditionMet = cellValue === null || cellValue === undefined || cellValue === '';
                break;
              default:
                return true;
            }
            return verb === 'is' ? conditionMet : !conditionMet;
          }

          // For other verbs, ignore `arg` and apply directly
          // Parse cellValue and value as String
          const cellValueString = String(cellValueRaw);
          const stringValue = String(val);

          switch (verb) {
            case 'contains':
              return cellValueString.includes(stringValue);
            case 'doesNotContain':
              return !cellValueString.includes(stringValue);
            case 'startsWith':
              return cellValueString.startsWith(stringValue);
            case 'doesNotStartWith':
              return !cellValueString.startsWith(stringValue);
            case 'endsWith':
              return cellValueString.endsWith(stringValue);
            case 'doesNotEndWith':
              return !cellValueString.endsWith(stringValue);
            default:
              return true;
          }
        });
      });
    })
    .map((row) => row[row.length - 1]); // only return rowIndex added before the filter
}
