/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { nanoid } from 'nanoid';
import dayjs from 'dayjs';
import * as schema from '@monestry-dev/schema';
import TableLayout, { getButtonsToShow } from './TableLayout';
import GridLayout from './GridLayout';
import ButtonBar from './ButtonBar';
import GettingStarted from './GettingStarted';
import * as common from './params/common';
import * as transactionsRealEstate from './transactions/realEstate';
import * as transactionsStocks from './transactions/stocks';
import * as transactionsDeposits from './transactions/deposits';
import * as transactionsLoans from './transactions/loans';
import * as transactionsPension from './transactions/pension';
import * as transactionsObjectsOfValue from './transactions/objectsOfValue';
import * as transactionsMetals from './transactions/metals';
import * as transactionsUnlistedShares from './transactions/unlistedShares';
import * as transactionsCrypto from './transactions/crypto';
import {
  depositTransactions,
  stockTransactions,
  realEstateTransactions,
  completeAssetView,
  globalQuotesView,
  getDataByAccount,
  loanTransactions,
  pensionTransactions,
  objectsOfValueTransactions,
  metalsTransactions,
  unlistedSharesTransactions,
  cryptoTransactions,
} from '../../redux/reducers/data';
import gridConvertGridDataToObject from './gridConvertGridDataToObject';

const debugLevel = 3;

// --- INFO ---
// this component applies category - specific changes to data and passes them onwards;
// manages table layout

export default function CategoryWrapperTransactions({
  account,
  setAccount,
  tableState,
  setTableState,
  displayedComponent,
  setDisplayedComponent,
  displayedComponentMode,
  setDisplayedComponentMode,
  userChangesPresent,
  setUserChangesPresent,
  highlightTransactionId,
}) {
  let categoryModule;
  let existingTransactions;
  let categoryArraySchema;
  const [syncStatus, setSyncStatus] = useState(false);

  const dispatch = useDispatch();

  // console.log('DEBUG cav', useSelector(completeAssetView));

  const quotes = useSelector((state) => globalQuotesView(state));
  // console.log('DEBUG gqv', quotes);
  const dashboardMode = useSelector((state) => state.simulation.dashboardMode);
  switch (account.category) {
    case 'realEstate':
      categoryModule = transactionsRealEstate;
      // eslint-disable-next-line max-len
      existingTransactions = displayedComponent === 'grid' ? useSelector(realEstateTransactions).filter((item) => item.accountId === account.id) : useSelector(completeAssetView)?.realEstate?.[account.id];
      // completeAssetView returns an object with all stocks assetIds > transactions and pre-calculates certain measures to be shown in the table (ROI, fees etc.)
      categoryArraySchema = schema.realEstateTransactions;
      break;
    case 'stocks':
      categoryModule = transactionsStocks;
      existingTransactions = displayedComponent === 'grid' ? useSelector(stockTransactions).filter((item) => item.accountId === account.id) : useSelector(completeAssetView)?.stocks?.[account.id];
      // completeAssetView returns an object with all stocks assetIds > transactions and pre-calculates certain measures to be shown in the table (ROI, fees etc.)
      categoryArraySchema = schema.stockTransactions;
      break;
    case 'crypto':
      categoryModule = transactionsCrypto;
      existingTransactions = displayedComponent === 'grid' ? useSelector(cryptoTransactions).filter((item) => item.accountId === account.id) : useSelector(completeAssetView)?.crypto?.[account.id];
      // completeAssetView returns an object with all stocks assetIds > transactions and pre-calculates certain measures to be shown in the table (ROI, fees etc.)
      categoryArraySchema = schema.cryptoTransactions;
      break;
    case 'deposits':
      categoryModule = transactionsDeposits;
      existingTransactions = useSelector(depositTransactions).filter((item) => item.accountId === account.id);
      categoryArraySchema = schema.depositTransactions;
      break;
    case 'loans':
      categoryModule = transactionsLoans;
      existingTransactions = useSelector(loanTransactions).filter((item) => item.accountId === account.id);
      categoryArraySchema = schema.loanTransactions;
      break;
    case 'pension':
      categoryModule = transactionsPension;
      existingTransactions = useSelector(pensionTransactions).filter((item) => item.accountId === account.id && item.label !== 'pension-purchase');
      categoryArraySchema = schema.pensionTransactions;
      break;
    case 'objectsOfValue':
      categoryModule = transactionsObjectsOfValue;
      // eslint-disable-next-line max-len
      existingTransactions = displayedComponent === 'grid' ? useSelector(objectsOfValueTransactions).filter((item) => item.accountId === account.id) : useSelector(completeAssetView)?.objectsOfValue?.[account.id];
      categoryArraySchema = schema.objectsOfValueTransactions;
      break;
    case 'metals':
      categoryModule = transactionsMetals;
      existingTransactions = displayedComponent === 'grid' ? useSelector(metalsTransactions).filter((item) => item.accountId === account.id) : useSelector(completeAssetView)?.metals?.[account.id];
      categoryArraySchema = schema.metalsTransactions;
      break;
    case 'unlistedShares':
      categoryModule = transactionsUnlistedShares;
      // eslint-disable-next-line max-len
      existingTransactions = displayedComponent === 'grid' ? useSelector(unlistedSharesTransactions).filter((item) => item.accountId === account.id) : useSelector(completeAssetView)?.unlistedShares?.[account.id];
      categoryArraySchema = schema.unlistedSharesTransactions;
      break;
    default:
      categoryModule = {};
  }

  async function handleSync() {
    if (debugLevel > 2) console.log('executing handleSync');
    setSyncStatus(true);
    await dispatch(getDataByAccount({ category: account.category, accountId: account.id }));
    setSyncStatus(false);
  }

  // if it is undefined, return empty
  if (!existingTransactions && displayedComponent === 'table') {
    const buttonsToShow = getButtonsToShow(account.category);
    return (
      <>
        <ButtonBar
          buttonsToShow={buttonsToShow}
          displayedComponent={displayedComponent}
          setDisplayedComponent={setDisplayedComponent}
          displayedComponentMode={displayedComponentMode}
          setDisplayedComponentMode={setDisplayedComponentMode}
          userChangesPresent={userChangesPresent}
        />
        {/* if sync is possible, then show the appropriate prompt in GettingStarted */}
        <GettingStarted showSync={buttonsToShow.includes('syncImport')} showImport={['syncImport', 'import'].some((a) => buttonsToShow.includes(a))} />
      </>
    );
  }

  // ------------------------------------------------------------
  // INPUT
  // ------------------------------------------------------------

  // add category-specific data to the transactions and sort
  const defaultSortingCallback = (a, b) => common.compare(a, b, tableState.sortBy, tableState.sortDirectionAsc);

  const sortingCallback = typeof categoryModule.getSortingCallback === 'function' ? categoryModule.getSortingCallback(tableState) : defaultSortingCallback;

  // customDropdownSources is an object with keys as column names and values as arrays of strings
  // if there is a customDropdown type of column in the grid layout for this category, it will try to find dropdown source values in this object
  // { columnName: [<array of string>] }
  const customDropdownSources = typeof categoryModule.getCustomDropdownSources === 'function' ? categoryModule.getCustomDropdownSources() : {};

  // customOnChange is executed after every value change in Grid
  // it receives (instance, cell, x, y, value);
  const customOnChange = typeof categoryModule.getCustomOnChange === 'function' ? categoryModule.getCustomOnChange() : () => {};

  // if (debugLevel >2) console.log('CategoryWrapperTransactions > transaction array: ', transactions);

  const transactionsWithCategoryData = categoryModule.applyCategorySpecificChanges(existingTransactions, account, displayedComponent, quotes).sort(sortingCallback); // final sort as per tableState;

  // if it is empty, return empty (only here, because in pension the purchase transactions are filtered out only in the previous step)
  if (transactionsWithCategoryData.length === 0 && displayedComponent === 'table') {
    const buttonsToShow = getButtonsToShow(account.category);
    return (
      <>
        <ButtonBar
          buttonsToShow={buttonsToShow}
          displayedComponent={displayedComponent}
          setDisplayedComponent={setDisplayedComponent}
          displayedComponentMode={displayedComponentMode}
          setDisplayedComponentMode={setDisplayedComponentMode}
          userChangesPresent={userChangesPresent}
        />
        <GettingStarted showSync={buttonsToShow.includes('syncImport')} showImport={['syncImport', 'import'].some((a) => buttonsToShow.includes(a))} />
      </>
    );
  }

  function identifyDeletedTransactions(inputData, outputData) {
    if (debugLevel > 2) console.log('input data', inputData, 'output data', outputData);
    // deleted transactions are those whose id is in inputData, but not in outputData
    const outputStack = [...outputData]; // all POST / PUT transactions are here already

    // DELETE: show me those entries from inputData, which have an id which does not exist in outputData
    const deletedItems = inputData
      .filter((inputObject) => !outputData.some((outputObject) => outputObject.id === inputObject.id))
      // removeGridFormatting, the following transformation, uses the order of properties in the object, so the deleted object must be structured the same way as the objects coming from Grid
      .map(categoryModule.categoryOrderedObject(account, displayedComponent))
      .map((item) => ({ ...item, importFlag: 'delete' }));
    if (debugLevel > 2) console.log('deleted items', deletedItems, displayedComponent);
    return outputStack.concat(deletedItems);
  }

  // ------------------------------------------------------------
  // OUTPUT
  // ------------------------------------------------------------

  // receives data from Grid (.getData()), in the displayed order and unchanged
  // gridLayout is an array of objects with properties id, type, label, and other properties
  // dirtyRows is a Set of row indices which have been changed by the user
  // spreadExtraData (optional) is an object with additional data which needs to be spread to each row
  //   e.g. { providerAssetId, assetId } from manual mapping in Crypto
  function prepAndValidateOutputData(data, gridLayout, dirtyRows, spreadExtraData = null) {
    // ------------------------------------------------------------
    // STEP 1: transform data from Grid to the "canonical" format
    // ------------------------------------------------------------

    // 'data' is an array of arrays
    const newTransactionsTransformed = gridConvertGridDataToObject(data, gridLayout, dirtyRows, spreadExtraData);

    if (debugLevel > 2) console.log(newTransactionsTransformed, 'transformed data');

    // ------------------------------------------------------------
    // STEP 2: add deleted items back into the array
    // ------------------------------------------------------------

    // put deleted transactions back into the array with 'delete' importFlag
    // caution: if validation fails for the 'delete' rows later, it will be displayed as errors in Grid in an empty row (because these rows have been added and do not exist in Grid)
    const newTransactionsWithDeletes = identifyDeletedTransactions(existingTransactions, newTransactionsTransformed);

    // ------------------------------------------------------------
    // STEP 3: remove grid formatting
    // ------------------------------------------------------------

    const newTransactionsAfterReformatting = common.removeGridFormatting(newTransactionsWithDeletes, gridLayout);

    // ------------------------------------------------------------
    // STEP 4: apply category-specific transformations
    // ------------------------------------------------------------

    // re-add all properties not used by Grid from store to each preexisting transaction
    // perform category-specific transformations, add importFlag
    const newTransactionsAfterCategorySpecificTransformations = newTransactionsAfterReformatting
      .map((item) => {
        const inputTransaction = existingTransactions.find((transaction) => transaction.id === item.id);
        let importFlag;
        if (item.importFlag === 'delete') importFlag = 'delete'; // keep 'delete' flag
        else if (item.id && item.isDirty === true) importFlag = 'put'; // if there is an id and the row has been changed, set importFlag to 'put'
        else if (!item.id) importFlag = 'post'; // if there is no id, use 'post';
        else importFlag = null; // if there is an id and the row has not been changed, set importFlag to null

        return {
          ...inputTransaction, // spread the original transaction from before Grid first
          ...item, // spread the modified transaction from Grid on top of that
          previousVersion: inputTransaction, // adding previous version so that comparisons between old and new values can be made (will be stripped in step 5)
          accountId: account.id, // 'post' transactions won't have accountId or currency, so we need to add them here
          currency: account.currency,
          importFlag,
          id: item.id || nanoid(),
        };
      })
      // .filter((item) => item.importFlag !== null) // remove rows which have not been changed --> 230805 REMOVED because custom validation in stocks requires the entire data set of the account
      .map(categoryModule.outputTransformCategoryTransactions(account));

    if (debugLevel > 2) console.log('dataAfterCategorySpeficicTransformations', newTransactionsAfterCategorySpecificTransformations);

    // ------------------------------------------------------------
    // STEP 5: validate data
    // ------------------------------------------------------------

    // if validation is passed, return the "canonical" object, otherwise throw an error (with the validation errors)
    // and show the errors to user

    // REMEMBER: for performance reasons, dataAfterCategorySpeficicTransformations only contains rows which have been changed (including deleted rows)
    // the original Grid row index is in the rowNumber property

    let newTransactionsValidated;
    // error handler is complicated, so will take it out of the try-catch block and treat it as separate function
    // expects error objects in the format { path: 'rowNumber.columnName', message: 'error message' }
    let validationErrors = []; // clear all errors

    // general validation: if dashboardMode is 'dashboard', then all dates must be <= today utc; otherwise push error to validationErrors (PRD-1215)
    newTransactionsAfterCategorySpecificTransformations.forEach((item) => {
      if (dashboardMode === 'dashboard' && item.date > dayjs.utc().startOf('day').valueOf()) {
        validationErrors.push({ path: `${item.rowNumber}.date`, message: 'Future-dated transactions can only be added in Project mode' });
      }
      if (dashboardMode === 'projects' && item.date <= dayjs.utc().startOf('day').valueOf()) {
        validationErrors.push({ path: `${item.rowNumber}.date`, message: 'Actual (past) transactions can only be added in Dashboard mode' });
      }
    });
    // return errors without waiting for validation if found
    if (validationErrors.length > 0) {
      if (debugLevel > 2) console.log('validationErrors after future date check', validationErrors);
      // return a list of errors back to caller (Grid) which can show it in the UI
      return { status: 'error', errors: validationErrors };
    }

    // then run schema validations
    try {
      // cast should remove unknown properties and not fail on the first error
      newTransactionsValidated = categoryArraySchema.validateSync(newTransactionsAfterCategorySpecificTransformations, { abortEarly: false, stripUnknown: true });
    } catch (err) {
      console.log('DEBUG ERROR after validation', err.message);
      // TODO find a way to catch the error and show it to the user
      // if there is err.inner, it means there is at least one validation error from yup validateSync
      // err.inner is an array of { name, value, path: 'rowNumber.columnName', errors: ['error message'] }; out of which we only use path and message (?)

      const isThisCustomError = String(err.message).includes('At least one of the prices');

      // first try to get stuff out of err.inner, regardless if there is a custom error or not
      if (err.inner) {
        validationErrors = err.inner.map((error) => {
          console.log('error item', JSON.stringify(error));
          if (debugLevel > 2) console.log('error item', error.path, Number(error.path.split('.')[0].slice(1, -1)));
          return {
            // take the last part of error.patch, which is the column name, and get the original row number from the item by indexing it in dataAfterCatgorySpecificTransformations
            // path comes as [10].columnName, so need to remove [] as well
            path: `${newTransactionsAfterCategorySpecificTransformations[Number(error.path.split('.')[0].slice(1, -1))].rowNumber}.${error.path.split('.')[1]}`,
            message: error.errors[0],
          };
        });
        // if there is a custom error, add it to the validationErrors
        if (isThisCustomError) {
          // find the first row without any value column and flag it as error
          // (we do not get path on this custom error)
          const suspectedTransaction = newTransactionsAfterCategorySpecificTransformations.find((item) => !item.upac && !item.upbc && !item.uptc);
          if (suspectedTransaction) {
            validationErrors.push({ path: `${suspectedTransaction.rowNumber}.valueAccountCurrency`, message: err.message });
          }
        }
        console.log('DEBUG validationErrors', validationErrors);
      } else {
        console.log('DEBUG about to throw an unhandled error');
        throw new Error(err);
      }
    }

    // -------------------------------------------------------------------------------------
    // STEP 6: perform category-specific validation and inject errors into validationErrors
    // -------------------------------------------------------------------------------------

    // requires the entire account data set, not just the changed rows (!)
    // if there is a function 'additionalValidations', then call it -- it will return an array of errors (if any)
    if (typeof categoryModule.additionalValidations === 'function') {
      const getAdditionalErrors = categoryModule.additionalValidations(newTransactionsValidated);
      if (getAdditionalErrors.length > 0) validationErrors.push(...getAdditionalErrors);
    }

    // handles validation errors
    if (validationErrors.length > 0) {
      // return a list of errors back to caller (Grid) which can show it in the UI
      return { status: 'error', errors: validationErrors };
    }

    const returnedData = (newTransactionsValidated || []).filter((item) => item.importFlag !== null);
    if (debugLevel > 2) console.log('validated data', returnedData);
    return { status: 'success', data: returnedData };
  }

  const commonProps = {
    account,
    data: transactionsWithCategoryData,
    mode: 'transactions',
    tableState,
    setTableState,
    displayedComponent,
    setDisplayedComponent,
    displayedComponentMode,
    setDisplayedComponentMode,
  };

  if (displayedComponent === 'grid') {
    return (
      <GridLayout
        {...commonProps}
        prepAndValidateOutputData={prepAndValidateOutputData}
        postCategoryItems={categoryModule.postCategoryItems}
        userChangesPresent={userChangesPresent}
        setUserChangesPresent={setUserChangesPresent}
        setAccount={setAccount}
        customDropdownSources={customDropdownSources}
        customOnChange={customOnChange}
        saveDialogPromiseCallback={categoryModule.saveDialogPromiseCallback}
      />
    );
  }
  if (displayedComponent === 'table') {
    return <TableLayout {...commonProps} handleSync={handleSync} accountSyncStatus={syncStatus} highlightTransactionId={highlightTransactionId} />;
  }
  return null;
}
CategoryWrapperTransactions.propTypes = {
  account: PropTypes.object.isRequired,
  setAccount: PropTypes.func.isRequired,
  tableState: PropTypes.object.isRequired,
  setTableState: PropTypes.func.isRequired,
  displayedComponent: PropTypes.string.isRequired,
  setDisplayedComponent: PropTypes.func.isRequired,
  displayedComponentMode: PropTypes.string.isRequired,
  setDisplayedComponentMode: PropTypes.func.isRequired,
  userChangesPresent: PropTypes.bool.isRequired,
  setUserChangesPresent: PropTypes.func.isRequired,
  highlightTransactionId: PropTypes.string,
};
CategoryWrapperTransactions.defaultProps = {
  highlightTransactionId: null,
};
