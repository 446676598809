/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray } from 'react-hook-form';
import { ChevronDownIcon, ChevronUpIcon, PlusIcon, XMarkIcon } from '@heroicons/react/20/solid';
import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/outline';
import { updateCognitoSettings } from '../../redux/actions/user';
import BaselineDropdown from '../kpis/BaselineDropdown';
import { LanguageDropdown } from '../../elements/Footer';
import Button from '../../elements/Button';

export default function Preferences({ calendarState, setCalendarState }) {
  const { t } = useTranslation('app', { keyPrefix: 'settings.preferences' });
  const { t: tG } = useTranslation('app', { keyPrefix: 'general' });
  const baseCurrency = useSelector((state) => state.user.profile.settings.baseCurrency);
  const expectedDateFormats = useSelector((state) => state.user.profile.settings.expectedDateFormats); // should be a parsed array
  const dispatch = useDispatch();

  const { control, register, handleSubmit, setValue, formState, reset } = useForm({
    defaultValues: {
      expectedDateFormats: expectedDateFormats.map((item) => ({ value: item })) || [{ value: 'DD.MM.YYYY' }, { value: 'YYYY-MM-DD' }], // useFieldArray doesn't work with primitives and needs an object
    },
    // mode: 'onBlur',
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'expectedDateFormats',
  });

  function handleRearrange(index, direction) {
    const newFields = [...fields];

    if (direction === 'up' && index > 0) {
      const itemToMove = newFields.splice(index, 1)[0];
      newFields.splice(index - 1, 0, itemToMove);
    } else if (direction === 'down' && index < newFields.length - 1) {
      const itemToMove = newFields.splice(index, 1)[0];
      newFields.splice(index + 1, 0, itemToMove);
    }
    setValue('expectedDateFormats', newFields);
  }

  function handleSubmitDateFormats(data) {
    dispatch(updateCognitoSettings({ expectedDateFormats: data.expectedDateFormats.map((item) => item.value.toUpperCase()) })); // will be parsed in action creator
    reset({
      ...data,
      expectedDateFormats: data.expectedDateFormats.map((item) => ({ value: item.value.toUpperCase() })),
    }); // reset form to remove dirty state
  }

  return (
    <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5 text-gray-700 font-normal">
      {/* Notifications: monthly report, tips? */}
      <div className="space-y-6 sm:space-y-5">
        <div className="space-y-6 sm:space-y-5">
          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <p className="text-sm pb-2 sm:pb-0">{t('baseCurrency')}</p>
            <p className="text-gray-500">{baseCurrency}</p>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5 text-sm">
            <p>{t('preferredLanguage')}</p>
            <div className="sm:max-w-xs">
              <LanguageDropdown />
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor="about" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              {t('baselineDate')}
            </label>
            <div className="mt-3 sm:col-span-2 sm:mt-0 sm:max-w-xs">
              <BaselineDropdown calendarState={calendarState} setCalendarState={setCalendarState} />
            </div>
          </div>

          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
            <label htmlFor="about" className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
              {t('expectedDateFormats')}
              <p className="mt-2 text-xs text-gray-500">{t('expectedDateFormatsInfo')}</p>
              <p className="mt-2 text-xs text-gray-500">
                {t('expectedDateFormatsAllowed')}
                {' '}
                <a href="https://day.js.org/docs/en/parse/string-format#list-of-all-available-parsing-tokens" target="_blank" rel="noreferrer" className="text-brandBlue-500">
                  {t('expectedDateFormatsAllowedLink')}
                  <ArrowTopRightOnSquareIcon className="pl-1 h-4 w-4 inline-block text-gray-400" aria-hidden="true" />
                </a>
              </p>
            </label>
            <form onSubmit={handleSubmit(handleSubmitDateFormats)} className="mt-3 sm:col-span-2 sm:mt-0 sm:max-w-xs">
              <ul className="">
                {fields.map((field, index) => (
                  <li key={field.id} className="grid grid-cols-[20px,auto,25px] py-2 gap-2 align-middle">
                    <div className="flex flex-col justify-center items-center">
                      {index > 0 && (
                        <button type="button" onClick={() => handleRearrange(index, 'up')}>
                          <ChevronUpIcon className="h-5 w-5 text-gray-400 hover:text-gray-500" aria-hidden="true" />
                        </button>
                      )}
                      {index < fields.length - 1 && (
                        <button type="button" onClick={() => handleRearrange(index, 'down')}>
                          <ChevronDownIcon className="h-5 w-5 text-gray-400 hover:text-gray-500" aria-hidden="true" />
                        </button>
                      )}
                    </div>
                    <div className="block w-full">
                      <input
                        type="text"
                        autoComplete="off"
                        className="block w-full pr-2 pb-2 text-gray-900 text-sm border border-gray-300 focus:outline-none focus:ring-brandBlue-500 rounded-md"
                        {...register(`expectedDateFormats.${index}.value`, {
                          required: true,
                          validate: (val) => val.toUpperCase().match(/[^DMYZX\-.]/g), // return true if there are characters from outside the whitelist
                        })}
                        defaultValue={field.value}
                      />
                      {formState.errors?.expectedDateFormats?.[index] && <p className="mt-1 ml-1 text-xs text-red-500">{t('expectedDateFormatsError')}</p>}
                    </div>
                    <button type="button" onClick={() => remove(index)}>
                      <XMarkIcon className="h-5 w-5 text-gray-400 hover:text-gray-500" aria-hidden="true" />
                    </button>
                  </li>
                ))}
                <li key="addnewitem" className="grid grid-cols-[20px,auto,25px] py-2 gap-2 align-middle">
                  <div className="col-start-2 grid grid-cols-2 gap-2">
                    <button className="py-1 px-1.5 hover:bg-gray-100 rounded-md" type="button" onClick={() => append('')}>
                      <PlusIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </button>
                    {formState.isDirty && <Button type="submit" size="md" withAccent text={tG('save')} />}
                  </div>
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
Preferences.propTypes = {
  calendarState: PropTypes.shape({
    year: PropTypes.number,
    month: PropTypes.number,
  }).isRequired,
  setCalendarState: PropTypes.func.isRequired,
};
