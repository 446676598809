/* eslint-disable react/jsx-no-bind */
/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ArrowUpTrayIcon, HomeIcon, PencilSquareIcon, ChartBarIcon, BanknotesIcon, TrophyIcon } from '@heroicons/react/24/outline';
import { TableCellsIcon, CogIcon, ArrowDownOnSquareIcon, XMarkIcon, ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { useDispatch, useSelector } from 'react-redux';
import { InformationCircleIcon, PlusIcon } from '@heroicons/react/24/solid';
import ToolTipNoIcon from '../../elements/ToolTipNoIcon';
import { SpinnerRight } from '../../assets/mdiIcons';
import { setAlert } from '../../redux/actions/message';
import Button from '../../elements/Button';

export default function ButtonBar({
  buttonsToShow,
  displayedComponent,
  setDisplayedComponent,
  displayedComponentMode,
  setDisplayedComponentMode,
  handleSync,
  accountSyncStatus,
  handleSave,
  userChangesPresent,
  handlePrevNext,
  afterSaveGoTo,
  transformationParams,
  setTransformationParams,
  handleUndoTransform,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.buttons' });

  const dashboardModeSelect = useSelector((state) => state.simulation.dashboardMode);
  const dashboardMode = React.useMemo(() => dashboardModeSelect, [dashboardModeSelect]);

  function getButtonFormatting(myId) {
    if (myId === displayedComponent) {
      return 'inset shadow-inner bg-gray-200 hover:bg-gray-300';
    }
    return 'bg-white hover:bg-gray-50';
  }

  const [getPurchaseValueLoading, setGetPurchaseValueLoading] = React.useState(false); // controls the loading state of the historical value calculation for metals

  function handleGridGetPurchaseValuesCompleted(event) {
    setGetPurchaseValueLoading(false);
    event.target.removeEventListener('gridGetPurchaseValues', handleGridGetPurchaseValuesCompleted);
  }

  // handle calculation of historical value for metals
  function handleHistoricalValueCalc() {
    setGetPurchaseValueLoading(true);
    window.dispatchEvent(new CustomEvent('gridGetPurchaseValues')); // this event notifies Grid that it should calculate historical values

    // when Grid is done, it sends this event
    window.addEventListener('handleGridGetPurchaseValuesCompleted', handleGridGetPurchaseValuesCompleted);
  }

  const formatBase = 'inline-flex items-center px-1.5 xs:px-4 py-2 border font-bold focus:z-10 focus:outline-none focus:ring-1 focus:ring-brandBlue-400 focus:border-brandBlue-400';
  const formatNoBG = `${formatBase} border-gray-300 text-gray-700 `;
  const formatRegular = `${formatBase} border-gray-300 bg-white hover:bg-gray-50 text-gray-700`;
  const formatRegularNoDisplay = formatRegular.replace('inline-flex', '');
  const formatAccent = `${formatBase} border-transparent text-white bg-brandBlue-500 hover:bg-brandBlue-600`;

  const formatSpan = 'relative inline-flex shadow-sm rounded-md';

  return (
    <section className="ml-1 xs:w-full flex xs:justify-between gap-2 text-xs xs:text-sm" id="account-menu-bar">
      {/* div to keep SYNC | IMPORT and TABLE | EDIT | SETTINGS on the left-hand side */}
      <div className="flex flex-rows gap-2 sm:gap-6" id="account-details-table-buttons">
        {/* SYNC | IMPORT */}

        {buttonsToShow.includes('syncImport') && (
          <span className={formatSpan}>
            <ToolTipNoIcon info={t('sync.tooltip')}>
              <button
                type="button"
                onClick={handleSync}
                className={`${accountSyncStatus ? 'bg-gray-200 hover:bg-gray-300' : 'bg-white hover:bg-gray-50'} relative rounded-l-md rounded-r-none ${formatNoBG}`}
              >
                <SpinnerRight className={`${accountSyncStatus ? 'animate-spin' : null} -ml-1 mr-2 h-4 w-4 hidden lg:block`} aria-hidden="true" />
                Sync
              </button>
            </ToolTipNoIcon>
            <ToolTipNoIcon info={t('import.tooltip')}>
              <button type="button" id="account-details-table-import-button" onClick={() => setDisplayedComponent('import')} className={`-ml-px relative rounded-r-md ${formatRegular}`}>
                <ArrowUpTrayIcon className="-ml-1 mr-2 h-4 w-4 hidden lg:block" aria-hidden="true" />
                Import
              </button>
            </ToolTipNoIcon>
          </span>
        )}

        {/* IMPORT */}

        {buttonsToShow.includes('import') && (
          <span className={formatSpan}>
            <ToolTipNoIcon info={t('import.tooltip')}>
              <button type="button" id="account-details-grid-import-button" onClick={() => setDisplayedComponent('import')} className={`rounded-md  ${formatAccent}`}>
                <ArrowUpTrayIcon className="-ml-1 mr-2 h-4 w-4 hidden lg:block" aria-hidden="true" />
                Import
              </button>
            </ToolTipNoIcon>
          </span>
        )}

        {/* SAVE */}

        {buttonsToShow.includes('save') && (
          <span className={formatSpan}>
            <ToolTipNoIcon info={t('save.tooltip')}>
              <button type="button" id="account-details-grid-save-button" onClick={handleSave} className={`rounded-md  ${formatAccent}`}>
                <ArrowDownOnSquareIcon className="-ml-1 mr-2 h-4 w-4 hidden lg:block" aria-hidden="true" />
                {t('save.label')}
              </button>
            </ToolTipNoIcon>
          </span>
        )}

        {/* SUBMIT - CANCEL  */}

        {buttonsToShow.includes('submit-cancel') && (
          <span className={formatSpan}>
            <ToolTipNoIcon info={t('save.tooltip')}>
              <button type="button" id="account-details-duplicate-submit-button" onClick={handleSave} className={`relative rounded-l-md rounded-r-md sm:rounded-r-none  ${formatRegular}`}>
                <ArrowDownOnSquareIcon className="-ml-1 mr-2 h-4 w-4 hidden -scale-100 lg:block" aria-hidden="true" />
                {t('save.label')}
              </button>
            </ToolTipNoIcon>
            <ToolTipNoIcon info={t('cancel.tooltip')}>
              <button
                type="button"
                id="account-details-duplicate-cancel-button"
                onClick={() => setDisplayedComponent(afterSaveGoTo)}
                className={`-ml-px relative hidden sm:inline-flex rounded-r-md ${formatRegularNoDisplay}`}
              >
                <XMarkIcon className="mr-2 h-4 w-4 hidden lg:block" aria-hidden="true" />
                {t('cancel.label')}
              </button>
            </ToolTipNoIcon>
          </span>
        )}

        {/* DECIMAL POINT SWITCH */}

        {buttonsToShow.includes('decimalChar') && (
          <span className="relative inline-flex">
            <ToolTipNoIcon info={t('decimalChar.tooltip')}>
              <span className="mr-2 ">{t('decimalChar.label')}</span>
              <button
                type="button"
                id="account-details-grid-decimal-button"
                onClick={() => {
                  const newDecimalChar = transformationParams.decimalChar === ',' ? '.' : ',';
                  setTransformationParams((prev) => ({ ...prev, decimalChar: newDecimalChar }));
                }}
                className={`rounded-md ${formatRegular}`}
              >
                {transformationParams.decimalChar}
              </button>
            </ToolTipNoIcon>
          </span>
        )}

        {/* HEADERS IN ROW */}

        {buttonsToShow.includes('headersInRow') && (
          <span className="relative inline-flex">
            <ToolTipNoIcon info={t('headersInRow.tooltip')}>
              <span className="mr-2 ">{t('headersInRow.label')}</span>
              <input
                type="number"
                min="0"
                step="1"
                defaultValue={transformationParams?.headersInRow ? transformationParams.headersInRow + 1 : ''}
                id="account-details-grid-headersinrow-button"
                onChange={(e) => {
                  if (Number.isNaN(Number(e.target.value)) || Number(e.target.value) < 0) setTransformationParams((prev) => ({ ...prev, headersInRow: null }));
                  setTransformationParams((prev) => ({ ...prev, headersInRow: Number(e.target.value) - 1 }));
                }}
                className="inline-flex items-center w-16 px-1.5 py-1.5 text-center border border-gray-300 bg-white font-medium rounded-md text-gray-700 focus:outline-none focus:ring-2
                      focus:ring-offset-2 focus:ring-brandBlue-400"
              />
            </ToolTipNoIcon>
          </span>
        )}

        {/* INFO MESSAGE */}

        {buttonsToShow.includes('infoDuplicates') && (
          <div className="flex items-center rounded-md bg-brandBlue-50 px-3 py-1" id="info-message-duplicate">
            <div className="flex-shrink-0">
              <InformationCircleIcon className="h-5 w-5 text-brandBlue-400" aria-hidden="true" />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-brandBlue-600">{t('duplicatesFound')}</p>
            </div>
          </div>
        )}

        {buttonsToShow.includes('infoSave') && (
          <div className="flex items-center rounded-md bg-brandBlue-50 px-3 py-1" id="info-message-save">
            <div className="flex-shrink-0">
              <InformationCircleIcon className="h-5 w-5 text-brandBlue-400" aria-hidden="true" />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between">
              <p className="text-sm text-brandBlue-600">{t('editYourFile')}</p>
            </div>
          </div>
        )}

        {buttonsToShow.includes('infoTransformed') && (
          <div className="rounded-md bg-brandBlue-50 px-3 py-1 flex items-center" id="info-message-transformed">
            <div className="flex-shrink-0">
              <InformationCircleIcon className="h-5 w-5 text-brandBlue-400" aria-hidden="true" />
            </div>
            <div className="ml-3 flex-1 md:flex md:justify-between md:items-center">
              <p className="text-sm text-brandBlue-600">{t('fileTransformed')}</p>
              <Button text={t('fileTransformedUndo')} onClick={handleUndoTransform} noFrame size="xs" formatting="ml-2" textColor="text-brandBlue-700" textColorHover="text-brandBlue-800" />
            </div>
          </div>
        )}

        {/* TABLE - EDIT - SETTINGS */}

        {/* FIXME: this is a div which needs its own className at times; change the existing className, which applies to the tooltip displayed, to something else */}
        {(buttonsToShow.includes('tableEditSettings') || buttonsToShow.includes('tableValuationWizardSettings') || buttonsToShow.includes('tableTransactionWizardSettings')) && (
          <span className="isolate inline-flex rounded-md shadow-sm">
            <ToolTipNoIcon info={t('table.tooltip')} classNameOwn="hidden lg:block">
              <button
                type="button"
                className={`inset relative rounded-l-md ${getButtonFormatting('table')} ${formatNoBG}`}
                onClick={() => {
                  // go to grid or grid-secondary, depending on the current display
                  setDisplayedComponent('table');
                }}
              >
                <TableCellsIcon className="-ml-1 mr-2 h-4 w-4 hidden lg:block" aria-hidden="true" />
                {t('table.label')}
              </button>
            </ToolTipNoIcon>

            {/* EDIT button for standard variant */}

            {buttonsToShow.includes('tableEditSettings') ? (
              <ToolTipNoIcon info={t('edit.tooltip')} classNameOwn="hidden lg:block">
                <button
                  type="button"
                  id="account-details-table-edit-button"
                  name="editbutton"
                  onClick={() => {
                    // normally go to grid or grid-secondary, depending on the current display
                    setDisplayedComponent('grid');
                  }}
                  className={`-ml-px ${getButtonFormatting('grid')} ${formatNoBG}`}
                >
                  <PencilSquareIcon className="-ml-1 mr-2 h-4 w-4 hidden lg:block" aria-hidden="true" />
                  {t('edit.label')}
                </button>
              </ToolTipNoIcon>
            ) : (
            // ADD NEW button for unlistedShares

              <ToolTipNoIcon info={t('addNew.tooltip')} classNameOwn="hidden lg:block">
                <button
                  type="button"
                  id="account-details-table-addnew-button"
                  name="addnewbutton"
                  onClick={() => {
                    // for unlistedShares, set the wizard and decide which one by evaluating the current mode (transactions / quotes)
                    setDisplayedComponent('wizard');
                  }}
                  className={`-ml-px ${getButtonFormatting('wizard')} ${formatNoBG}`}
                >
                  <PlusIcon className="-ml-1 mr-2 h-4 w-4 hidden lg:block" aria-hidden="true" />
                  {t('addNew.label')}
                </button>
              </ToolTipNoIcon>
            )}
            <ToolTipNoIcon info={t('settings.tooltip')}>
              <button
                type="button"
                name="settingsbutton"
                onClick={() => {
                  setDisplayedComponent('settings'); // 'settings' is always added on top of the previous component, so that it knows where to go back to
                }}
                className={`-ml-px relative rounded-r-md rounded-l-md lg:rounded-l-none ${getButtonFormatting('settings')} ${formatNoBG}`}
              >
                <CogIcon className="-ml-1 mr-2 h-4 w-4 hidden lg:block" aria-hidden="true" />
                {t('settings.label')}
              </button>
            </ToolTipNoIcon>
          </span>
        )}

        {dashboardMode === 'projects' && (
          <ToolTipNoIcon info={t('readOnly.tooltip')} classNameOwn="h-full flex items-center">
            <span className="ml-2 px-1.5 rounded-full bg-brandBlue-300 border-[1px] border-brandBlue-300 text-white text-xs font-bold">i</span>
            <p className="ml-2 text-sm text-gray-500">{t('readOnly.label')}</p>
          </ToolTipNoIcon>
        )}

        {buttonsToShow.includes('historicalValueCalc') && (
          <ToolTipNoIcon info={t('historicalValueCalc.tooltip')}>
            <Button text={t('historicalValueCalc.label')} onClick={handleHistoricalValueCalc} noFrame formatting="mt-0.5" spinnerOn={getPurchaseValueLoading} />
          </ToolTipNoIcon>
        )}

        {buttonsToShow.includes('prevNext') && (
          <div id="button-group-prev-next" className="self-center xs:my-0 mr-2">
            <span className={formatSpan}>
              <ToolTipNoIcon info={t('previous.tooltip')}>
                <button type="button" id="button-previous" onClick={() => handlePrevNext('previous')} className={`relative rounded-l-md ${formatRegular}`}>
                  <ChevronLeftIcon className="-ml-1 mr-1 h-5 w-5 hidden text-gray-500 lg:block" aria-hidden="true" />
                  {t('previous.label')}
                </button>
              </ToolTipNoIcon>
              <ToolTipNoIcon info={t('next.tooltip')}>
                <button type="button" id="button-next" onClick={() => handlePrevNext('next')} className={`-ml-px relative rounded-r-md ${formatRegular}`}>
                  {t('next.label')}
                  <ChevronRightIcon className="ml-1 -mr-1 h-5 w-5 text-gray-500 hidden lg:block" aria-hidden="true" />
                </button>
              </ToolTipNoIcon>
            </span>
          </div>
        )}
      </div>

      {/* TRANSACTION | QUOTES (on the opposite side) */}

      {buttonsToShow.includes('hasWorth') && (
        <div id="button-group-two-views" className="self-center xs:my-0 mr-2">
          <span className={formatSpan}>
            <ToolTipNoIcon info={t('whatYouOwn.tooltip')}>
              <button
                type="button"
                id="button-whatyouown"
                onClick={() => {
                  if (userChangesPresent) {
                    dispatch(
                      setAlert('aboutToLoseData', 'ButtonBar', () => {
                        setDisplayedComponentMode('transactions');
                      }),
                    );
                  } else setDisplayedComponentMode('transactions');
                }}
                className={`relative ${
                  displayedComponentMode === 'transactions' ? 'bg-gray-200 hover:bg-gray-300 shadow-inner text-gray-600' : 'bg-white hover:bg-gray-50 text-gray-700'
                } rounded-l-md ${formatBase}`}
              >
                <HomeIcon className="-ml-1 mr-2 h-4 w-4 hidden text-gray-500 lg:block" aria-hidden="true" />
                {t('whatYouOwn.label')}
              </button>
            </ToolTipNoIcon>
            <ToolTipNoIcon info={t('whatIsItWorth.tooltip')}>
              <button
                type="button"
                id="button-whatisitworth"
                onClick={() => {
                  if (userChangesPresent) {
                    dispatch(
                      setAlert('aboutToLoseData', 'ButtonBar', () => {
                        setDisplayedComponentMode('quotes');
                      }),
                    );
                  } else setDisplayedComponentMode('quotes');
                }}
                className={`-ml-px relative ${displayedComponentMode === 'quotes' ? 'bg-gray-200 hover:bg-gray-300 shadow-inner' : 'bg-white hover:bg-gray-50'} rounded-r-md ${formatBase}`}
              >
                <ChartBarIcon className="-ml-1 mr-2 h-4 w-4 text-gray-500 hidden lg:block" aria-hidden="true" />
                {t('whatIsItWorth.label')}
              </button>
            </ToolTipNoIcon>
          </span>
        </div>
      )}
      {/* TRANSACTION | QUOTES (pension version)
       /* (logic identical to the above, but with different button labels and icons) */}

      {buttonsToShow.includes('hasPayments') && (
        <div id="button-group-two-views" className="self-center xs:my-0 mr-2">
          <span className={formatSpan}>
            <ToolTipNoIcon info={t('payments.label')}>
              <button
                type="button"
                id="button-whatyouown"
                onClick={() => {
                  if (userChangesPresent) {
                    dispatch(
                      setAlert('aboutToLoseData', 'ButtonBar', () => {
                        setDisplayedComponentMode('transactions');
                      }),
                    );
                  } else setDisplayedComponentMode('transactions');
                }}
                className={`${displayedComponentMode === 'transactions' ? 'bg-gray-200 hover:bg-gray-300 shadow-inner text-gray-600' : 'bg-white hover:bg-gray-50 text-gray-700'}
                relative rounded-l-md ${formatBase}`}
              >
                <BanknotesIcon className="-ml-1 mr-2 h-4 w-4 hidden text-gray-500 lg:block" aria-hidden="true" />
                {t('payments.label')}
              </button>
            </ToolTipNoIcon>
            <ToolTipNoIcon info={t('presentValue.label')}>
              <button
                type="button"
                id="button-whatisitworth"
                onClick={() => {
                  if (userChangesPresent) {
                    dispatch(
                      setAlert('aboutToLoseData', 'ButtonBar', () => {
                        setDisplayedComponentMode('quotes');
                      }),
                    );
                  } else setDisplayedComponentMode('quotes');
                }}
                className={`${displayedComponentMode === 'quotes' ? 'bg-gray-200 hover:bg-gray-300 shadow-inner' : 'bg-white hover:bg-gray-50'} -ml-px relative rounded-r-md ${formatBase}`}
              >
                <TrophyIcon className="-ml-1 mr-2 h-4 w-4 text-gray-500 hidden lg:block" aria-hidden="true" />
                {t('presentValue.label')}
              </button>
            </ToolTipNoIcon>
          </span>
        </div>
      )}
    </section>
  );
}
ButtonBar.propTypes = {
  buttonsToShow: PropTypes.arrayOf(PropTypes.string).isRequired,
  displayedComponent: PropTypes.string.isRequired,
  setDisplayedComponent: PropTypes.func.isRequired,
  displayedComponentMode: PropTypes.string.isRequired,
  setDisplayedComponentMode: PropTypes.func.isRequired,
  handleSync: PropTypes.func,
  accountSyncStatus: PropTypes.bool,
  handleSave: PropTypes.func,
  userChangesPresent: PropTypes.bool.isRequired,
  handlePrevNext: PropTypes.func,
  afterSaveGoTo: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  transformationParams: PropTypes.object.isRequired,
  setTransformationParams: PropTypes.func.isRequired,
  handleUndoTransform: PropTypes.func,
};
ButtonBar.defaultProps = {
  handleSync: () => {},
  accountSyncStatus: false,
  handleSave: () => {},
  handlePrevNext: () => {},
  afterSaveGoTo: 'table',
  handleUndoTransform: () => {},
};
