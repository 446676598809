/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';

export function PinIcon(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" viewBox="0 0 24 24" fill="currentColor">
      <g>
        <path d="M0,0h24v24H0V0z" fill="none" />
      </g>
      <g>
        <g>
          <path d="M20,4H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V6C22,4.9,21.1,4,20,4z M20,18H4V6h16V18z" />
          <polygon points="6.49,10.5 6.49,15 7.64,15 7.64,9 6.77,9 5.01,10.27 5.59,11.16" />
          <path d="M11.47,10.05c0.5,0,0.81,0.32,0.81,0.72c0,0.37-0.14,0.64-0.54,1.06c-0.36,0.38-1.06,1.08-2.13,2.15V15h3.89v-0.99h-2.37 l-0.03-0.05c0.68-0.68,1.15-1.14,1.4-1.39c0.61-0.6,0.92-1.22,0.92-1.86c0-0.24-0.05-1.04-0.91-1.48C12.04,9,11.25,8.87,10.56,9.2 c-0.82,0.39-0.99,1.13-1,1.15l1.01,0.42C10.67,10.44,10.95,10.05,11.47,10.05z" />
          <path d="M16.99,13.94c-0.83,0-0.99-0.76-1.02-0.86l-1.03,0.41c0.45,1.59,2.01,1.51,2.05,1.51c1.2,0,1.68-0.72,1.76-0.85 c0.32-0.49,0.36-1.24-0.01-1.76c-0.17-0.24-0.4-0.41-0.68-0.52V11.8c0.2-0.1,0.37-0.26,0.52-0.48c0.26-0.41,0.31-1.07-0.02-1.57 C18.48,9.64,18.03,9,16.94,9c-1.26,0-1.74,0.9-1.85,1.24l0.99,0.41c0.11-0.32,0.35-0.64,0.85-0.64c0.44,0,0.75,0.26,0.75,0.65 c0,0.58-0.55,0.72-0.88,0.72h-0.46v1h0.5c0.56,0,1.04,0.24,1.04,0.79C17.88,13.66,17.4,13.94,16.99,13.94z" />
        </g>
      </g>
    </svg>
  );
}

export function CategoriesIcon(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M12 2l-5.5 9h11L12 2zm0 3.84L13.93 9h-3.87L12 5.84zM17.5 13c-2.49 0-4.5 2.01-4.5 4.5s2.01 4.5 4.5 4.5 4.5-2.01 4.5-4.5-2.01-4.5-4.5-4.5zm0 7c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5zM3 21.5h8v-8H3v8zm2-6h4v4H5v-4z" />
    </svg>
  );
}

export function UndoIcon(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M12.5 8c-2.65 0-5.05.99-6.9 2.6L2 7v9h9l-3.62-3.62c1.39-1.16 3.16-1.88 5.12-1.88 3.54 0 6.55 2.31 7.6 5.5l2.37-.78C21.08 11.03 17.15 8 12.5 8z" />
    </svg>
  );
}

export function RedoIcon(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M18.4 10.6C16.55 8.99 14.15 8 11.5 8c-4.65 0-8.58 3.03-9.96 7.22L3.9 16c1.05-3.19 4.05-5.5 7.6-5.5 1.95 0 3.73.72 5.12 1.88L13 16h9V7l-3.6 3.6z" />
    </svg>
  );
}

export function TableRowInsertBefore(props) {
  return (
    <svg {...props} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M3 0a1 1 0 0 1 1 1v1h1a1 1 0 0 1 0 2H4v1a1 1 0 0 1-2 0V4H1a1 1 0 0 1 0-2h1V1a1 1 0 0 1 1-1zM10 2h6v2h-6V2zM8 1v4a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1zM0 9v8a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1zm16 5v2H2v-2h14zm0-4v2H2v-2h14z"
      />
    </svg>
  );
}

export function TableRowInsertAfter(props) {
  return (
    <svg {...props} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M3 12a1 1 0 0 1 1 1v1h1a1 1 0 0 1 0 2H4v1a1 1 0 0 1-2 0v-1H1a1 1 0 0 1 0-2h1v-1a1 1 0 0 1 1-1zM10 14h6v2h-6v-2zm-2-1v4a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-4a1 1 0 0 0-1-1H9a1 1 0 0 0-1 1zM0 1v8a1 1 0 0 0 1 1h16a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1zm16 5v2H2V6h14zm0-4v2H2V2h14z"
      />
    </svg>
  );
}

export function TableColumnInsertBefore(props) {
  return (
    <svg {...props} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M 6 3 a 1 1 90 0 1 -1 1 h -1 v 1 a 1 1 90 0 1 -2 0 V 4 h -1 a 1 1 90 0 1 -0 -2 h 1 V 1 a 1 1 90 0 1 2 -0 v 1 h 1 a 1 1 90 0 1 1 1 z M 4 10 v 6 h -2 v -6 h 2 z m 1 -2 h -4 a 1 1 90 0 0 -1 1 v 8 a 1 1 90 0 0 1 1 h 4 a 1 1 90 0 0 1 -1 V 9 a 1 1 90 0 0 -1 -1 z M 17 0 h -8 a 1 1 90 0 0 -1 1 v 16 a 1 1 90 0 0 1 1 H 17 a 1 1 90 0 0 1 -1 V 1 a 1 1 90 0 0 -1 -1 z m -5 16 h -2 V 2 H 12 v 14 z m 4 -0 h -2 V 2 H 16 v 14 z"
      />
    </svg>
  );
}

export function TableColumnInsertAfter(props) {
  return (
    <svg {...props} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="M 18 3 a 1 1 90 0 1 -1 1 h -1 v 1 a 1 1 90 0 1 -2 0 V 4 h -1 a 1 1 90 0 1 -0 -2 H 14 V 1 a 1 1 90 0 1 2 -0 v 1 H 17 a 1 1 90 0 1 1 1 z M 16 10 v 6 h -2 v -6 H 16 z M 17 8 h -4 a 1 1 90 0 0 -1 1 v 8 a 1 1 90 0 0 1 1 H 17 a 1 1 90 0 0 1 -1 V 9 a 1 1 90 0 0 -1 -1 z M 9 -0 h -8 a 1 1 90 0 0 -1 1 v 16 a 1 1 90 0 0 1 1 H 9 a 1 1 90 0 0 1 -1 V 1 a 1 1 90 0 0 -1 -1 z m -5 16 h -2 V 2 h 2 v 14 z m 4 -0 h -2 V 2 h 2 v 14 z"
      />
    </svg>
  );
}

export function DuplicateRow(props) {
  return (
    <svg {...props} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="m 16 4 H 2 c -0.6 0 -1 -0.4 -1 -1 S 1.4 2 2 2 H 16 C 16.6 2 17 2.4 17 3 S 16.6 4 16 4 Z m 0 4 h -14 c -0.6 0 -1 -0.4 -1 -1 s 0.4 -1 1 -1 h 14 c 0.6 0 1 0.4 1 1 s -0.4 1 -1 1 z m 1 4 v 2 c 0 1 -1 2 -2 2 h -2 c 0 0.3 0 0.5 -0.3 0.7 c -0.2 0.2 -0.4 0.3 -0.7 0.3 s -0.5 0 -0.7 -0.3 l -1 -1 c -0.4 -0.4 -0.4 -1 0 -1.4 l 1 -1 c 0.4 -0.4 1 -0.4 1.4 0 c 0.2 0.2 0.3 0.4 0.3 0.7 h 2 v -2 h -13 c -0.6 0 -1 -0.5 -1 -1 s 0.5 -1 1 -1 h 13 c 1 0 2 1 2 2 z m -15 4 H 2 c -0.6 0 -1 -0.4 -1 -1 c 0 -0.6 0.4 -1 1 -1 l 6 0 c 0.6 0 1 0.4 1 1 c 0 0.6 -0.4 1 -1 1 l -6 0 z"
      />
    </svg>
  );
}
export function DuplicateColumn(props) {
  return (
    <svg {...props} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <path
        fill="currentColor"
        d="m 3 2 v 14 c 0 0.6 -0.4 1 -1 1 s -1 -0.4 -1 -1 v -14 c -0 -0.6 0.4 -1 1 -1 s 1 0.4 1 1 z m 4 -0 v 14 c 0 0.6 -0.4 1 -1 1 s -1 -0.4 -1 -1 v -14 c -0 -0.6 0.4 -1 1 -1 s 1 0.4 1 1 z m 4 -1 h 2 c 1 -0 2 1 2 2 v 2 c 0.3 -0 0.5 -0 0.7 0.3 c 0.2 0.2 0.3 0.4 0.3 0.7 s 0 0.5 -0.3 0.7 l -1 1 c -0.4 0.4 -1 0.4 -1.4 0 l -1 -1 c -0.4 -0.4 -0.4 -1 -0 -1.4 c 0.2 -0.2 0.4 -0.3 0.7 -0.3 v -2 h -2 v 13 c 0 0.6 -0.5 1 -1 1 s -1 -0.5 -1 -1 v -13 c -0 -1 1 -2 2 -2 z m 4 15 V 16 c 0 0.6 -0.4 1 -1 1 c -0.6 0 -1 -0.4 -1 -1 l -0 -6 c -0 -0.6 0.4 -1 1 -1 c 0.6 -0 1 0.4 1 1 l 0 6 z"
      />
    </svg>
  );
}

export function DeleteColumn(props) {
  return (
    <svg {...props} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
      <g id="SVGRepo_iconCarrier">
        <path
          fill="currentColor"
          d="M18 1a1 1 0 0 0-1-1H1a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h10.02A4.98 4.98 0 0 1 10 15V2h2v9.024a4.953 4.953 0 0 1 2-.923V2h2v8.116a5.077 5.077 0 0 1 2 .9V1zM4 16H2V2h2v14zm4 0H6V2h2v14z"
        />
        <path fill="currentColor" d="M13 14h4a1 1 0 0 1 1 1 1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1 1 1 0 0 1 1-1z" />
      </g>
    </svg>
  );
}

export function DeleteRow(props) {
  return (
    <svg {...props} viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
      <g id="SVGRepo_bgCarrier" strokeWidth="0" />
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round" />
      <g id="SVGRepo_iconCarrier">
        <path
          fill="currentColor"
          d="M17 0H1a1 1 0 0 0-1 1v16a1 1 0 0 0 1 1h10.02a5.064 5.064 0 0 1-.9-2H2v-2h8.1a4.944 4.944 0 0 1 .923-2H2v-2h13a4.98 4.98 0 0 1 3 1.02V1a1 1 0 0 0-1-1zm-1 8H2V6h14v2zm0-4H2V2h14v2z"
        />
        <path fill="currentColor" d="M13 14h4a1 1 0 0 1 1 1 1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1 1 1 0 0 1 1-1z" />
      </g>
    </svg>
  );
}

export function Reset(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" viewBox="0 0 24 24">
      <g>
        <path d="M0,0h24v24H0V0z" fill="none" />
      </g>
      <g>
        <g>
          <path fill="currentColor" d="M12,5V2L8,6l4,4V7c3.31,0,6,2.69,6,6c0,2.97-2.17,5.43-5,5.91v2.02c3.95-0.49,7-3.85,7-7.93C20,8.58,16.42,5,12,5z" />
          <path fill="currentColor" d="M6,13c0-1.65,0.67-3.15,1.76-4.24L6.34,7.34C4.9,8.79,4,10.79,4,13c0,4.08,3.05,7.44,7,7.93v-2.02 C8.17,18.43,6,15.97,6,13z" />
        </g>
      </g>
    </svg>
  );
}

// heroicons
export function SpinnerRight(props) {
  return (
    <svg {...props} fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth="2" xmlns="http://www.w3.org/2000/svg">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M 4 19.998 L 4 14.998 L 4.582 14.998 M 19.938 12.998 C 19.169 19.109 12.073 22.096 7.165 18.374 C 6.015 17.502 5.123 16.336 4.582 14.998 M 4.582 14.998 L 9 14.998 M 20 3.998 L 20 8.998 L 19.419 8.998 M 19.419 8.998 C 17.106 3.288 9.479 2.224 5.69 7.082 C 4.805 8.217 4.243 9.57 4.062 10.998 M 19.419 8.998 L 15 8.998"
        transform="matrix(-1, 0, 0, -1, 24, 24.007215)"
      />
    </svg>
  );
}

export function InflowIcon(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15M9 12l3 3m0 0l3-3m-3 3V2.25"
      />
    </svg>
  );
}

export function OutflowIcon(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15m0-3l-3-3m0 0l-3 3m3-3V15"
      />
    </svg>
  );
}

export function TransferIcon(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
      <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
    </svg>
  );
}

export function BuyIcon(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
      />
    </svg>
  );
}

export function SellIcon(props) {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z"
      />
    </svg>
  );
}

export function TrendingUpIcon(props) {
  return (
    <svg {...props} className="w-5 h-5" stroke="none" fill="currentColor" focusable="false" aria-hidden="true" viewBox="0 0 24 24">
      <path d="m16 6 2.29 2.29-4.88 4.88-4-4L2 16.59 3.41 18l6-6 4 4 6.3-6.29L22 12V6z" />
    </svg>
  );
}
