const debugLevel = process.env.MDEBUG || 0;

// expects: full currentAllocation array, full targetAllocation array
// returns: ONLY category-level allocations (assetClass and currency are null)

export default function calculatePortfolioAllocation(currentAllocationFiltered, targetAllocationFiltered) {
  // get names of all  categories which exist from currentAllocation
  const categories = [...new Set(currentAllocationFiltered.map((entry) => entry.category))];
  const portfolioLevelAllocation = targetAllocationFiltered.filter((e) => !e.assetClass && !e.currency);
  if (debugLevel > 2) console.info('calculatePortfolioAllocation: received the following portfolio-level target allocation rows:', JSON.stringify(portfolioLevelAllocation, null, 2));

  // if there are no portfolio-level allocations at all, return null value for each category
  if (portfolioLevelAllocation.length === 0 || portfolioLevelAllocation.every((e) => e.value === undefined || e.value === null)) {
    return categories.map((c) => ({
      category: c,
      assetClass: null,
      currency: null,
      value: null,
    }));
  } // return null value for each category

  const sumOfAllocation = portfolioLevelAllocation.reduce((sum, e) => sum + Number(e.value), 0);
  if (debugLevel > 2) console.info('calculatePortfolioAllocation: allocation of received rows is', sumOfAllocation);
  const remainingAllocation = 100 - sumOfAllocation;

  // there is one allocation per each category and allocation is complete - returning input allocation
  if (portfolioLevelAllocation.lentgh === categories.length && remainingAllocation === 0) return portfolioLevelAllocation;

  const lockedCategoriesAllocation = portfolioLevelAllocation.filter((e) => e.locked).reduce((acc, e) => acc + Number(e.value), 0);
  if (debugLevel > 2) console.info('calculatePortfolioAllocation: locked categories allocation is', lockedCategoriesAllocation);

  // handle the case where all categories are there, but there is not 100% allocation
  if (portfolioLevelAllocation.length === categories.length && remainingAllocation !== 0) {
    if (debugLevel > 2) console.info('calculatePortfolioAllocation: all categories present in targetAllocation, but do not sum up to 100', portfolioLevelAllocation);

    const multiplier = (100 - lockedCategoriesAllocation) / (sumOfAllocation - lockedCategoriesAllocation || 1); // portfolioLevelAllocation.filter((e) => !e.locked).length;
    return portfolioLevelAllocation.map((e) => (e.locked
      ? e
      : {
        ...e,
        value: (e.value || 1) * multiplier, // handle case where value is null
      }));
  }

  // handle the case where some categories are missing in targetAllocation and there is at least one other category with a value
  // find out which ones, add them and spread the remaining allocation equally (considering the locked categories)
  const missingCategories = categories.filter((c) => !portfolioLevelAllocation.find((e) => e.category === c));

  const categoryFactor = remainingAllocation / missingCategories.length;

  return [
    ...portfolioLevelAllocation,
    ...missingCategories.map((c) => ({
      category: c,
      assetClass: null,
      currency: null,
      value: Math.round(categoryFactor),
    })),
  ];
}
