import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ArrowPathIcon, CloudArrowUpIcon, LockClosedIcon } from '@heroicons/react/20/solid';

export default function GettingStarted({ showSync, showImport }) {
  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.gettingStarted' });

  const features = [
    {
      name: t('features.0.name'),
      description: t('features.0.description'),
      icon: CloudArrowUpIcon,
    },
  ];

  if (showImport) {
    features.push({
      name: t('features.1.name'),
      description: t('features.1.description'),
      icon: LockClosedIcon,
    });
  }

  if (showSync) {
    features.push({
      name: t('features.2.name'),
      description: t('features.2.description'),
      icon: ArrowPathIcon,
    });
  }

  return (
    <div className="bg-white py-8">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:text-center">
          <h2 className="text-base font-semibold leading-8 tracking-wide text-brandBlue-500 uppercase">{t('heading')}</h2>
          <p className="mt-2 text-3xl font-bold text-gray-900 sm:text-4xl">{t('subheading')}</p>
          <p className="mt-4 text-lg leading-7 text-gray-600">{t('intro')}</p>
        </div>
        <div className="mx-auto mt-8 max-w-2xl sm:mt-20 lg:mt-24 lg:max-w-none">
          <dl className="grid max-w-xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-3">
            {features.map((feature, idx) => (
              <>
                {/* if there are 2 features, leave the middle one free */}
                {features.length === 2 && idx === 1 && <div />}

                <div key={feature.name} className="flex flex-col">
                  <dt className="flex items-center gap-x-3 text-base font-semibold leading-7 text-gray-900">
                    <feature.icon className="h-5 w-5 flex-none text-brandBlue-500" aria-hidden="true" />
                    {feature.name}
                  </dt>
                  <dd className="mt-4 flex flex-auto flex-col text-base leading-6 text-gray-600">
                    <p className="flex-auto">{feature.description}</p>
                  </dd>
                </div>
              </>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
GettingStarted.propTypes = {
  showSync: PropTypes.bool,
  showImport: PropTypes.bool,
};
GettingStarted.defaultProps = {
  showSync: false,
  showImport: false,
};
