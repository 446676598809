/* eslint-disable no-param-reassign */
// Delete rows from the data array
function deleteRows(data, rowNumber, numOfRows) {
  for (let i = 0; i < numOfRows; i += 1) {
    const rowIndex = rowNumber + i;
    if (rowIndex >= 0 && rowIndex < data.length) {
      data.splice(rowIndex, 1); // Remove the row at the specified index
    }
  }
  return data;
}

// Insert a new column in the data array
function insertColumn(data, numOrData, columnNumber, insertBefore, properties) {
  const numOfColumns = typeof numOrData === 'number' ? numOrData : 1; // Check if it’s a number or single column data

  data.forEach((row) => {
    for (let i = 0; i < numOfColumns; i += 1) {
      const index = insertBefore ? columnNumber : columnNumber + 1;
      row.splice(index, 0, typeof numOrData === 'string' ? numOrData : null); // Insert data or empty column
    }
  });

  return data;
}

// Delete a column from the data array
function deleteColumn(data, columnNumber, numOfColumns = 1) {
  console.log('DEBUG: deleteColumn', columnNumber, numOfColumns);
  data.forEach((row) => {
    for (let i = 0; i < numOfColumns; i += 1) {
      if (columnNumber >= 0 && columnNumber < row.length) {
        row.splice(columnNumber, 1); // Remove the column at the specified index
      }
    }
  });
  return data;
}

// Insert a formula into a column, beginning on the row below headersInRow + marginTop number of rows
// formula comes with arguments that will be replaced with the current row index
// formula comes as string "=SUM(§#§[-2,0]§#§,§#§[-1,0]§#§)", [<column_offset>, <row_offset> vs current column]

// Note: this function operates on actual data i.e. also with rows above the header row
function formula(data, columnNumber, formulaString, headersInRow) {
  // Start from the row after headersInRow, or from row 0 if headersInRow is not provided
  const totalRows = data.length;

  // Iterate through the rows where the formula should be inserted
  // start at the row after headersInRow
  for (let row = 1 + headersInRow; row < totalRows; row += 1) {
    // Replace relative references with absolute references
    const absoluteFormula = formulaString.replace(/§#§\[(.*?)\]§#§/g, (match, offsets) => {
      const [colOffset, rowOffset] = offsets.split(',').map(Number); // Split offsets into column and row
      const colIndex = columnNumber + colOffset; // Adjust column by offset
      const rowIndex = row + rowOffset; // Adjust row by offset

      // Convert colIndex to column letter (A, B, C, etc.)
      const colLetter = String.fromCharCode('A'.charCodeAt(0) + colIndex);

      // Return the absolute cell reference
      return `${colLetter}${rowIndex + 1}`; // data is 0-based and grid is 1-based
    });

    // Insert the computed absolute formula into the data array (caveat: this needs to start BELOW header row, which is still there, invisible to user)
    data[row][columnNumber] = absoluteFormula;
  }

  return data; // Return the updated data array
}

// Main function to process the commands
export default function applyCommands(data, commands, headersInRow = 0) {
  console.log('DEBUG: applyCommands started with args', data, commands, headersInRow);
  // Create a copy of the data array
  let newData = JSON.parse(JSON.stringify(data));

  // Process each command in the array
  commands.forEach(({ command, args }) => {
    switch (command) {
      case 'deleteRows':
        newData = deleteRows(newData, ...args);
        break;
      case 'insertColumn':
        newData = insertColumn(newData, ...args);
        break;
      case 'deleteColumn':
        newData = deleteColumn(newData, ...args);
        break;
      case 'formula':
        newData = formula(newData, ...args, headersInRow); // args have here [columnNumber, formulaString]
        break;
      default:
        console.error(`Unknown command: ${command}`);
    }
  });

  return newData;
}
