/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Dialog, Listbox } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon, PlusIcon, XMarkIcon } from '@heroicons/react/20/solid';
import Button from '../../elements/Button';

export default function GridFilterDialog({ currentRules, callback, callbackCancel }) {
  const { t } = useTranslation('app', { keyPrefix: 'accountDetails.filterDialog' });

  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      rules: currentRules,
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'rules',
  });

  const rules = watch('rules');

  const args = ['greaterThan', 'greaterThanOrEqual', 'lessThan', 'lessThanOrEqual', 'equalTo', 'blank'];
  const verbs = ['is', 'isNot', 'contains', 'doesNotContain', 'startsWith', 'doesNotStartWith', 'endsWith', 'doesNotEndWith'];

  function handleSave(e) {
    handleSubmit((data) => callback(data))();
  }

  return (
    <div className="relative mx-auto rounded-lg px-4 text-left sm:my-8 align-bottom  sm:align-middle sm:p-6 bg-white shadow-xl sm:max-w-2xl">
      <div>
        <div className="mt-3 text-center">
          <Dialog.Title as="h3" className="text-lg leading-6 font-bold text-gray-900">
            {t('filter')}
          </Dialog.Title>
          <p className="mt-2 text-sm text-gray-500">{t('prompt')}</p>
        </div>
      </div>
      <div className="mx-4 mt-6 space-y-2">
        {fields.map((item, index) => (
          <div key={item.id} className="grid grid-cols-[30%,30%,30%,10%] justify-items-stretch space-x-2">
            <Controller
              control={control}
              name={`rules.${index}.verb`}
              render={({ field: { onChange, value } }) => (
                <Listbox as="div" value={value} onChange={onChange} className="relative">
                  <Listbox.Button className="w-full space-x-2 px-2.5 py-2 rounded-md border border-gray-300 hover:bg-gray-50 shadow-sm text-sm uppercase text-center">
                    {({ disabled }) => (
                      <>
                        <span className={`block truncate ${disabled ? 'text-gray-300' : ''}`}>{t(value)}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                        </span>
                      </>
                    )}
                  </Listbox.Button>
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg sm:text-sm">
                    {verbs.map((verb) => (
                      <Listbox.Option key={verb} value={verb} as={Fragment}>
                        {({ active, selected }) => (
                          <li className={`group relative cursor-pointer select-none py-2 pl-3 pr-9 ${active ? 'bg-brandBlue-500 text-white' : 'text-gray-900'}`}>
                            <span className={`block truncate ${selected ? 'font-semibold' : 'font-normal'}`}>{t(verb)}</span>
                            <span className={`absolute inset-y-0 right-0 flex items-center pr-4  ${selected ? (active ? 'block text-white' : 'block text-brandBlue-500') : 'hidden'}`}>
                              <CheckIcon aria-hidden="true" className="h-5 w-5" />
                            </span>
                          </li>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Listbox>
              )}
            />
            <Controller
              control={control}
              name={`rules.${index}.arg`}
              render={({ field: { onChange, value } }) => (
                <Listbox as="div" value={value} onChange={onChange} disabled={!['is', 'isNot'].includes(rules[index]?.verb)} className="relative">
                  <Listbox.Button className="w-full space-x-2 px-2.5 py-2 rounded-md border border-gray-300 hover:bg-gray-50 shadow-sm text-sm uppercase text-center">
                    {({ disabled }) => (
                      <>
                        <span className={`block truncate ${disabled ? 'text-gray-300' : ''}`}>{t(value)}</span>
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronUpDownIcon aria-hidden="true" className="h-5 w-5 text-gray-400" />
                        </span>
                      </>
                    )}
                  </Listbox.Button>
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg sm:text-sm">
                    {args.map((arg) => (
                      <Listbox.Option key={arg} value={arg} as={Fragment}>
                        {({ active, selected }) => (
                          <li className={`group relative cursor-pointer select-none py-2 pl-3 pr-9 ${active ? 'bg-brandBlue-500 text-white' : 'text-gray-900'}`}>
                            <span className={`block truncate ${selected ? 'font-semibold' : 'font-normal'}`}>{t(arg)}</span>
                            <span className={`absolute inset-y-0 right-0 flex items-center pr-4  ${selected ? (active ? 'block text-white' : 'block text-brandBlue-500') : 'hidden'}`}>
                              <CheckIcon aria-hidden="true" className="h-5 w-5" />
                            </span>
                          </li>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Listbox>
              )}
            />
            <input
              disabled={['blank'].includes(rules[index]?.arg)}
              type="text"
              autoComplete="off"
              className={`border rounded-md text-sm text-right text-gray-700 ${['blank'].includes(rules[index]?.arg) ? 'bg-gray-50 border-gray-200' : 'border-gray-300'}`}
              {...register(`rules.${index}.val`, {
                validate: (value) => {
                  const { verb, arg } = rules[index];
                  // Validation for numbers if verb is 'is' or 'isNot' and arg is not 'blank'
                  if ((verb === 'is' || verb === 'isNot') && arg !== 'blank') {
                    return /^\d+$/.test(value) || 'rulesErrorNumber';
                  }
                  // Validation for strings with max 200 characters in other cases
                  return value.length <= 200 || 'rulesErrorLength';
                },
              })}
            />
            <Button onClick={() => remove(index)} size="md" Icon={XMarkIcon} />
            {errors.rules?.[index]?.val?.type === 'validate' && <span className="pt-1 text-red-500 text-xs">{t(errors.rules?.[index]?.val?.message)}</span>}
          </div>
        ))}
        <Button text={t('addMore')} onClick={() => append({ verb: verbs[0], arg: args[0], val: '' })} Icon={PlusIcon} size="md" formatting="mt-2" />
      </div>

      <div className="mt-7 sm:mt-8 grid grid-cols-2 gap-2">
        <Button text={t('cancel')} onClick={callbackCancel} id="dialog-form-cancel-button" name="dialog-cancel-button" />
        <Button text="OK" withAccent onClick={handleSave} id="dialog-form-save-button" name="dialog-save-button" />
      </div>
    </div>
  );
}
GridFilterDialog.propTypes = {
  callback: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  currentRules: PropTypes.arrayOf(PropTypes.object),
  callbackCancel: PropTypes.func.isRequired,
};
GridFilterDialog.defaultProps = {
  currentRules: [],
};
