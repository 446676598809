/* eslint-disable no-param-reassign */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import utc from 'dayjs/plugin/utc';
import { PivotData } from 'react-pivottable/Utilities';
import { ResponsiveLine } from '@nivo/line';
import merge from 'lodash/merge';

dayjs.extend(utc);

const colors = ['#1797F2', '#92D050', '#BB6DCE', '#FFD600', '#ED1C23', '#2E3192'];

// LineChart can only be used for x-axis with time scale (it makes no sense for other data like accounts or categories)
function makeNivoRenderer(NivoChart, layoutOptions = {}) {
  return function NivoRenderer(props) {
    const { i18n } = useTranslation();

    console.log('DEBUG LineChart started with props:', props);
    const pivotData = new PivotData(props);
    const rowKeys = pivotData.getRowKeys();
    const colKeys = pivotData.getColKeys();

    // when there are no dates in the data, the chart cannot be rendered
    if (!colKeys.every((x) => dayjs.utc(Number(x)).isValid())) {
      return <div>{i18n.language === 'de' ? 'Dieses Diagramm kann nur mit Kalendardaten auf Achse X dargestellt werden.' : 'This type of chart only supports dates on the X axis.'}</div>;
    }

    let fullAggName = props.aggregatorName;
    const numInputs = props.aggregators[fullAggName]([])().numInputs || 0;
    if (numInputs !== 0) {
      fullAggName += ` of ${props.vals.slice(0, numInputs).join(', ')}`;
    }

    // rowKeys and colKeys have all the needed combinations of keys already
    // e.g. [[2015, 'EUR'], [2015, 'USD'], [2016, 'EUR'], [2017, 'EUR']]
    // even if there are more than 1 attribute in each group
    // so all that needs to happen is to produce for each object (which is an array) in rowKeys
    // an object with the joinedKey
    const rowNames = pivotData.props.rows; // ["Date (Year)", "accountName"]
    const colNames = pivotData.props.cols; // names of the fields that go to secondary category ["otherParty", "currency"]

    function joinKeys(keys) {
      return typeof keys === 'object' ? keys.join(String.fromCharCode(0)) : keys;
    }

    const mainCategory = rowNames.join(' ');
    const secondaryCategories = colKeys.map((colName) => joinKeys(colName));

    let data = [];

    // this ↓↓ has all unique values organised by field name, i.e.:
    // { 'Date (Year)': [2015, 2016, 2017, 2018, 2019],
    //   'accountName': ['DKB Giro', 'Festgeld', 'Girokonto', 'Kreditkarte', 'Sparkonto'], ...
    // }
    // the sequence of keys is the hierarchy structure, i.e.: first object goes on top level, then the next object goes as a child of the first object, etc.
    const rowKeysByName = rowNames.reduce(
      (prevRowName, currRowName, rowNameIdx) => ({
        ...prevRowName,
        [currRowName]: rowKeys.reduce((prevRowKey, currRowKey) => {
          if (!prevRowKey.includes(currRowKey[rowNameIdx])) prevRowKey.push(currRowKey[rowNameIdx]);
          return prevRowKey;
        }, []),
      }),
      {},
    );
    const colKeysByName = colNames.reduce(
      (prevColName, currColName, colNameIdx) => ({
        ...prevColName,
        [currColName]: colKeys.reduce((prevColKey, currColKey) => {
          if (!prevColKey.includes(currColKey[colNameIdx])) prevColKey.push(currColKey[colNameIdx]);
          return prevColKey;
        }, []),
      }),
      {},
    );

    // data comes as a flat array of objects

    // Step 1: generate an array of object with the main category as a key
    const mainArray = rowKeysByName[rowNames[0]].map((rowKey) => ({ id: rowKey }));
    console.log('DEBUG LineCgart mainArray', mainArray);

    console.log('DEBUG LineChart colKeys', colKeys);
    // Step 2: for each object in the main array, generate the data elements
    data = mainArray.map((rowKey) => ({
      ...rowKey,
      data: colKeys.map((x) => ({
        x: dayjs.utc(Number(joinKeys(x))).format('YYYY-MM-DD'),
        y: pivotData.getAggregator([rowKey.id], [joinKeys(x)]).value(),
      })),
    }));

    // line chart input object looks like that:
    // [ { "id": "japan", "data": [{ "x": "plane", "y": 223 }, { "x": "helicopter", "y": 126 }] },
    // { "id": "france", "data": [{ "x": "plane", "y": 149 }, { "x": "helicopter", "y": 281 }] }, ...]

    // Step 3: take data array and find the highest value from the value prop for each rowKey
    // this is needed to set the y-axis max value
    const maxValue = props.data.reduce((prev, quoteItem) => {
      if (quoteItem[props.vals[0]] > prev) return quoteItem.quote;
      return prev;
    }, 0);

    const basicUnit = 10 ** (String(maxValue).length - 1) / 2;

    const YValues = [];
    let val = 0;
    while (val <= maxValue + basicUnit) {
      YValues.push(val);
      val += basicUnit;
    }

    // TODO implement time scale on x:
    // xScale: {
    //   format: '%Y-%m-%d',
    //   precision: 'month',
    //   type: 'time',
    //   useUTC: true,
    // },

    let chartProps = {
      margin: {
        top: 36,
        right: 16,
        bottom: 36,
        left: 60,
      },
      colors,
      enableArea: true,
      areaOpacity: 0.1,
      enablePoints: false,
      // gridXValues: colKeys.length > 15 ? colKeys.filter((x, i) => i % parseInt(colKeys.length / 15, 10) === 0) : colKeys,
      // gridYValues: YValues,
      axisLeft: {
        format: (t) => t.toLocaleString('de', { maximumFractionDigits: 0 }),
        // tickValues: YValues,
      },
      // axisBottom: {
      // tickValues: colKeys.length > 15 ? colKeys.filter((x, i) => i % parseInt(colKeys.length / 15, 10) === 0) : colKeys,
      //   format: (t) => (dayjs.utc(Number(t.join(''))).isValid()
      //     ? dayjs
      //       .utc(Number(t.join('')))
      //       .format('MM–YYYY')
      //       .toString()
      //     : t),
      // },
      axisBottom: {
        format: '%Y', // TODO: make it dynamic (if more than 18 months, use years, otherwise year-month '%Y-%m')
        tickValues: 'every 1 year',
      },
      xFormat: 'time:%Y-%m-%d',
      xScale: {
        format: '%Y-%m-%d',
        precision: 'month',
        type: 'time',
        useUTC: true,
      },
      enableSlices: 'x',
      theme: { fontFamily: 'Lato' },
      padding: 0.2,
      labelTextColor: 'inherit:darker(1.4)',
      labelSkipWidth: 16,
      labelSkipHeight: 16,
    };

    // deep merge incoming layout options to prevent overwriting nested objects
    chartProps = merge(chartProps, props.layoutOptions);

    // DEBUG
    // data = [
    //   {
    //     id: 'test',
    //     data: [
    //       { x: }
    //     ]
    //   }
    // ]

    chartProps = {
      ...chartProps,
      data,
      indexBy: mainCategory,
      keys: secondaryCategories,
    };

    console.log('chartProps', chartProps);

    return <NivoChart {...chartProps} />;
  };
}

export default function createNivoRenderers() {
  return {
    'Line chart, horizontal': makeNivoRenderer(ResponsiveLine, { mode: 'horizontal' }),
  };
}
