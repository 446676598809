import calculatePortfolioAllocation from './calculatePortfolioAllocation';
import calculateDimensionAllocation from './calculateDimensionAllocation';
import getPortfolioDeltaFactors from './getPortfolioDeltaFactors';
import getDimensionDeltaFactors from './getDimensionDeltaFactors';

export const debugLevel = process.env.MDEBUG || 0;

// current allocation is an array of all positions
// target allocation is an array of all rows from the target allocation table
export default function* calculateRebalancing(currentAllocation, targetAllocation) {
  if (debugLevel > 2) console.info('calculateRebalancing: starting with currentAllocation', currentAllocation, 'and targetAllocation', targetAllocation);
  const portfolioAllocation = calculatePortfolioAllocation(currentAllocation, targetAllocation);
  const dimensionAllocation = calculateDimensionAllocation(targetAllocation, currentAllocation);

  const completeTargetAllocation = [...portfolioAllocation, ...dimensionAllocation];

  yield completeTargetAllocation;

  if (debugLevel > 2) console.info('calculateRebalancing: adjustment of target allocation completed for portfolio and dimension', completeTargetAllocation);

  const currentAllocationWithPortfolioFactor = getPortfolioDeltaFactors(currentAllocation, completeTargetAllocation);
  console.log('calculateRebalancing:', 'currentAllocationWithPortfolioFactor', currentAllocationWithPortfolioFactor);
  const currentAllocationWithBothFactors = getDimensionDeltaFactors(currentAllocationWithPortfolioFactor, completeTargetAllocation);
  console.log('calculateRebalancing:', 'currentAllocationWithBothFactors', currentAllocationWithBothFactors);

  yield currentAllocationWithBothFactors.map((row) => ({
    ...row,
    // was: row.amount (deprecated)
    targetAmount: row.current.valueBaseCurrency * (1 + row.categoryRebalancingAssetDeltaFactor) * (1 + row.dimensionRebalancingAssetDeltaFactor),
  }));
}
