import { API, Auth } from 'aws-amplify';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { setMessage } from '../../redux/actions/message';

dayjs.extend(utc);

export default async function getPurchaseValues(jRef, gridLayout, account, dispatch) {
  function getColIdxFromLayout(columnId) {
    if (!gridLayout) return null;
    return gridLayout.findIndex((item) => item.id === columnId);
  }

  try {
    // get data from spreadsheet (getData, returns array of array)
    const jData = jRef.current.jspreadsheet.getData();

    // convert data to object
    const columnNames = gridLayout.map((column) => column.id);
    const jDataObj = jData.map((row) => {
      const newRow = {};
      row.forEach((value, index) => {
        const columnName = columnNames[index];
        newRow[columnName] = value;
      });
      return newRow;
    });

    // identify which rows have all data (date, assetWeight, assetPurity, evtl. assetAdditionalValue || 0) and no price yet
    const quotesToGet = { assets: [], dates: [] };
    jDataObj.forEach((row, index) => {
      const { date, assetMetal, assetPurity, assetWeight, assetAdditionalValue, upac, transactionValue, uptc, transactionCurrencyValue } = row;
      if (date && assetMetal && assetPurity && assetWeight && !upac && !transactionValue && !uptc && !transactionCurrencyValue) {
        // criteria fulfilled, let us get the quote
        // TODO assetId index is all right?
        quotesToGet.assets.push({
          assetId: `row${index}`,
          providerAssetId: { assetMetal, assetMetalWeight: assetWeight * (assetPurity / 1000), assetAdditionalValue },
          currency: account.currency,
        });
        quotesToGet.dates.push(dayjs.utc(date).startOf('day').valueOf());
      }
    });

    // get quotes for that data (can be via direct api call, so that we bypass redux)
    let result;
    if (quotesToGet.assets.length > 0) {
      const session = await Auth.currentSession();
      const payload = {
        body: quotesToGet,
        headers: {
          'Content-Type': 'application/json',
          Authorization: session.idToken.jwtToken,
        },
      };

      result = await API.post('myAPI', 'quotes/get', payload); // getQuotes API expects { assets: [{ assetId, providerAssetId }], dates: [dates]}
      console.log('DEBUG result', result);

      // run setValue for the rows above
      result.quotes.forEach((quote, index) => {
        if (quote.quote) {
          const rowIndex = quote.assetId.split('row')[1];
          jRef.current.jexcel.setValueFromCoords(getColIdxFromLayout('upac'), rowIndex, quote.quote * ((account.spread || 0) / 100 + 1)); // purchase price, so with spread on top
          jRef.current.jexcel.setValueFromCoords(getColIdxFromLayout('uptc'), rowIndex, quote.quote * ((account.spread || 0) / 100 + 1));
          jRef.current.jexcel.setValueFromCoords(getColIdxFromLayout('transactionCurrency'), rowIndex, quote.currency);
        }
      });
    } else {
      dispatch(setMessage('gridGetPurchaseValuesNoRows'));
    }
    // if all rows had data and were updated
    window.dispatchEvent(new CustomEvent('handleGridGetPurchaseValuesCompleted', { outcome: (result.quotes || []).length > jData.length ? 'allRowsUpdated' : 'someRowsUpdated' }));
  } catch (error) {
    console.error('Grid > getPurchaseValue error', error);
    window.dispatchEvent(new CustomEvent('handleGridGetPurchaseValuesCompleted', { outcome: 'error' }));
  }
}
