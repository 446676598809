/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/forbid-prop-types */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ButtonBar from './ButtonBar';
import Table from './Table';
import getTableLayout from './tableLayouts';

export function getButtonsToShow(category, mode = 'transactions') {
  switch (category) {
    case 'stocks':
    case 'realEstate':
      if (mode === 'quotes') return ['tableEditSettings', 'import', 'hasWorth'];
      return ['tableEditSettings', 'import', 'hasWorth'];

    case 'objectsOfValue':
    case 'metals':
      return ['tableEditSettings', 'import', 'hasWorth'];

    case 'deposits':
    case 'loans':
      return ['tableEditSettings', 'syncImport'];

    case 'pension':
      return ['tableEditSettings', 'import', 'hasPayments'];

    case 'unlistedShares':
      if (mode === 'quotes') return ['tableValuationWizardSettings', 'hasWorth'];
      return ['tableTransactionWizardSettings', 'hasWorth'];

    default:
      return ['tableEditSettings', 'hasWorth'];
  }

  // const standardButtons = ['tableEditSettings'];
  // if (!['realEstate', 'loans', 'objectsOfValue', 'metals', 'unlistedShares'].includes(category)) standardButtons.push('syncImport');
  // if (['realEstate'].includes(category) && mode === 'quotes') standardButtons.push('import');
  // if (!['deposits', 'loans', 'pension'].includes(category)) standardButtons.push('hasWorth'); // everything but deposits, loans, pensions
  // if (['pension'].includes(category)) standardButtons.push('hasPayments'); // different transactions | quotes labels for products which do not have "what do you have"
  // return standardButtons;
}

// applies category-specific changes to data and passes them onwards;
// manages table layout
// props usage in propTypes below
export default function TableLayout({
  account,
  data,
  tableState,
  setTableState,
  mode,
  setDisplayedComponent,
  displayedComponentMode,
  setDisplayedComponentMode,
  handleSync,
  highlightTransactionId,
  accountSyncStatus,
}) {
  const tableLayout = getTableLayout(account.category, mode);

  return (
    <>
      <ButtonBar
        buttonsToShow={getButtonsToShow(account.category, displayedComponentMode)}
        displayedComponent="table"
        setDisplayedComponent={setDisplayedComponent}
        displayedComponentMode={displayedComponentMode}
        setDisplayedComponentMode={setDisplayedComponentMode}
        handleSync={handleSync}
        userChangesPresent={false}
        accountSyncStatus={accountSyncStatus}
      />
      <Table
        // data={(data || []).sort((a, b) => compare(a, b, tableState.sortBy, tableState.sortDirectionAsc))} // final sort as per tableState
        data={data || []}
        tableLayout={tableLayout}
        tableState={tableState}
        setTableState={setTableState}
        highlightTransactionId={highlightTransactionId}
      />
    </>
  );
}
TableLayout.propTypes = {
  account: PropTypes.object.isRequired, // to select table layout, sync function, buttons to show; also provided as context to BlueTransactionsDialog and TransactionContextMenu
  data: PropTypes.array.isRequired, // array of objects (each object should have exactly the same keys as there are columns in the table + an id)
  tableState: PropTypes.object.isRequired, // contains column widths, sorting, filtering, etc., has to be passed to e.g. TableHeaders to be able to change it
  setTableState: PropTypes.func.isRequired,
  mode: PropTypes.string.isRequired, // used to select table layout
  setDisplayedComponent: PropTypes.func.isRequired, // switch among table, form, and settings
  displayedComponentMode: PropTypes.string.isRequired, // switch between transactions and quotes
  setDisplayedComponentMode: PropTypes.func.isRequired,
  handleSync: PropTypes.func.isRequired, // passed from CategoryWrapper to ButtonBar
  highlightTransactionId: PropTypes.string, // coming from search function, hightlights a transaction in the table
  accountSyncStatus: PropTypes.bool.isRequired, // used to disable sync button
};
TableLayout.defaultProps = {
  highlightTransactionId: null,
};
